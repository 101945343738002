import React, { Component } from 'react'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading } from 'src/components/Text'
import PageMeta from 'src/components/PageMeta'
import { onboardConfirmInfoPath, onboardHaulEquipmentPath } from 'src/paths'
import OnboardingContainer from 'src/components/OnboardingContainer'
import AddHaulerForm from 'src/components/AddHaulerForm'
import OnboardingCounter from 'src/components/OnboardingCounter'
import { OnboardFormMaxWidth } from 'src/components/MaxWidth'
import Box from 'src/components/Box'

class OnboardAddHauler extends Component {
  render() {
    return (
      <OnboardingContainer>
        <PageMeta title="Onboard Add Hauler" />
        <OnboardingCounter position={2} backTo={onboardConfirmInfoPath()} />
        <HeadingContainer>
          <Heading>Tell us about your fleet.</Heading>
        </HeadingContainer>

        <Box mb={4}>
          <OnboardFormMaxWidth>
            <AddHaulerForm
              nextPath={onboardHaulEquipmentPath()}
              cancelPath={onboardConfirmInfoPath()}
              allowMultipleTrucks={true}
            />
          </OnboardFormMaxWidth>
        </Box>
      </OnboardingContainer>
    )
  }
}

export default OnboardAddHauler
