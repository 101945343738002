import React from 'react'
import Flex from 'src/components/Flex'
import Box from './Box'
import { BoxButton } from 'src/components/Button'
import withBackground from 'src/hocs/withBackground'
import Avatar from 'src/components/Avatar'
import { Body1, Body2 } from 'src/components/Text'
import styles from 'src/components/TruckCard.css'
import underlinedStyles from 'src/components/ButtonUnderlined.css'

/**
 * @typedef {Object} TCProps
 * @property {Haully.CarrierTruck} truck
 *
 * @param {TCProps} props
 */
export const TruckCard = ({ truck }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Flex>
      <Box pt={1} pb={1} mr={3}>
        <Avatar name={truck.quantity} bg="light-blue" color="white" />
      </Box>
      <div>
        <Body1 data-test="TruckCardLabel">
          {truck.isFlatBed ? 'Flatbed' : `${truck.capacity}-Car Hauler`}
        </Body1>
        <Body2 color="dark-gray">{truck.carrierEquipmentType}</Body2>
      </div>
    </Flex>
    <div className={underlinedStyles.ButtonUnderlinedH2}>Edit</div>
  </Flex>
)

/**
 * @typedef {Object} TCBProps
 * @property {(e: React.MouseEvent) => void} props.onClick
 * @property {string|undefined} [props.className]
 *
 * @param {TCBProps & TCProps} props
 */
const _TruckCardButton = ({ onClick, ...rest }) => (
  <BoxButton
    onClick={onClick}
    testID="TruckCard"
    className={styles.TruckCardButton}
  >
    <Box pt={1} pb={1} pl={3} pr={3}>
      <TruckCard {...rest} />
    </Box>
  </BoxButton>
)

export const TruckCardButton = withBackground(_TruckCardButton)
