import React from 'react'
import styles from './CloseButton.css'
import { PlainButton } from 'src/components/Button'
import XIcon from 'src/icons/X.svg'
import cn from 'classnames'

/** @param {{variant?: 'white'|'black'} & React.ComponentProps<typeof PlainButton>} props */
const CloseButton = ({ variant = 'white', title, className, ...props }) => (
  <PlainButton
    className={cn(className, styles.CloseButton, styles[`variant-${variant}`])}
    title={title || 'Close'}
    {...props}
  >
    <XIcon />
  </PlainButton>
)

export default CloseButton
