import React, { useState } from 'react'
import CloseButton from 'src/components/CloseButton'
import InputGroup from 'src/components/InputGroup'
import Container from 'src/components/Container'
import { Heading, TextContainer } from 'src/components/Text'
import { PrimaryButton } from 'src/components/Button'
import { Screen, ScreenBottom } from 'src/components/Screen'
import TextInput from 'src/components/TextInput'
import {
  OverlayHeader,
  OverlayHeaderRightButton,
} from 'src/components/OverlayHeader'
import { Column, Row } from 'src/components/Layout'
import styles from './SearchLoadNumber.css'
import { navigate } from '@reach/router'
import { loadPath } from 'src/paths'
import { useLoadStore } from 'src/hooks/useStores'

const SearchLoadNumber = ({ onClose }) => {
  const loadStore = useLoadStore()
  const [loadSearchInput, setLoadSearchInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const findLoad = async () => {
    setIsLoading(true)
    const load = await loadStore.fetchLoad(parseInt(loadSearchInput), false)
    setIsLoading(false)
    if (load) {
      if (load.assignedToCurrentCarrier || load.unAssigned)
        navigate(loadPath(load.id))
      else {
        setErrorMessage(
          `We're sorry, the load you are looking for has already been claimed.`
        )
      }
    } else {
      setErrorMessage(
        `We're sorry, the load you are looking for does not exist.`
      )
    }
  }
  return (
    <Screen className={styles.Screen}>
      <OverlayHeader>
        <Heading ml={2}>Search Load Number</Heading>
        <OverlayHeaderRightButton>
          <CloseButton onClick={onClose} />
        </OverlayHeaderRightButton>
      </OverlayHeader>

      <Container>
        <Row pt={3} pb={5}>
          <Column>
            <InputGroup>
              <TextInput
                id="loadNumber"
                name="loadNumber"
                value={loadSearchInput}
                onChange={e => setLoadSearchInput(e.target.value)}
                type="text"
                placeholder="Enter Load Number"
              />
            </InputGroup>
          </Column>
        </Row>
      </Container>

      <ScreenBottom p={3}>
        <PrimaryButton
          variant="primary"
          block={true}
          onClick={() => findLoad()}
          isLoading={isLoading}
        >
          Find Load
        </PrimaryButton>

        {errorMessage && <TextContainer mt={3}>{errorMessage}</TextContainer>}
      </ScreenBottom>
    </Screen>
  )
}

export default SearchLoadNumber
