import '@ungap/url-search-params'
import 'focus-visible'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import App from './App'
import ErrorReporter from './containers/ErrorReporter'
import './index.global.css'
import { env } from './util/env'

TagManager.initialize({
  gtmId: env('GOOGLE_ANALYTICS_ID'),
})

ReactDOM.render(
  <ErrorReporter>
    <App />
  </ErrorReporter>,
  document.getElementById('react-root')
)
