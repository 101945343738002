import { Observer } from 'mobx-react'
import React from 'react'
import Box from 'src/components/Box'
import Flex from 'src/components/Flex'
import NoResultsMessage from 'src/components/NoResultsMessage'
import PaginatedList from 'src/components/PaginatedList'
import Responsive from 'src/components/Responsive'
import { Option, Select } from 'src/components/Select'
import SelectLabelContainer from 'src/components/SelectLabelContainer'
import { LinkedLoadCardContainer } from 'src/containers/LoadCardContainer'
import useWindowSize from 'src/hooks/useWindowSize'
import { searchLoadPath } from 'src/paths'
import LoadsMap from 'src/screens/LoadsMap'

/**
 * @param {object} props
 * @param {{ label: string }[]} props.sortOptions
 * @param {number[]} props.sortOptionsDisabled
 * @param {(e: React.ChangeEvent<HTMLSelectElement>) => void} props.onSortChange
 * @param {number} props.sortValue
 * @param {any} props.searchLoads
 * @param {any} props.mapSearchLoads
 */
export const LoadSearchResults = ({
  searchLoads,
  mapSearchLoads,
  sortOptions,
  onSortChange,
  sortValue,
  sortOptionsDisabled,
}) => {
  const { isLarge } = useWindowSize()
  return (
    <Observer>
      {() => (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            border="bottom"
            borderWidth={2}
            bg="white"
            pl={3}
            pr={3}
            lmr={3}
          >
            {searchLoads.totalCount === 0 ? (
              <Flex justifyContent="center" flex={1} pb={3} pt={3}>
                <NoResultsMessage>No results</NoResultsMessage>
              </Flex>
            ) : (
              <>
                <div>
                  <span data-test="searchResultsTotalCount">
                    {searchLoads.totalCount}
                  </span>{' '}
                  LOADS
                </div>

                <Box pt={2} pb={2}>
                  <SelectLabelContainer label="SORT BY">
                    <Select value={sortValue} onChange={onSortChange}>
                      {sortOptions.map((option, i) => (
                        <Option
                          key={i}
                          value={i}
                          disabled={sortOptionsDisabled.includes(i)}
                        >
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </SelectLabelContainer>
                </Box>
              </>
            )}
          </Flex>

          {searchLoads.totalCount !== 0 && (
            <Flex pt={3} pb={5} pl={3} lpl={0} pr={3} bg="light-gray">
              <Flex
                style={{ flex: 2, maxWidth: isLarge ? 384 : undefined }}
                flexDirection="column"
              >
                <PaginatedList
                  paginatedRequestState={searchLoads}
                  emptyMessage={false}
                >
                  {searchLoads.collection.map(load => (
                    <Box key={load.id} mb={3}>
                      <LinkedLoadCardContainer
                        load={load}
                        to={searchLoadPath(load.id)}
                        preserveLocationSearch={true}
                      />
                    </Box>
                  ))}
                </PaginatedList>
              </Flex>
              {searchLoads.collection.length > 0 && (
                <Responsive
                  large={
                    <Box
                      style={{
                        minWidth: 300,
                        flex: 1,
                      }}
                    >
                      <Flex
                        pl={3}
                        style={{
                          height: 800,
                          maxHeight: 'calc(100vh - 32px)',
                          minWidth: 300,
                          flex: 1,
                          position: 'sticky',
                          top: 16,
                        }}
                      >
                        <LoadsMap loads={mapSearchLoads.collection.slice()} />
                      </Flex>
                    </Box>
                  }
                />
              )}
            </Flex>
          )}
        </>
      )}
    </Observer>
  )
}
