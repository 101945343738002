import { addDays, parse } from 'date-fns'
import PaginatedRequestState from 'src/models/PaginatedRequestState'
import { paginatedRequestCriteria } from 'src/util/paginatedRequest'
import Store from './Store'

// a hack to make generic types pass into the pagination container
// prettier-ignore
const LoadType = /** @type Haully.Load */ ({})

export default class BackhaulStore extends Store {
  /**
   * @param {Haully.Load} load
   * @returns {Promise<Haully.SearchParameters & Haully.SavedSearchParameters>}
   */
  backhaulSearchParameters = async load => {
    const radiusOptions = ['50', '100', '150', '200']
    const radiusOptionsToTest = radiusOptions.slice(0, radiusOptions.length - 1)
    const radiusOptionLast = radiusOptions.slice(-1)[0]

    const staticParams = {
      originGeoCodeLatitude: load.destinationLat,
      originGeoCodeLongitude: load.destinationLon,
      originCity: load.destinationCity,
      originState: load.destinationState,
      destinationGeoCodeLatitude: load.originLat,
      destinationGeoCodeLongitude: load.originLon,
      destinationCity: load.originCity,
      destinationState: load.originState,
      ...(load.latestDelivery && {
        availableFrom: parse(load.latestDelivery),
        availableThru: addDays(parse(load.latestDelivery), 3),
      }),
    }

    const radius = await findAsync(radiusOptionsToTest, async radius => {
      const search = new PaginatedRequestState(pageNumber =>
        paginatedRequestCriteria({
          path: 'CarrierLoads/search/available',
          method: 'POST',
          rootStore: this.rootStore,
          pageSize: 1,
          data: {
            criteria: {
              ...staticParams,
              originGeoCodeMileRadius: parseFloat(radius),
              destinationGeoCodeMileRadius: parseFloat(radius),
            },
          },
        })(pageNumber)
      )
      await search.fetchFirstPage()

      if (search.totalCount === null) return false
      return search.totalCount > 0
    })

    const radiusWithDefault = radius !== undefined ? radius : radiusOptionLast

    return {
      ...staticParams,
      originGeoCodeMileRadius: radiusWithDefault,
      destinationGeoCodeMileRadius: radiusWithDefault,
    }
  }

  /** @param {Haully.Load} load */
  backhauls = async load => {
    const params = await this.backhaulSearchParameters(load)
    return new PaginatedRequestState(
      pageNumber =>
        paginatedRequestCriteria({
          path: 'CarrierLoads/search/available',
          method: 'POST',
          rootStore: this.rootStore,
          pageSize: 12,
          data: { criteria: params },
        })(pageNumber),
      // @ts-ignore not supported by ts 3.8
      LoadType
    )
  }
}

/**
 * @param {T[]} collection
 * @param {(record: T) => Promise<boolean>} predicate
 * @template T
 */
const findAsync = async (collection, predicate) => {
  for (let i = 0; i < collection.length; i++) {
    const instance = collection[i]
    const predicateResult = await predicate(instance)

    if (predicateResult) {
      return instance
    }
  }
  return undefined
}
