import React from 'react'
import cn from 'classnames'
import styles from './Link.css'

/**
 * Link with a basic style reset
 *
 * @param {import('@reach/router').LinkProps} props
 */
export const Link = props => (
  <Link className={cn(props.className, styles.Link)} {...props} />
)

/**
 * Anchor with a basic style reset
 *
 * @param {React.AnchorHTMLAttributes<HTMLAnchorElement>} props
 */
export const Anchor = props => (
  <a className={cn(props.className, styles.Link)} {...props} />
)
