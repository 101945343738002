import Form from './Form'

const EMPTY_TRUCK = {
  quantity: '1',
  carrierTruckIdx: '', // translated into "capacity" and "isflatbed"
  carrierEquipmentType: 'Open',
}

class TruckForm {
  form = new Form({
    values: {
      fleetSize: '1 Truck',
      trucks: [EMPTY_TRUCK],
    },
    choices: {
      fleetSize: ['1 Truck', 'Many'],
      carrierEquipmentType: ['Open', 'Enclosed'],
    },
  })

  values = this.form.values
  reset = this.form.reset
  choices = this.form.choices

  update = values => {
    const previousValues = { ...this.values }

    this.form.update(values)

    if (
      values.fleetSize !== previousValues.fleetSize &&
      values.fleetSize === '1 Truck'
    ) {
      this.values.trucks = [
        {
          ...this.values.trucks[0],
          quantity: '1',
        },
      ]
    }
  }

  addTruck = () => {
    this.values.trucks = [...this.values.trucks, EMPTY_TRUCK]
  }

  removeTruck = index => {
    this.values.trucks = [
      ...this.values.trucks.slice(0, index),
      ...this.values.trucks.slice(index + 1),
    ]
  }
}

export default TruckForm
