import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { AssignedDriver } from 'src/components/AssignedDriver'
import Box from 'src/components/Box'
import CenteredContainer from 'src/components/CenteredContainer'
import Flex from 'src/components/Flex'
import LoadBackhaulsSection from 'src/components/LoadBackhaulsSection'
import PageMeta from 'src/components/PageMeta'
import { PickupDropoffDetails } from 'src/components/PickupDropoffDetails'
import RouteMapWithMaps from 'src/components/RouteMap'
import { Body1, Body2, Body3, H2, Title } from 'src/components/Text'
import Wrapper from 'src/components/Wrapper'
import { DetailLoadCardContainer } from 'src/containers/LoadCardContainer'
import useWindowSize from 'src/hooks/useWindowSize'
import isBlank from 'src/util/isBlank'

@inject('loadStore')
@observer
class LoadDetail extends Component {
  componentDidMount() {
    this.props.loadStore.fetchLoad(parseInt(this.props.loadId))
  }

  componentDidUpdate(prevProps) {
    if (this.props.loadId !== prevProps.loadId) {
      this.props.loadStore.fetchLoad(parseInt(this.props.loadId))
    }
  }

  render() {
    const { loadId } = this.props
    const load = this.props.loadStore.load(parseInt(loadId))

    return (
      <Wrapper>
        <PageMeta title={`Load ${loadId}`} />
        {load && (
          <>
            <DetailLoadCardContainer load={load} />

            {load.assignedToCurrentCarrier && (
              <LoadAssignedToCarrierDetails load={load} />
            )}
            <LoadDetails load={load} />
          </>
        )}
      </Wrapper>
    )
  }
}

/**
 * @param {object} props
 * @param {Haully.LoadDetailed} props.load
 */
const LoadDetails = ({ load }) => {
  const { isLarge } = useWindowSize()
  const noBorderOnLarge = isLarge ? undefined : 'bottom'

  return (
    <CenteredContainer width="900">
      <Flex flexDirection={isLarge ? 'row' : 'column'}>
        <Flex p={3} border={noBorderOnLarge} flex={1} flexDirection="column">
          <PickupDropoff
            label="Pick-Up"
            name={load.originName}
            address={load.originAddress}
            city={load.originCity}
            state={load.originState}
            zip={load.originZip}
            hours={load.originBusinessHours}
          />
        </Flex>
        <Flex p={3} border={noBorderOnLarge} flex={1} flexDirection="column">
          <PickupDropoff
            label="Drop-Off"
            name={load.destinationName}
            address={load.destinationAddress}
            city={load.destinationCity}
            state={load.destinationState}
            zip={load.destinationZip}
            hours={load.destinationBusinessHours}
          />
        </Flex>
      </Flex>

      <Flex flexDirection={isLarge ? 'row' : 'column'}>
        <Flex p={3} flexDirection="column" flex={isLarge ? 1 : undefined}>
          <VehicleList vehicles={load.vehicles} />

          {isLarge && (
            <Flex flex={1} lmt={3}>
              <LoadBackhaulsSection load={load} />
            </Flex>
          )}
        </Flex>

        <Flex flexDirection="column" flex={isLarge ? 1 : undefined}>
          {!isBlank(load.originComments) && (
            <Box p={3} lpl={0} border={noBorderOnLarge}>
              <H2 mb={2}>ORIGIN INSTRUCTIONS AND NOTES</H2>
              <Body3>{load.originComments}</Body3>
            </Box>
          )}

          {!isBlank(load.destinationComments) && (
            <Box p={3} lpl={0} border={noBorderOnLarge}>
              <H2 mb={2}>DESTINATION INSTRUCTIONS AND NOTES</H2>
              <Body3>{load.destinationComments}</Body3>
            </Box>
          )}

          {isLarge && (
            <>
              <Flex flex={1}>
                <RouteMapWithMaps load={load} />
              </Flex>
            </>
          )}
        </Flex>
      </Flex>

      {!isLarge && (
        <Flex flexDirection={isLarge ? 'row' : 'column'} lmb={4}>
          <Flex
            flex={1}
            style={{ width: isLarge ? '50%' : '100%' }}
            lpr={4}
            border="bottom"
          >
            <LoadBackhaulsSection load={load} />
          </Flex>
          <Flex flex={1}>
            <RouteMapWithMaps load={load} />
          </Flex>
        </Flex>
      )}
    </CenteredContainer>
  )
}

/**
 * @param {object} props
 * @param {Haully.Vehicle[]} props.vehicles
 */
const VehicleList = ({ vehicles }) => (
  <div>
    <H2 mb={2}>CARS</H2>
    {vehicles.map((vehicle, i) => (
      <Body3 key={i} color={vehicle.inoperable ? 'red' : undefined} mb={2}>
        {vehicle.year} {vehicle.make} {vehicle.model} {vehicle.subModel}
      </Body3>
    ))}
  </div>
)

/**
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.name
 * @param {string} props.address
 * @param {string} props.city
 * @param {string} props.state
 * @param {string} props.zip
 * @param {string|null} props.hours
 */
const PickupDropoff = ({ label, name, address, city, state, zip, hours }) => (
  <>
    <H2 mb={1}>{label.toUpperCase()}</H2>
    <Title mb={2}>{name}</Title>
    <Box mb={2}>
      <Body1>{address}</Body1>
      <Body1>
        {city}, {state} {zip}
      </Body1>
    </Box>
    {hours && hours.split('\n').map((text, i) => <Body2 key={i}>{text}</Body2>)}
  </>
)

/**
 * @param {object} props
 * @param {Haully.LoadDetailed} props.load
 */
const LoadAssignedToCarrierDetails = ({ load }) => {
  const { isLarge } = useWindowSize()

  return (
    <CenteredContainer width="900">
      <Flex
        flexDirection={isLarge ? 'row' : 'column'}
        border={isLarge ? 'bottom' : undefined}
      >
        <Flex border={isLarge ? 'right' : 'bottom'} flex={1}>
          <PickupDropoffDetails load={load} />
        </Flex>
        <Flex border={isLarge ? undefined : 'bottom'} flex={1}>
          <AssignedDriver load={load} />
        </Flex>
      </Flex>
    </CenteredContainer>
  )
}

export default LoadDetail
