import React from 'react'
import styles from './Avatar.css'
import Initials from 'src/components/Initials'
import cn from 'classnames'
import withColor from 'src/hocs/withColor'
import withBackground from 'src/hocs/withBackground'

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {string} [props.className]
 * @param {"default"|"desktopNav"|"scaleable"} [props.size]
 */
const Avatar = ({ className, name, size = 'default' }) => (
  <div
    className={cn(className, styles.Avatar, styles[`size-${size}`])}
    aria-hidden={true}
  >
    {name.length > 2 ? <Initials name={name} /> : name}
  </div>
)

export default withColor(withBackground(Avatar))
