import RootStore from 'src/stores/RootStore'
import { stringify } from 'qs'
import lscache from 'lscache'

// use seconds for cache expiry
lscache.setExpiryMilliseconds(1000)

/**
 * @typedef PaginatedRequestProps
 * @property {string} path
 * @property {RootStore} rootStore
 * @property {'GET'|'PATCH'|'PUT'|'POST'} [method]
 * @property {any} [queryParams]
 * @property {any} [data]
 * @property {number} [pageSize]
 * @property {(data: any, paginationParams: any) => any} [mergePostData]
 * @property {boolean|undefined} [cache]
 */

/** @param {PaginatedRequestProps} params */
export const paginatedRequest = ({
  path,
  queryParams = {},
  data,
  method = 'GET',
  pageSize = 20,
  mergePostData = (data, paginationParams) => ({
    ...data,
    ...paginationParams,
  }),
  rootStore,
  cache,
}) => {
  if (path.includes('?')) {
    throw new Error('path cannot include ?, use queryParams')
  }

  return (
    /** @param {number} pageNumber */
    async pageNumber => {
      const paginationParams = {
        pageNumber,
        pageSize: pageSize,
      }

      const queryParamData = {
        ...queryParams,
        ...(method === 'GET' && paginationParams),
      }

      const dataWithPagination =
        data && method !== 'GET'
          ? mergePostData(data, paginationParams)
          : undefined

      const queryParamString = stringify(queryParamData, {
        addQueryPrefix: true,
      })

      const requestKey = `v1::${method}::${path}::${queryParamString}::${JSON.stringify(
        dataWithPagination
      )}`

      // Getting data from cache
      const cachedResponse = cache ? lscache.get(requestKey) : null

      // If response is cached, return it
      if (cachedResponse) {
        console.log('[CACHE] Returning cached response for', requestKey)
        return cachedResponse
      }

      // Make request and cache data for 60 seconds
      const response = await rootStore.apiRequest(
        `${path}${queryParamString}`,
        {
          method: method,
        },
        dataWithPagination
      )

      if (cache) {
        console.log('[CACHE] Caching response for', requestKey)
        // Cache response for 10 minutes
        lscache.set(requestKey, response, 60 * 10)
      }

      return response
    }
  )
}

/** @param {PaginatedRequestProps} params */
export const paginatedRequestCriteria = params => {
  const mergePostData = (data, paginationParams) => {
    const { criteria, ...rest } = data || { criteria: {} }
    const dataWithPagination = {
      ...rest,
      criteria: {
        ...criteria,
        ...paginationParams,
      },
    }
    return dataWithPagination
  }
  return paginatedRequest({ ...params, mergePostData })
}
