import { Location } from '@reach/router'
import cn from 'classnames'
import React, { useState, useEffect } from 'react'
import FlashMessages from 'src/containers/FlashMessages'
import useWindowSize from 'src/hooks/useWindowSize'
import ArrowLeft from 'src/icons/ArrowLeft.svg'
import ArrowRight from 'src/icons/ArrowRight.svg'
import ChevronLeft from 'src/icons/ChevronLeft.svg'
import ChevronRight from 'src/icons/ChevronRight.svg'
import Box from './Box'
import { BlockButton } from './Button'
import Flex from './Flex'
import LoginModal from './LoginModal'
import styles from './MarketingContainer.css'
import MarketingNavigation from './MarketingNavigation'
import MarketingNavigationDesktop from './MarketingNavigationDesktop'
import Modal from './Modal'
import Responsive from './Responsive'
import { Body1, Body2, H2Bold } from './Text'
import { useAnnouncements } from './useAnnouncements'
import AnnouncementLink from './AnnouncementLink'

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string?} [props.className]
 */
const MarketingContainer = ({ children, className }) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

  return (
    <div className={cn(styles.Wrapper, className)}>
      <Responsive
        large={
          <MarketingNavigationDesktop
            onRequestLogin={() => setIsLoginModalOpen(true)}
          />
        }
      >
        <MarketingNavigation onRequestLogin={() => setIsLoginModalOpen(true)} />
      </Responsive>

      <Annoucements />

      <Location>
        {({ location }) => <FlashMessages currentUrl={location.href} />}
      </Location>
      <div className={styles.WrapperContainer}>
        <div className={styles.WrapperBody}>{children}</div>
      </div>

      <LoginModal
        isOpen={isLoginModalOpen}
        onRequestClose={() => setIsLoginModalOpen(false)}
      />
    </div>
  )
}

export default MarketingContainer

const Annoucements = () => {
  const { announcements: allAnnouncements } = useAnnouncements()
  const announcements = allAnnouncements.filter(
    a => a.targetType === 'CIHeader'
  )

  const [activeAnnouncementIndex, setActiveAnnoucementIndex] = useState(0)

  const announcementPosition = Math.abs(
    activeAnnouncementIndex % announcements.length
  )
  const announcement =
    announcements.length > 0 ? announcements[announcementPosition] : undefined

  const [isDetailsVisible, setIsDetailsVisible] = useState(false)
  const { isMedium } = useWindowSize()

  useEffect(() => {
    if (announcements.length <= 1) return
    if (!announcement) return
    if (!announcement.rotationTimeInSeconds) return

    const timerId = window.setTimeout(() => {
      setActiveAnnoucementIndex(prev => prev + 1)
    }, announcement.rotationTimeInSeconds * 1000)

    return () => {
      window.clearTimeout(timerId)
    }
  }, [announcement, announcements.length])

  if (!announcement) return null

  return (
    <>
      <Flex
        flexDirection={isMedium ? 'row' : 'column'}
        bg="black"
        border="bottom"
        color="white"
      >
        <Flex
          alignItems="center"
          style={{
            flex: 1,
            textAlign: 'center',
            paddingLeft: isMedium
              ? 74 + (announcement.details ? 134 : 0) + 16
              : undefined,
          }}
        >
          <Box p={3} style={{ flex: 1 }}>
            {announcement.title}
          </Box>
        </Flex>
        <Flex alignItems="center">
          {announcement.details && (
            <Flex p={1}>
              <BlockButton
                bg="transparent"
                color="light-blue"
                onClick={() => setIsDetailsVisible(x => !x)}
                style={{ paddingLeft: 12, height: 30 }}
              >
                <Box mr={1}>View Details</Box>
                <ChevronRight style={{ height: 14 }} />
              </BlockButton>
            </Flex>
          )}
          {announcements.length > 1 && (
            <Box p={1} style={{ marginLeft: 'auto' }}>
              <BlockButton
                bg="transparent"
                mr={1}
                onClick={() => {
                  setActiveAnnoucementIndex(x => x - 1)
                  setIsDetailsVisible(false)
                }}
                style={{ padding: 8, height: 30 }}
              >
                <ArrowLeft />
              </BlockButton>
              <BlockButton
                bg="transparent"
                onClick={() => {
                  setActiveAnnoucementIndex(x => x + 1)
                  setIsDetailsVisible(false)
                }}
                style={{ padding: 8, height: 30 }}
              >
                <ArrowRight />
              </BlockButton>
            </Box>
          )}
        </Flex>
      </Flex>
      <Modal
        isOpen={isDetailsVisible}
        contentBackground="white"
        onRequestClose={() => setIsDetailsVisible(false)}
      >
        {isDetailsVisible && (
          <Box p={3}>
            <H2Bold color="light-blue" mb={2}>
              HAULLY NOTIFICATIONS
            </H2Bold>
            <Body1 mb={2}>{announcement.title}</Body1>
            <Body2 mb={3} style={{ whiteSpace: 'pre-wrap', color: '#535353' }}>
              {announcement.details}
            </Body2>
            <AnnouncementLink announcement={announcement} />

            {announcements.length > 1 && (
              <Flex justifyContent="center">
                <Flex
                  alignItems="center"
                  style={{
                    border: '1px solid #eee',
                    borderRadius: 30,
                    overflow: 'hidden',
                    width: 'auto',
                  }}
                >
                  <Flex mr={1}>
                    <BlockButton
                      bg="transparent"
                      style={{ paddingLeft: 16, paddingRight: 16 }}
                      onClick={() => {
                        setActiveAnnoucementIndex(x => x - 1)
                      }}
                      aria-label="Previous"
                    >
                      <ChevronLeft style={{ height: 12 }} />
                    </BlockButton>
                  </Flex>
                  <Body2 pl={2} pr={2}>
                    {announcementPosition + 1} of {announcements.length}
                  </Body2>
                  <BlockButton
                    style={{ paddingLeft: 16, paddingRight: 16 }}
                    bg="transparent"
                    onClick={() => {
                      setActiveAnnoucementIndex(x => x + 1)
                    }}
                    aria-label="Next"
                  >
                    <ChevronRight style={{ height: 12 }} />
                  </BlockButton>
                </Flex>
              </Flex>
            )}
          </Box>
        )}
      </Modal>
    </>
  )
}
