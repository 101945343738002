import React, { useEffect } from 'react'
import styles from './DropdownMenu.css'
import { BoxButton } from './Button'
import cn from 'classnames'
import { ESC_KEY } from 'src/data/keys'

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {boolean} props.isOpen
 * @param {() => void} [props.onRequestClose]
 */
export const DropdownMenu = ({ children, onRequestClose, isOpen }) => {
  const focusRef = React.createRef()

  useEffect(() => {
    if (!focusRef.current) return
    if (isOpen) focusRef.current.focus()
  }, [focusRef, isOpen])

  if (!isOpen) return null

  return (
    <div
      tabIndex={-1}
      className={styles.DropdownMenu}
      ref={focusRef}
      onKeyDown={e => {
        if (e.keyCode == ESC_KEY) {
          e.stopPropagation()
          onRequestClose && onRequestClose()
        }
      }}
      onBlur={e => {
        const isRelatedChild =
          e.relatedTarget instanceof HTMLElement &&
          e.currentTarget.contains(e.relatedTarget)

        if (!isRelatedChild) {
          setTimeout(() => onRequestClose(), 100)
        }
      }}
    >
      {children}
    </div>
  )
}

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.testID]
 * @param {boolean} [props.isLast]
 * @param {(e: React.MouseEvent<any, MouseEvent>) => void} [props.onClick]
 */
export const DropdownMenuItem = ({
  children,
  isLast = false,
  onClick = () => {},
  testID,
}) => (
  <BoxButton
    className={cn(
      styles.DropdownMenuItem,
      isLast && styles.DropdownMenuItemLast
    )}
    onClick={onClick}
    data-test={testID}
  >
    {children}
  </BoxButton>
)
