import React from 'react'
import styles from './SelectLabelContainer.css'

/**
 * @param {{ label: string } & React.HTMLAttributes} props
 */
const SelectLabelContainer = ({ label, children, ...props }) => (
  <label className={styles.SelectLabelContainer} {...props}>
    <div className={styles.label}>{label}:</div>
    {children}
  </label>
)

export default SelectLabelContainer
