import React from 'react'
import styles from './LocationSearchSection.css'

/** @param {React.ButtonHTMLAttributes<HTMLButtonElement>} props */
export const LocationSearchSectionButton = props => (
  <button className={styles.LocationSearchSectionButton} {...props} />
)

/** @param {React.ButtonHTMLAttributes<HTMLButtonElement>} props */
export const LocationSearchIconSectionButton = props => (
  <button className={styles.LocationSearchIconSectionButton} {...props} />
)

/** @param {React.HTMLAttributes<HTMLDivElement>} props */
export const LocationSearchHeader = props => (
  <div className={styles.LocationSearchHeader} {...props} />
)
