import { format } from 'date-fns'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import CenteredContainer from 'src/components/CenteredContainer'
import { LoadBasicCard } from 'src/components/LoadCard'
import LoadingScreen from 'src/components/Loading'
import PageMeta from 'src/components/PageMeta'
import { Body1 } from 'src/components/Text'
import Wrapper from 'src/components/Wrapper'
import LoadPickupDropoffForm from 'src/containers/LoadPickupDropoffForm'
import { INPUT_DATE, INPUT_TIME } from 'src/data/dateFormats'
import { loadPath } from 'src/paths'

/**
 * @typedef Props
 * @property {import('src/stores/LoadStore').default} loadStore
 * @property {import('src/stores/FlashMessageStore').default} flashMessageStore
 * @property {string} loadId
 */

/**
 * @augments {Component<Props & import('@reach/router').History>}
 */
@inject('loadStore', 'flashMessageStore')
@observer
class LoadEditPickupDropoff extends Component {
  state = {
    isLoading: true,
  }

  async componentDidMount() {
    const { loadStore } = this.props
    const form = loadStore.loadPickupDeliveryForm
    form.reset()

    const load = await loadStore.fetchLoad(parseInt(this.props.loadId))

    if (!load) throw new Error(`Load ${this.props.loadId} not found`)

    form.update({
      pickupDate: safeFmt(load.scheduledPickup, INPUT_DATE),
      pickupTime: safeFmt(load.scheduledPickup, INPUT_TIME),
      deliveryDate: safeFmt(load.scheduledDelivery, INPUT_DATE),
      deliveryTime: safeFmt(load.scheduledDelivery, INPUT_TIME),
    })

    this.setState({ isLoading: false })
  }

  handleSubmit = async () => {
    const loadId = parseInt(this.props.loadId)
    if (await this.props.loadStore.updateLoadDates(loadId)) {
      this.props.flashMessageStore.queue(
        `Updated pick-up and drop-off successfully`
      )
      this.props.navigate(loadPath(loadId))
    }
  }

  render() {
    const loadId = parseInt(this.props.loadId)
    const load = this.props.loadStore.load(loadId)

    if (this.state.isLoading) return <LoadingScreen />
    if (!load) throw new Error('invariant')

    return (
      <Wrapper>
        <PageMeta title={`Claim Load ${load.id}`} />
        <LoadBasicCard load={load} />
        <CenteredContainer width="900">
          <Box p={3}>
            <Body1 mb={4}>Set your pick-up and drop-off times.</Body1>
            <LoadPickupDropoffForm
              loadStore={this.props.loadStore}
              load={load}
              onSubmit={this.handleSubmit}
            />
          </Box>
        </CenteredContainer>
      </Wrapper>
    )
  }
}

/**
 * @param {string | null} date
 * @param {string} fmt
 */
const safeFmt = (date, fmt) => {
  if (!date) return ''
  return format(date, fmt)
}

export default LoadEditPickupDropoff
