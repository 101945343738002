import { isAfter, isWithinRange, startOfDay, subDays, endOfDay } from 'date-fns'

/**
 * @param {Haully.LoadDetailed} load
 * @param {string|Date} [time]
 */
export const isPickupMissed = (load, time = new Date()) => {
  if (load.orderStatus === 'PendingPickup' && load.scheduledPickup != null) {
    return isAfter(time, endOfDay(load.scheduledPickup))
  }
  return false
}

/**
 * @param {Haully.LoadDetailed} load
 * @param {string|Date} [time]
 */
export const isDeliveryMissed = (load, time = new Date()) => {
  if (load.orderStatus === 'Delivered') return false
  if (load.scheduledDelivery == null) return false
  return isAfter(time, endOfDay(load.scheduledDelivery))
}

/**
 * @param {number} days
 * @param {Haully.LoadDetailed} load
 * @param {string|Date} [time]
 */
export const isPickupWithinDays = (days, load, time = new Date()) => {
  if (load.orderStatus !== 'PendingPickup') return false
  if (!load.scheduledPickup) return false

  const min = startOfDay(subDays(load.scheduledPickup, days))
  const max = load.scheduledPickup

  return isWithinRange(time, min, max)
}

/**
 * @param {number} days
 * @param {Haully.LoadDetailed} load
 * @param {string|Date} [time]
 */
export const isDeliveryWithinDays = (days, load, time = new Date()) => {
  if (load.orderStatus !== 'InRoute') return false
  if (!load.scheduledDelivery) return false

  const min = startOfDay(subDays(load.scheduledDelivery, days))
  const max = load.scheduledDelivery

  return isWithinRange(time, min, max)
}
