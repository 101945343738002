import React from 'react'
import Box from './Box'
import Flex from './Flex'
import { Title } from './Text'
import { LinkUnderlinedH2 } from './ButtonUnderlined'
import { loadBackhaulPath } from 'src/paths'
import LoadBackhaulSection from 'src/containers/LoadBackhaulSection'
import CenteredContainer from './CenteredContainer'

/**
 * @param {object} props
 * @param {Haully.Load} props.load
 */
const LoadBackhaulsSection = ({ load }) => {
  return (
    <Box bg="light-gray-blue" style={{ width: '100%' }}>
      <CenteredContainer width="900">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pl={3}
          pr={3}
          pt={3}
          lmb={3}
        >
          <Title mr={3}>Find Backhaul</Title>
          <LinkUnderlinedH2 to={loadBackhaulPath(load.id)}>
            VIEW ALL
          </LinkUnderlinedH2>
        </Flex>
        <LoadBackhaulSection load={load} />
      </CenteredContainer>
    </Box>
  )
}

export default LoadBackhaulsSection
