import React from 'react'
import styles from './DaySelectorButton.css'
import CalendarIcon from 'src/icons/Calendar.svg'

/**
 * @param {*} props
 */
export const DaySelectorButton = props => (
  <button type="button" className={styles.DaySelectorButton} {...props} />
)

/** @param {*} props */
export const DaySelectorButtonLabel = ({ children, ...props }) => (
  <div className={styles.DaySelectorButtonLabel} {...props}>
    <CalendarIcon />
    <div className={styles.DaySelectorButtonText}>{children}</div>
  </div>
)
