import React from 'react'
import { LinkUnderlinedH2 } from 'src/components/ButtonUnderlined'
import { Column, Row } from 'src/components/Layout'
import { Body2, H2 } from 'src/components/Text'
import AssignedDriverContainer from 'src/containers/AssignedDriverContainer'
import { loadEditDriverPath } from 'src/paths'

/** @param {{ load: Haully.LoadDetailed }} _props */
export const AssignedDriver = ({ load }) => (
  <Row p={3}>
    <Column>
      <H2>ASSIGNED DRIVER</H2>
      {load.driverId === null ? (
        <Body2>No assigned driver</Body2>
      ) : (
        <AssignedDriverContainer driverId={load.driverId} />
      )}
    </Column>

    <Column width="auto">
      <LinkUnderlinedH2 to={loadEditDriverPath(load.id)}>Edit</LinkUnderlinedH2>
    </Column>
  </Row>
)
