import Store from './Store'
import { observable, computed } from 'mobx'

export default class FlashMessageStore extends Store {
  /** @type {import('mobx').IObservableArray<string>} */
  _current = observable.array([])

  /** @type {import('mobx').IObservableArray<string>} */
  _next = observable.array([])

  /**
   * Display a flash message immediately
   * @param {string} message
   */
  add = message => this._current.push(message)

  /**
   * Display a flash message after a redirect
   *
   * @param {string} message
   */
  queue = message => this._next.push(message)

  /** @param {number} position */
  dismiss = position => {
    this._current.splice(position, 1)
  }

  next = () => {
    this._current.replace(this._next)
    this._next.clear()
  }

  @computed
  get messages() {
    return this._current
  }
}
