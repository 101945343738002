import { observable } from 'mobx'
import FilterForm from 'src/models/FilterForm'
import Form from 'src/models/Form'
import PaginatedRequestState from 'src/models/PaginatedRequestState'
import {
  emailActionBasic,
  emailActionPaginated,
  tsvDownloadAction,
} from 'src/util/apiActions'
import openBlob from 'src/util/openBlob'
import { paginatedRequestCriteria } from 'src/util/paginatedRequest'
import Store from './Store'
import lscache from 'lscache'

// a hack to make generic types pass into the pagination container
/** @type Haully.Load */
let LoadType
/** @type Haully.LoadDetailed */
let LoadDetailedType

export default class LoadStore extends Store {
  /** @type {import('mobx').ObservableMap<Haully.LoadId, Haully.LoadDetailed>} */
  loads = observable.map([])

  @observable
  searchForm = new Form({
    /** @type {Haully.SearchParameters & Haully.SavedSearchParameters & { sortKey: string}} */
    values: {
      originGeoCodeLatitude: undefined,
      originGeoCodeLongitude: undefined,
      originCity: '',
      originState: '',
      originGeoCodeMileRadius: '50',
      destinationGeoCodeLatitude: undefined,
      destinationGeoCodeLongitude: undefined,
      destinationCity: '',
      destinationState: '',
      destinationGeoCodeMileRadius: '50',
      minPricePerMile: undefined,
      maxVehicles: undefined,
      maxWeightLbs: undefined,
      enclosedTruckRequired: false,
      availableFrom: undefined,
      availableThru: undefined,
      origins: [],
      destinations: [],
      inOperableVehiclesQueryType: 'Any',
      sortField: /** @type {"AvailableDate"} */ ('AvailableDate'),
      sortAscending: false,
      sortKey: '0',
    },
    choices: { sortOptions: FIND_LOADS_SORT_OPTIONS },
  })

  @observable
  loadPickupDeliveryForm = new Form({
    values: {
      pickupDate: '',
      pickupTime: '12:00',
      deliveryDate: '',
      deliveryTime: '12:00',
    },
  })

  @observable
  recommendedLoads = null

  manageLoadFilterForm = new FilterForm()

  loadsTsvDownloadAction = tsvDownloadAction(this.rootStore.apiRequest)
  loadsEmailActionPaginated = emailActionPaginated(this.rootStore.apiRequest)
  loadsEmailActionBasic = emailActionBasic(this.rootStore.apiRequest)

  favoriteLoads = new PaginatedRequestState(
    paginatedRequestCriteria({
      path: 'CarrierLoads/list/favorites',
      rootStore: this.rootStore,
      method: 'POST',
      data: { criteria: {} },
    }),
    // @ts-ignore not supported by ts 3.8
    LoadType
  )

  favoriteLoadsDownload = this.loadsTsvDownloadAction({
    apiPath: 'CarrierLoads/list/favorites',
    filename: 'Favorite Loads.csv',
    // method: 'GET',
  })

  favoriteLoadsEmail = this.loadsEmailActionPaginated({
    apiPath: 'CarrierLoads/list/favorites',
  })

  dateRangeFilterForStore = () => {
    const { from, to } = this.manageLoadFilterForm.dateDropdownParams()
    return { deliveredFrom: from, deliveredTo: to }
  }

  completedLoadsParameters = () => ({
    criteria: {
      sortField: 'DeliveryDate',
      sortAscending: false,
      driverId: this.manageLoadFilterForm.values.driverId,
      ...this.dateRangeFilterForStore(),
    },
  })

  /**
   * https://popups.vtvsolutions.com/Oviss/swagger/ui/index#!/CarrierLoads/CarrierLoads_ListCompleted
   */
  completedLoads = new PaginatedRequestState(
    pageNumber =>
      paginatedRequestCriteria({
        path: 'CarrierLoads/list/delivered',
        method: 'POST',
        rootStore: this.rootStore,
        data: this.completedLoadsParameters(),
      })(pageNumber),
    // @ts-ignore not supported by ts 3.8
    LoadType
  )

  completedLoadsDownload = this.loadsTsvDownloadAction({
    apiPath: 'CarrierLoads/list/delivered',
    data: this.completedLoadsParameters(),
    filename: 'Completed Loads.csv',
  })

  completedLoadsEmail = this.loadsEmailActionPaginated({
    apiPath: 'CarrierLoads/list/delivered',
    data: this.completedLoadsParameters(),
  })

  claimedLoadsParameters = () => ({
    criteria: {
      sortField: 'AvailableDate', // alternatively "CarrierAssinged" but results didn't look right, "DeliveryDate" -> 500 error
      sortAscending: false,
      ...this.manageLoadFilterForm.dateRangeParams(
        this.manageLoadFilterForm.values.dateRange
      ),
      driverId: this.manageLoadFilterForm.values.driverId,
    },
  })

  /**
   * https://popups.vtvsolutions.com/Oviss/swagger/ui/index#!/CarrierLoads/CarrierLoads_ListClaimed
   */
  claimedLoads = new PaginatedRequestState(
    pageNumber =>
      paginatedRequestCriteria({
        path: 'CarrierLoads/list/pendingPickup',
        rootStore: this.rootStore,
        method: 'POST',
        data: this.claimedLoadsParameters(),
      })(pageNumber),
    // @ts-ignore not supported by ts 3.8
    LoadDetailedType
  )

  claimedLoadsDownload = this.loadsTsvDownloadAction({
    data: this.claimedLoadsParameters(),
    apiPath: 'CarrierLoads/list/pendingPickup',
    filename: 'Claimed Loads.csv',
  })

  claimedLoadsEmail = this.loadsEmailActionPaginated({
    data: this.claimedLoadsParameters(),
    apiPath: 'CarrierLoads/list/pendingPickup',
  })

  inProgressLoadsParameters = () => ({
    criteria: {
      sortField: 'AvailableDate',
      sortAscending: false,
      driverId: this.manageLoadFilterForm.values.driverId,
    },
  })

  /**
   * https://popups.vtvsolutions.com/Oviss/swagger/ui/index#!/CarrierLoads/CarrierLoads_ListInProgress
   */
  inProgressLoads = new PaginatedRequestState(
    pageNumber =>
      paginatedRequestCriteria({
        path: 'CarrierLoads/list/inRoute',
        rootStore: this.rootStore,
        method: 'POST',
        data: this.inProgressLoadsParameters(),
      })(pageNumber),
    // @ts-ignore not supported by ts 3.8
    LoadDetailedType
  )

  inProgressLoadsDownload = this.loadsTsvDownloadAction({
    data: this.inProgressLoadsParameters(),
    apiPath: 'CarrierLoads/list/inRoute',
    filename: 'In Progress Loads.csv',
  })

  inProgressLoadsEmail = this.loadsEmailActionPaginated({
    data: this.inProgressLoadsParameters(),
    apiPath: 'CarrierLoads/list/inRoute',
  })

  /**
   * https://popups.vtvsolutions.com/Oviss/swagger/ui/index#!/CarrierLoads/CarrierLoads_PostSearchAvailable
   * @param {number|null|undefined} [pageSize]
   * @param {any} [params]
   */
  loadSearchRequestState = (pageSize = 20, params) =>
    new PaginatedRequestState(page => {
      const {
        minPricePerMile,
        maxVehicles,
        maxWeightLbs,
        originGeoCodeMileRadius,
        destinationGeoCodeMileRadius,
        ...rest
      } = this.searchForm.values

      // only include geo radius when we're searching by lat lng
      // turn advanced search fields into Floats
      const data = {
        minPricePerMile: numberField(minPricePerMile),
        maxVehicles: numberField(maxVehicles),
        maxWeightLbs: numberField(maxWeightLbs),
        ...rest,
        ...(rest.originGeoCodeLatitude != null && {
          originGeoCodeMileRadius: tryConvert(originGeoCodeMileRadius),
        }),
        ...(rest.destinationGeoCodeLatitude != null && {
          destinationGeoCodeMileRadius: tryConvert(
            destinationGeoCodeMileRadius
          ),
        }),
      }

      return paginatedRequestCriteria({
        path: 'CarrierLoads/search/available',
        rootStore: this.rootStore,
        method: 'POST',
        ...(pageSize != null && { pageSize }),
        data: { criteria: data, ...params },
        cache: true,
      })(page)
      // @ts-ignore not supported by ts 3.8
    }, LoadType)

  searchLoads = this.loadSearchRequestState()

  // note: the page size (500 here) is ignored by the api for view=map
  mapSearchLoads = this.loadSearchRequestState(500, { view: 'map' })

  /** @param {Haully.LoadId} loadId */
  getLoad = async loadId => {
    const cachedLoad = this.loads.get(loadId)
    if (cachedLoad) return cachedLoad
    return this.fetchLoad(loadId)
  }

  // https://popups.vtvsolutions.com/Oviss/swagger/ui/index#!/CarrierLoadDocuments/CarrierLoadDocuments_GetAllOrderReportPdfs
  sendLoadEmail = loadId =>
    this.loadsEmailActionBasic({
      // apiPath: `CarrierLoads/get/${loadId}`,
      apiPath: `CarrierLoadDocuments/All`,
      data: { orderId: loadId },
    })

  /**
   * @param {Haully.LoadId} loadId
   * @param {boolean} [isErrorGlobal]
   * */
  fetchLoad = async (loadId, isErrorGlobal = true) => {
    const resp = await this.rootStore.apiRequest(`CarrierLoads/get/${loadId}`, {
      is404OK: true,
      isErrorGlobal: isErrorGlobal,
      method: 'POST',
    })

    if (resp.ok) {
      /** @type {Haully.LoadDetailed} */
      const load = resp.data
      this.loads.set(loadId, load)
      return load
    }

    return null
  }

  /**
   * @param {Haully.LoadId} loadId
   * */
  fetchLoadPresence = async loadId => {
    const resp = await this.rootStore.apiRequest(`CarrierLoads/get/${loadId}`, {
      is404OK: true,
      isErrorGlobal: false,
      method: 'POST',
    })

    if (resp.ok || resp.status === 403) {
      return resp
    }

    return null
  }

  fetchRecommendedLoads = async () => {
    const resp = await this.rootStore.apiRequest(
      'CarrierLoads/search/available',
      { method: 'POST' },
      { criteria: {} }
    )

    if (resp.ok) {
      this.recommendedLoads = resp.data
      return true
    }
    return false
  }

  /** @param {Haully.LoadId} loadId */
  assignLoad = async loadId => {
    lscache.flush()

    const form = this.loadPickupDeliveryForm

    form.isSubmitting = true

    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/${loadId}/assignment`,
      { method: 'POST', isErrorGlobal: false },
      {
        ScheduledPickup: `${form.values.pickupDate}T${form.values.pickupTime}`,
        ScheduledDelivery: `${form.values.deliveryDate}T${form.values.deliveryTime}`,
        DriverId: null,
      }
    )

    form.isSubmitting = false

    if (!resp.ok && resp.data.message) {
      form.updateValidationErrors([
        { field: 'root', message: resp.data.message },
      ])
    }

    return resp.ok
  }

  /** @param {Haully.LoadId} loadId */
  updateLoadDates = async loadId => {
    const form = this.loadPickupDeliveryForm

    form.isSubmitting = true

    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/${loadId}/ChangeDates`,
      { method: 'PUT' },
      {
        ScheduledPickup: `${form.values.pickupDate}T${form.values.pickupTime ||
          '12:00:00'}`,
        ScheduledDelivery: `${form.values.deliveryDate}T${form.values
          .deliveryTime || '12:00:00'}`,
      }
    )

    form.isSubmitting = false

    return resp.ok
  }

  /** @param {Haully.LoadId} loadId */
  load = loadId => this.loads.get(loadId)

  /**
   * @param {Haully.Load} load
   */
  downloadLoadInformation = async load => {
    const { apiRequest } = this.rootStore
    const resp = await apiRequest(
      `CarrierLoadDocuments/All`,
      {
        method: 'POST',
      },
      { orderId: load.id }
    )
    if (!resp.ok) return
    if (!resp.blob) throw new Error('invariant: expected load info blob')
    openBlob(resp.blob, `Load ${load.id}.pdf`, 'application/pdf')
  }
}

/** @param {string} [str] */
const numberField = str => {
  if (str == null) return undefined
  const num = parseFloat(str)
  return Number.isFinite(num) ? num : undefined
}

const FIND_LOADS_SORT_OPTIONS = /** @type {{field: Haully.SearchSortField, label: string, ascending: boolean, key: string }[]} */ ([
  {
    field: 'AvailableDate',
    label: 'Recently Added',
    ascending: false,
  },
  {
    field: 'Rate',
    label: '$ High-Low',
    ascending: false,
  },
  {
    field: 'Units',
    label: '# Units/Load – High-Low',
    ascending: false,
  },
  {
    field: 'Units',
    label: '# Units/Load – Low–High',
    ascending: true,
  },
  {
    field: 'Miles',
    label: '# Miles/Load – High-Low',
    ascending: false,
  },
  {
    field: 'Miles',
    label: '# Miles/Load – Low-High',
    ascending: true,
  },
  {
    field: 'AvailableDate',
    label: 'P/U Date – Earliest-Latest',
    ascending: true,
  },
  {
    field: 'AvailableDate',
    label: 'P/U Date – Latest-Earliest',
    ascending: false,
  },
  {
    field: 'DeliveryDate',
    label: 'Delv Date – Earliest-Latest',
    ascending: true,
  },
  {
    field: 'DeliveryDate',
    label: 'Delv Date – Latest-Earliest',
    ascending: false,
  },
  {
    field: 'OriginCity',
    label: 'Nearest to Origin',
    ascending: true,
  },
].map((x, i) => ({ ...x, key: i.toString() })))

/** @param {string|null|undefined} val */
const tryConvert = val => {
  if (val == null) return null
  const input = parseFloat(val)
  if (Number.isNaN(input)) return null
  return input
}
