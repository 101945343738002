import React from 'react'
import styles from './CollapsibleContainer.css'
import cn from 'classnames'
import withBackground from 'src/hocs/withBackground'
import { BoxButton } from './Button'

/**
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 * @param {string} props.title
 * @param {() => void } props.onToggle
 * @param {boolean} props.isExpanded
 * @param {string|undefined} [props.className]
 */
const CollapsibleContainer = ({
  children,
  title,
  onToggle,
  isExpanded,
  ...props
}) => (
  <div className={styles.CollapsibleContainer} {...props}>
    <BoxButton
      className={cn(
        styles.CollapsibleHeader,
        isExpanded && styles.CollapsibleHeaderExpanded
      )}
      onClick={onToggle}
      title={isExpanded ? `Collapse ${title}` : `Expand ${title}`}
    >
      {title}
    </BoxButton>
    {isExpanded && <div className={styles.CollapsibleSection}>{children}</div>}
  </div>
)

export default withBackground(CollapsibleContainer)
