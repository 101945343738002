import React from 'react'
import styles from './Screen.css'
import withSpacing from 'src/hocs/withSpacing'
// import PropTypes from 'prop-types'
import cn from 'classnames'

export const Screen = props => <div className={styles.Screen} {...props} />

export const ScreenTop = withSpacing(({ className, ...props }) => (
  <div className={cn(className, styles.ScreenTop)} {...props} />
))

export const ScreenBottom = withSpacing(({ className, ...props }) => (
  <div className={cn(className, styles.ScreenBottom)} {...props} />
))
