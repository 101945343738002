import React, { Component } from 'react'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading, Body2 } from 'src/components/Text'
import styles from './Loading.css'
import { differenceInSeconds } from 'date-fns'
import Box from './Box'
import Flex from './Flex'
import VisuallyHidden from '@reach/visually-hidden'

const LoadingScreen = () => (
  <Flex justifyContent="center" alignItems="center" flex={1}>
    <LoadingHeading />
  </Flex>
)
export default LoadingScreen

export const LoadingHeading = () => (
  <HeadingContainer>
    <Heading>
      <LoadingMessage />
    </Heading>
  </HeadingContainer>
)

/**
 * @typedef {object} Props
 *
 * @typedef State
 * @property {Date} startedAt
 * @property {Date} tickAt
 *
 * @augments {Component<Props, State>}
 */
export class LoadingMessage extends Component {
  TIMEOUT_S = 10

  /** @type {State} */
  state = {
    startedAt: new Date(),
    tickAt: new Date(),
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    if (this.timerID != null) clearInterval(this.timerID)
  }

  tick() {
    this.setState({ tickAt: new Date() })
  }

  render() {
    const { tickAt, startedAt } = this.state
    const diffS = differenceInSeconds(tickAt, startedAt)

    if (diffS > this.TIMEOUT_S) {
      return (
        <Box>
          <Box mb={3}>
            <Spinner />
          </Box>
          <Body2>
            ⚠️ It’s taking longer than usual to load.{' '}
            <span className={styles.Diff}>({diffS}s)</span>
            <a href="#" onClick={() => window.location.reload()}>
              Refresh
            </a>
          </Body2>
        </Box>
      )
    }

    return <Spinner />
  }
}

/** @param {React.HTMLAttributes<HTMLDivElement>} props */
export const Spinner = props => (
  <div className={styles.Spinner} {...props}>
    <VisuallyHidden>Loading</VisuallyHidden>
  </div>
)
