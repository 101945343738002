import { computed } from 'mobx'
import request from 'src/request'
import ConfigStore from 'src/stores/ConfigStore'
import DashboardStore from 'src/stores/DashboardStore'
import ErrorStore from 'src/stores/ErrorStore'
import FavoriteStore from 'src/stores/FavoriteStore'
import LoadStore from 'src/stores/LoadStore'
import PlacesStore from 'src/stores/PlacesStore'
import RegistrationStore from 'src/stores/RegistrationStore'
import SearchStore from 'src/stores/SearchStore'
import SessionStore from 'src/stores/SessionStore'
import getStack from 'src/util/getStack'
import BackhaulStore from './BackhaulStore'
import DocumentStore from './DocumentStore'
import DriverStore from './DriverStore'
import FlashMessageStore from './FlashMessageStore'
import InvoiceStore from './InvoiceStore'
import NotificationSettingsStore from './NotificationSettingsStore'
import TruckStore from './TruckStore'
import UnassignReasonStore from './UnassignReasonStore'

/**
 * @typedef {(path: string) => string}
 */

function pathWithHaullySourceParam(path, otherParams) {
  const url = new URL(`http://www.example.com/${path}`)
  url.searchParams.append('SourceApplication', 'Haully')
  for (const [key, value] of Object.entries(otherParams || {})) {
    url.searchParams.append(key, value)
  }
  return `${url.pathname}${url.search}${url.hash}`
}

/**
 * @typedef {(path: string, init?: (RootRequestOptions & RequestInit) | undefined, data?: any) => Promise<import('src/request').RequestResponse>} ApiRequest
 */

export default class RootStore {
  /**
   * @typedef RootRequestOptions
   * @property {boolean} [is404OK]
   * @property {boolean} [isErrorGlobal] - wether the global handler shows the errors or not, defaults to true
   *
   * @typedef {RootRequestOptions & RequestInit} RequestOptions
   */

  /** @type {ApiRequest} */
  request = async (path, opts = {}, data = undefined) => {
    const stack = getStack()

    if (path.startsWith('/')) throw new Error('path should not start with /')

    const pathWithParams = pathWithHaullySourceParam(path, {
      ...(this.sessionStore.isLoggedIn &&
        this.sessionStore.viewer && {
          debug_client_id: this.sessionStore.clientId,
          debug_user_id: this.sessionStore.viewer.userId,
        }),
    })

    const input = `${this.configStore.apiRoot}/${pathWithParams}`

    const { is404OK = false, isErrorGlobal = true, ...init } = opts

    const req = this.sessionStore.isLoggedIn
      ? this._authenticatedRequest
      : request

    const resp = await req(input, init, data)

    if (resp.failure) {
      if (resp.status === 401) {
        this.sessionStore.destroySession()
        throw new Error('[NOREPORT] Logging out')
      }

      // some 404s are normal, like a load being deleted
      const isExpected404 = resp.status === 404 && is404OK

      // 400 errors are also normal, e.g. trying to assign a load that you cannot
      const is400Error = resp.status === 400

      const isExpectedError = isExpected404 || is400Error

      const isFailed = resp.error && resp.error.message === 'Failed to fetch'

      if (isErrorGlobal) {
        const failureMessage = resp.failure || 'Unknown error'
        const statusPrefix = resp.status ? `HTTP ${resp.status} on ` : ``
        const userMessage = isFailed
          ? `A network error occured, please check your connection`
          : `${failureMessage} (${statusPrefix}${resp.url})`

        this.errorStore.addError(userMessage, {
          report: !isExpectedError && resp.status != null,
          stack,
          context: {
            failure: resp.failure,
            init,
            requestUrl: resp.url,
            requestData: data,
            responseData: resp.data,
          },
        })
      }
    }

    return resp
  }

  /** @type {ApiRequest} */
  apiRequest = async (path, init = {}, data = undefined) =>
    this.request(`api/${path}`, init, data)

  /**
   * @param {string | Request} input
   * @param {RequestOptions} [init] - fetch options that get some defaults applied
   * @param {*} [data] - data to underscore-ize and stringify as JSON as the body
   */
  _authenticatedRequest = (input, init = {}, data = undefined) => {
    if (!this.sessionStore.token)
      throw new Error('Attempted to make authenticated request without a token')

    const initWithAuth = {
      ...init,
      headers: {
        Authorization: this.sessionStore.token,
        ...init.headers,
      },
    }
    return request(input, initWithAuth, data)
  }

  registrationStore = new RegistrationStore(this)
  errorStore = new ErrorStore(this)
  configStore = new ConfigStore(this)
  dashboardStore = new DashboardStore(this)
  favoriteStore = new FavoriteStore(this)
  placesStore = new PlacesStore(this)
  loadStore = new LoadStore(this)
  searchStore = new SearchStore(this)
  driverStore = new DriverStore(this)
  truckStore = new TruckStore(this)
  backhaulStore = new BackhaulStore(this)
  unassignReasonStore = new UnassignReasonStore(this)
  flashMessageStore = new FlashMessageStore(this)
  invoiceStore = new InvoiceStore(this)
  documentStore = new DocumentStore(this)
  notificationSettingsStore = new NotificationSettingsStore(this)

  get stores() {
    return {
      rootStore: this,
      ...this.childStores,
    }
  }

  get childStores() {
    return {
      sessionStore: this.sessionStore,
      registrationStore: this.registrationStore,
      errorStore: this.errorStore,
      configStore: this.configStore,
      dashboardStore: this.dashboardStore,
      favoriteStore: this.favoriteStore,
      placesStore: this.placesStore,
      loadStore: this.loadStore,
      searchStore: this.searchStore,
      driverStore: this.driverStore,
      truckStore: this.truckStore,
      backhaulStore: this.backhaulStore,
      unassignReasonStore: this.unassignReasonStore,
      flashMessageStore: this.flashMessageStore,
      invoiceStore: this.invoiceStore,
      documentStore: this.documentStore,
      notificationSettingsStore: this.notificationSettingsStore,
    }
  }

  @computed
  get serialize() {
    return Object.keys(this.childStores).reduce(
      (acc, storeKey) => ({
        ...acc,
        [storeKey]: this.stores[storeKey].serialize,
      }),
      {}
    )
  }

  deserialize = data => {
    Object.keys(this.childStores).forEach(storeKey => {
      this.stores[storeKey].deserialize(data[storeKey] || {})
    })
  }

  /**
   * @param {object} args
   * @param {() => void} [args.onLogout]
   */
  constructor({ onLogout: onLogout } = {}) {
    this.sessionStore = new SessionStore(this, onLogout)
  }
}
