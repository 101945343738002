import React from 'react'
import cn from 'classnames'
import styles from './Truncated.css'

/** @param {{className?: string, title: string, children?: React.ReactNode }} props */
const Truncated = ({ className, title, children }) => (
  <div className={cn(className, styles.Truncated)} title={title}>
    {children || title}
  </div>
)

export default Truncated
