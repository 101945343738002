import { navigate } from '@reach/router'
import { inject, observer } from 'mobx-react'
import { parse } from 'qs'
import React, { Component } from 'react'
import { PrimaryButton } from 'src/components/Button'
import {
  AnchorUnderlinedH2,
  LinkUnderlinedH2,
} from 'src/components/ButtonUnderlined'
import FieldErrors from 'src/components/FieldErrors'
import Flex from 'src/components/Flex'
import HeadingContainer from 'src/components/HeadingContainer'
import InputGroup from 'src/components/InputGroup'
import Spacer from 'src/components/Spacer'
import { Heading } from 'src/components/Text'
import TextInput from 'src/components/TextInput'
import { SIGN_UP_URL } from 'src/data/navLinks'
import { expiredPasswordPath, resetPath, searchPath } from 'src/paths'
import SessionStore from 'src/stores/SessionStore'
import Box from './Box'
import CloseButton from './CloseButton'
import styles from './LoginModal.css'
import Modal from './Modal'

/**
 * @typedef {object} Props
 * @property {import('src/stores/SessionStore').default} [sessionStore]
 *
 * @augments {Component<Props>}
 */
@inject('sessionStore')
@observer
class LoginModalBody extends Component {
  componentDidMount() {
    this.sessionStore.resetLoginForm()
  }

  get sessionStore() {
    return /** @type {SessionStore} */ (this.props.sessionStore)
  }

  queryParams = () =>
    parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

  handleSubmit = async e => {
    e.preventDefault()
    const { redirectTo } = this.queryParams()

    const [ok, errorType] = await this.sessionStore.createSession()
    if (ok) {
      navigate(redirectTo || searchPath())
    } else {
      if (errorType === 'expired') {
        navigate(expiredPasswordPath())
      }
    }
  }

  handleChange = e => {
    this.sessionStore.updateLoginForm({
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  render() {
    const { loginForm } = this.sessionStore

    return (
      <Box m={2} lm={4} className={styles.LoginModal}>
        <HeadingContainer>
          <Heading>Log In to Your Account</Heading>
        </HeadingContainer>

        <Flex justifyContent="center" mb={4}>
          <AnchorUnderlinedH2 href={SIGN_UP_URL}>
            Not signed up yet? Sign up now!
          </AnchorUnderlinedH2>
        </Flex>
        <form onSubmit={this.handleSubmit} id="loginForm">
          <InputGroup>
            <TextInput
              id="email"
              name="emailAddress"
              placeholder="Enter your email"
              type="text"
              autoFocus={true}
              value={loginForm.emailAddress}
              onChange={this.handleChange}
              autoCapitalize="none"
              autoCorrect="off"
            />
            <FieldErrors
              field="emailAddress"
              errors={loginForm.validationErrors}
            />
          </InputGroup>

          <InputGroup>
            <TextInput
              id="password"
              name="password"
              placeholder="Enter your password"
              type="password"
              value={loginForm.password}
              onChange={this.handleChange}
            />
            <FieldErrors field="password" errors={loginForm.validationErrors} />
            <Box>
              <LinkUnderlinedH2 to={resetPath()}>
                Forgot Password
              </LinkUnderlinedH2>
            </Box>
          </InputGroup>

          <Spacer />

          <PrimaryButton
            type="submit"
            block={true}
            isLoading={loginForm.isLoading}
            data-test="loginSubmitButton"
            variant="black"
          >
            Go!
          </PrimaryButton>
        </form>
      </Box>
    )
  }
}

/**
 * @param {import('./Modal').ModalProps} props
 */
const LoginModal = props => {
  return (
    <Modal overlayBackground="blue" {...props}>
      <Flex justifyContent="flex-end" pt={2} pr={2}>
        <CloseButton variant="black" onClick={props.onRequestClose} />
      </Flex>
      <Flex pl={3} pr={3} pb={4} lpl={4} lpr={4} bg="white">
        <LoginModalBody />
      </Flex>
    </Modal>
  )
}

export default LoginModal
