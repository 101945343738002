import React from 'react'
import styles from './LocationSearchInput.css'

/** @param {React.InputHTMLAttributes<HTMLInputElement>} props */
const LocationSearchInput = props => (
  <input
    type="text"
    data-test="search-overlay-input"
    className={styles.LocationSearchInput}
    {...props}
  />
)

export default LocationSearchInput
