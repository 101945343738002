import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { CollectionCount } from 'src/components/CollectionCounts'
import Flex from 'src/components/Flex'
import { InvoiceCardButton, InvoiceHeader } from 'src/components/InvoiceCard'
import PageMeta from 'src/components/PageMeta'
import PaginatedList from 'src/components/PaginatedList'
import TabNavInvoices from 'src/components/TabNavInvoices'
import Wrapper from 'src/components/Wrapper'
import DriverDateFilters from 'src/containers/DriverDateFilters'
import { invoiceDetailsPath } from 'src/paths'
import InvoiceStore from 'src/stores/InvoiceStore'
import Grid from 'src/components/Grid'
import EmailLoadsButton from 'src/components/EmailLoadsButton'
import DownloadButton from 'src/components/DownloadButton'

/**
 * @typedef {object} Props
 * @property {import('src/stores/InvoiceStore').default} invoiceStore
 * @property {import('src/stores/DriverStore').default} driverStore
 * @augments {Component<Props>}
 */
@inject('invoiceStore', 'driverStore')
@observer
class InvoicesPaid extends Component {
  componentDidMount() {
    this.props.invoiceStore.invoicesPaid.fetchFirstPage()
  }

  get invoiceStore() {
    return /** @type {InvoiceStore} */ (this.props.invoiceStore)
  }

  onSortChanged = ({ field, ascending }) => {
    this.props.invoiceStore.invoiceFilterForm.values.sortField = field
    this.props.invoiceStore.invoiceFilterForm.values.sortAscending = ascending
  }

  render() {
    const { invoicesPaid } = this.props.invoiceStore

    return (
      <Wrapper bg="light-gray">
        <PageMeta title="Paid" section="Invoices" />
        <TabNavInvoices />
        {/* <InvoiceChart /> */}
        <DriverDateFilters
          form={this.props.invoiceStore.invoiceFilterForm}
          driverStore={this.props.driverStore}
          filterDrivers={true}
          filterTomorrowFuture={false}
          filterDateDropdown={true}
          onFilterChange={() =>
            this.props.invoiceStore.invoicesPaid.fetchFirstPage()
          }
        />

        <Flex p={3} bg="white" border="bottom" justifyContent="space-between">
          <CollectionCount
            labelPlural="Paid"
            labelSingular="Paid"
            paginatedRequestState={invoicesPaid}
          />

          <Grid gap={2} autoFlow="column">
            <EmailLoadsButton
              onSendEmail={this.props.invoiceStore.invoicesPaidEmail}
              actionLabel="Email Paid Invoices"
            />
            <DownloadButton
              onClick={this.props.invoiceStore.invoicesPaidDownload}
              actionLabel="Download Paid Invoices"
            />
          </Grid>
        </Flex>

        <Box bg="white" mb={3}>
          <PaginatedList
            paginatedRequestState={invoicesPaid}
            emptyMessage={<Box p={3}>No paid invoices found</Box>}
          >
            <InvoiceHeader
              status="Paid"
              sortOption={{
                field: this.props.invoiceStore.invoiceFilterForm.values
                  .sortField,
                ascending: this.props.invoiceStore.invoiceFilterForm.values
                  .sortAscending,
              }}
              onSortChanged={sortOption => {
                this.onSortChanged(sortOption)
                this.props.invoiceStore.invoicesPaid.fetchFirstPage()
              }}
            />
            {invoicesPaid.collection.map((invoice, i) => (
              <InvoiceCardButton
                invoice={invoice}
                status="Paid"
                key={i}
                bg="white"
                to={invoiceDetailsPath(invoice.orderNumber)}
              />
            ))}
          </PaginatedList>
        </Box>
      </Wrapper>
    )
  }
}

export default InvoicesPaid
