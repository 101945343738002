import {
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from '@reach/listbox'
import '@reach/listbox/styles.css'
import React from 'react'
import styles from './BlockSelect.css'

export const BlockListbox: React.FC<{
  title: string
  options: Map<string, string>
  value: string
  onChange: (value: string) => void
  style?: React.CSSProperties | undefined
  includeBlank?: boolean
}> = ({ title, options, value, onChange, style, includeBlank }) => {
  return (
    <ListboxInput
      value={value}
      onChange={onChange}
      className={styles.listboxInput}
      style={style}
    >
      <ListboxButton className={styles.listboxButton}>
        <div className={styles.label}>{title}</div>
        <div className={styles.optionLabel}>
          {options.get(value) || 'Choose one'}
        </div>
      </ListboxButton>
      <ListboxPopover>
        <ListboxList>
          {includeBlank && (
            <ListboxOption value="default">&nbsp;</ListboxOption>
          )}
          {[...options].map(([value, label]) => (
            <ListboxOption key={value} value={value}>
              {label}
            </ListboxOption>
          ))}
        </ListboxList>
      </ListboxPopover>
    </ListboxInput>
  )
}
