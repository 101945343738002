import React from 'react'
import styles from './ErrorMessage.css'

const ErrorMessage = ({ children }) => (
  <span className={styles.errorMessage} data-test="error-message">
    {children}
  </span>
)

export default ErrorMessage
