import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { LoadCollectionCount } from 'src/components/CollectionCounts'
import Flex from 'src/components/Flex'
import LoadCardGrid from 'src/components/LoadCardGrid'
import OverlayButton from 'src/components/OverlayButton'
import PageMeta from 'src/components/PageMeta'
import PaginatedList from 'src/components/PaginatedList'
import { ResponsiveParent } from 'src/components/Responsive'
import TabNavManageLoads from 'src/components/TabNavManageLoads'
import { Body2 } from 'src/components/Text'
import Wrapper from 'src/components/Wrapper'
import { LinkedLoadCardContainer } from 'src/containers/LoadCardContainer'
import FavoriteStore from 'src/stores/FavoriteStore'
import LoadStore from 'src/stores/LoadStore'

/**
 * @augments {Component<{loadStore: LoadStore, favoriteStore: FavoriteStore}>}
 */
@inject('loadStore', 'favoriteStore')
@observer
class LoadsSaved extends Component {
  componentDidMount() {
    this.props.loadStore.favoriteLoads.fetchFirstPage()
  }

  /** @param {Haully.Load} load */
  handleRemoveLoad = load => async () => {
    await this.props.favoriteStore.deleteFavorite(load)
    this.props.loadStore.favoriteLoads.collection.remove(load)
  }

  render() {
    const { favoriteLoads } = this.props.loadStore

    const loadsAvailable = favoriteLoads.collection.filter(
      load => !isUnavailable(load)
    )
    const loadsUnavailable = favoriteLoads.collection.filter(load =>
      isUnavailable(load)
    )

    return (
      <Wrapper bg="light-gray">
        <PageMeta title="Saved Loads" section="Manage Loads" />

        <TabNavManageLoads />

        <Flex p={3} bg="white" border="bottom" justifyContent="space-between">
          <LoadCollectionCount paginatedRequestState={favoriteLoads} />
        </Flex>

        <PaginatedList
          paginatedRequestState={favoriteLoads}
          emptyMessage={<Box p={3}>No saved loads</Box>}
        >
          <ResponsiveParent base={<Box p={3} />} large={<LoadCardGrid p={3} />}>
            {loadsAvailable.map(load => (
              <Box key={load.id} mb={3} lmb={0}>
                <LinkedLoadCardContainer load={load} />
              </Box>
            ))}
          </ResponsiveParent>
        </PaginatedList>

        <meta
          data-test="LoadsSavedUnavailableCount"
          content={`${loadsUnavailable.length}`}
        />

        {loadsUnavailable.length > 0 && (
          <Box p={3} bg="light-gray-blue">
            <Body2 mb={2}>SAVED HAULS NO LONGER AVAILABLE</Body2>
            <ResponsiveParent base={<div />} large={<LoadCardGrid />}>
              {loadsUnavailable.map(load => (
                <OverlayButton
                  key={load.id}
                  onClick={this.handleRemoveLoad(load)}
                  buttonText="Remove"
                  data-test="LoadsSavedRemoveLoad"
                >
                  <LinkedLoadCardContainer load={load} />
                </OverlayButton>
              ))}
            </ResponsiveParent>
          </Box>
        )}
      </Wrapper>
    )
  }
}

/** @param {Haully.Load} load */
const isUnavailable = load => !load.unAssigned && !load.assignedToCurrentCarrier

export default LoadsSaved
