import React from 'react'
import styles from './FromToButton.css'
import { Small } from './Text'
import cx from 'classnames'

/** @param {{ city?: string, state?: string, label: string, onClick: () => void, className?: string }} props */
export const FromToButton = ({ onClick, className, ...rest }) => (
  <button
    type="button"
    className={cx(styles.FromToButton, className)}
    onClick={onClick}
  >
    <FromToButtonLabel {...rest} />
  </button>
)

/** @param {{ city?: string, state?: string, label: string}} props */
export const FromToButtonLabel = ({ city, state, label }) => (
  <div className={styles.FromToButtonLabel}>
    {city || state ? (
      <>
        {state}
        <Small>{city}</Small>
      </>
    ) : (
      label
    )}
  </div>
)
