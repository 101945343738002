import React, { memo, useEffect } from 'react'
import { Provider as MobxProvider, Observer } from 'mobx-react'
import styles from './App.css'
import Routes from 'src/Routes'
import cn from 'classnames'
import GlobalErrors from 'src/containers/GlobalErrors'
import { EndpointNotice } from 'src/screens/Config'
import { useRootStore } from './hooks/useStores'
import useWindowSize from './hooks/useWindowSize'
import { Location } from '@reach/router'
import { env } from 'src/util/env'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { OfflineWarning } from './containers/OfflineWarning'
import { loadHubspot, trackHubspotPageView } from './util/hubspot'

const App = () => {
  const rootStore = useRootStore()
  const { isLarge, isMedium } = useWindowSize()

  useEffect(() => {
    loadHubspot()
  }, [])

  return (
    <MobxProvider {...rootStore.stores}>
      <Observer>
        {() => (
          <div
            className={cn(styles.App, {
              breakpointLarge: isLarge,
              breakpointMedium: isMedium,
            })}
          >
            <GlobalErrors />
            <OfflineWarning />
            <TrackPageViewRouter />
            <EndpointNotice />
          </div>
        )}
      </Observer>
    </MobxProvider>
  )
}

export default App

const TrackPageViewRouter = memo(() => {
  const rootStore = useRootStore()

  const googleTrackingId = env('GOOGLE_ANALYTICS_ID')
  const facebookTrackingId = env('FACEBOOK_TRACKING_ID')
  if (facebookTrackingId) ReactPixel.init(facebookTrackingId)
  if (googleTrackingId)
    ReactGA.initialize(googleTrackingId, {
      debug: window.location.search.includes('debug'),
    })

  const track = path => {
    trackHubspotPageView(path)
    if (googleTrackingId) {
      ReactGA.set({ page: path })
      ReactGA.pageview(path)
    }
    if (facebookTrackingId) ReactPixel.pageView()
  }

  return (
    <Location>
      {({ location }) => {
        track(location.pathname + location.search)

        return (
          <Routes sessionStore={rootStore.sessionStore} location={location} />
        )
      }}
    </Location>
  )
})
