import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { LinkButton } from 'src/components/Button'
import { LinkUnderlinedH2 } from 'src/components/ButtonUnderlined'
import HeadingContainer from 'src/components/HeadingContainer'
import LoadingScreen from 'src/components/Loading'
import MaxWidth from 'src/components/MaxWidth'
import MobileButtonContainer from 'src/components/MobileButtonContainer'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import { BodyText, Heading, TextContainer } from 'src/components/Text'
import { contactDispatchPath, onboardConfirmInfoPath } from 'src/paths'
import SessionStore from 'src/stores/SessionStore'

@inject('sessionStore')
@observer
class OnboardWelcome extends Component {
  static propTypes = {
    sessionStore: PropTypes.instanceOf(SessionStore).isRequired,
  }

  /** @returns {SessionStore} */
  get sessionStore() {
    return this.props.sessionStore
  }

  render() {
    const { viewer } = this.sessionStore

    if (!viewer) return <LoadingScreen />

    const firstName = viewer.firstName

    return (
      <OnboardingContainer>
        <PageMeta title="Onboard Welcome" />
        <HeadingContainer>
          <Heading mb={2} lmb={4}>
            Welcome{firstName ? ` ${firstName}` : null},<br />
            We’re so happy you’re here.
          </Heading>
          <MaxWidth value={632}>
            <BodyText lmb={3}>
              Let’s get to know each other a little bit better. Share a few
              details about yourself so we can show you the loads best suited
              for you. It’ll only take a few minutes, <br />
              <span style={{ whiteSpace: 'nowrap' }}>we promise.</span>
            </BodyText>
          </MaxWidth>
        </HeadingContainer>
        <Box mb={3} lmb={4}>
          <MobileButtonContainer>
            <LinkButton
              to={onboardConfirmInfoPath()}
              data-test="onboardWelcomeContinueButton"
            >
              Get Started
            </LinkButton>
          </MobileButtonContainer>
        </Box>

        <TextContainer lpt={5} lmb={2}>
          <LinkUnderlinedH2 color="light-blue" to={contactDispatchPath()}>
            Skip and schedule a call with dispatch.
          </LinkUnderlinedH2>
        </TextContainer>
      </OnboardingContainer>
    )
  }
}

export default OnboardWelcome
