export const DISPATCH_PHONE_NUMBER = '(888) 278-2793'
export const CONTACT_PHONE_NUMBER = '(888) 278-2793'
export const CONTACT_ADDRESS =
  '41100 Plymouth Road, 4th Floor, Plymouth, MI 48170'

export const REGIONS = [
  { label: 'Mid Atlantic', field: 'MidAtlantic' },
  { label: 'Midwest', field: 'MidWest' },
  { label: 'North Central', field: 'NorthCentral' },
  { label: 'Northeast', field: 'NorthEast' },
  { label: 'Northwest', field: 'NorthWest' },
  { label: 'South Central', field: 'SouthCentral' },
  { label: 'Southeast', field: 'SouthEast' },
  { label: 'Southwest', field: 'SouthWast' },
  { label: 'Rocky Mountains', field: 'RockyMountains' },
]

/**
 * @param {Haully.Viewer} viewer
 * @param {string} subject
 * @param {string} orderId
 */
export const emailDisputeFormatter = (subject, viewer, orderId) => ({
  subject: `${subject} - ${viewer.client.name} - Load ${orderId}`,
  body: encodeURI(`
  
  
------------
${viewer.name ? viewer.name : viewer.userName}
${viewer.email}
${viewer.primaryPhone.phoneNumber}
`),
})

export const emailSignupFormatter = () => ({
  subject: `I want to drive for Haully`,
  body: encodeURI(`Hello,

I am interested in becoming a Haully Carrier. Please find my full name and DOT number below:
[ Enter Name ]
[ Enter DOT ]`),
})

export const NOTIFICATION_SETTINGS_DESCRIPTIONS = {
  newLoadsOfSavedSearches: {
    heading: 'New loads for saved searches',
    description: 'Find available loads that match your saved searches.',
  },
  urgentLoads: {
    heading: 'Urgent loads',
    description:
      'Find loads that need premier and immediate service. These loads often pay premium rates.',
  },
  claimSavedLoads: {
    heading: 'Claim saved loads',
    description: "Get loads you've saved when they become available to claim.",
  },
  loadsDelivered: {
    heading: 'Loads delivered',
    description: 'Know when your drivers have delivered their loads.',
  },
  paymentErrors: {
    heading: 'Errors for pending payments',
    description:
      "Get notified when there's an error in processing a payment, missing information, or other issues.",
  },
  paymentsPosted: {
    heading: 'Payments Posted',
    description:
      'Stay up to date when your payments have been posted to your account.',
  },
}
