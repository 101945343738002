import { navigate } from '@reach/router'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { LinkButton, PrimaryButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import LinkBasic from 'src/components/LinkBasic'
import PageMeta from 'src/components/PageMeta'
import { Screen } from 'src/components/Screen'
import { Body1, Body2, Title } from 'src/components/Text'
import TextInput from 'src/components/TextInput'
import { configPath, loginPath } from 'src/paths'
import ConfigStore, { DEFAULT_API_ROOT } from 'src/stores/ConfigStore'
import SessionStore from 'src/stores/SessionStore'
import appVersion from 'src/util/appVersion'

/**
 * @typedef Props
 * @property {ConfigStore} [configStore]
 * @property {SessionStore} [sessionStore]
 *
 * @augments Component<Props>
 */
@inject('configStore', 'sessionStore')
@observer
class Config extends Component {
  state = {
    apiRoot: this.configStore.apiRoot,
  }

  get configStore() {
    return /** @type {ConfigStore} */ (this.props.configStore)
  }

  get sessionStore() {
    return /** @type {SessionStore} */ (this.props.sessionStore)
  }

  handleEndpointSubmit = e => {
    e.preventDefault()
    this.configStore.apiRoot = this.state.apiRoot
  }

  handlePresetClick = url => e => {
    e.preventDefault()
    this.configStore.apiRoot = url
    this.setState({ apiRoot: this.configStore.apiRoot })
    navigate(loginPath())
  }

  handleLogout = () => {
    // triggers refresh
    this.sessionStore.destroySession()
  }

  render() {
    const { isLoggedIn } = this.sessionStore

    return (
      <Screen>
        <Box p={3} bg="white">
          <PageMeta title="Config" />
          <Title mb={3}>Config</Title>

          <Body1 mb={3}>Features</Body1>

          <Box mb={2}>
            <Body1>Endpoint</Body1>
          </Box>
          {isLoggedIn ? (
            <>
              <Body2 mb={3}>You must log out to change endpoints</Body2>
              <PrimaryButton onClick={this.handleLogout}>Log out</PrimaryButton>
            </>
          ) : (
            <>
              <Box mb={4}>
                {presets.map(({ name, apiRoot }) => (
                  <Box key={name} mb={2}>
                    <PrimaryButton
                      type="button"
                      onClick={this.handlePresetClick(apiRoot)}
                      variant={
                        apiRoot === this.configStore.apiRoot
                          ? 'primary'
                          : 'outline'
                      }
                    >
                      {name}
                      {apiRoot === DEFAULT_API_ROOT && ' (default)'}
                    </PrimaryButton>
                  </Box>
                ))}
              </Box>
              <form onSubmit={this.handleEndpointSubmit}>
                <TextInput
                  placeholder="Custom Api Root"
                  value={this.state.apiRoot}
                  onChange={e =>
                    this.setState({ apiRoot: e.currentTarget.value })
                  }
                />
                <Flex mb={2}>
                  <PrimaryButton variant="outline" type="submit">
                    Set Custom Api Root
                  </PrimaryButton>
                </Flex>
              </form>
            </>
          )}
          <Box pt={4} mb={3}>
            <LinkButton to={'/'}>Dashboard</LinkButton>
          </Box>
          <Box mb={3}>Haully Version {appVersion() || 'UNKNOWN'}</Box>
        </Box>
      </Screen>
    )
  }
}

const presets = [
  {
    name: 'urs-uat',
    apiRoot: 'https://urs-uat.vtvsolutions.com/Oviss',
  },
  {
    name: 'Demo',
    apiRoot: 'https://demo.vtvsolutions.com/Oviss',
  },
  {
    name: 'UR Popups',
    apiRoot: 'https://popups.vtvsolutions.com/Oviss',
  },
  { name: 'Local Dev', apiRoot: 'http://localhost:7001' },
  {
    name: 'Mock API',
    apiRoot: 'https://haully-api-staging.herokuapp.com',
  },
]

/** @param {*} props */
const _EndpointNotice = ({ configStore }) => {
  if (configStore.DEFAULT_API_ROOT === configStore.apiRoot) return null

  const preset = presets.find(({ apiRoot }) => apiRoot === configStore.apiRoot)
  const name = preset ? preset.name : configStore.apiRoot

  return (
    <Box
      p={2}
      bg="light-gray"
      style={{ textAlign: 'center', wordBreak: 'break-all' }}
    >
      Api Endpoint: {name}
      <Box pl={2}>
        <LinkBasic to={configPath()}>Change</LinkBasic>
      </Box>
    </Box>
  )
}
export const EndpointNotice = inject('configStore')(observer(_EndpointNotice))

export default Config
