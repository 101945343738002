import React from 'react'
import { Link } from '@reach/router'
import Box from 'src/components/Box'
import { Title } from 'src/components/Text'
import PageMeta from 'src/components/PageMeta'
import { welcomePath } from 'src/paths'

const NotFound = () => (
  <Box p={4}>
    <PageMeta title="Page Not Found" />
    <Title mb={4}>Page Not Found</Title>
    <Link to={welcomePath()}>Go back</Link>
  </Box>
)

export default NotFound
