import React from 'react'
import PropTypes from 'prop-types'
import styles from './Checkbox.css'
import cn from 'classnames'

import Checkmark from 'src/icons/Checkmark.svg'

/** @param {React.InputHTMLAttributes<HTMLInputElement>} props */
const Checkbox = ({ children, ...props }) => (
  <label className={cn(styles.label, { [styles.disabled]: props.disabled })}>
    <input type="checkbox" className={styles.input} {...props} />
    <div className={styles.icon}>
      <Checkmark className={styles.Checkmark} />
    </div>
    {children}
  </label>
)

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Checkbox
