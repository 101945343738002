import React from 'react'
import Box from 'src/components/Box'
import LinkBannerTipsTools from 'src/components/LinkBannerTipsTools'
import Responsive from 'src/components/Responsive'
import {
  HorizontalScrollCard,
  HorizontalScrollContainer,
} from 'src/components/ScrollContainer'
import { SearchCard } from 'src/components/SearchCard'
import { Title } from 'src/components/Text'
import { LinkedLoadCardContainer } from 'src/containers/LoadCardContainer'
import { searchLoadPath } from 'src/paths'

export const FindLoadsSplash = ({
  savedSearches,
  recentSearches,
  recommendedLoads,
  onSearchClick,
}) => {
  const isSavedSearchesEmpty = !savedSearches || savedSearches.totalCount === 0
  const isRecentSearchesEmpty =
    !recentSearches || recentSearches.totalCount === 0
  const isRecommendedLoadsEmpty =
    !recommendedLoads || recommendedLoads.totalCount === 0
  return (
    <Box pt={3} pb={3}>
      {!isSavedSearchesEmpty && (
        <Box mb={3}>
          <Title pl={3}>Saved Searches</Title>

          <HorizontalScrollContainer>
            {savedSearches.map((savedSearch, i) => (
              <HorizontalScrollCard
                collection={savedSearches}
                position={i}
                key={i}
              >
                <SearchCard
                  search={savedSearch}
                  onClick={() => onSearchClick(savedSearch)}
                />
              </HorizontalScrollCard>
            ))}
          </HorizontalScrollContainer>
        </Box>
      )}

      {!isRecentSearchesEmpty && (
        <Box mb={3}>
          <Title pl={3}>Recent Searches</Title>
          <HorizontalScrollContainer>
            {recentSearches.map((recentSearch, i) => (
              <HorizontalScrollCard
                collection={recentSearches}
                position={i}
                key={i}
              >
                <SearchCard
                  search={recentSearch}
                  onClick={_e => onSearchClick(recentSearch)}
                  key={recentSearch.id}
                />
              </HorizontalScrollCard>
            ))}
          </HorizontalScrollContainer>
        </Box>
      )}

      {!isRecommendedLoadsEmpty && (
        <Box mb={3}>
          <Title pl={3}>New Loads for You</Title>
          <HorizontalScrollContainer>
            {recommendedLoads.records.map((load, i) => (
              <HorizontalScrollCard
                collection={recommendedLoads.records}
                position={i}
                key={load.id}
                width={'load'}
              >
                <LinkedLoadCardContainer
                  load={load}
                  to={searchLoadPath(load.id)}
                  preserveLocationSearch={true}
                />
              </HorizontalScrollCard>
            ))}
          </HorizontalScrollContainer>
        </Box>
      )}

      <Responsive large={null}>
        <Box pb={4}>
          <LinkBannerTipsTools />
        </Box>
      </Responsive>
    </Box>
  )
}
