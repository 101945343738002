import React, { useEffect, useState } from 'react'
import { useApiRequest } from 'src/hooks/useStores'

/**
 * @param {object} props
 * @param {Haully.LookupKey} props.lookupKey
 * @param {(value: any) => React.ReactNode} [props.children]
 */
const LookupValue = ({ lookupKey, children }) => {
  const { data, isLoading, error } = useLookup(lookupKey)
  if (isLoading) return null
  if (error) return <>{children('⚠️ Lookup value not found')}</>
  return <>{children(data)}</>
}

export default LookupValue

/**
 * @param {Haully.LookupKey} lookupKey
 * @returns {{isLoading: true, data: null, error: null}|{isLoading: false, data: any, error: null}|{isLoading:false, error: string, data: null }}
 */
export const useLookup = lookupKey => {
  const apiRequest = useApiRequest()
  const [data, setData] = useState(/** @type {null|any} */ (null))
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(/** @type {string|null} */ (null))

  useEffect(() => {
    const fetchData = async () => {
      const resp = await apiRequest(`Lookups/${lookupKey}`)

      if (!resp.ok) {
        setError(resp.failure)
      } else {
        setData(resp.data)
      }

      setIsLoading(false)
    }
    fetchData()
  }, [apiRequest, lookupKey])

  if (isLoading) {
    return { isLoading, data: null, error: null }
  }
  if (error) {
    return { isLoading, error, data: null }
  }
  return { isLoading, data, error: null }
}
