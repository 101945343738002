import { observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { DriverCardButton } from 'src/components/DriverCard'
import { LoadingHeading } from 'src/components/Loading'
import { ListEmptyMessage } from 'src/components/PaginatedList'
import { InputDebounced } from 'src/components/TextInput'
import { ESC_KEY } from 'src/data/keys'
import Form from 'src/models/Form'

/**
 * @typedef {Object} Props
 * @property {import('src/stores/DriverStore').default} driverStore
 * @property {(user: Haully.UserTypeahead | null) => void} onUserSelected
 * @property {number | null} selectedUserId
 *
 * @typedef {Object} State
 * @property {string} driverSearch
 *
 * @augments Component<Props>
 */
@observer
class FilteredUserList extends Component {
  form = new Form({
    values: {
      userSearch: '',
      /** @type {?number} */
      selectedUser: null,
    },
  })

  componentDidMount() {
    this.form.reset()
    this.props.driverStore.filteredUsers.replace([])

    if (this.props.selectedUserId) {
      this.form.values.selectedUser = this.props.selectedUserId
    }
  }

  render() {
    const { driverStore, onUserSelected } = this.props
    const form = this.form
    const { selectedUser } = form.values

    return (
      <>
        <Box pl={3} pr={3}>
          <InputDebounced
            placeholder="Name"
            value={form.values.userSearch}
            onChangeText={text => {
              form.update({ userSearch: text })
              const trimmed = text.trim()
              trimmed && driverStore.updateFilteredUsers(trimmed)
            }}
            data-test="loadAssignDriverFilterInput"
            onKeyDown={e => {
              if (e.keyCode === ESC_KEY) {
                e.stopPropagation()
                this.props.onUserSelected(null)
              }
            }}
            autoFocus={true}
          />
        </Box>

        <Box mb={4} pb={4}>
          <UserList
            onSelectUser={user => {
              form.values.selectedUser = user ? user.id : null
              onUserSelected(user)
            }}
            selectedUserId={selectedUser}
            users={driverStore.filteredUsers}
            isZeroState={form.values.userSearch.length === 0}
            isLoading={driverStore.filteredUsersIsLoading}
          />
          <Box pb={2} />
        </Box>
      </>
    )
  }
}
export default FilteredUserList

/**
 * @param {Object} props
 * @param {(driver: Haully.UserTypeahead | null) => void} props.onSelectUser
 * @param {Haully.DriverId|null} props.selectedUserId
 * @param {(Haully.UserTypeahead)[]} props.users
 * @param {boolean} props.isZeroState
 * @param {boolean} props.isLoading
 */
const _UserList = ({
  onSelectUser,
  selectedUserId,
  users,
  isZeroState,
  isLoading,
}) => {
  if (isLoading) return <LoadingHeading />
  if (!isZeroState && !isLoading && users.length === 0)
    return (
      <Box p={3}>
        <ListEmptyMessage>No results</ListEmptyMessage>
      </Box>
    )

  return (
    <>
      {users.map(user => {
        const isSelected = selectedUserId === user.id
        return (
          <DriverCardButton
            driver={user}
            key={user.id}
            onClick={() => onSelectUser(user)}
            isActive={isSelected}
          />
        )
      })}
    </>
  )
}
const UserList = observer(_UserList)
