import { stringify } from 'qs'

/** @typedef {Object<string, *>} Params */

/** @param {string} path */
const withParams = path => {
  /** @param {Params} [params] */
  const fn = params => {
    const basepath = process.env['BASEPATH'] || ''
    const queryString = stringify(params, { addQueryPrefix: true })
    return `${basepath.replace(/\/$/, '')}${path}${queryString}`
  }

  return fn
}

export const todoPath = withParams('/todo')
export const configPath = withParams('/config')

export const welcomePath = withParams('/')
export const dashboardPath = withParams('/dashboard')
export const invoicesPath = withParams('/invoices')
export const loginPath = withParams('/login')
export const signupPath = withParams('/signup')
export const onboardWelcomePath = withParams('/onboard/welcome')
export const onboardConfirmInfoPath = withParams('/onboard/confirm-info')
export const onboardAddHaulerPath = withParams('/onboard/add-hauler')
export const onboardHaulEquipmentPath = withParams('/onboard/haul-equipment')
export const onboardAddDriverPath = withParams('/onboard/add-driver')
export const onboardAddDriverConfirmPath = withParams(
  '/onboard/add-driver-confirm'
)
export const onboardHaulDrivePrefPath = withParams(
  '/onboard/haul-drive-preferences'
)
export const onboardLocationPath = withParams('/onboard/location')
export const onboardLoadsPrefPath = withParams('/onboard/load-preferences')
export const onboardEndPath = withParams('/onboard/complete')

// Registration
/**
 * @param {string} registrationCode
 * @param {Params} [params]
 */
export const registerWelcomePath = (registrationCode, params) =>
  withParams(`/register/welcome/${registrationCode}`)(params)

export const registerBusinessLocationPath = withParams(
  '/register/business-location'
)
export const registerInsuranceDetailsPath = withParams(
  '/register/insurance-details'
)
export const registerFleetPath = withParams('/register/fleet')
export const registerConfirmationPath = withParams('/register/confirmation')

export const searchPath = withParams('/search')
export const searchMapPath = withParams('/search/map')

// manage loads
export const loadsSavedPath = withParams('/loads/saved')
export const loadsClaimedPath = withParams('/loads/claimed')
export const loadsInProgressPath = withParams('/loads/in-progress')
export const loadsCompletedPath = withParams('/loads/completed')

// my account
export const myAccountPath = withParams('/my-account')
export const editProfilePath = withParams('/profile/edit')
export const accountSettingsPath = withParams('/settings')
export const businessInfoPath = withParams('/business-info')
export const settingsDriversPath = withParams('/drivers-and-fleet/drivers')
export const settingsFleetPath = withParams('/drivers-and-fleet/fleet')
export const settingsAddDriverPath = withParams('/drivers-and-fleet/add-driver')
export const settingsAddHaulerPath = withParams('/drivers-and-fleet/add-hauler')
export const tipsAndToolsPath = withParams('/tips-and-tools')
export const faqPath = withParams('/tips-and-tools/faq')
export const contactUsPath = withParams('/contact-us')
export const legalInformationPath = withParams('/legal')
export const myDocumentsPath = withParams('/my-account/documents')
export const paymentPreferencesPath = withParams('/payment-preferences')
export const settingsEmailNotificationsPath = withParams(
  '/settingst/email-notifications'
)
export const settingsTextNotificationsPath = withParams(
  '/settingst/text-notifications'
)

/**
 * @param {Haully.VideoResourceSlug|string} slug
 * @param {Params} [params]
 */
export const videoResourcePath = (slug, params) =>
  withParams(`/tips-and-tools/videos/${slug}`)(params)

/**
 * @param {Haully.DriverId|string} id - the driver's id
 * @param {Params} [params]
 */
export const settingsEditDriverPath = (id, params) =>
  withParams(`/drivers-and-fleet/edit-driver/${id}`)(params)

/**
 * @param {Haully.CarrierTruck|string} carrierTruckId - the CarrierTruck's id
 * @param {Params} [params]
 */
export const settingsEditHaulerPath = (carrierTruckId, params) =>
  withParams(`/drivers-and-fleet/edit-truck/${carrierTruckId}`)(params)

/**
 * @param {Haully.LoadId|string} id - the load's id
 * @param {Params} [params]
 */
export const loadPath = (id, params) => withParams(`/load/${id}`)(params)

/**
 * @param {Haully.LoadId|string} id - the load's id
 * @param {Params} [params]
 */
export const searchLoadPath = (id, params) =>
  withParams(`/search/load/${id}`)(params)

/**
 * @param {Haully.LoadId|string} id - the load's id
 * @param {Params} [params]
 */
export const loadAssignPath = (id, params) =>
  withParams(`/load/${id}/assign`)(params)

/**
 * @param {Haully.LoadId|string} id - the load's id
 * @param {Params} [params]
 */
export const loadAssignDriverPath = (id, params) =>
  withParams(`/load/${id}/assign-driver`)(params)

/**
 * @param {Haully.LoadId|string} id - the load's id
 * @param {Params} [params]
 */
export const loadEditDriverPath = (id, params) =>
  withParams(`/load/${id}/change-driver`)(params)

/**
 * @param {Haully.LoadId|string} id - the load's id
 * @param {Params} [params]
 */
export const loadEditPickupDropoffPath = (id, params) =>
  withParams(`/load/${id}/change-pickup-dropoff`)(params)

/**
 * @param {Haully.LoadId|string} id - the load's id
 * @param {Params} [params]
 */
export const loadAssignDetailsPath = (id, params) =>
  withParams(`/load/${id}/assign-details`)(params)

export const invoicesPaidPath = withParams(`/invoices/paid`)
export const invoicesPendingPath = withParams(`/invoices/pending`)
/**
 * @param {Haully.OrderId|string} id - the invoice's id
 * @param {Params} [params]
 */
export const invoiceDetailsPath = (id, params) =>
  withParams(`/invoices/details/${id}`)(params)

/**
 * @param {Haully.LoadId|string} id - the load's id
 * @param {Params} [params]
 */
export const loadBackhaulPath = (id, params) =>
  withParams(`/load/${id}/backhauls`)(params)

export const contactDispatchPath = withParams(`/contact-dispatch`)

export const resetPath = withParams(`/reset`)
export const resetPasswordPath = withParams(`/reset/password`)
export const comingSoonPath = withParams(`/coming-soon`)
export const expiredPasswordPath = withParams(`/password-expired`)
