import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { LoadAssignCard } from 'src/components/LoadCard'
import LoadingScreen from 'src/components/Loading'
import PageMeta from 'src/components/PageMeta'
import { Body2, Body1 } from 'src/components/Text'
import Box from 'src/components/Box'
import { loadAssignDriverPath } from 'src/paths'
import LoadPickupDropoffForm from 'src/containers/LoadPickupDropoffForm'
import { Screen } from 'src/components/Screen'
import CenteredContainer from 'src/components/CenteredContainer'
import Flex from 'src/components/Flex'

/**
 * @typedef Props
 * @property {import('src/stores/LoadStore').default} loadStore
 * @property {string} loadId
 */

/**
 * @augments {Component<Props & import('@reach/router').History>}
 */
@inject('loadStore')
@observer
class LoadAssign extends Component {
  state = {
    isCancelModalOpen: false,
  }

  componentDidMount() {
    this.props.loadStore.fetchLoad(parseInt(this.props.loadId))
    this.props.loadStore.loadPickupDeliveryForm.reset()
  }

  handleSubmit = async () => {
    const loadId = parseInt(this.props.loadId)
    if (await this.props.loadStore.assignLoad(loadId)) {
      this.props.navigate(loadAssignDriverPath(loadId))
    }
  }

  render() {
    const loadId = parseInt(this.props.loadId)
    const load = this.props.loadStore.load(loadId)

    if (!load) return <LoadingScreen />

    return (
      <Screen>
        <PageMeta title={`Claim Load ${load.id}`} />
        <LoadAssignCard
          load={load}
          stepCount={1}
          stepDescription="Schedule"
          title="A few details to claim your load."
          onRequestClose={() => window.history.go(-1)}
        />
        <CenteredContainer width="600" breakpoint="large">
          <Box p={3}>
            <Flex justifyContent="center">
              <Body2 mb={2}>
                We just need you to complete a few details before you hit the
                road.
              </Body2>
            </Flex>
            <Flex justifyContent="center">
              <Body1 mb={4}>Set your pick-up and drop-off times.</Body1>
            </Flex>
            <CenteredContainer>
              <LoadPickupDropoffForm
                loadStore={this.props.loadStore}
                load={load}
                onSubmit={this.handleSubmit}
              />
            </CenteredContainer>
          </Box>
        </CenteredContainer>
      </Screen>
    )
  }
}

export default LoadAssign
