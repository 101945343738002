import { format } from 'date-fns'
import subMonths from 'date-fns/sub_months'
import { Observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Box from 'src/components/Box'
import CollapsibleContainer from 'src/components/CollapsibleContainer'
import selectLabelContainerStyles from 'src/components/SelectLabelContainer.css'
import { REGIONS } from 'src/data/company'
import { useDashboardStore } from 'src/hooks/useStores'
import useWindowSize from 'src/hooks/useWindowSize'
import { invoicesPaidPath, loadsClaimedPath } from 'src/paths'
import { formatCurrency, formatDollars } from 'src/util/formatCurrency'
import { formatNumber } from 'src/util/formatNumber'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
} from 'victory'
import { LinkUnderlinedH2 } from './ButtonUnderlined'
import styles from './CollapsibleContainerMySummary.css'
import Flex from './Flex'
import { Option, Select } from './Select'
import { Heading, Numerals, Small, TextContainer, H2Bold } from './Text'

import * as colors from 'src/data/colors'
import { MONTH_SHORT } from 'src/data/dateFormats'

const COMING_SOON = true

const CollapsibleContainerMySummary = () => {
  const [regionValue, setRegionValue] = useState('MidAtlantic')

  const dashboardStore = useDashboardStore()

  useEffect(() => {
    dashboardStore.loadPaymentSummary()
    dashboardStore.loadLoadSummary()
    dashboardStore.loadRevenueSummary(regionValue)
  }, [dashboardStore, regionValue])

  return (
    <Observer>
      {() => {
        const {
          paymentSummary,
          loadSummary,
          revenueSummary,
          sectionExpandedState,
        } = dashboardStore

        if (!paymentSummary || !loadSummary || !revenueSummary) return null

        return (
          <CollapsibleContainer
            title="6 Month Summary"
            isExpanded={sectionExpandedState.mySummary}
            onToggle={() => dashboardStore.toggleSectionCollapse('mySummary')}
            bg="white"
          >
            {COMING_SOON ? (
              <>
                <SectionComingSoon title="Payments" />
                <SectionComingSoon title="Loads" />
                <SectionComingSoon title="Rev/Mile Trend" />
              </>
            ) : (
              <>
                <PaymentSummarySection data={paymentSummary} />
                <LoadSummarySection data={loadSummary} />
                <RevenueSummarySection
                  data={revenueSummary}
                  regionValue={regionValue}
                  onRegionChange={e => setRegionValue(e.target.value)}
                />
              </>
            )}
          </CollapsibleContainer>
        )
      }}
    </Observer>
  )
}

/*** @param {Haully.PaymentSummary} data */
const PaymentSummarySection = ({ data }) => {
  const today = new Date()
  const formattedChartData = [...Array(6).keys()].map(i => ({
    x: format(subMonths(today, 6 - i), MONTH_SHORT),
    y: data[`paymentAmountMonth${i + 1}`],
    label: formatCurrency(data[`paymentAmountMonth${i + 1}`]),
  }))

  return (
    <Box p={3} border="bottom">
      <ChartGrid
        title="Payments"
        cta="View Payments"
        ctaTo={invoicesPaidPath()}
        stat1Label="Total Payments"
        stat1Value={formatDollars(data.paymentsAmountTotal)}
        stat2Label="Average per truck"
        stat2Value={formatDollars(data.paymentsAmountPerTruck)}
      />

      <VictoryChart
        domainPadding={20}
        containerComponent={<VictoryContainer desc="Payments summary chart" />}
      >
        <VictoryAxis
          offsetY={48}
          style={{
            axis: {
              stroke: colors.MID_GRAY,
              strokeWidth: 4,
              strokeLinecap: 'square',
            },
          }}
        />
        <VictoryBar
          barWidth={40}
          data={formattedChartData}
          style={{
            data: { fill: colors.LIGHT_BLUE },
            labels: { fill: colors.WHITE },
          }}
          labelComponent={
            <VictoryTooltip
              cornerRadius={0}
              flyoutStyle={{
                stroke: colors.BLACK,
                fill: colors.BLACK,
              }}
            />
          }
        />
      </VictoryChart>
    </Box>
  )
}

/*** @param {Haully.LoadSummary} data */
const LoadSummarySection = ({ data }) => {
  const today = new Date()

  const milesChartData = [...Array(6).keys()].map(i => ({
    x: format(subMonths(today, 6 - i), MONTH_SHORT),
    y: data[`milesPerMonth${i + 1}`],
    label: data[`milesPerMonth${i + 1}`],
  }))

  const carsChartData = [...Array(6).keys()].map(i => ({
    x: format(subMonths(today, 6 - i), MONTH_SHORT),
    y: data[`carsPerMonth${i + 1}`],
    label: data[`carsPerMonth${i + 1}`],
  }))

  return (
    <Box p={3} border="bottom">
      <ChartGrid
        title="Loads"
        cta="View My Loads"
        ctaTo={loadsClaimedPath()}
        stat1Label={`${formatNumber(
          data.averageMilesPerTruck
        )} Average per truck`}
        stat1Value={`${formatNumber(data.totalMiles)} mi.`}
        stat2Label={`${data.averageCarsPerTruck} Average per truck`}
        stat2Value={`${data.totalCars} cars`}
      />

      <VictoryChart
        domainPadding={20}
        containerComponent={<VictoryContainer desc="Load summary chart" />}
      >
        <VictoryAxis
          offsetY={48}
          style={{
            axis: {
              stroke: colors.MID_GRAY,
              strokeWidth: 4,
              strokeLinecap: 'square',
            },
          }}
        />

        <VictoryGroup offset={23}>
          <VictoryBar
            barWidth={20}
            data={carsChartData}
            style={{ labels: { fill: colors.WHITE } }}
            labelComponent={
              <VictoryTooltip
                cornerRadius={0}
                flyoutStyle={{
                  stroke: colors.BLACK,
                  fill: colors.BLACK,
                }}
              />
            }
          />
          <VictoryBar
            barWidth={20}
            data={milesChartData}
            style={{
              data: { fill: colors.LIGHT_BLUE },
              labels: { fill: colors.WHITE },
            }}
            labelComponent={
              <VictoryTooltip
                cornerRadius={0}
                flyoutStyle={{
                  stroke: colors.BLACK,
                  fill: colors.BLACK,
                }}
              />
            }
          />
        </VictoryGroup>
      </VictoryChart>
    </Box>
  )
}

/*** @param {Haully.RevenueSummary} data */
const RevenueSummarySection = ({ data, regionValue, onRegionChange }) => {
  const { isLarge } = useWindowSize()

  const today = new Date()

  const totalRevenueData = [...Array(6).keys()].map(i => ({
    x: format(subMonths(today, 6 - i), MONTH_SHORT),
    y: data[`totalRevenuePerMileMonth${i + 1}`],
  }))

  const compareRevenueData = [...Array(6).keys()].map(i => ({
    x: format(subMonths(today, 6 - i), MONTH_SHORT),
    y: data[`compareRevenuePerMileMonth${i + 1}`],
  }))

  return (
    <Box p={3}>
      <Flex
        justifyContent="space-between"
        alignItems={isLarge ? 'center' : undefined}
        flexDirection={isLarge ? undefined : 'column'}
      >
        <Heading>Rev/Mile Trend</Heading>

        <Box className={selectLabelContainerStyles.SelectLabelContainer}>
          <Select value={regionValue} onChange={onRegionChange}>
            {REGIONS.map((option, i) => (
              <Option key={i} value={option.field}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Box>
      </Flex>

      <VictoryChart
        domainPadding={20}
        containerComponent={<VictoryContainer desc="Revenue summary chart" />}
      >
        <VictoryAxis />
        <VictoryAxis
          dependentAxis
          tickFormat={x => `${x}/mi`}
          tickLabelComponent={<VictoryLabel dx={5} />}
        />

        <VictoryGroup offset={20}>
          <VictoryLine data={totalRevenueData} />

          <VictoryLine
            data={compareRevenueData}
            style={{ data: { stroke: colors.LIGHT_BLUE } }}
          />
        </VictoryGroup>
      </VictoryChart>
    </Box>
  )
}
export default CollapsibleContainerMySummary

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.cta
 * @param {string} props.ctaTo
 * @param {string} props.stat1Label
 * @param {string} props.stat1Value
 * @param {string} props.stat2Label
 * @param {string} props.stat2Value
 */
const ChartGrid = props => (
  <div className={styles.ChartGrid}>
    <Heading className={styles.ChartGridTitle}>{props.title}</Heading>
    <Box className={styles.ChartGridCta}>
      <LinkUnderlinedH2 to={props.ctaTo}>{props.cta}</LinkUnderlinedH2>
    </Box>

    <Box className={styles.ChartGridStat1}>
      <Numerals>{props.stat1Value}</Numerals>
      <Small>{props.stat1Label}</Small>
    </Box>

    <Box className={styles.ChartGridStat2}>
      <Numerals>{props.stat2Value}</Numerals>
      <Small>{props.stat2Label}</Small>
    </Box>
  </div>
)

/**
 * @param {object} props
 * @param {string} props.title
 */
const SectionComingSoon = props => (
  <Box p={3} border="bottom">
    <div className={styles.ChartGrid}>
      <Heading className={styles.ChartGridTitle}>{props.title}</Heading>
      <TextContainer pt={2} pb={2}>
        <H2Bold>Coming Soon!</H2Bold>
      </TextContainer>
    </div>
  </Box>
)
