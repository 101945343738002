import React from 'react'
import styles from './TabNav.css'
import { Link } from '@reach/router'
import cn from 'classnames'

export const TabNav = props => (
  <div className={styles.TabNavContainer}>
    <nav className={styles.TabNav} {...props} />
  </div>
)

export const Tab = ({ to, children }) => (
  <Link
    to={to}
    getProps={({ isPartiallyCurrent }) => ({
      className: cn(styles.Tab, { [styles.TabActive]: isPartiallyCurrent }),
    })}
  >
    {children}
  </Link>
)
