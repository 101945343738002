import React from 'react'
import { Link } from '@reach/router'

import HeadingContainer from 'src/components/HeadingContainer'
import PageMeta from 'src/components/PageMeta'
import Container from 'src/components/Container'
import { Heading } from 'src/components/Text'

const Invoices = () => (
  <Container>
    <PageMeta title="Invoices" />
    <HeadingContainer>
      <Heading>Invoices</Heading>
    </HeadingContainer>

    <p>Example Page</p>

    <Link to="/">Dashboard</Link>
  </Container>
)

export default Invoices
