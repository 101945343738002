import React from 'react'
import { Component } from 'react'
import { observer } from 'mobx-react'
import { H2, NoWrap } from 'src/components/Text'
import { BoxButton, PlainButton } from 'src/components/Button'
import FilteredDriverList from 'src/containers/FilteredDriverList'
import { DriverName } from 'src/components/DriverCard'
import Flex from 'src/components/Flex'
import { RadioButton } from 'src/components/RadioButton'
import { HorizontalScrollContainer } from 'src/components/ScrollContainer'
import { Option, PlainSelect } from 'src/components/Select'
import DateSelector from 'src/screens/DateSelector'
import Box from 'src/components/Box'
import { format } from 'date-fns'
import { MONTH_DAY } from 'src/data/dateFormats'
import { FixedFullWindow } from 'src/components/Fixed'

/**
 * @typedef {object} Props
 * @property {import('src/models/FilterForm').default} form
 * @property {import('src/stores/DriverStore').default} driverStore
 * @property {boolean} filterDrivers
 * @property {boolean} filterTomorrowFuture
 * @property {boolean} filterDateDropdown
 * @property {boolean} [isFilterDriversOnRight]
 * @property {() => void} onFilterChange
 *
 * @augments {Component<Props>}
 */
@observer
class DriverDateFilters extends Component {
  componentDidMount() {
    this.form.reset()
  }

  handleShowDriverFilter = () => {
    this.form.values.showDriverFilter = true
  }

  handleHideDriverFilter = () => {
    this.form.values.showDriverFilter = false
    window.scrollTo(0, 0)
  }

  get form() {
    return this.props.form
  }

  /** @param {Haully.Driver|Haully.DriverTypeahead | null } selectedDriver */
  handleSelectDriver = selectedDriver => {
    const { form } = this
    form.update({ driverId: selectedDriver ? selectedDriver.driverId : null })
    if (form.values.driverId !== null) {
      this.props.driverStore.loadDriver(form.values.driverId)
    }
    this.handleHideDriverFilter()
    this.props.onFilterChange()
  }

  /** @param {import('src/models/FilterForm').DateRangeChoice} range */
  handleDateRangeClick = range => () => {
    const { form } = this
    if (form.values.dateRange === range) {
      form.values.dateRange = null
    } else {
      form.values.dateRange = range
    }
    this.props.onFilterChange()
  }

  handleDateDropdownChange = e => {
    const option = this.form.choices.dateDropdown.find(
      ({ value }) => e.currentTarget.value === value
    )

    if (!option) throw new Error()

    this.form.update({
      dateDropdown: option.value,
      showDatePicker: option.value === 'custom',
    })

    this.props.onFilterChange()
  }

  handleDismissCustomDateRangeClick = () => {
    this.form.update({
      dateDropdown: '',
      customDateFrom: null,
      customDateTo: null,
    })
  }

  render() {
    const form = this.form
    const { showDriverFilter } = form.values

    let driver = null
    if (form.values.driverId !== null) {
      driver = this.props.driverStore.drivers.get(form.values.driverId)
    }

    const { customDateFrom, customDateTo } = form.values

    const appendFilterBy =
      form.values.dateDropdown !== ''
        ? `Filter By: ${
            form.choices.dateDropdown.filter(
              c => c.value === form.values.dateDropdown
            )[0].label
          }`
        : null

    const customOptionLabel =
      customDateFrom && customDateTo
        ? [
            format(customDateFrom, MONTH_DAY),
            ' – ',
            format(customDateTo, MONTH_DAY),
          ].join('')
        : appendFilterBy

    return (
      <>
        <HorizontalScrollContainer snap={false}>
          <Flex
            justifyContent="space-between"
            bg="white"
            p={3}
            border="bottom"
            flex={1}
          >
            {this.props.filterTomorrowFuture && (
              <Flex pr={3}>
                <RadioButton
                  isActive={form.values.dateRange === 'tomorrow'}
                  onPress={this.handleDateRangeClick('tomorrow')}
                  title="Tomorrow"
                  isFirst={true}
                  testID="LoadsClaimedDateRangeTomorrow"
                />
                <RadioButton
                  isActive={form.values.dateRange === 'future'}
                  onPress={this.handleDateRangeClick('future')}
                  title="Future"
                  isLast={true}
                  testID="LoadsClaimedDateRangeFuture"
                />
              </Flex>
            )}

            <PlainButton
              onClick={
                showDriverFilter
                  ? this.handleHideDriverFilter
                  : this.handleShowDriverFilter
              }
              data-test="LoadsClaimedToggleDriverFilter"
              style={{
                marginLeft: this.props.isFilterDriversOnRight
                  ? 'auto'
                  : undefined,
              }}
            >
              <H2>
                <NoWrap>
                  FILTER BY:{' '}
                  {driver ? <DriverName driver={driver} /> : 'ALL DRIVERS'}
                </NoWrap>
              </H2>
            </PlainButton>

            {this.props.filterDateDropdown &&
              (customOptionLabel ? (
                <Flex>
                  <BoxButton onClick={this.handleDismissCustomDateRangeClick}>
                    <NoWrap>{customOptionLabel}</NoWrap>
                  </BoxButton>
                </Flex>
              ) : (
                <PlainSelect
                  value={form.values.dateDropdown}
                  onChange={this.handleDateDropdownChange}
                >
                  {form.choices.dateDropdown.map((option, i) => (
                    <Option key={i} value={option.value}>
                      {option.value === 'custom'
                        ? customOptionLabel || option.label
                        : option.label}
                    </Option>
                  ))}
                </PlainSelect>
              ))}
          </Flex>
        </HorizontalScrollContainer>

        {form.values.showDatePicker && (
          <FixedFullWindow>
            <Box bg="white" pt={2}>
              <DateSelector
                onSubmit={dates => {
                  form.update({
                    showDatePicker: false,
                    customDateFrom: dates.from,
                    customDateTo: dates.to,
                  })
                  this.props.onFilterChange()
                }}
                onClose={() => {
                  form.update({
                    showDatePicker: false,
                    dateDropdown: '',
                  })
                  this.props.onFilterChange()
                }}
              />
            </Box>
          </FixedFullWindow>
        )}

        {showDriverFilter && (
          <Flex bg="white" flexDirection="column">
            <FilteredDriverList
              driverStore={this.props.driverStore}
              onDriverSelected={this.handleSelectDriver}
              selectedDriverId={form.values.driverId}
              isAllDriversOption={true}
            />
          </Flex>
        )}
      </>
    )
  }
}

export default DriverDateFilters
