const getStack = () => {
  const stack = new Error().stack
  if (!stack) return
  return stack
    .split('\n')
    .slice(3)
    .join('\n')
}

export default getStack
