import React from 'react'
import styles from './NoticeBanner.css'
import Flex from 'src/components/Flex'
import CloseButton from './CloseButton.js'

/**
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 * @param {() => void} [props.onDismiss]
 */
const NoticeBanner = ({ children, onDismiss, ...props }) => (
  <div className={styles.NoticeBanner} data-test="noticeBanner" {...props}>
    <NoticeBannerHeader onDismiss={onDismiss} />
    {children}
  </div>
)

/**
 * @param {object} props
 * @param {() => void} [props.onDismiss]
 * @param {React.ReactNode} [props.children]
 */
export const NoticeBannerHeader = ({ onDismiss, children }) => (
  <Flex pr={2} className={styles.NoticeBannerHeader} flexDirection="column">
    {onDismiss && (
      <CloseButton
        data-test="noticeBannerClose"
        onClick={onDismiss}
        className={styles.NoticeBannerHeaderClose}
      />
    )}
    {children}
  </Flex>
)

export default NoticeBanner
