import { filenameWithDate } from './filename'
import { openCsv } from './openBlob'
import { addParams } from './url'

const ARBITRARY_LARGE_PAGE_SIZE_NUMBER_FOR_DOWNLOADS_AND_EMAILS = 10000

/**
 * @typedef {(args: { driverId: Haully.DriverId, subject: string, message: string }) => Promise<[boolean, string]>} EmailCallback
 */

/** @param {import("src/stores/RootStore").ApiRequest} apiRequest */
export const emailActionBasic = apiRequest =>
  /**
   * @param {object} args
   * @param {{[x: string]: any}} [args.data]
   * @param {string} args.apiPath
   *
   * @returns {EmailCallback}
   */
  ({ data = {}, apiPath }) =>
    /**
     * @param {object} cargs
     * @param {Haully.DriverId} cargs.driverId
     * @param {string} cargs.subject
     * @param {string} cargs.message
     * @returns {Promise<[boolean, string]>}
     */
    async ({ driverId, subject, message }) => {
      apiPath = addParams(apiPath, { resultType: 'Email' })

      const resp = await apiRequest(
        apiPath,
        { method: 'POST' },
        {
          ...data,
          email: {
            toUserId: driverId,
            subject,
            body: message,
          },
        }
      )

      return [resp.ok, resp.data]
    }

/** @param {import("src/stores/RootStore").ApiRequest} apiRequest */
export const emailActionPaginated = apiRequest =>
  /**
   * @param {object} args
   * @param {{[x: string]: any}} [args.data]
   * @param {string} args.apiPath
   *
   * @returns {EmailCallback}
   */
  ({ data = {}, apiPath }) =>
    /**
     * @param {object} cargs
     * @param {Haully.DriverId} cargs.driverId
     * @param {string} cargs.subject
     * @param {string} cargs.message
     * @returns {Promise<[boolean, string]>}
     */
    async ({ driverId, subject, message }) => {
      apiPath = addParams(apiPath, {
        resultType: 'Email',
      })

      const { criteria, ...rest } = data

      const resp = await apiRequest(
        apiPath,
        { method: 'POST' },
        {
          criteria: {
            ...criteria,
            pageSize: ARBITRARY_LARGE_PAGE_SIZE_NUMBER_FOR_DOWNLOADS_AND_EMAILS,
          },
          email: {
            toUserId: driverId,
            subject,
            body: message,
          },
          ...rest,
        }
      )

      return [resp.ok, resp.data]
    }

/** @param {import("src/stores/RootStore").ApiRequest} apiRequest */
export const tsvDownloadAction = apiRequest =>
  /**
   * @param {object} args
   * @param {any} [args.data]
   * @param {string} args.apiPath
   * @param {string} args.filename
   */
  ({ data = {}, apiPath, filename }) => async () => {
    apiPath = addParams(apiPath, { resultType: 'CSV' })
    const { criteria, ...rest } = data
    const resp = await apiRequest(
      apiPath,
      { method: 'POST' },
      {
        criteria: {
          ...criteria,
          pageSize: ARBITRARY_LARGE_PAGE_SIZE_NUMBER_FOR_DOWNLOADS_AND_EMAILS,
        },
        ...rest,
      }
    )

    if (resp.text != null) {
      openCsv(resp.text, filenameWithDate(filename))
      return
    }

    throw new Error('invariant: expected data')
  }
