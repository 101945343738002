import React, { useState } from 'react'
import { useLoadStore } from 'src/hooks/useStores'
import styles from './GlobalSearchBar.css'
import { navigate } from '@reach/router'
import { loadPath, searchPath } from 'src/paths'
import SearchIcon from 'src/icons/SearchIcon.svg'
import Flex from './Flex'
import Box from './Box'
import Modal from './Modal'
import CloseButton from './CloseButton'
import { TextContainer, Heading } from './Text'
import { LinkButton, IconButton } from './Button'
import { Spinner } from './Loading'

export const GlobalSearchBar = () => {
  const loadStore = useLoadStore()
  const [loadSearchInput, setLoadSearchInput] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    if (loadSearchInput.length) {
      setIsLoading(true)
      const response = await loadStore.fetchLoadPresence(
        parseInt(loadSearchInput)
      )
      setIsLoading(false)
      if (response) {
        if (response.ok) {
          /** @type {Haully.LoadDetailed} */
          let load = response.data
          if (load.assignedToCurrentCarrier || load.unAssigned)
            navigate(loadPath(load.id))
          else {
            setErrorMessage(
              `We're sorry, the load you are looking for has already been claimed.`
            )
          }
        } else {
          setErrorMessage(response.data.message)
        }
      } else {
        setErrorMessage(
          `We're sorry, the load you are looking for does not exist.`
        )
      }
    }
  }

  return (
    <>
      <Flex alignItems="center">
        <input
          className={styles.SearchBar}
          placeholder="Search Load Number"
          value={loadSearchInput}
          onChange={e => setLoadSearchInput(e.target.value)}
          disabled={isLoading}
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              onSubmit()
            }
          }}
        />
        <IconButton mt={1} ml={1} onClick={onSubmit}>
          {isLoading ? (
            <Spinner style={{ width: 24, height: 24, marginRight: '6px' }} />
          ) : (
            <SearchIcon className={styles.Icon} />
          )}
        </IconButton>
      </Flex>
      <NoticeModal
        isOpen={errorMessage !== ''}
        errorMessage={errorMessage}
        onRequestClose={() => setErrorMessage('')}
      />
    </>
  )
}

/**
 * @param {{errorMessage?: string} & import('./Modal').ModalProps} props
 */
const NoticeModal = ({ errorMessage, ...props }) => {
  return (
    <Modal overlayBackground="blue" {...props}>
      <Flex justifyContent="flex-end" pt={2} pr={2}>
        <CloseButton variant="black" onClick={props.onRequestClose} />
      </Flex>
      <Flex pl={3} pr={3} pb={4} lpl={4} lpr={4} bg="white">
        <Box m={2} lm={4} style={{ width: '100%' }}>
          <Heading mb={3}>{errorMessage}</Heading>
          <TextContainer lpt={5} lmb={2}>
            <LinkButton to={searchPath()}>Find Another Load</LinkButton>
          </TextContainer>
        </Box>
      </Flex>
    </Modal>
  )
}
