import React, { Component } from 'react'
import DayPicker from 'react-day-picker'
// @ts-ignore
import { DateUtils } from 'react-day-picker'
import CloseButton from 'src/components/CloseButton'
import { Screen, ScreenBottom } from 'src/components/Screen'
import { PrimaryButton } from 'src/components/Button'
import DayPickerStyles from 'src/components/DayPicker.css'
import Flex from 'src/components/Flex'
import { Row, Column } from 'src/components/Layout'
import { parse } from 'date-fns'

/**
 * @typedef {object} Props
 * @property {Date|undefined} [from]
 * @property {Date|undefined} [to]
 * @property {({ from, to }: { from: Date, to: Date } | { from: undefined, to: undefined }) => void} onSubmit
 * @property {() => void} onClose
 *
 * @typedef {object} State
 * @property {Date|undefined} from
 * @property {Date|undefined} to
 *
 * @augments {Component<Props, State>}
 */
class DateSelector extends Component {
  /** @type {State} */
  state = {
    from: this.props.from ? parse(this.props.from) : undefined,
    to: this.props.to ? parse(this.props.to) : undefined,
  }

  resetFromAndTo() {
    this.setState({
      from: undefined,
      to: undefined,
    })
  }

  handleDayClick = day => {
    this.setState(state => {
      // if both "from" and "to" are set, and user chooses a date, start over
      const rangeState = state.from && state.to ? {} : state
      // @ts-ignore
      return DateUtils.addDayToRange(day, rangeState)
    })
  }

  render() {
    const { onClose, onSubmit } = this.props
    const { from, to } = this.state

    const selectedDays = from && to ? { from, to } : undefined

    return (
      <Flex bg="white">
        <Screen>
          <Flex ml={3} mt={2}>
            <CloseButton variant="black" onClick={() => onClose()} />
          </Flex>

          <DayPicker
            classNames={DayPickerStyles}
            selectedDays={selectedDays}
            numberOfMonths={2}
            modifiers={{
              [DayPickerStyles.from]: from,
              [DayPickerStyles.to]: to,
            }}
            onDayClick={this.handleDayClick}
          />

          <ScreenBottom p={3}>
            {' '}
            <Row>
              <Column width="equal">
                <PrimaryButton
                  variant="outline"
                  block={true}
                  onClick={() => this.resetFromAndTo()}
                >
                  Reset
                </PrimaryButton>
              </Column>
              <Column width="equal">
                <PrimaryButton
                  variant="primary"
                  block={true}
                  onClick={() => {
                    if (from && to) return onSubmit({ from, to })
                    if (!from && !to) return onSubmit({ from, to })
                    if (from && !to) return onSubmit({ from, to: from })

                    throw new Error(
                      `invariant: unexpected from/to: ${from}, ${to}`
                    )
                  }}
                >
                  Select Dates
                </PrimaryButton>
              </Column>
            </Row>
          </ScreenBottom>
        </Screen>
      </Flex>
    )
  }
}

export default DateSelector
