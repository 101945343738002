import React, { useState } from 'react'
import Box from 'src/components/Box'
import MarketingContainer from 'src/components/MarketingContainer'
import PageMeta from 'src/components/PageMeta'
import { Body2, H1, Heading, TextContainer } from 'src/components/Text'
import CenteredContainer from 'src/components/CenteredContainer'
import { Link } from '@reach/router'
import {
  contactUsPath,
  paymentPreferencesPath,
  videoResourcePath,
} from 'src/paths'
import styles from 'src/components/FAQ.css'
import cn from 'classnames'
import { BoxButton } from 'src/components/Button'
import { CONTACT_PHONE_NUMBER } from 'src/data/company'
import InsuranceDocument from 'src/documents/schedule_a_insurance.pdf'

const FAQ = () => (
  <MarketingContainer>
    <PageMeta title="Tips and Tools - FAQ" />

    <CenteredContainer width="900">
      <Box p={3} bg="white">
        <Heading mb={3}>FAQ</Heading>

        <H1 mb={2}>What is Haully?</H1>
        <Body2>
          Haully is a new mobile optimized technology platform developed by
          car-hauling experts at United Road. This platform is designed to find
          great loads faster that help you grow your business with new tools to
          manage your business.
        </Body2>

        {/*

        // shop section
        // original req: https://www.pivotaltracker.com/story/show/171965923
        // hidden: https://www.pivotaltracker.com/story/show/172062590
        // will be brought back soon i hope!

        import shopsData from '../data/shops.json'
        <FaqSection title="Mechanic Shop Locations & Contact Information">
          <Body2>
            United Road is currently offering carrier partners the opportunity
            to utilize our Mechanic Shops at reduced rates. Contact the shop
            directly to make an appointment. Below is a list of locations and
            contact information.
          </Body2>
          <div className={styles.shopsTableContainer}>
            <table className={styles.shopsTable}>
              <thead>
                <tr>
                  <th className={styles.leftAlign}>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zip Code</th>
                  <th>Office Phone</th>
                  <th>Ext</th>
                </tr>
              </thead>
              <tbody>
                {shopsData.map((shop, i) => (
                  <tr key={i}>
                    <td className={styles.leftAlign}>{shop.Address}</td>
                    <td>{shop.City}</td>
                    <td>{shop.State}</td>
                    <td>{shop['Zip Code']}</td>
                    <td colSpan={shop.Ext ? undefined : 2}>
                      {shop['Office Phone'] && (
                        <a
                          href={`tel:${shop['Office Phone']},${shop.Ext}`}
                          style={{ color: 'inherit' }}
                        >
                          {shop['Office Phone']}
                        </a>
                      )}
                      {shop['Office Email'] && (
                        <a
                          href={`mailto:${shop['Office Email']}`}
                          style={{ color: 'inherit' }}
                        >
                          {shop['Office Email']}
                        </a>
                      )}
                    </td>
                    {shop.Ext != null && <td>{shop.Ext}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </FaqSection> */}
        <FaqSection title="COVID 19">
          <H1 mt={3}>
            Considering the COVID-19 pandemic, have any delivery protocols
            changed?
          </H1>
          <Body2 mt={2}>
            Yes! The health and safety of all our professional car and
            truckhaulers is our priority. We have new touchless delivery
            protocols. Signatures are no longer required at delivery, however,
            in order to get paid, you still need to submit receipt through our
            V-TAS app. We also provide steering wheel covers. And, as always,
            please do your part: use good sanitization methods, wear your masks
            when you’re around others, practice social distancing when picking
            up and delivering loads, and keep your trucks clean.
          </Body2>
        </FaqSection>
        <FaqSection title="Insurance">
          <Body2>
            To keep you safe while you&#39;re driving, United Road has specific
            insurance requirements for cargo and automobile liability. For
            detailed information regarding our insurance requirements, please
            contact us at{' '}
            <a href={`tel:${CONTACT_PHONE_NUMBER}`}>{CONTACT_PHONE_NUMBER}</a>.
          </Body2>

          <H1 mt={3}>What is Gap Insurance?</H1>
          <Body2 mt={2}>
            Gap Insurance coverage is needed to ensure you are protected in the
            event a damage occurs when you are driving the vehicles during the
            loading/unloading process. Generally, cargo and auto liability
            insurance policies do not cover a vehicle for the short
            distance/time between being unloaded from the trailer to the parking
            location at the destination. Typically, an additional policy (known
            as Gap Insurance) is needed to ensure you don’t experience a lapse
            in total insurance coverage.
          </Body2>

          <H1 mt={3}>What if I can&#39;t obtain Gap Insurance?</H1>
          <Body2 mt={2}>
            In the event you are not able to obtain Gap Insurance through your
            provider, United Road can help. We offer you coverage on a per
            vehicle basis; no additional policies are required. The gap
            surcharge fee is $1.25 per vehicle delivered and is deducted from
            the order invoice.
          </Body2>
          <Body2 mt={2}>
            If you have any questions about Gap Insurance or would like to
            evaluate your existing insurance policies, our partners at Hylant
            Insurance can help:
          </Body2>
          <TextContainer mt={2}>
            <Body2>
              Phone: <a href="tel:260-969-3979">260-969-3979</a>
              <br />
              Email:{' '}
              <a href="mailto:urshield@hylant.com">urshield@hylant.com</a>
            </Body2>
          </TextContainer>

          <TextContainer mt={2}>
            <Body2>
              <a href={InsuranceDocument} download>
                Schedule A Insurance
              </a>
            </Body2>
          </TextContainer>
        </FaqSection>

        <FaqSection title="Damages/Accidents">
          <H1 mt={3}>How do I report a damage or an accident?</H1>
          <Body2 mt={2}>
            United Road believes in safety and quality, but we also understand
            that things can happen on the road and damages and accidents occur.
            To communicate a damage or an accident, please refer to the Claims
            Settlement Investigation (CSI) Department contact information below:
          </Body2>
          <TextContainer mt={2}>
            <Body2>
              United Road CSI Department
              <br />
              1398 North Sooner Road
              <br />
              Oklahoma City, OK 73117
              <br />
              Toll Free: <a href="tel:844.556.2625">844.556.2625</a>
              <br />
              Local: <a href="tel:405.619.1400">405.619.1400</a>
              <br />
              Fax: <a href="tel:888.275.8051">888.275.8051</a>
            </Body2>
          </TextContainer>
        </FaqSection>

        <FaqSection title="Payments">
          <H1 mt={3}>When will I receive payment for my orders?</H1>
          <Body2 mt={2}>
            Payment dates can be viewed on the Haully site on the Pending
            Payments or the Paid screen.  If you have an issue or questions
            about a payment, our team is ready to help.
          </Body2>
          <Body2 mt={2}>
            <Link to={contactUsPath()}>Contact Us</Link>
          </Body2>

          <H1 mt={3}>
            Why is my rate on pending/paid screen less than what I was told at
            the time of dispatch?
          </H1>
          <Body2 mt={2}>
            At the time of load assignment, you will receive a confirmation
            email showing detailed information about load assignment including
            the rate of pay.  In the event you cannot pick up a vehicle on your
            load, the payment for the load will be adjusted and you will receive
            an email communication with the updated payment amount for your
            load. We recommend that you review this communication as soon as
            possible and reach out to dispatch for resolution.
          </Body2>
          <Body2 mt={2}>
            <Link to={contactUsPath()}>Contact Us</Link>
          </Body2>

          <H1 mt={3}>Why is my account on hold?</H1>
          <Body2 mt={2}>
            Sometimes unexpected things can happen and we want to make sure we
            get your payments right! If there is an issue with your account
            (payment information/damages/etc.), we may place your account on
            hold. If your account is placed on hold, our team is here to help.
          </Body2>
          <Body2 mt={2}>
            <Link to={contactUsPath()}>Contact Us</Link>
          </Body2>

          <H1 mt={3}>Am I able to receive payment the same day I deliver?</H1>
          <Body2 mt={2}>
            Our verification process of load accuracy occurs within 24 business
            hours after you submit, through V-TAS Delivery app, that the load
            was received. Importantly, we now have touchless delivery protocols
            to help ensure your safety and health. Verifying the loads is key to
            ensuring you are paid accurately and in a timely manner for each
            load you haul for United Road. While we require the use of our V-TAS
            delivery app for proof of delivery, we do understand that issues may
            arise which prevent you from using this mobile app such as a broken
            phone or lack of service. When this occurs, a manually signed bill
            of lading (BOL) must be submitted before payment is sent. To
            manually submit a signed BOL, please email{' '}
            <a href="mailto:AcctsPayURS@unitedroad.com">
              AcctsPayURS@unitedroad.com
            </a>
            .
          </Body2>
          <Body2 mt={2}>
            When you registered with United Road, you selected one of the
            following payment terms:
          </Body2>
          <TextContainer mt={2}>
            <Body2>
              Express Pay (2-day ACH)
              <br />
              Quick Pay (14-day ACH)
              <br />
              30-Day Pay (ACH)
            </Body2>
          </TextContainer>
          <Body2 mt={2}>
            You can easily change your payment terms by clicking on the link
            below which will take you to the Haully Payment Preferences Screen.
          </Body2>
          <Body2 mt={2}>
            <Link to={paymentPreferencesPath()}>Payment Preferences</Link>
          </Body2>
        </FaqSection>

        <FaqSection title="Equipment">
          <H1 mt={3}>What types of equipment does United Road accept?</H1>
          <Body2 mt={2}>
            <ol>
              <li>
                Transportation vehicles must have a fifth wheel on all equipment
                (no goose necks are allowed).
              </li>
              <li>All equipment must meet DOT safety regulations.</li>
              <li>
                During vehicle transport, all four wheels of transported
                vehicles must be properly strapped (chains are not allowed for
                tire strapping). NOTE: It is assumed that all carrier drivers
                follow the federal DOT requirements for equipment size. For
                detailed DOT requirements and information, please click on the
                DOT link provided below:
                <br />
                <a href="https://www.fmcsa.dot.gov">www.fmcsa.dot.gov</a>
              </li>
            </ol>
          </Body2>
        </FaqSection>

        <FaqSection title="Communication">
          <>
            <Body2 mt={3}>
              We’re moving to keep you moving. So, if you are in need of more
              information or have a question, please contact our dispatch team.
            </Body2>
            <Body2 mt={2}>
              <Link to={contactUsPath()}>Contact Us</Link>
            </Body2>
          </>
        </FaqSection>

        <FaqSection title="Finding Freight">
          <Body2 mt={3}>
            For detailed explanation on how to find great loads, please click on
            the link below:
          </Body2>
          <Body2 mt={2}>
            <Link to={videoResourcePath('find-hauls')}>
              Finding Freight Tutorial
            </Link>
          </Body2>
        </FaqSection>
      </Box>
    </CenteredContainer>
  </MarketingContainer>
)

/**
 * @param {object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 */
const FaqSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Box mt={3}>
      <BoxButton
        className={cn(styles.FaqHeader, isExpanded && styles.FaqHeaderExpanded)}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Heading mb={2}>{title}</Heading>
      </BoxButton>
      {isExpanded && <div className={styles.FaqContents}>{children}</div>}
    </Box>
  )
}
export default FAQ
