import React from 'react'
import { LinkUnderlinedH2 } from 'src/components/ButtonUnderlined'
import Flex from 'src/components/Flex'
import { H2, Numerals } from 'src/components/Text'
import DownloadButton from './DownloadButton'
import EmailLoadsButton from './EmailLoadsButton'
import Grid from './Grid'
import { formatNumber } from 'src/util/formatNumber'

/**
 * @param {object} props
 * @param {number|undefined} props.count
 * @param {string} props.label
 * @param {string} props.linkPath
 * @param {string} [props.ctaLabel]
 * @param {() => Promise<void>} [props.onDownload]
 * @param {import('src/util/apiActions').EmailCallback} [props.onEmail]
 */
const LoadCountCard = ({
  count,
  label,
  linkPath,
  ctaLabel = 'View Loads',
  onDownload,
  onEmail,
  ...props
}) => (
  <Flex
    flexDirection="column"
    flex={1}
    pl={3}
    pr={3}
    pt={2}
    pb={2}
    bg="white"
    {...props}
  >
    <Numerals>{count != null ? formatNumber(count) : '-'}</Numerals>
    <H2 mb={2}>{label}</H2>
    <Flex justifyContent="space-between" alignItems="center">
      <LinkUnderlinedH2 to={linkPath}>
        {ctaLabel.toUpperCase()}
      </LinkUnderlinedH2>

      <Grid gap={2} autoFlow="column">
        {onDownload && (
          <DownloadButton
            onClick={onDownload}
            actionLabel={`Download ${label}`}
          />
        )}

        {onEmail && (
          <EmailLoadsButton
            actionLabel={`Email ${label}`}
            onSendEmail={onEmail}
          />
        )}
      </Grid>
    </Flex>
  </Flex>
)

export default LoadCountCard
