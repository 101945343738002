import React from 'react'
import styles from './HeaderWithBackButton.css'
import { PlainButton } from 'src/components/Button'
import {
  OverlayHeader,
  OverlayHeaderLeftButton,
  OverlayHeaderContent,
} from 'src/components/OverlayHeader'
import { BodyText } from 'src/components/Text'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'

/**
 * @param {Object} props
 * @param {string} props.label
 */ const HeaderWithBackButton = ({ label, ...props }) => (
  <OverlayHeader {...props}>
    <OverlayHeaderLeftButton>
      <PlainButton onClick={() => window.history.go(-1)}>
        <LeftArrowIcon className={styles.FillCurrentColor} />
      </PlainButton>
    </OverlayHeaderLeftButton>
    <OverlayHeaderContent>
      <BodyText>{label}</BodyText>
    </OverlayHeaderContent>
  </OverlayHeader>
)

export default HeaderWithBackButton
