import React, { useEffect, useState } from 'react'
import Box from 'src/components/Box'
import { LinkButton } from 'src/components/Button'
import CenteredContainer from 'src/components/CenteredContainer'
import Flex from 'src/components/Flex'
import LoadBackhaulsSection from 'src/components/LoadBackhaulsSection'
import {
  LoadAssignCardBody,
  LoadBackButton,
  LoadCardTopContainer,
  LoadPickupDropoff,
} from 'src/components/LoadCard'
import LoadingScreen from 'src/components/Loading'
import Modal from 'src/components/Modal'
import { NoticeBannerHeader } from 'src/components/NoticeBanner'
import PageMeta from 'src/components/PageMeta'
import { PickupDropoffDetails } from 'src/components/PickupDropoffDetails'
import { Body2, Title } from 'src/components/Text'
import Wrapper from 'src/components/Wrapper'
import CancelLoad from 'src/containers/CancelLoad'
import { useErrorStore, useLoadStore } from 'src/hooks/useStores'
import useWindowSize from 'src/hooks/useWindowSize'
import { loadsClaimedPath, searchPath } from 'src/paths'
import { AssignedDriver } from '../components/AssignedDriver'

/**
 * @param {object} props
 * @param {string} props.loadId
 */
const LoadAssignDetails = props => {
  const { loadId } = props
  const loadIdNumber = parseInt(loadId)
  const [isLoading, setIsLoading] = useState(true)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const loadStore = useLoadStore()
  const errorStore = useErrorStore()
  const { isLarge } = useWindowSize()

  useEffect(() => {
    loadStore.fetchLoad(loadIdNumber).then(load => {
      if (!load) {
        return
      }

      if (load.assignedToCurrentCarrier) {
        setIsLoading(false)
      } else {
        errorStore.addError('Load is not assigned to your carrier', {
          report: false,
        })
      }
    })
  }, [errorStore, loadIdNumber, loadStore])

  const load = loadStore.load(loadIdNumber)

  if (isLoading) return <LoadingScreen />
  if (!load) throw new Error('invariant: expected load')

  return (
    <Wrapper>
      <PageMeta title={`Claimed Load Details`} section={`Load ${load.id}`} />
      <NoticeBannerHeader>
        <CenteredContainer width="1050">
          <Flex flexDirection="column" p={3}>
            <Body2>LOAD CLAIMED</Body2>
            <Title>You’re ready to hit the road</Title>
          </Flex>
        </CenteredContainer>
      </NoticeBannerHeader>
      <Box bg="black" color="white">
        <LoadCardTopContainer>
          <LoadBackButton load={load} />
        </LoadCardTopContainer>

        <LoadAssignCardBody>
          <Box pl={3} pr={3} pb={3}>
            <LoadPickupDropoff load={load} />
            <Flex mt={3}>
              <Box mr={2}>
                <LinkButton to={loadsClaimedPath()} variant="outline">
                  View All Claimed
                </LinkButton>
              </Box>
              <LinkButton to={searchPath()} variant="primary">
                Find More Loads
              </LinkButton>
            </Flex>
          </Box>
        </LoadAssignCardBody>
      </Box>

      <CenteredContainer width="900">
        <Flex flexDirection={isLarge ? 'row' : 'column'}>
          <Flex flex={1} border={isLarge ? 'right' : 'bottom'}>
            <PickupDropoffDetails load={load} />
          </Flex>
          <Flex flex={1} border={isLarge ? undefined : 'bottom'}>
            <AssignedDriver load={load} />
          </Flex>
        </Flex>
      </CenteredContainer>

      <LoadBackhaulsSection load={load} />

      <Box pb={4} pt={4} bg="light-gray-blue">
        &nbsp;
      </Box>
      <Modal
        isOpen={isCancelModalOpen}
        onRequestClose={() => setIsCancelModalOpen(false)}
      >
        <CancelLoad
          load={load}
          onRequestClose={() => setIsCancelModalOpen(false)}
          returnToDetailOnCancel={true}
        />
      </Modal>
    </Wrapper>
  )
}

export default LoadAssignDetails
