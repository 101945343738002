import { Link } from '@reach/router'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { PrimaryButton } from 'src/components/Button'
import CenteredContainer from 'src/components/CenteredContainer'
import CloseButton from 'src/components/CloseButton'
import { FixedBottom } from 'src/components/Fixed'
import Flex from 'src/components/Flex'
import { LoadBasicCard } from 'src/components/LoadCard'
import LoadingScreen from 'src/components/Loading'
import Modal from 'src/components/Modal'
import PageMeta from 'src/components/PageMeta'
import { Body1, Body2 } from 'src/components/Text'
import Wrapper from 'src/components/Wrapper'
import FilteredDriverList from 'src/containers/FilteredDriverList'
import { loadEditPickupDropoffPath, loadPath } from 'src/paths'

/**
 * @typedef Props
 * @property {import('src/stores/LoadStore').default} loadStore
 * @property {import('src/stores/DriverStore').default} driverStore
 * @property {import('src/stores/FlashMessageStore').default} flashMessageStore
 * @property {string} loadId
 *
 * @augments {Component<Props & import('@reach/router').History>}
 */
@inject('loadStore', 'driverStore', 'flashMessageStore')
@observer
class LoadEditDriver extends Component {
  state = {
    isLoading: true,
  }

  async componentDidMount() {
    const loadId = parseInt(this.props.loadId)
    const load = await this.props.loadStore.fetchLoad(loadId)

    if (load) {
      this.props.driverStore.loadDriverForm.update({ driverId: load.driverId })
      this.setState({ isLoading: false })
    }
  }

  handleSubmit = async () => {
    const loadId = parseInt(this.props.loadId)

    if (await this.props.driverStore.submitAssignLoadDriverForm(loadId)) {
      this.props.flashMessageStore.queue(`Changed driver successfully`)
      this.props.navigate(loadPath(loadId))
    }
  }

  goToLoadDetail = () => this.props.navigate(loadPath(this.props.loadId))

  render() {
    const loadId = parseInt(this.props.loadId)
    const load = this.props.loadStore.load(loadId)
    const form = this.props.driverStore.loadDriverForm

    if (this.state.isLoading || !load) return <LoadingScreen />

    return (
      <Wrapper>
        <PageMeta title={`Change Driver`} section={`Load ${load.id}`} />
        <LoadBasicCard load={load} />
        <CenteredContainer width="600" breakpoint="large">
          <Box p={3}>
            <Body1>Change driver</Body1>
          </Box>
          <FilteredDriverList
            driverStore={this.props.driverStore}
            onDriverSelected={selectedDriver => {
              form.update({
                driverId: selectedDriver ? selectedDriver.driverId : null,
              })
            }}
            selectedDriverId={form.values.driverId}
          />
          <FixedBottom bg="white" p={2}>
            <Flex justifyContent="center">
              <PrimaryButton
                onClick={this.handleSubmit}
                disabled={form.isSubmitting}
                data-test="LoadEditDriverSubmit"
              >
                Change Driver
              </PrimaryButton>
            </Flex>
          </FixedBottom>
        </CenteredContainer>

        <Modal
          isOpen={!load.scheduledDelivery || !load.scheduledPickup}
          onRequestClose={this.goToLoadDetail}
        >
          <Flex justifyContent="flex-end" pt={2} pr={2}>
            <CloseButton variant="black" onClick={this.goToLoadDetail} />
          </Flex>
          <Flex
            pl={3}
            pr={3}
            pb={4}
            lpl={4}
            lpr={4}
            bg="white"
            flexDirection="column"
          >
            <Box m={2} lm={4}>
              <Body2>
                Please enter your{' '}
                <Link to={loadEditPickupDropoffPath(load.id)}>
                  pick up and drop off dates
                </Link>{' '}
                before assigning a driver.
              </Body2>
            </Box>
          </Flex>
        </Modal>
      </Wrapper>
    )
  }
}

export default LoadEditDriver
