import { reaction } from 'mobx'

/**
 * @param {string} key
 * @param {() => Object} serializeFn
 * @param {(data: Object) => void} deserializeFn
 */
const localStorageSync = (key, serializeFn, deserializeFn) => {
  const unreact = reaction(serializeFn, data => {
    localStorage.setItem(key, JSON.stringify(data))
  })

  const persistedState = localStorage.getItem(key)
  if (persistedState) {
    deserializeFn(JSON.parse(persistedState))
  }

  return unreact
}

export default localStorageSync
