import React, { useState } from 'react'
import Flex from 'src/components/Flex'
import MarketingContainer from 'src/components/MarketingContainer'
import PageMeta from 'src/components/PageMeta'
import { Title } from 'src/components/Text'
import useWindowSize from 'src/hooks/useWindowSize'
import styles from 'src/components/VideoResource.css'
import ReactPlayer from 'react-player'

const VIDEO_RESOURCES = {
  'search-by-load': {
    title: 'Search by Load - #1 Requested Feature Requested by Carriers',
    asset: 'https://youtu.be/cy0KmA_6nbk',
  },
  overview: {
    title: 'Overview',
    asset: 'https://youtu.be/mCCseCvoWso',
  },
  'navigation-overview': {
    title: 'Navigation Overview',
    asset: 'https://youtu.be/RrU96F3Qi8U',
  },
  'find-hauls': {
    title: 'How to Find Hauls',
    asset: 'https://youtu.be/yk5AAJtTGu4',
  },
  'claiming-freight': {
    title: 'Claiming Freight',
    asset: 'https://youtu.be/un6q1kODzJk',
  },
  'managing-hauls': {
    title: 'Managing Hauls',
    asset: 'https://youtu.be/A2dEjYYOO9c',
  },
  'vtas-andriod': {
    title: 'V-TAS Delivery Guide - Android',
    asset: 'https://youtu.be/DCkfALpIzlU',
  },
  'vtas-andriod-spanish': {
    title: 'V-TAS Delivery Guide - Android - Español',
    asset: 'https://youtu.be/K1Gksr8v2IE',
  },
  'vtas-iphone': {
    title: 'V-TAS Delivery Guide - iPhone',
    asset: 'https://youtu.be/DMKuJDIkAUI',
  },
  'vtas-iphone-spanish': {
    title: 'V-TAS Delivery Guide - iPhone - Español',
    asset: 'https://youtu.be/fI6g2aCd42w',
  },
}

/**
 * @typedef Props
 * @property {string} [slug]
 */

/** @param {Props} props */
const VideoResource = ({ slug }) => (
  <MarketingContainer>
    <PageMeta title={VIDEO_RESOURCES[slug]['title']} />
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      lm={5}
      m={3}
    >
      <Title mb={3} lmb={4}>
        {VIDEO_RESOURCES[slug]['title']}
      </Title>
      <VideoResourcePlayer video={VIDEO_RESOURCES[slug]['asset']} />
    </Flex>
  </MarketingContainer>
)
export default VideoResource

/**
 * @param {*} props
 */
const VideoResourcePlayer = props => {
  const { isLarge } = useWindowSize()
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  return (
    <>
      <ReactPlayer
        url={props.video}
        className={styles.Video}
        width={isLarge ? 640 : 340}
        height={isLarge ? 360 : 190}
        onClick={() => setIsVideoPlaying(!isVideoPlaying)}
        playing={isVideoPlaying}
        controls={true}
        {...props}
      />
    </>
  )
}
