import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@reach/disclosure'
import { format, getYear } from 'date-fns'
import React from 'react'
import Box from 'src/components/Box'
import { BlockButton } from 'src/components/Button'
import { LinkUnderlinedH2 } from 'src/components/ButtonUnderlined'
import {
  DaySelectorButton,
  DaySelectorButtonLabel,
} from 'src/components/DaySelectorButton'
import Flex from 'src/components/Flex'
import { FromToButton } from 'src/components/FromToButton'
import { Title } from 'src/components/Text'
import { MONTH_DAY, MONTH_DAY_YEAR_SHORT } from 'src/data/dateFormats'
import useWindowSize from 'src/hooks/useWindowSize'
import SlideControlIcon from 'src/icons/SlideControls.svg'
import TrackBadge from 'src/icons/TrackBadge.svg'
import { searchMapPath } from 'src/paths'
import AdvancedSearch from 'src/screens/AdvancedSearch'
import { BooleanParam, useQueryParam } from 'use-query-params'
import { SEARCH_MODES } from './LoadSearch'
import styles from './MainSearchForm.css'

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string|undefined} props.originCity
 * @param {string|undefined} props.originState
 * @param {string|undefined} props.destinationCity
 * @param {string|undefined} props.destinationState
 * @param {Date|undefined} props.availableFrom
 * @param {Date|undefined} props.availableThru
 * @param {() => void} props.onFromClick
 * @param {() => void} props.onToClick
 * @param {() => void} props.onDateClick
 * @param {boolean} props.isMapButtonVisible
 * @param {boolean} props.showSaveSearchButton
 * @param {boolean} props.currentSearchIsSaved
 * @param {() => void} props.onSaveSearch
 * @param {boolean} props.isSearchLoading
 * @param {() => void} props.onSubmit
 * @param {() => string} props.searchValuesAsString
 * @param {() => void} props.onApplyFilters
 *
 */
export const MainSearchForm = ({
  title,
  originCity,
  originState,
  destinationCity,
  destinationState,
  onFromClick,
  onToClick,
  onDateClick,
  availableFrom,
  availableThru,
  isMapButtonVisible,
  showSaveSearchButton,
  currentSearchIsSaved,
  onSaveSearch,
  isSearchLoading,
  onSubmit,
  searchValuesAsString,
  onApplyFilters: onApplyFiltersProp,
}) => {
  const { isLarge } = useWindowSize()

  const [isOpen, setOpen] = useQueryParam('advanced', BooleanParam)

  const onApplyFilters = () => {
    setOpen(false)
    onApplyFiltersProp()
  }

  return (
    <Box lmr={3} bg={'light-gray-blue'} className={styles.container}>
      <form id="findLoads" onSubmit={onSubmit}>
        <Disclosure open={isOpen || false} onChange={() => setOpen(!isOpen)}>
          <Flex justifyContent="center" pt={3} mb={3}>
            <Title>{title}</Title>
          </Flex>
          <div className={styles.searchControlsGrid}>
            <FromToButton
              onClick={onFromClick}
              city={originCity}
              state={originState}
              label={SEARCH_MODES.from}
              className={styles.searchControlsFrom}
            />

            <TrackBadge className={styles.LocationSearchTrackBadge} />

            <FromToButton
              onClick={onToClick}
              city={destinationCity}
              state={destinationState}
              label={SEARCH_MODES.to}
              className={styles.searchControlsTo}
            />

            <Flex lmt={0} lmb={0} className={styles.searchControlsDates}>
              <DaySelectorButton onClick={onDateClick}>
                <Box lpl={4} lpr={4}>
                  <DaySelectorButtonLabel>
                    {availableFrom && availableThru
                      ? [
                          formatDate(availableFrom),
                          ' - ',
                          formatDate(availableThru),
                        ].join('')
                      : 'Pick-up Dates'}
                  </DaySelectorButtonLabel>
                </Box>
              </DaySelectorButton>
            </Flex>

            <DisclosureButton
              as={props => (
                <BlockButton
                  color="white"
                  bg="light-blue"
                  className={styles.searchControlsAdvanced}
                  {...props}
                />
              )}
            >
              <SlideControlIcon style={{ marginRight: 10 }} />
              ADVANCED SEARCH OPTIONS
            </DisclosureButton>

            <BlockButton
              color="light-blue"
              bg="transparent"
              onClick={onSaveSearch}
              border="all"
              borderWidth={2}
              borderColor="current"
              disabled={currentSearchIsSaved || showSaveSearchButton}
              className={styles.searchControlsSave}
            >
              {currentSearchIsSaved ? 'SAVED' : 'SAVE SEARCH'}
            </BlockButton>

            <Flex className={styles.searchControlsSearch}>
              <BlockButton
                color="white"
                bg="light-blue"
                style={{ flexGrow: 1 }}
                disabled={isSearchLoading}
                onClick={onSubmit}
              >
                {isSearchLoading ? 'SEARCHING...' : 'SEARCH FOR LOADS'}
              </BlockButton>
            </Flex>

            <DisclosurePanel className={styles.advancedSearch}>
              <AdvancedSearch onApplyFilters={onApplyFilters} />
            </DisclosurePanel>
          </div>

          {isMapButtonVisible && !isLarge && (
            <Flex
              flexDirection={isLarge ? 'row' : 'column'}
              flex={1}
              justifyContent="space-between"
              lmt={3}
            >
              <Flex justifyContent="space-between" mt={2} mb={3}>
                <Flex>
                  <LinkUnderlinedH2
                    to={`${searchMapPath()}?${searchValuesAsString()}`}
                    ml={2}
                  >
                    Map
                  </LinkUnderlinedH2>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Disclosure>
      </form>
    </Box>
  )
}

/** @param {Date} date */
function formatDate(date) {
  if (getYear(date) === getYear(new Date())) {
    return format(date, MONTH_DAY)
  }
  return format(date, MONTH_DAY_YEAR_SHORT)
}
