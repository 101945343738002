import React, { Component } from 'react'
import CloseButton from 'src/components/CloseButton'
import {
  LocationSearchSectionButton,
  LocationSearchIconSectionButton,
  LocationSearchHeader,
} from 'src/components/LocationSearchSection'
import HeadingContainer from 'src/components/HeadingContainer'
import { inject, observer } from 'mobx-react'
import LocationSearchInput from 'src/components/LocationSearchInput'
import LocationSearchIcon from 'src/components/LocationSearchIcon'
import LoadingScreen from 'src/components/Loading'
import CurrentLocationIcon from 'src/icons/CurrentLocation.svg'
import AnywhereIcon from 'src/icons/Anywhere.svg'
import PlacesStore from 'src/stores/PlacesStore'
import { Screen } from 'src/components/Screen'
import SearchRow from 'src/components/SearchRow'
import Box from 'src/components/Box'
import Flex from 'src/components/Flex'

/**
 * @typedef Props
 * @property {() => void} onClose
 * @property {(placeResult: import('src/stores/PlacesStore').PlaceResult) => void} onSelect
 * @property {"From"|"To"} searchMode
 * @property {PlacesStore} [placesStore]
 * @property {any} savedSearches
 * @property {any} recentSearches
 * @property {(search: *) => void} onSearchClick
 * @property {() => void} onAnywhereClick
 *
 * @typedef State
 * @property {string} searchTerm
 * @property {boolean} isCurrentLocationLoading
 *
 * @augments {Component<Props, State>}
 */
@inject('placesStore')
@observer
class LocationSearch extends Component {
  state = {
    searchTerm: '',
    isCurrentLocationLoading: false,
  }

  componentWillUnmount() {
    this.placesStore.reset()
  }

  componentDidMount() {
    this.placesStore.loadGoogle()
  }

  handleLocationSearchResultInput = e => {
    e.preventDefault()
    this.setState({
      searchTerm: e.currentTarget.value,
    })
    this.placesStore.fetchPredictions(e.currentTarget.value)
  }

  handleSuggestionClick = async (placeId, e) => {
    e.preventDefault()
    const place = await this.placesStore.geocodeAndFormatResult({
      placeId: placeId,
    })

    if (place) this.props.onSelect(place)
  }

  /** @param {React.KeyboardEvent<HTMLInputElement>} e */
  handleInputKeyDown = e => {
    if (e.key === 'Enter') e.preventDefault()
  }

  get placesStore() {
    return /** @type {PlacesStore} */ (this.props.placesStore)
  }

  handleCurrentLocationClick = async () => {
    this.setState({ isCurrentLocationLoading: true })
    const placeResult = await this.placesStore.findUserLocation()
    this.setState({ isCurrentLocationLoading: false })
    if (placeResult) this.props.onSelect(placeResult)
  }

  render() {
    const {
      onClose,
      searchMode,
      savedSearches,
      recentSearches,
      onSearchClick,
      onAnywhereClick,
    } = this.props

    const { isLoadingMaps, filteredSuggestions } = this.placesStore

    if (isLoadingMaps) return <LoadingScreen />

    return (
      <Screen>
        <Flex bg="white" flexDirection="column">
          <LocationSearchHeader>
            <Box mr={3}>
              <CloseButton variant="black" onClick={onClose} />
            </Box>

            <LocationSearchInput
              placeholder={searchMode + ' where?'}
              onChange={this.handleLocationSearchResultInput}
              value={this.state.searchTerm}
              onKeyDown={this.handleInputKeyDown}
              autoFocus={true}
            />
          </LocationSearchHeader>

          {filteredSuggestions.length > 0 ? (
            <>
              {filteredSuggestions.map(suggestion => (
                <LocationSearchSectionButton
                  key={suggestion.place_id}
                  onClick={e =>
                    this.handleSuggestionClick(suggestion.place_id, e)
                  }
                >
                  {suggestion.description}
                </LocationSearchSectionButton>
              ))}
            </>
          ) : (
            <>
              <LocationSearchIconSectionButton
                onClick={this.handleCurrentLocationClick}
                disabled={this.state.isCurrentLocationLoading}
              >
                <LocationSearchIcon>
                  <CurrentLocationIcon />
                </LocationSearchIcon>
                {this.state.isCurrentLocationLoading
                  ? 'Loading Location...'
                  : 'Current Location'}
              </LocationSearchIconSectionButton>

              <LocationSearchIconSectionButton onClick={onAnywhereClick}>
                <LocationSearchIcon>
                  <AnywhereIcon />
                </LocationSearchIcon>
                Anywhere
              </LocationSearchIconSectionButton>
            </>
          )}

          <HeadingContainer theme="table">Saved Searches</HeadingContainer>

          {savedSearches.map(search => (
            <LocationSearchSectionButton
              key={search.id}
              onClick={() => {
                onSearchClick(search)
                onClose()
              }}
            >
              <SearchRow search={search} />
            </LocationSearchSectionButton>
          ))}

          <HeadingContainer theme="table">Recent Searches</HeadingContainer>

          {recentSearches.map(search => (
            <LocationSearchSectionButton
              key={search.id}
              onClick={() => {
                onSearchClick(search)
                onClose()
              }}
            >
              <SearchRow search={search} />
            </LocationSearchSectionButton>
          ))}
        </Flex>
      </Screen>
    )
  }
}

export default LocationSearch
