import RootStore from './RootStore'
import localStorageSync from './localStorageSync'
import { createContext } from 'react'
import { setupDevelopmentErrorReporting } from 'src/util/developmentErrors'

const LOCALSTORAGE_KEY = 'haullyRootStore'

const rootStore = new RootStore({
  onLogout: () => {
    localStorage.removeItem(LOCALSTORAGE_KEY)
    window.location.reload()
  },
})

localStorageSync(
  LOCALSTORAGE_KEY,
  () => rootStore.serialize,
  state => rootStore.deserialize(state)
)

if (rootStore.sessionStore.isLoggedIn) rootStore.sessionStore.loadViewer()

setupDevelopmentErrorReporting(rootStore)

export default createContext(rootStore)
