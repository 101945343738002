import React from 'react'
import { format } from 'date-fns'
import { Small } from 'src/components/Text'
import cityState from 'src/util/cityState'
import Box from './Box'

/**
 * @typedef {object} SearchRowProps
 * @property {object} props.search
 */

/**
 * @param {SearchRowProps} props
 */
const SearchRow = props => {
  const { search } = props
  const {
    originCity,
    originState,
    destinationCity,
    destinationState,
    availableFrom,
    availableThru,
  } = search.carrierSearchDto

  return (
    <>
      <Box mb={1}>
        {cityState(originCity, originState)} -{' '}
        {cityState(destinationCity, destinationState)}
      </Box>
      <Small>
        {format(availableFrom, 'MMM D')} - {format(availableThru, 'MMM D')}
      </Small>
    </>
  )
}

export default SearchRow
