import Form from './Form'
import {
  addDays,
  startOfDay,
  endOfDay,
  startOfYear,
  endOfYear,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  endOfMonth,
} from 'date-fns'

/** @type {{label: string, value: DateDropdownChoice}[]} */
const DATE_FILTER_OPTIONS = [
  { label: 'Filter By: Date', value: '' },
  { label: 'This Year', value: 'thisYear' },
  { label: 'This Month', value: 'thisMonth' },
  { label: 'This Week', value: 'thisWeek' },
  { label: 'Today', value: 'today' },
  { label: 'Custom Date Range', value: 'custom' },
]

/**
 * @typedef {'tomorrow'|'future'|null} DateRangeChoice
 * @typedef {''|'thisYear'|'thisMonth'|'thisWeek'|'today'|'custom'} DateDropdownChoice
 */

/** @type {DateDropdownChoice} */
const DATE_DROPDOWN_DEFAULT = ''

class FilterForm {
  form = new Form({
    values: {
      /** @type {number|null} */
      driverId: null,
      /** @type {DateRangeChoice} */
      dateRange: null,
      showDriverFilter: false,
      /** @type {DateDropdownChoice} */
      dateDropdown: DATE_DROPDOWN_DEFAULT,
      showDatePicker: false,
      /** @type {Date|null} */
      customDateFrom: null,
      /** @type {Date|null} */
      customDateTo: null,
      /** @type {string|null} */
      sortField: null,
      /** @type {boolean|null} */
      sortAscending: null,
    },
    choices: {
      dateDropdown: DATE_FILTER_OPTIONS,
    },
  })

  values = this.form.values
  reset = this.form.reset
  choices = this.form.choices
  update = this.form.update

  /** @param {DateRangeChoice} rangeChoice */
  dateRangeParams = rangeChoice => {
    switch (rangeChoice) {
      case null:
        return null
      case 'tomorrow': {
        const tomorrow = startOfDay(addDays(new Date(), 1))
        return {
          scheduledPickupFrom: tomorrow,
          scheduledPickupThru: endOfDay(tomorrow),
        }
      }
      case 'future':
        return {
          scheduledPickupFrom: new Date(),
        }
      default:
        throw new Error('unexpected rangeChoice: ' + rangeChoice)
    }
  }

  dateDropdownParams = () => {
    const dropdownChoice = this.form.values.dateDropdown
    const customFrom = this.form.values.customDateFrom
    const customTo = this.form.values.customDateTo

    const [from, to] = (() => {
      const now = new Date()
      switch (dropdownChoice) {
        case '':
          return [undefined, undefined]
        case 'thisYear':
          return [startOfYear(now), endOfYear(now)]
        case 'thisMonth':
          return [startOfMonth(now), endOfMonth(now)]
        case 'thisWeek':
          return [startOfWeek(now), endOfWeek(now)]
        case 'today':
          return [startOfDay(now), endOfDay(now)]
        case 'custom': {
          if (!customFrom || !customTo) return [undefined, undefined]
          return [startOfDay(customFrom), endOfDay(customTo)]
        }
        default:
          throw new Error('unexpected dropdown choice: ' + dropdownChoice)
      }
    })()

    return { from: from, to: to }
  }
}

export default FilterForm
