import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import TextInput from 'src/components/TextInput'
import { PrimaryButton } from 'src/components/Button'
import { Row, Column } from 'src/components/Layout'
import { navigate } from '@reach/router'
import SessionStore from 'src/stores/SessionStore'
import TruckStore from 'src/stores/TruckStore'
import Flex from 'src/components/Flex'
import { NoWrap, Heading } from 'src/components/Text'
import Divider from 'src/components/Divider'
import { CARRIER_TRUCK_TYPES } from 'src/models/CarrierTruck'
import { ButtonUnderlinedH2 } from 'src/components/ButtonUnderlined'
import Box from 'src/components/Box'

/**
 * @typedef {object} Props
 * @property {import('src/stores/SessionStore').default} [sessionStore]
 * @property {import('src/stores/TruckStore').default} [truckStore]
 * @property {string} cancelPath
 * @property {string} nextPath
 * @property {Haully.CarrierTruck} truck
 *
 * @augments {Component<Props>}
 */
@inject('sessionStore', 'truckStore')
@observer
class EditHaulerForm extends Component {
  componentDidMount() {
    this.truckStore.truckForm.reset()
    const { truck } = this.props

    this.truckStore.truckForm.update({
      fleetSize: truck.quantity === 1 ? '1 Truck' : 'Many',
      trucks: [
        {
          carrierEquipmentType: truck.carrierEquipmentType,
          quantity: truck.quantity || 1,
        },
      ],
    })
  }

  handleCancel = e => {
    e.preventDefault()
    navigate(this.props.cancelPath)
  }

  handleSubmit = async e => {
    e.preventDefault()

    if (await this.truckStore.submitEditTruckForm(this.props.truck)) {
      navigate(this.props.nextPath)
    }
  }

  removeTruck = async truck => {
    if (await this.truckStore.deleteTruck(truck)) {
      navigate(this.props.nextPath)
    }
  }

  get sessionStore() {
    return /** @type {SessionStore} */ (this.props.sessionStore)
  }

  get truckStore() {
    return /** @type {TruckStore} */ (this.props.truckStore)
  }

  render() {
    const isLoading = !this.sessionStore.viewer

    const { truck } = this.props
    const truckForm = this.truckStore.truckForm.values.trucks[0]
    const truckLabelIndex = truck.isFlatBed ? 10 : truck.capacity - 1

    return (
      <form onSubmit={this.handleSubmit}>
        <Heading>Edit Truck Quantity</Heading>
        <Divider />

        <Flex flexDirection="row" alignItems="center" pr={4} pl={4} mb={3}>
          <Box mr={3}>
            <NoWrap>I have</NoWrap>
          </Box>

          <Box mr={3}>
            <TextInput
              name="quantity"
              id={`truck-quantity`}
              value={truckForm.quantity.toString()}
              type="number"
              onChange={e => (truckForm.quantity = e.currentTarget.value)}
              required={true}
              placeholder=""
            />
          </Box>
          <Box ml={2}>
            <NoWrap>
              {truck.carrierEquipmentType}{' '}
              {CARRIER_TRUCK_TYPES[truckLabelIndex].label}
            </NoWrap>
          </Box>
        </Flex>

        <Row>
          <Column>
            <PrimaryButton
              variant="outline"
              block={true}
              onClick={this.handleCancel}
            >
              Cancel
            </PrimaryButton>
          </Column>
          <Column>
            <PrimaryButton
              block={true}
              type="submit"
              disabled={isLoading}
              data-test="onboardAddHaulerSubmitButton"
            >
              Update Truck
            </PrimaryButton>
          </Column>
        </Row>
        <Row>
          <Flex mt={2} flex={1} justifyContent="center">
            <ButtonUnderlinedH2
              onClick={() => {
                if (
                  window.confirm('Are you sure you wish to delete this truck?')
                )
                  this.removeTruck(truck.carrierTruckId)
              }}
            >
              Remove this Truck
            </ButtonUnderlinedH2>
          </Flex>
        </Row>
      </form>
    )
  }
}

export default EditHaulerForm
