import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import TextInput from 'src/components/TextInput'
import InputGroup from 'src/components/InputGroup'
import { PrimaryButton, IconButton, PlainButton } from 'src/components/Button'
import { Row, Column } from 'src/components/Layout'
import { navigate, Link } from '@reach/router'
import SessionStore from 'src/stores/SessionStore'
import TruckStore from 'src/stores/TruckStore'
import Radio from 'src/components//Radio'
import Flex from 'src/components//Flex'
import { NoWrap, Heading } from 'src/components/Text'
import { Select, Option, OptionBlank } from 'src/components/Select'
import Divider from 'src/components/Divider'
import Remove from 'src/icons/Remove.svg'
import AddTruck from 'src/icons/Add Truck.svg'
import { CARRIER_TRUCK_TYPES } from 'src/models/CarrierTruck'
import Box from './Box'
import ErrorMessage from './ErrorMessage'
import { FieldErrorMessages } from './FieldErrors'

/**
 * @typedef {object} Props
 * @property {import('src/stores/SessionStore').default} [sessionStore]
 * @property {import('src/stores/TruckStore').default} [truckStore]
 * @property {string} cancelPath
 * @property {string} nextPath
 * @property {boolean} [allowMultipleTrucks]
 *
 * @augments {Component<Props>}
 */
@inject('sessionStore', 'truckStore')
@observer
class AddHaulerForm extends Component {
  componentDidMount() {
    this.truckStore.truckForm.reset()
  }

  handleBasicChange = e => {
    this.truckStore.truckForm.update({
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  handleCancel = e => {
    e.preventDefault()
    navigate(this.props.cancelPath)
  }

  handleSubmit = async e => {
    e.preventDefault()

    if (await this.truckStore.submitTruckForm()) {
      navigate(this.props.nextPath)
    }
  }

  get sessionStore() {
    return /** @type {SessionStore} */ (this.props.sessionStore)
  }

  get truckStore() {
    return /** @type {TruckStore} */ (this.props.truckStore)
  }

  render() {
    const isLoading = !this.sessionStore.viewer
    const form = this.truckStore.truckForm
    const { values } = form
    const { allowMultipleTrucks } = this.props

    return (
      <form onSubmit={this.handleSubmit}>
        {allowMultipleTrucks ? (
          <>
            <p>How many trucks do you have?</p>
            <Row>
              {form.choices.fleetSize.map(choice => (
                <Column key={choice}>
                  <Radio
                    name="fleetSize"
                    value={choice}
                    checked={values.fleetSize === choice}
                    onChange={this.handleBasicChange}
                  >
                    {choice}
                  </Radio>
                </Column>
              ))}
            </Row>
          </>
        ) : (
          <Heading>Add a Truck</Heading>
        )}
        {values.trucks.map((truck, i) => (
          <Fragment key={i}>
            <Divider />

            <Flex justifyContent="space-between" alignItems="center">
              <p>Is your truck...</p>
              {values.trucks.length > 1 && (
                <div>
                  <IconButton onClick={() => form.removeTruck(i)}>
                    <Remove />
                  </IconButton>
                </div>
              )}
            </Flex>

            <Row>
              {form.choices.carrierEquipmentType.map(choice => (
                <Column key={choice}>
                  <Radio
                    name={`truck-${i}-style`}
                    value={choice}
                    checked={truck.carrierEquipmentType === choice}
                    onChange={e =>
                      (truck.carrierEquipmentType = e.currentTarget.value)
                    }
                  >
                    {choice}
                  </Radio>
                </Column>
              ))}
            </Row>

            <InputGroup>
              <Row key={i} center={true}>
                <Column width="auto">
                  <label htmlFor={`truck-${i}-type`}>
                    <NoWrap>
                      {values.fleetSize === '1 Truck' && 'It’s a'}
                      {values.fleetSize === 'Many' && 'I have'}
                    </NoWrap>
                  </label>
                </Column>

                {values.fleetSize === 'Many' && (
                  <Column width="auto" style={{ flex: 1 }}>
                    <TextInput
                      name="quantity"
                      id={`truck-${i}-quantity`}
                      value={truck.quantity.toString()}
                      type="number"
                      onChange={e => (truck.quantity = e.currentTarget.value)}
                      required={true}
                      placeholder=""
                    />
                  </Column>
                )}

                <Column width="auto" style={{ flex: 4 }}>
                  <Select
                    id={`truck-${i}-type`}
                    value={truck.carrierTruckIdx}
                    onChange={e =>
                      (truck.carrierTruckIdx = e.currentTarget.value)
                    }
                    required={true}
                  >
                    <OptionBlank />

                    {CARRIER_TRUCK_TYPES.map((truckType, i) => (
                      <Option key={i} value={i}>
                        {truckType.label}
                      </Option>
                    ))}
                  </Select>
                </Column>
              </Row>
            </InputGroup>
          </Fragment>
        ))}

        {values.fleetSize === 'Many' && allowMultipleTrucks && (
          <InputGroup>
            <Divider />

            <Row>
              <Column width="auto">
                <PlainButton onClick={form.addTruck}>
                  <AddTruck style={{ marginRight: 8 }} />
                  <span>Add Another Truck Type</span>
                </PlainButton>
              </Column>
            </Row>
          </InputGroup>
        )}

        <Row>
          <Column>
            <PrimaryButton
              variant="outline"
              block={true}
              onClick={this.handleCancel}
            >
              Cancel
            </PrimaryButton>
          </Column>
          <Column>
            <PrimaryButton
              block={true}
              type="submit"
              disabled={isLoading}
              data-test="onboardAddHaulerSubmitButton"
            >
              Add Truck
            </PrimaryButton>
          </Column>
        </Row>

        <FieldErrorMessages field="root" errors={form.form.validationErrors}>
          {({ messages }) => (
            <ErrorMessage>
              <Box mb={2} mt={2}>
                An error occured, you can{' '}
                <Link to={this.props.nextPath}>skip this step</Link>.
              </Box>
              <div>Error details:</div>
              <code>{messages.join(', ')}</code>
            </ErrorMessage>
          )}
        </FieldErrorMessages>
      </form>
    )
  }
}

export default AddHaulerForm
