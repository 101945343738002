import React from 'react'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading, Subheading, Body2 } from 'src/components/Text'
import { searchPath } from 'src/paths'
import PageMeta from 'src/components/PageMeta'
import OnboardingContainer from 'src/components/OnboardingContainer'
import { Screen } from 'src/components/Screen'
import { LinkButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import { OnboardFormMaxWidth } from 'src/components/MaxWidth'

const OnboardEnd = () => (
  <Screen>
    <OnboardingContainer>
      <PageMeta title="Great, let’s get moving!" />
      <OnboardFormMaxWidth>
        <HeadingContainer>
          <Heading mb={2}>Great, let’s get moving!</Heading>
          <Subheading mb={3}>
            You’ll receive a call from your dedicated dispatch member within the
            next 24 hours to help you book your first load.
          </Subheading>
          <Body2 mb={3}>Or</Body2>
          <Subheading>
            Get direct access to high-quality freight right now and browse, save
            and claim loads on your own.
          </Subheading>
        </HeadingContainer>
      </OnboardFormMaxWidth>
      <Flex justifyContent="center" lmb={3}>
        <LinkButton to={searchPath()} data-test="onboardEndSubmitButton">
          Find Loads
        </LinkButton>
      </Flex>
    </OnboardingContainer>
  </Screen>
)

export default OnboardEnd
