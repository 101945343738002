import React from 'react'
import styles from './Grid.css'
import cn from 'classnames'

/**
 * @param {object} props
 * @param {0|1|2|3|4|5|6} [props.gap]
 * @param {"row"|"column"} [props.autoFlow]
 * @param {"1fr"} [props.autoColumns]
 * @param {React.ReactNode} props.children
 */
const Grid = props => (
  <div
    className={cn(styles.Grid, {
      [styles[`Grid-gap-${props.gap}`]]: props.gap != null,
      [styles[`Grid-autoFlow-${props.autoFlow}`]]: props.autoFlow,
      [styles[`Grid-autoColumns-${props.autoColumns}`]]: props.autoColumns,
    })}
  >
    {props.children}
  </div>
)
export default Grid
