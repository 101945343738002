import React from 'react'
import { ScreenTop, Screen } from 'src/components/Screen'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading, Subheading } from 'src/components/Text'
import { onboardAddDriverPath, onboardHaulDrivePrefPath } from 'src/paths'
import PageMeta from 'src/components/PageMeta'
import LinkBasic from 'src/components/LinkBasic'
import Flex from 'src/components/Flex'
import OnboardingContainer from 'src/components/OnboardingContainer'
import OnboardingCounter from 'src/components/OnboardingCounter'
import { LinkButton } from 'src/components/Button'
import MobileButtonContainer from 'src/components/MobileButtonContainer'

const OnboardAddDriverConfirm = () => (
  <Screen>
    <PageMeta title="Add Driver Confirmation" />
    <ScreenTop>
      <OnboardingContainer>
        <OnboardingCounter position={5} backTo={onboardAddDriverPath()} />
        <HeadingContainer>
          <Heading mb={2}>Great, let’s get your driver on the road.</Heading>
          <Subheading>
            We’ll send your driver a link to VTAS to get started.
          </Subheading>
        </HeadingContainer>

        <MobileButtonContainer>
          <LinkButton
            to={onboardHaulDrivePrefPath()}
            data-test="onboardAddDriverConfirmSubmitButton"
          >
            Continue with Driving Preferences
          </LinkButton>
        </MobileButtonContainer>

        <Flex mt={3} justifyContent="center">
          <LinkBasic color="light-blue" to={onboardAddDriverPath()}>
            Or, add another driver.
          </LinkBasic>
        </Flex>
      </OnboardingContainer>
    </ScreenTop>
  </Screen>
)

export default OnboardAddDriverConfirm
