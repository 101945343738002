import React from 'react'
import styles from './withBackground.css'
import cn from 'classnames'
import getDisplayName from './getDisplayName'

interface HOCProps {
  bg?:
    | 'light-gray'
    | 'light-gray-blue'
    | 'black'
    | 'white'
    | 'light-blue'
    | 'transparent'
    | undefined
}

interface WithClass {
  className?: string | undefined
}

export default function withBackground<T extends WithClass>(
  TargetComponent: React.ComponentType<T>
) {
  const WrappedComponent: React.FC<HOCProps & T> = props => {
    const { className, bg, ...rest } = props
    const enhancedClassName = cn(className, {
      [styles[`Background-${bg}`]]: bg,
    })
    const newProps = rest as T

    return <TargetComponent className={enhancedClassName} {...newProps} />
  }

  WrappedComponent.displayName = `WithBackground(${getDisplayName(
    TargetComponent
  )})`

  return WrappedComponent
}
