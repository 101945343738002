import { computed, observable } from 'mobx'
import { stringify } from 'qs'
import Form from 'src/models/Form'
import PaginatedRequestState from 'src/models/PaginatedRequestState'
import { paginatedRequest } from 'src/util/paginatedRequest'
import Store from './Store'

const DRIVER_SORT_FIELD = 'LastName'

export default class DriverStore extends Store {
  clientDrivers = new PaginatedRequestState(
    pageNumber =>
      paginatedRequest({
        path: `Clients/${this.clientId}/Drivers`,
        rootStore: this.rootStore,
        method: 'GET',
        queryParams: { sortField: DRIVER_SORT_FIELD },
      })(pageNumber),
    // @ts-ignore not supported by ts 3.8
    /** @type {Haully.Driver} */ ({})
  )

  clientDriversIncludingInactive = new PaginatedRequestState(
    pageNumber =>
      paginatedRequest({
        path: `Clients/${this.clientId}/DriversIncludingInactive`,
        rootStore: this.rootStore,
        method: 'GET',
        queryParams: { sortField: DRIVER_SORT_FIELD },
      })(pageNumber),
    // @ts-ignore not supported by ts 3.8
    /** @type {Haully.Driver} */ ({})
  )

  /** @type {import('mobx').IObservableArray<Haully.DriverTypeahead>} */
  filteredClientDrivers = observable.array([])

  /** @type {import('mobx').IObservableArray<Haully.UserTypeahead>} */
  filteredUsers = observable.array([])

  @observable
  filteredUsersIsLoading = false

  /** @type {import('mobx').ObservableMap<Haully.DriverId, Haully.Driver>} */
  drivers = observable.map([])

  /** @type {import('mobx').IObservableArray<Haully.DriverId>} */
  loadingDrivers = observable.array([])

  loadDriverForm = new Form({
    values: {
      /** @type {number|null} */
      driverId: null,
    },
  })

  @observable
  addDriverForm = new Form({
    values: {
      firstName: '',
      lastName: '',
      email: '',
      cellPhoneNumber: '',
      twicCardExpiration: '',
      vtasPassword: '',
      notes: '',
      hasHandHeld: 'true',
    },
  })

  driversAddedCount = 0

  /** @param {string} searchTerm */
  updateFilteredDrivers = async searchTerm => {
    const params = stringify({
      searchTerm,
      maxResults: null,
      sortField: DRIVER_SORT_FIELD,
    })

    const resp = await this.rootStore.apiRequest(
      `Drivers/SearchByNameorEmail?${params}`
    )

    if (resp.ok) {
      if (!Array.isArray(resp.data))
        throw new Error(
          `invariant: expected array, got ${JSON.stringify(resp.data)}`
        )

      this.filteredClientDrivers.replace(resp.data)
    }
  }

  /** @param {string} searchTerm */
  updateFilteredUsers = async searchTerm => {
    this.filteredUsersIsLoading = true
    const resp = await this.rootStore.apiRequest(
      `UserAutoComplete/${searchTerm}/Contains`
    )

    if (resp.ok) {
      if (!Array.isArray(resp.data))
        throw new Error(
          `invariant: expected array, got ${JSON.stringify(resp.data)}`
        )

      this.filteredUsers.replace(resp.data)
      this.filteredUsersIsLoading = false
    }
  }

  submitAssignLoadDriverForm = async loadId => {
    const form = this.loadDriverForm

    form.isSubmitting = true
    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/${loadId}/ChangeDriver`,
      { method: 'PUT' },
      {
        driverId: form.values.driverId,
      }
    )
    form.isSubmitting = false

    return resp.ok
  }

  submitAddDriverForm = async () => {
    const resp = await this.rootStore.apiRequest(
      'Drivers',
      { method: 'POST', isErrorGlobal: false },
      {
        ...this.addDriverForm.values,
        clientId: this.rootStore.sessionStore.clientId,
      }
    )

    if (resp.ok) {
      this.driversAddedCount++
      return true
    }

    /** @type {import('src/models/Form').ValidationError[]} */
    const validationErrors = []

    if (resp.validationErrors) {
      validationErrors.push(...resp.validationErrors)
    }

    if (resp.data.message) {
      validationErrors.push({ field: 'root', message: resp.data.message })
    }

    this.addDriverForm.updateValidationErrors(validationErrors)

    return false
  }

  submitEditDriverForm = async driver => {
    const resp = await this.rootStore.apiRequest(
      'Drivers',
      { method: 'PUT' },
      {
        ...this.addDriverForm.values,
        driverId: driver.driverId,
        clientId: this.rootStore.sessionStore.clientId,
      }
    )

    /** @type {import('src/models/Form').ValidationError[]} */
    const validationErrors = []

    if (resp.validationErrors) {
      validationErrors.push(...resp.validationErrors)
    }

    if (resp.data.message) {
      validationErrors.push({ field: 'root', message: resp.data.message })
    }

    this.addDriverForm.updateValidationErrors(validationErrors)

    return resp.ok
  }

  /**
   * @param {Haully.DriverId} id
   */
  loadDriver = async id => {
    // bail early if driver was already fetched
    if (this.drivers.get(id)) return

    // bail early if driver is already being loaded
    if (this.loadingDrivers.includes(id)) return

    this.loadingDrivers.push(id)
    await this.fetchDriver(id)
    this.loadingDrivers.remove(id)
  }

  /** @param {Haully.DriverId} id */
  fetchDriver = async id => {
    const resp = await this.rootStore.apiRequest(`Drivers?driverId=${id}`)

    if (resp.data) {
      this.drivers.set(id, resp.data)
      return true
    }

    throw new Error('expected data')
  }

  /** @param {Haully.DriverId} id */
  deactivateDriver = async id => {
    const resp = await this.rootStore.apiRequest(`Drivers?driverId=${id}`, {
      method: 'DELETE',
    })

    return resp.ok
  }

  /** @param {Haully.DriverId} id */
  activateDriver = async id => {
    const resp = await this.rootStore.apiRequest(
      `Drivers/ActivateDriver?driverId=${id}`,
      {
        method: 'PUT',
      }
    )

    return resp.ok
  }

  @computed
  get clientId() {
    return this.rootStore.sessionStore.clientId
  }
}
