import React from 'react'
import CollapsibleContainerMyTasks from 'src/components/CollapsibleContainerMyTasks'
import LinkBannerTipsTools from 'src/components/LinkBannerTipsTools'
import Box from 'src/components/Box'

const Sidebar = () => (
  <Box mr={3} mt={4}>
    <CollapsibleContainerMyTasks />
    <LinkBannerTipsTools />
  </Box>
)

export default Sidebar
