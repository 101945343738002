import React, { useEffect, useState } from 'react'
import { Observer } from 'mobx-react'
import CollapsibleContainer from 'src/components/CollapsibleContainer'
import TrendCard from 'src/components/TrendCard'
import SelectLabelContainer from './SelectLabelContainer'
import { Select, Option } from './Select'
import Flex from './Flex'
import { useDashboardStore } from 'src/hooks/useStores'
import styles from './CollapsibleContainerMyTrends.css'
import { TextContainer, H2Bold } from './Text'

const COMPARE_RANGES = ['This Week', 'This Month', 'This Year']
const COMING_SOON = true

const CollapsibleContainerMyTrends = () => {
  const dashboardStore = useDashboardStore()
  const [rangeValue, setRangeValue] = useState(0)

  useEffect(() => {
    dashboardStore.loadLoadTrends(COMPARE_RANGES[rangeValue])
  }, [rangeValue, dashboardStore])

  return (
    <Observer>
      {() => {
        const { loadTrends, sectionExpandedState } = dashboardStore

        if (!loadTrends) return null

        if (COMING_SOON)
          return (
            <CollapsibleContainer
              title="My Trends"
              isExpanded={sectionExpandedState.myTrends}
              onToggle={() => dashboardStore.toggleSectionCollapse('myTrends')}
              bg="white"
            >
              <TextContainer pt={4} pb={4}>
                <H2Bold>Coming Soon!</H2Bold>
              </TextContainer>
            </CollapsibleContainer>
          )

        return (
          <CollapsibleContainer
            title="My Trends"
            isExpanded={sectionExpandedState.myTrends}
            onToggle={() => dashboardStore.toggleSectionCollapse('myTrends')}
            bg="white"
          >
            <Flex p={2} border="bottom" flex={1} alignItems="flex-end">
              <SelectLabelContainer label="Compare">
                <Select
                  value={rangeValue}
                  onChange={e => setRangeValue(parseInt(e.target.value))}
                >
                  {COMPARE_RANGES.map((option, i) => (
                    <Option key={i} value={i}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </SelectLabelContainer>
            </Flex>
            <div className={styles.TrendCardGrid}>
              <TrendCard
                label="Payments"
                fromValue={loadTrends.paymentsAmountFrom}
                toValue={loadTrends.paymentsAmountCompareTo}
                isCurrency={true}
              />
              <TrendCard
                label="Average Rate Per Mile"
                fromValue={loadTrends.ratePerMileFrom}
                toValue={loadTrends.ratePerMileCompareTo}
                isCurrency={true}
              />
              <TrendCard
                label="Units Per Load"
                fromValue={loadTrends.unitsPerLoadFrom}
                toValue={loadTrends.unitsPerLoadCompareTo}
              />
              <TrendCard
                label="Loads"
                fromValue={loadTrends.numLoadsFrom}
                toValue={loadTrends.numLoadsCompareTo}
              />
              <TrendCard
                label="On-Time Delivery"
                fromValue={loadTrends.onTimeLoadsFrom}
                toValue={loadTrends.onTimeLoadsCompareTo}
              />
              <TrendCard
                label="Damage Claims"
                fromValue={loadTrends.damageClaimsFrom}
                toValue={loadTrends.damageClaimsCompareTo}
              />
            </div>
          </CollapsibleContainer>
        )
      }}
    </Observer>
  )
}

export default CollapsibleContainerMyTrends
