import { observable } from 'mobx'
import Form from 'src/models/Form'
import Store from './Store'

class RegistrationStore extends Store {
  @observable
  carrierRegistrationForm = new Form({
    values: {
      registrationCode: '',
      companyName: '',
      ownerName: '',
      companyEmailAddress: '',
      mobilePhone: '',
      officePhone: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      dispatchContactName: '',
      dispatchContactNumber: '',
      insuranceAgentName: '',
      agentPhone: '',
      agentEmail: '',
      insuranceName: '',
      dotNumber: '',
      mcNumber: '',
      federalTaxId: '',
      totalNumberOfTrucks: '',
      totalCapacity: '',
    },
  })

  @observable
  registrationForm = new Form({
    values: {
      emailAddress: '',
      temporaryPassword: '',
      password: '',
      confirmationPassword: '',
    },
  })

  @observable
  userDetailsForm = new Form({
    values: {
      primaryPhone: '',
      cellPhone: '',
      userName: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      /** @type {number?} */
      id: null,
      /** @type {number?} */
      clientId: null,
    },
  })

  @observable
  contactUsForm = new Form({
    values: {
      feedback: '',
    },
  })

  @observable
  haulEquipmentForm = new Form({
    values: {
      carrierHaveTwicCard: false,
      carrierHaveStraps: false,
      carrierHaveWinches: false,
      carrierTrucksHaveInternet: false,
      carrierHandleInops: false,
    },
  })

  @observable
  haulDrivePrefForm = new Form({
    values: {
      lessThan500: false,
      moreThan500: false,
    },
  })

  @observable
  locationForm = new Form({
    values: {
      /** @type string[] */
      stateProvinceRegion: [],
    },
    choices: {
      currentLocations: [
        {
          label: 'Mid Atlantic',
          states: '(VA, WV, MD, DE, PA, NJ, DC)',
          value: 'MidAtlantic',
        },
        { label: 'Midwest', states: '(IL, IN, OH, MI, KY)', value: 'MidWest' },
        {
          label: 'North Central',
          states: '(KS, NE, SD, ND, WI, MN, IA, MO)',
          value: 'NorthCentral',
        },
        {
          label: 'Northeast',
          states: '(NY, CT, RI, MA, VT, NH, ME)',
          value: 'NorthEast',
        },
        {
          label: 'Northwest',
          states: '(OR, WA, ID, MT, AK)',
          value: 'NorthWest',
        },
        {
          label: 'South Central',
          states: '(TX, LA, OK, OR, AR, MS)',
          value: 'SouthCentral',
        },
        {
          label: 'Southeast',
          states: '(TN, NC, SC, GA, AL, FL)',
          value: 'SouthEast',
        },
        { label: 'Southwest', states: '(AZ, CA, NV)', value: 'SouthWest' },
        {
          label: 'Rocky Mountains',
          states: '(UT, CO, WY)',
          value: 'RockyMountains',
        },
      ],
    },
  })

  @observable
  suggestedLoads = null

  register = async () => {
    const { emailAddress, temporaryPassword } = this.registrationForm.values

    const sessionResp = await this.rootStore.sessionStore.createSessionRequest({
      username: emailAddress,
      password: temporaryPassword,
    })

    if (sessionResp.validationErrors) {
      this.registrationForm.updateValidationErrors(sessionResp.validationErrors)
      return false
    }

    if (!sessionResp.ok) return false
    if (sessionResp.data) {
      this.rootStore.sessionStore.setToken(sessionResp.data)
      await this.rootStore.sessionStore.loadViewer()

      const userResp = await this.updatePassword()

      return userResp
    }

    throw new Error('unexpected code path: expected data or validation errors')
  }

  updatePassword = async () => {
    const { password, confirmationPassword } = this.registrationForm.values

    // https://popups.vtvsolutions.com/Oviss/swagger/ui/index#!/Users/Users_Put
    const resp = await this.rootStore.apiRequest(
      'Users/PasswordUpdate',
      { method: 'PUT', isErrorGlobal: false },
      {
        username: this.rootStore.sessionStore.viewer.userName,
        password,
        confirmationPassword,
      }
    )

    if (resp.data && resp.data.message) {
      this.registrationForm.updateValidationErrors([
        { field: 'confirmationPassword', message: resp.data.message },
      ])
    }

    return resp.ok
  }

  saveUserDetails = async () => {
    const resp = await this.rootStore.apiRequest(
      'Users/User',
      { method: 'PUT' },
      this.userDetailsForm.values
    )

    if (resp.validationErrors) {
      this.userDetailsForm.updateValidationErrors(resp.validationErrors)
      return false
    }

    if (resp.ok) {
      this.rootStore.sessionStore.fetchViewer()
      return true
    }

    return false
  }

  submitHaulEquipmentForm = async () => {
    const resp = await this.rootStore.apiRequest(
      `Clients/${this.rootStore.sessionStore.clientId}/CarrierEquipment`,
      { method: 'POST' },
      this.haulEquipmentForm.values
    )

    return resp.ok
  }

  submitHaulDrivePrefForm = async () => {
    this.haulDrivePrefForm.isSubmitting = true
    const resp = await this.rootStore.apiRequest(
      'CarrierUserPreferences',
      { method: 'PUT' },
      {
        userId: this.rootStore.sessionStore.viewer.userId,
        ...this.haulDrivePrefForm.values,
      }
    )
    this.haulDrivePrefForm.isSubmitting = false

    return resp.ok
  }

  submitLocationForm = async () => {
    const resp = await this.rootStore.apiRequest(
      'CarrierUserPreferences',
      { method: 'PUT' },
      {
        userId: this.rootStore.sessionStore.viewer.userId,
        ...this.locationForm.values,
      }
    )

    return resp.ok
  }

  loadSuggestedLoads = async () => {
    // TODO: use a more customized load search, e.g.
    // const resp = await this.rootStore.apiRequest('CarrierLoads/suggested')

    const resp = await this.rootStore.apiRequest(
      'CarrierLoads/search/available',
      {
        method: 'POST',
      },
      { criteria: {} }
    )

    if (resp.ok) {
      if (resp.data && resp.data.records) {
        this.suggestedLoads = resp.data.records
        return true
      }
      throw new Error(
        `Expected data with records, got ${JSON.stringify(resp.data)}`
      )
    }
    return false
  }

  submitContactUsForm = async () => {
    const resp = await this.rootStore.apiRequest(
      `Feedback?message=${this.contactUsForm.values.feedback}`,
      { method: 'POST' }
    )

    return resp.ok
  }

  registrationValidate = async registrationCode => {
    const resp = await this.rootStore.apiRequest(
      `CarrierRegistration/Validate/${registrationCode}`,
      {
        isErrorGlobal: false,
      }
    )

    return resp.ok
  }

  registerCarrier = async () => {
    this.carrierRegistrationForm.isSubmitting = true
    const resp = await this.rootStore.apiRequest(
      `CarrierRegistration/Complete`,
      { method: 'POST' },
      {
        ...this.carrierRegistrationForm.values,
      }
    )
    this.carrierRegistrationForm.isSubmitting = false

    return resp.ok
  }
}

export default RegistrationStore
