import React, { useState } from 'react'
import Box from 'src/components/Box'
import { PrimaryButton } from 'src/components/Button'
import HeadingContainer from 'src/components/HeadingContainer'
import MaxWidth from 'src/components/MaxWidth'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import { BodyText, Heading } from 'src/components/Text'
import TextInput from 'src/components/TextInput'
import { useApiRequest } from 'src/hooks/useStores'
import { resetPasswordPath } from 'src/paths'
import { addParams } from 'src/util/url'

const Reset = () => {
  const apiRequest = useApiRequest()
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setIsSubmitting(true)

    const resp = await apiRequest(
      addParams('Users/ForgotPassword', {
        username: email,
        passwordResetUrl: window.location.origin + resetPasswordPath(),
      }),
      { method: 'POST' }
    )

    setIsComplete(resp.ok)

    setIsSubmitting(false)
  }

  return (
    <OnboardingContainer>
      <PageMeta title="Reset" />

      <MaxWidth value={320}>
        <HeadingContainer>
          <Heading mb={2} lmb={4}>
            Reset Password
          </Heading>
          <BodyText>
            {isComplete ? (
              <>
                An email is on its way to you. Follow the instructions to reset
                your password.
              </>
            ) : (
              <>
                Enter your email address below and we’ll send you a link to
                reset your password.
              </>
            )}
          </BodyText>
        </HeadingContainer>

        {!isComplete && (
          <form onSubmit={handleSubmit}>
            <Box mb={3}>
              <TextInput
                type="email"
                value={email}
                onChangeText={setEmail}
                placeholder="Your Email"
                required={true}
              />
            </Box>
            <PrimaryButton type="submit" block={true} disabled={isSubmitting}>
              Send reset password email
            </PrimaryButton>
          </form>
        )}
      </MaxWidth>
    </OnboardingContainer>
  )
}
export default Reset
