import React from 'react'
import PropTypes from 'prop-types'
import styles from './Radio.css'
import cn from 'classnames'
import Checkmark from 'src/icons/Checkmark.svg'

/** @param {React.InputHTMLAttributes<HTMLInputElement>} props */
const Radio = ({ children, ...props }) => (
  <label className={cn(styles.label, props.disabled && styles.disabled)}>
    <input type="radio" className={styles.input} {...props} />

    <div className={styles.icon}>
      <Checkmark className={styles.Checkmark} />
    </div>
    {children}
  </label>
)

Radio.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Radio
