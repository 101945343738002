import React, { Component } from 'react'
import { navigate } from '@reach/router'
import Wrapper from 'src/components/Wrapper'
import PageMeta from 'src/components/PageMeta'
import { inject, observer } from 'mobx-react'
import MyAccountTabNav from 'src/components/MyAccountTabNav'
import Box from 'src/components/Box'
import { Title } from 'src/components/Text'
import Flex from 'src/components/Flex'
import { PrimaryButton } from 'src/components/Button'
import LoadingScreen from 'src/components/Loading'
import { DriverCardButton } from 'src/components/DriverCard'
import pluralize from 'src/util/pluralize'
import { settingsAddDriverPath, settingsEditDriverPath } from 'src/paths'
import CenteredContainer from 'src/components/CenteredContainer'

/**
 * @typedef {object} Props
 * @property {import('src/stores/DriverStore').default} driverStore
 *
 * @augments {Component<Props>}
 */
@inject('driverStore')
@observer
class SettingsDrivers extends Component {
  componentDidMount() {
    this.props.driverStore.clientDriversIncludingInactive.fetchFirstPage()
  }

  render() {
    const drivers = this.props.driverStore.clientDriversIncludingInactive
    if (!drivers) return <LoadingScreen />

    return (
      <Wrapper bg="light-gray">
        <PageMeta title="Drivers and Fleet" section="Account" />

        <MyAccountTabNav />
        <Box p={3} bg="black" color="white">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Title mb={2}>
              {drivers.totalCount}{' '}
              {pluralize(drivers.totalCount || 0, 'Driver')}
            </Title>

            <PrimaryButton
              variant="outline"
              onClick={() => navigate(settingsAddDriverPath())}
            >
              Add a Driver
            </PrimaryButton>
          </Flex>
        </Box>

        <CenteredContainer width="900">
          <Box bg="white" pb={3}>
            {drivers.collection.map(driver => (
              <DriverCardButton
                driver={driver}
                key={driver.driverId}
                showDriverId={true}
                onClick={() =>
                  navigate(settingsEditDriverPath(driver.driverId))
                }
              />
            ))}
            {drivers.isMoreResults && (
              <Box pl={3} pr={3} pt={2}>
                <PrimaryButton
                  onClick={() => drivers.fetchNextPage()}
                  disabled={drivers.isLoading}
                >
                  Load More
                </PrimaryButton>
              </Box>
            )}
          </Box>
        </CenteredContainer>
      </Wrapper>
    )
  }
}

export default SettingsDrivers
