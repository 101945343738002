import React from 'react'
import LinkBanner from './LinkBanner'
import { tipsAndToolsPath } from 'src/paths'
import { Title } from './Text'

const LinkBannerTipsTools = () => (
  <LinkBanner to={tipsAndToolsPath()}>
    <Title>Tips and Tools</Title>
    <p>Find loads faster and earn more with these tips and tools.</p>
  </LinkBanner>
)

export default LinkBannerTipsTools
