import React from 'react'
import { Screen } from 'src/components/Screen'
import Box from 'src/components/Box'
import {
  H1,
  H2,
  Title,
  Body1,
  Body2,
  Body3,
  ButtonCTA,
  Numerals,
} from 'src/components/Text'
import { PrimaryButton } from 'src/components/Button'
import { ButtonUnderlinedH2 } from 'src/components/ButtonUnderlined'
import PageMeta from 'src/components/PageMeta'

const components = [
  { component: H1, label: 'H1' },
  { component: H2, label: 'H2' },
  { component: Title, label: 'Title' },
  { component: Body1, label: 'Body1' },
  { component: Body2, label: 'Body2' },
  { component: Body3, label: 'Body3' },
  { component: ButtonCTA, label: 'ButtonCTA' },
  { component: Numerals, label: 'Numerals', example: '$2,450' },
]

const Styleguide = () => (
  <Screen>
    <PageMeta title="Styleguide" />
    <Box p={4}>
      <p contentEditable={true}>Styleguide</p>
      <p contentEditable={true}>Hint: text is editable</p>

      {components.map((c, i) => (
        <Box key={i} pb={2}>
          <c.component contentEditable={true}>{c.label}</c.component>
        </Box>
      ))}

      <Box mb={2}>
        <PrimaryButton variant="primary">Button Primary</PrimaryButton>
      </Box>
      <Box mb={2}>
        <PrimaryButton variant="black">Button Black</PrimaryButton>
      </Box>
      <Box mb={2}>
        <PrimaryButton variant="outline">Button Outline</PrimaryButton>
      </Box>
      <Box mb={2}>
        <ButtonUnderlinedH2>ADVANCED OPTIONS</ButtonUnderlinedH2>
      </Box>

      <Box mb={2}>
        <ButtonUnderlinedH2 color="light-blue">
          Skip and schedule a call with dispatch.
        </ButtonUnderlinedH2>
      </Box>

      <Box mb={2} bg="black">
        <Box bg="light-gray">Space Scale</Box>
        <Box ml={1} pl={2} bg="light-gray">
          1
        </Box>
        <Box ml={2} pl={2} bg="light-gray">
          2
        </Box>
        <Box ml={3} pl={2} bg="light-gray">
          3
        </Box>
        <Box ml={4} pl={2} bg="light-gray">
          4
        </Box>
      </Box>
    </Box>
  </Screen>
)

export default Styleguide
