import Store from './Store'
import { observable } from 'mobx'

export default class UnassignReasonStore extends Store {
  @observable unassignReasonGroups = []

  @observable isUnassignReasonsLoading = false

  getReasons = async () => {
    if (this.unassignReasonGroups.length === 0) await this.fetchReasons()
  }

  fetchReasons = async () => {
    this.isUnassignReasonsLoading = true
    const resp = await this.rootStore.apiRequest(
      `Lookups/GetGroupedUnassignReasons`
    )
    this.isUnassignReasonsLoading = false

    if (resp.ok) {
      this.unassignReasonGroups = Object.keys(resp.data).map(group => ({
        label: resp.data[group]['groupTitle'],
        reasons: Object.keys(resp.data[group]['groupList']).map(reason => ({
          code: reason,
          label: resp.data[group]['groupList'][reason],
        })),
      }))

      return true
    }

    return false
  }

  /**
   * @param {Haully.LoadId} loadId
   * @param {string} reason
   */
  submitCancellation = async (loadId, reason) => {
    const reasonFormatted = reason.toUpperCase()
    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/${loadId}/unassign?unassignmentReason=${reasonFormatted}`,
      { method: 'POST' }
    )
    return resp.ok
  }
}
