import React, { useEffect, useState } from 'react'
import Box from 'src/components/Box'
import { PrimaryButton } from 'src/components/Button'
import HeadingContainer from 'src/components/HeadingContainer'
import MobileButtonContainer from 'src/components/MobileButtonContainer'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import { Heading, TextContainer } from 'src/components/Text'
import { registerBusinessLocationPath } from 'src/paths'
import { useRegistrationStore } from 'src/hooks/useStores'
import TextInput from 'src/components/TextInput'
import MaxWidth from 'src/components/MaxWidth'
import { Observer } from 'mobx-react'
import { Row, Column } from 'src/components/Layout'
import OnboardingCounter from 'src/components/OnboardingCounter'
import { navigate } from '@reach/router'
import LookupValue from 'src/components/LookupValue'
import { Anchor } from 'src/components/Link'
import { LoadingHeading } from 'src/components/Loading'

const RegisterWelcome = props => {
  const registrationStore = useRegistrationStore()
  const { carrierRegistrationForm } = registrationStore
  const [registrationCodeIsValid, setRegistrationCodeIsValid] = useState(true)
  const [validationCheckIsLoading, setValidationCheckIsLoading] = useState(true)

  carrierRegistrationForm.update({
    registrationCode: props.registrationCode,
  })

  const handleSubmit = e => {
    e.preventDefault()
    navigate(registerBusinessLocationPath())
  }

  const handleBasicFieldChange = e => {
    carrierRegistrationForm.update({
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    const validateRegistration = async () => {
      setRegistrationCodeIsValid(
        await registrationStore.registrationValidate(props.registrationCode)
      )
      setValidationCheckIsLoading(false)
    }
    validateRegistration()
  }, [registrationStore, props.registrationCode])

  if (!registrationCodeIsValid) {
    return (
      <OnboardingContainer>
        <PageMeta title="Registration - Welcome" />
        <HeadingContainer>
          <LookupValue lookupKey="CarrierOnBoardingEmailAddress">
            {email => (
              <>
                <Heading mb={2} lmb={4}>
                  Sorry, but the registration token is not valid or has already
                  been used.
                </Heading>
                <TextContainer>
                  Please email <Anchor href={`mailto:${email}`}>{email}</Anchor>{' '}
                  for a new token.
                </TextContainer>
              </>
            )}
          </LookupValue>
        </HeadingContainer>
      </OnboardingContainer>
    )
  }

  return (
    <Observer>
      {() => {
        return (
          <OnboardingContainer>
            <PageMeta title="Registration - Welcome" />
            {validationCheckIsLoading ? (
              <LoadingHeading />
            ) : (
              <>
                <OnboardingCounter position={1} total={4} backTo={'/'} />

                <HeadingContainer>
                  <Heading mb={2} lmb={4}>
                    Tell us about your company so we can get you on the road.
                  </Heading>
                </HeadingContainer>

                <form onSubmit={handleSubmit}>
                  <Box mb={3} lmb={4}>
                    <MaxWidth value={460}>
                      <TextInput
                        name="companyName"
                        value={carrierRegistrationForm.values.companyName}
                        onChange={handleBasicFieldChange}
                        required={true}
                        placeholder="Company Name"
                      />
                      <TextInput
                        name="ownerName"
                        value={carrierRegistrationForm.values.ownerName}
                        onChange={handleBasicFieldChange}
                        required={true}
                        placeholder="Owner's Name"
                      />
                      <TextInput
                        name="companyEmailAddress"
                        value={
                          carrierRegistrationForm.values.companyEmailAddress
                        }
                        onChange={handleBasicFieldChange}
                        required={true}
                        type="email"
                        placeholder="Company Email Address"
                      />
                      <Row>
                        <Column>
                          <TextInput
                            name="officePhone"
                            value={carrierRegistrationForm.values.officePhone}
                            onChange={handleBasicFieldChange}
                            required={true}
                            type="tel"
                            placeholder="Office Phone"
                          />
                        </Column>
                        <Column>
                          <TextInput
                            name="mobilePhone"
                            value={carrierRegistrationForm.values.mobilePhone}
                            onChange={handleBasicFieldChange}
                            required={false}
                            type="tel"
                            placeholder="Mobile Phone (optional)"
                          />
                        </Column>
                      </Row>
                      <TextInput
                        name="dispatchContactName"
                        value={
                          carrierRegistrationForm.values.dispatchContactName
                        }
                        onChange={handleBasicFieldChange}
                        required={true}
                        placeholder="Dispatch Contact Name"
                      />
                      <TextInput
                        name="dispatchContactNumber"
                        value={
                          carrierRegistrationForm.values.dispatchContactNumber
                        }
                        onChange={handleBasicFieldChange}
                        required={false}
                        type="tel"
                        placeholder="Dispatch Contact Number (optional)"
                      />
                    </MaxWidth>
                  </Box>
                  <Box mb={3} lmb={4}>
                    <MobileButtonContainer>
                      <PrimaryButton type="submit">Next</PrimaryButton>
                    </MobileButtonContainer>
                  </Box>
                </form>
              </>
            )}
          </OnboardingContainer>
        )
      }}
    </Observer>
  )
}

export default RegisterWelcome
