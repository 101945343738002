import { navigate } from '@reach/router'
import { inject, observer } from 'mobx-react'
import { parse } from 'qs'
import React, { Component } from 'react'
import Avatar from 'src/components/Avatar'
import Box from 'src/components/Box'
import { PlainButton, PrimaryButton } from 'src/components/Button'
import Divider from 'src/components/Divider'
import FieldErrors from 'src/components/FieldErrors'
import Flex from 'src/components/Flex'
import Grid from 'src/components/Grid'
import InputGroup from 'src/components/InputGroup'
import LoadingScreen from 'src/components/Loading'
import {
  OverlayHeader,
  OverlayHeaderContent,
  OverlayHeaderLeftButton,
} from 'src/components/OverlayHeader'
import PageMeta from 'src/components/PageMeta'
import { Screen } from 'src/components/Screen'
import styles from 'src/components/Settings.css'
import { BodyText, Title } from 'src/components/Text'
import TextInput from 'src/components/TextInput'
import { nameOrUsername } from 'src/formatter/user'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import { myAccountPath } from 'src/paths'

/**
 * @typedef {object} Props
 * @property {import('src/stores/SessionStore').default} sessionStore
 * @property {import('src/stores/RegistrationStore').default} registrationStore
 * @property {import('src/stores/FlashMessageStore').default} flashMessageStore
 *
 * @augments {Component<Props>}
 */
@inject('sessionStore', 'registrationStore', 'flashMessageStore')
@observer
class EditProfile extends Component {
  state = {
    shouldSubmitUserDetailsForm: false,
    shouldSubmitPasswordUpdateForm: false,
    backTo: '',
  }

  async componentDidMount() {
    const { viewer } = this.props.sessionStore

    this.props.registrationStore.userDetailsForm.reset()
    this.props.registrationStore.userDetailsForm.update({
      primaryPhone: viewer.primaryPhone.phoneNumber || '',
      userName: viewer.userName || '',
      email: viewer.email || '',
      clientId: viewer.client.clientId,
      id: viewer.userId,
    })

    this.props.registrationStore.registrationForm.reset()
    this.props.registrationStore.registrationForm.update({
      emailAddress: viewer.email,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    let userFormSuccess = true
    let passwordFormSuccess = true

    if (this.state.shouldSubmitUserDetailsForm) {
      userFormSuccess = await this.props.registrationStore.saveUserDetails()
    }

    if (this.state.shouldSubmitPasswordUpdateForm) {
      passwordFormSuccess = await this.props.registrationStore.updatePassword()
    }

    if (userFormSuccess && passwordFormSuccess) {
      this.props.flashMessageStore.queue('Successfully updated profile')
      navigate(this.returnPath())
    }
  }

  handleBasicFieldChange = e => {
    this.props.registrationStore.userDetailsForm.update({
      [e.currentTarget.name]: e.currentTarget.value,
    })
    this.setState({
      shouldSubmitUserDetailsForm: true,
    })
  }

  handleSecurityFieldChange = e => {
    this.props.registrationStore.registrationForm.update({
      [e.currentTarget.name]: e.currentTarget.value,
    })
    this.setState({
      shouldSubmitPasswordUpdateForm: true,
    })
  }

  backToQueryParam() {
    const queryParams = parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { backTo } = queryParams

    return backTo
  }

  returnPath() {
    return this.backToQueryParam() ? this.backToQueryParam() : myAccountPath()
  }

  render() {
    const {
      shouldSubmitUserDetailsForm,
      shouldSubmitPasswordUpdateForm,
    } = this.state
    const { viewer } = this.props.sessionStore
    const userDetailsForm = this.props.registrationStore.userDetailsForm
    const registrationForm = this.props.registrationStore.registrationForm

    const submitDisabled =
      !shouldSubmitUserDetailsForm && !shouldSubmitPasswordUpdateForm

    if (!viewer || !userDetailsForm.values) return <LoadingScreen />

    return (
      <Screen>
        <PageMeta title="Edit Profile" section="Account" />

        <OverlayHeader>
          <OverlayHeaderLeftButton>
            <PlainButton onClick={() => navigate(this.returnPath())}>
              <LeftArrowIcon className={styles.FillCurrentColor} />
            </PlainButton>
          </OverlayHeaderLeftButton>
          <OverlayHeaderContent>
            <BodyText>
              {this.backToQueryParam() ? 'Account Settings' : 'My Account'}
            </BodyText>
          </OverlayHeaderContent>
        </OverlayHeader>

        <Box p={4}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              name={nameOrUsername(viewer)}
              bg="light-gray-blue"
              color="black"
            />

            <Title mb={2}>{nameOrUsername(viewer)}</Title>
          </Flex>
        </Box>

        <Box pl={4} pr={4} pb={3}>
          <Divider />

          <form id="editProfileForm" onSubmit={this.handleSubmit}>
            {BASIC_FIELDS.map(({ id, ...rest }) => (
              <InputGroup key={id}>
                <TextInput
                  id={id}
                  name={id}
                  value={userDetailsForm.values[id]}
                  onChange={this.handleBasicFieldChange}
                  required={true}
                  {...rest}
                />
                <FieldErrors
                  field={id}
                  errors={userDetailsForm.validationErrors}
                />
              </InputGroup>
            ))}

            <Title mt={4}>Security</Title>
            <Divider />

            <BodyText>Change Password</BodyText>
            {SECURITY_FIELDS.map(({ id, ...rest }) => (
              <InputGroup key={id}>
                <TextInput
                  id={id}
                  name={id}
                  value={registrationForm.values[id]}
                  onChange={this.handleSecurityFieldChange}
                  {...rest}
                />
                <FieldErrors
                  field={id}
                  errors={registrationForm.validationErrors}
                />
              </InputGroup>
            ))}
            <Grid autoFlow="column" gap={3}>
              <PrimaryButton
                variant="outline"
                onClick={() => navigate(this.returnPath())}
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton
                type="submit"
                variant="primary"
                disabled={submitDisabled}
              >
                Save
              </PrimaryButton>
            </Grid>
          </form>
        </Box>
      </Screen>
    )
  }
}

const BASIC_FIELDS = [
  {
    id: 'userName',
    placeholder: 'Username',
  },
  {
    id: 'email',
    placeholder: 'Email',
    type: 'email',
  },
  {
    id: 'primaryPhone',
    placeholder: 'Phone',
    type: 'tel',
  },
]

const SECURITY_FIELDS = [
  {
    id: 'password',
    placeholder: 'New Password',
    type: 'password',
  },
  {
    id: 'confirmationPassword',
    placeholder: 'Confirm Password',
    type: 'password',
  },
]

export default EditProfile
