import { computed, observable } from 'mobx'

/** @type {any} */
let any = {}

/**
 * @template T
 */

/**
 * Holds onto state for paginated requests.
 * see also: paginatedRequest
 *
 * @example
 * const loads = new PaginatedRequestState(
 *   async pageNumber =>
 *     (await fetch(`/api/loads?pageNumber=${pageNumber}`)).json(),
 *   LoadInstance
 * )
 * loads.fetchfirstPage() // calls paginatedRequest(1)
 * loads.collection.map( ... )
 */
export default class PaginatedRequestState {
  @observable isLoading = false
  @observable currentPage = 1

  /** @type {number|null} */
  @observable totalCount = null

  /**
   * @param {(pageNumber: number) => Promise<any>} paginatedRequest
   * @param {T} [instance] - only used for types!
   */
  constructor(paginatedRequest, instance = any) {
    this.instance = instance
    this.paginatedRequest = paginatedRequest

    /** @type {import('mobx').IObservableArray<T>} */
    this.collection = observable.array([])
  }

  fetchFirstPage = () => {
    this.collection.clear()
    this.currentPage = 1
    return this._fetchCurrentPage()
  }

  fetchNextPage = () => {
    this.currentPage++
    return this._fetchCurrentPage()
  }

  clear = () => {
    this.collection.clear()
    this.totalCount = null
  }

  @computed
  get isMoreResults() {
    if (this.totalCount === null) return true
    return this.collection.length < this.totalCount
  }

  _fetchCurrentPage = async () => {
    this.isLoading = true
    const resp = await this.paginatedRequest(this.currentPage)
    this.isLoading = false

    if (resp.ok) {
      if (resp.data && resp.data.records) {
        this.totalCount = resp.data.totalRecordCount
        this.collection.push(...resp.data.records)
        return true
      }

      if (Array.isArray(resp.data)) {
        console.info('attempting to paginate a non-paginated api request')
        this.totalCount = resp.data.length
        this.collection.push(...resp.data)
        return true
      }

      throw new Error(
        `expected paginated or array data, got ${JSON.stringify(resp.data)}`
      )
    }
    return false
  }
}
