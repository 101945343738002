import React from 'react'
// import PropTypes from 'prop-types'
import LeftArrow from 'src/icons/LeftArrow.svg'
import { Link } from '@reach/router'
import styles from './OnboardingCounter.css'

/**
 * @param {Object} props
 * @param {number} props.position
 * @param {string} props.backTo
 * @param {number} [props.total]
 */
const OnboardingCounter = ({ position, backTo, total = 7 }) => {
  const message = `${position}/${total}`

  return (
    <Link to={backTo} className={styles.OnboardingCounter}>
      <div className={styles.OnboardingCounterArrowContainer}>
        <LeftArrow />
      </div>
      {message}
    </Link>
  )
}

export default OnboardingCounter
