import React from 'react'
import styles from './Container.css'
import cn from 'classnames'

/**
 * @param {object} props
 * @param {'white'|'light'|'dark'} [props.theme]
 * @param {React.ReactNode} props.children
 */
const Container = ({ theme = 'white', ...rest }) => (
  <div className={cn(styles.Container, styles[`theme-${theme}`])} {...rest} />
)

export default Container
