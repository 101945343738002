import React from 'react'
import styles from './MaxWidth.css'

/**
 * @param {object} props
 * @param {number} props.value
 * @param {React.ReactNode} props.children
 */
const MaxWidth = ({ value, children }) => (
  <div className={styles.MaxWidth} style={{ maxWidth: value }}>
    {children}
  </div>
)

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 */
export const OnboardFormMaxWidth = props => <MaxWidth {...props} value={460} />

export default MaxWidth
