import React from 'react'
import CenteredContainer from './CenteredContainer'
import Box from './Box'
import { Title, Body2 } from './Text'
import { LinkButton } from './Button'

/**
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 */
export const CallToActionContainer = props => (
  <Box bg="light-gray-blue" p={4}>
    <CenteredContainer centerText={true}>{props.children}</CenteredContainer>
  </Box>
)

/**
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 */
export const CallToActionTitle = props => <Title mb={3} {...props} />

/**
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 */
export const CallToActionDescription = props => <Body2 mb={4} {...props} />

/**
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 */
export const CallToActionLink = LinkButton

/**
 * @param {object} props
 * @param {React.ReactNode} props.title
 * @param {React.ReactNode} props.description
 * @param {React.ReactNode} props.actionLabel
 * @param {string} props.actionTo
 */
export const CallToActionBasic = props => (
  <CallToActionContainer>
    <CallToActionTitle>{props.title}</CallToActionTitle>
    <CallToActionDescription>{props.description}</CallToActionDescription>
    <CallToActionLink to={props.actionTo}>{props.actionLabel}</CallToActionLink>
  </CallToActionContainer>
)
