import URLSearchParams from '@ungap/url-search-params'
import { subMonths, subYears, subWeeks } from 'date-fns'

/** @param {string} range */
const dateRangeParams = range => {
  let params = new URLSearchParams()
  const today = new Date()
  params.append('fromEnd', today.toISOString())

  switch (range) {
    case 'This Month':
      params.append('fromStart', subMonths(today, 1).toISOString())
      params.append('compareToStart', subMonths(today, 2).toISOString())
      params.append('compareToEnd', subMonths(today, 1).toISOString())
      break
    case 'This Year':
      params.append('fromStart', subYears(today, 1).toISOString())
      params.append('compareToStart', subYears(today, 2).toISOString())
      params.append('compareToEnd', subYears(today, 1).toISOString())
      break
    default:
      // 'This Week'
      params.append('fromStart', subWeeks(today, 1).toISOString())
      params.append('compareToStart', subWeeks(today, 2).toISOString())
      params.append('compareToEnd', subWeeks(today, 1).toISOString())
  }

  return params
}
export default dateRangeParams
