import React, { Component } from 'react'
import Wrapper from 'src/components/Wrapper'
import PageMeta from 'src/components/PageMeta'
import { inject, observer } from 'mobx-react'
import MyAccountTabNav from 'src/components/MyAccountTabNav'
import Box from 'src/components/Box'
import Avatar from 'src/components/Avatar'
import { nameOrUsername } from 'src/formatter/user'
import { Title } from 'src/components/Text'
import Flex from 'src/components/Flex'
import LinkBasic from 'src/components/LinkBasic'
import * as paths from 'src/paths'
import { PrimaryButton } from 'src/components/Button'
import LinkedList from 'src/components/LinkedList'
import LinkedListItem from 'src/components/LinkedListItem'
import CenteredContainer from 'src/components/CenteredContainer'

const MY_ACCOUNT_LINKS = [
  { to: paths.businessInfoPath(), label: 'Business Information' },
  {
    to: paths.paymentPreferencesPath(),
    label: 'Payment Preference Change Request',
  },
  { to: paths.myDocumentsPath(), label: 'My Documents' },
  { to: paths.tipsAndToolsPath(), label: 'Resources and FAQs' },
  { to: paths.contactUsPath(), label: 'Contact Us' },
  { to: paths.legalInformationPath(), label: 'Legal Information' },
]

/**
 * @typedef {object} Props
 * @property {import('src/stores/SessionStore').default} sessionStore
 *
 * @augments {Component<Props>}
 */
@inject('sessionStore')
@observer
class MyAccount extends Component {
  handleLogout = () => {
    this.props.sessionStore.destroySession()
  }

  render() {
    const { viewer } = this.props.sessionStore

    return (
      <Wrapper bg="light-gray">
        <PageMeta title="My Account" section="Account" />

        <MyAccountTabNav />

        <Box p={3} lp={4} bg="light-gray-blue">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              name={nameOrUsername(viewer)}
              bg="white"
              color="light-blue"
              size="scaleable"
            />

            <Title mb={2}>{nameOrUsername(viewer)}</Title>

            <LinkBasic color="black" to={paths.editProfilePath()}>
              View and edit profile
            </LinkBasic>
          </Flex>
        </Box>

        <CenteredContainer width="900">
          <LinkedList>
            {MY_ACCOUNT_LINKS.map(({ to, label }, i) => (
              <LinkedListItem key={i} to={to}>
                {label}{' '}
                {to === paths.todoPath() ? '·' : '' /* TODO: deleteme */}
              </LinkedListItem>
            ))}
          </LinkedList>
        </CenteredContainer>

        <Flex pl={3} lmt={3} lmb={4} justifyContent="center">
          <PrimaryButton variant="primary" onClick={this.handleLogout}>
            Log out
          </PrimaryButton>
        </Flex>
      </Wrapper>
    )
  }
}

export default MyAccount
