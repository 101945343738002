import React from 'react'
import Box from 'src/components/Box'
import HeadingContainer from 'src/components/HeadingContainer'
import MobileButtonContainer from 'src/components/MobileButtonContainer'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import { Heading } from 'src/components/Text'
import {
  registerInsuranceDetailsPath,
  registerConfirmationPath,
} from 'src/paths'
import { useRegistrationStore } from 'src/hooks/useStores'
import TextInput from 'src/components/TextInput'
import MaxWidth from 'src/components/MaxWidth'
import { Observer } from 'mobx-react'
import { Row, Column } from 'src/components/Layout'
import OnboardingCounter from 'src/components/OnboardingCounter'
import { navigate } from '@reach/router'
import { PrimaryButton } from 'src/components/Button'

const RegisterFleet = () => {
  const registrationStore = useRegistrationStore()

  return (
    <Observer>
      {() => {
        const { carrierRegistrationForm } = registrationStore

        const handleBasicFieldChange = e => {
          carrierRegistrationForm.update({
            [e.target.name]: e.target.value,
          })
        }

        const handleSubmit = async e => {
          e.preventDefault()
          if (await registrationStore.registerCarrier()) {
            navigate(registerConfirmationPath())
          }
        }

        return (
          <OnboardingContainer>
            <PageMeta title="Tell us about your fleet" />
            <OnboardingCounter
              position={4}
              total={4}
              backTo={registerInsuranceDetailsPath()}
            />

            <HeadingContainer>
              <Heading mb={2} lmb={4}>
                Tell us about your fleet
              </Heading>
            </HeadingContainer>
            <form onSubmit={handleSubmit}>
              <Box mb={3} lmb={4}>
                <MaxWidth value={460}>
                  <Row>
                    <Column>
                      <TextInput
                        name="totalNumberOfTrucks"
                        value={
                          carrierRegistrationForm.values.totalNumberOfTrucks
                        }
                        onChange={handleBasicFieldChange}
                        required={true}
                        placeholder="Total # of Trucks"
                      />
                    </Column>
                    <Column>
                      <TextInput
                        name="totalCapacity"
                        value={carrierRegistrationForm.values.totalCapacity}
                        onChange={handleBasicFieldChange}
                        required={true}
                        placeholder="Total Capacity"
                      />
                    </Column>
                  </Row>
                </MaxWidth>
              </Box>
              <Box mb={3} lmb={4}>
                <MobileButtonContainer>
                  <PrimaryButton
                    type="submit"
                    disabled={carrierRegistrationForm.isSubmitting}
                  >
                    Submit
                  </PrimaryButton>
                </MobileButtonContainer>
              </Box>
            </form>
          </OnboardingContainer>
        )
      }}
    </Observer>
  )
}

export default RegisterFleet
