import { observable } from 'mobx'
import Store from './Store'
import appVersion from 'src/util/appVersion'

Error.stackTraceLimit = 100

/**
 * @typedef {object} AppError
 * @property {number} id
 * @property {string} message
 * @property {boolean} report
 * @property {string} [eventId]
 */

let id = 0
class ErrorStore extends Store {
  /** @type {import('mobx').IObservableArray<AppError>} */
  errors = observable.array([])

  /**
   * @param {string} message
   * @param {Object} options
   * @param {boolean} [options.report]
   * @param {{[x:string]: any}} [options.context]
   * @param {string|undefined} [options.stack]
   */
  addError = (
    message,
    { report = false, context: userContext, stack } = {}
  ) => {
    const error = new Error(message)
    if (stack) {
      error.stack = `${error.stack}\n${stack}`
    }

    const context = {
      ...userContext,
      config: this.rootStore.configStore.serialize,
      version: appVersion(),
    }

    this.errors.push({
      id: id++,
      message,
      report,
    })

    if (report) {
      console.warn({ error, context }) // eslint-disable-line no-console
    }
  }

  clear = () => {
    this.errors.clear()
  }
}

export default ErrorStore
