import React, { Component } from 'react'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading, Subheading, NoWrap, Small } from 'src/components/Text'
import Checkbox from 'src/components/Checkbox'
import { PrimaryButton } from 'src/components/Button'
import PageMeta from 'src/components/PageMeta'
import { inject, observer } from 'mobx-react'
import RegistrationStore from 'src/stores/RegistrationStore'
import { onboardLoadsPrefPath, onboardHaulDrivePrefPath } from 'src/paths'
import OnboardingContainer from 'src/components/OnboardingContainer'
import OnboardingCounter from 'src/components/OnboardingCounter'
import CheckboxContainer from 'src/components/CheckboxContainer'
import Flex from 'src/components/Flex'
import MaxWidth from 'src/components/MaxWidth'
import Box from 'src/components/Box'

/**
 * @typedef Props
 * @property {RegistrationStore} registrationStore
 *
 * @typedef State
 * @property {boolean} isAnywhereChecked
 *
 * @augments Component<Props & import('@reach/router').History, State>
 */
@inject('registrationStore')
@observer
class OnboardLocation extends Component {
  state = {
    isAnywhereChecked: false,
  }

  /** @returns {RegistrationStore} */
  get registrationStore() {
    return this.props.registrationStore
  }

  componentDidMount() {
    this.registrationStore.locationForm.reset()
  }

  handleSubmit = async e => {
    e.preventDefault()

    if (await this.registrationStore.submitLocationForm()) {
      this.props.navigate(onboardLoadsPrefPath())
    }
  }

  handleCheck = label => e => {
    const form = this.registrationStore.locationForm

    form.values.stateProvinceRegion = e.currentTarget.checked
      ? [...form.values.stateProvinceRegion, label]
      : form.values.stateProvinceRegion.filter(x => x !== label)
  }
  /** @param {React.ChangeEvent<HTMLInputElement>} e */
  handleAnywhereCheck = e => {
    const { checked } = e.currentTarget
    this.setState({ isAnywhereChecked: checked })
    if (checked) {
      const form = this.registrationStore.locationForm
      form.values.stateProvinceRegion = []
    }
  }

  render() {
    const form = this.registrationStore.locationForm

    return (
      <OnboardingContainer>
        <PageMeta title="Where’s the best place to find your hauls this week?" />
        <OnboardingCounter position={7} backTo={onboardHaulDrivePrefPath()} />
        <HeadingContainer>
          <Heading mb={3}>
            Where’s the best place to find your hauls this week?
          </Heading>
          <Subheading>Check all that apply.</Subheading>
        </HeadingContainer>
        <MaxWidth value={260}>
          <form onSubmit={this.handleSubmit}>
            <Box lmb={4}>
              <CheckboxContainer>
                <Checkbox
                  checked={this.state.isAnywhereChecked}
                  onChange={this.handleAnywhereCheck}
                >
                  Anywhere
                </Checkbox>
              </CheckboxContainer>

              {form.choices.currentLocations.map(({ label, states, value }) => (
                <CheckboxContainer key={label}>
                  <Checkbox
                    checked={form.values.stateProvinceRegion.includes(value)}
                    onChange={this.handleCheck(value)}
                    disabled={this.state.isAnywhereChecked}
                  >
                    <NoWrap>{label}</NoWrap> <Small ml={1}>{states}</Small>
                  </Checkbox>
                </CheckboxContainer>
              ))}
            </Box>
            <Flex mt={3} lmb={4} justifyContent="center">
              <PrimaryButton type="submit" data-test="OnboardLocationSubmit">
                Next
              </PrimaryButton>
            </Flex>
          </form>
        </MaxWidth>
      </OnboardingContainer>
    )
  }
}

export default OnboardLocation
