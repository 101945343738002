import Store from './Store'
import { observable } from 'mobx'

class DocumentStore extends Store {
  /** @type {import('mobx').IObservableArray<Haully.CarrierDocument>} */
  insuranceDocuments = observable.array([])

  /** @type {import('mobx').IObservableArray<Haully.CarrierDocument>} */
  brokerAgreements = observable.array([])

  @observable isInsuranceDocumentsLoading = false
  @observable isBrokerAgreementsLoading = false

  getInsuranceDocuments = async () => {
    this.isInsuranceDocumentsLoading = true
    const resp = await this.rootStore.apiRequest(
      `CarrierDocuments/InsuranceDocuments`
    )

    if (resp.ok) {
      this.insuranceDocuments = resp.data
    }
    this.isInsuranceDocumentsLoading = false

    return true
  }

  getBrokerAgreements = async () => {
    this.isBrokerAgreementsLoading = true

    const resp = await this.rootStore.apiRequest(
      `CarrierDocuments/BrokerAgreement`
    )

    if (resp.ok) {
      this.brokerAgreements = resp.data
    }
    this.isBrokerAgreementsLoading = false

    return true
  }

  getDocumentUrls = async documentId => {
    const resp = await this.rootStore.apiRequest(
      `DocumentUrls?documentIds=${documentId}`
    )

    if (resp.ok) {
      const downloadUrl = resp.data[0].managedDocumentUrl
      return downloadUrl
    }

    return false
  }
}

export default DocumentStore
