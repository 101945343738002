import React, { Component } from 'react'
import Box from 'src/components/Box'
import { Screen } from 'src/components/Screen'
import { Body2, Title } from 'src/components/Text'

const PROD = process.env.NODE_ENV === 'production'

/**
 * basic error handling example shown on sentry docs:
 * https://docs.sentry.io/clients/javascript/integrations/react/
 *
 * @typedef {object} Props
 *
 * @typedef {object} State
 * @property {*?} [error]
 *
 *  @augments {Component<Props, State>}
 */
class ErrorReporter extends Component {
  /** @type {State} */
  state = {
    error: null,
  }

  componentDidCatch(error, _errorInfo) {
    this.setState({ error })
  }

  render() {
    const { error } = this.state

    if (error) {
      //render fallback UI
      return (
        <Screen>
          <Box m={2}>
            <Title mb={2}>An unexpected error occured:</Title>
            <pre>{error.message}</pre>
            {!PROD && (
              <Body2 mb={2} color="red">
                You are in development:
                <br /> check your console for details.
              </Body2>
            )}
          </Box>
        </Screen>
      )
    } else {
      //when there's not an error, render children untouched
      return this.props.children
    }
  }
}

export default ErrorReporter
