import { navigate } from '@reach/router'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import AnnouncementLink from 'src/components/AnnouncementLink'
import Box from 'src/components/Box'
import { AnchorButton, BlockButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import LoginModal from 'src/components/LoginModal'
import MarketingContainer from 'src/components/MarketingContainer'
import styles from 'src/components/MarketingLanding.css'
import PageMeta from 'src/components/PageMeta'
import { HorizontalScrollContainer } from 'src/components/ScrollContainer'
import Spacer from 'src/components/Spacer'
import {
  Body2,
  Body3,
  H2Bold,
  Heading,
  MediumBodyBold,
  Subheading,
} from 'src/components/Text'
import { useAnnouncements } from 'src/components/useAnnouncements'
import { CONTACT_ADDRESS, CONTACT_PHONE_NUMBER } from 'src/data/company'
import { SIGN_UP_URL } from 'src/data/navLinks'
import useWindowSize from 'src/hooks/useWindowSize'
import ChevronLeft from 'src/icons/ChevronLeft.svg'
import ChevronRight from 'src/icons/ChevronRight.svg'
import FacebookIcon from 'src/icons/facebook.svg'
import HaullyLogoWhite from 'src/icons/HaullyLogoWhite.svg'
import FindLoadsImage from 'src/images/FindLoads.png'
import MobileFriendlyImage from 'src/images/MobileFriendly.png'
import MoveMoreImage from 'src/images/MoveMore.png'
import { loginPath, welcomePath } from 'src/paths'
import Responsive from '../components/Responsive'

const DIFFERENCE_POINTS = [
  {
    image: MobileFriendlyImage,
    heading: 'Mobile-Friendly',
    text: 'Freight at your fingertips–no matter where you are on the road.',
  },
  {
    image: FindLoadsImage,
    heading: 'Find Loads Faster',
    text:
      "Set advanced preferences so you can get matched with freight 24/7, including back-haul. We'll even send loads that match your preferences right to your inbox.",
  },
  {
    image: MoveMoreImage,
    heading: 'Move More',
    text:
      "Driving with Haully means you have access to the largest direct source of high-quality freight. And with a dedicated team, we're always ready to help you move more vehicles.",
  },
]

const TESTIMONIALS = [
  {
    message:
      '"I work with United Road Services and 95% my work comes from them. Haully has helped me find loads much easier and to expand my routes."',
    attribution: 'Owner Raymond James, East Side Cars LLC',
  },
  {
    message:
      '"Haully’s really easy to use for finding loads, and for coordination. We do a lot of dealer trades, so it helps us fill in the gaps. The self-assignment is my go-to. I like that the prices are there so we don’t have to talk to a bunch of other people that may get in the middle of it all."',
    attribution: 'Owner Tommy Keller, TK Transport LLC',
  },
  {
    message:
      '"I get to look at the picture of my business and stress less. Haully helps with inventory. I lock and load and find ways to reload in the same places I am in. It also helps me view the expected income for the day which gives me a sense of what I’m going to bring home so there is less worrying."',
    attribution: 'Rodney Strickland, Hot Rod Transport',
  },
]

const MarketingLanding = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

  const openLoginModal = () => {
    setIsLoginModalOpen(true)
  }

  const atLoginPath = () => window.location.pathname === loginPath()

  useEffect(() => {
    if (atLoginPath()) openLoginModal()
  }, [])

  const { announcements: allAnnouncements } = useAnnouncements()
  const announcements = allAnnouncements.filter(a => a.targetType === 'CIBody')

  const [activeAnnouncementIndex, setActiveAnnoucementIndex] = useState(0)

  useEffect(() => {
    setActiveAnnoucementIndex(getRandomInt(announcements.length))
  }, [announcements.length])

  const announcementPosition = Math.abs(
    activeAnnouncementIndex % announcements.length
  )
  const announcement =
    announcements.length > 0 ? announcements[announcementPosition] : undefined

  const announcementControls =
    announcements.length > 1 ? (
      <>
        <Flex mr={1}>
          <BlockButton
            bg="transparent"
            style={{ paddingLeft: 16, paddingRight: 16 }}
            onClick={() => {
              setActiveAnnoucementIndex(x => x - 1)
            }}
            aria-label="Previous"
          >
            <ChevronLeft style={{ height: 12 }} />
          </BlockButton>
        </Flex>
        <Body2 pl={2} pr={2}>
          {announcementPosition + 1} of {announcements.length}
        </Body2>
        <BlockButton
          style={{ paddingLeft: 16, paddingRight: 16 }}
          bg="transparent"
          onClick={() => {
            setActiveAnnoucementIndex(x => x + 1)
          }}
          aria-label="Next"
        >
          <ChevronRight style={{ height: 12 }} />
        </BlockButton>
      </>
    ) : null

  return (
    <MarketingContainer>
      <PageMeta title="Haully - Powered by United Road" />

      {/* Hero */}
      <Flex className={styles.Hero} pt={3} pb={3} pl={4} pr={4} lp={4}>
        <Spacer />
        <Flex justifyContent={'space-between'}>
          <Box m={2} lm={4}>
            <Heading color="black">We’re Moving to Keep You Moving.</Heading>
            <Spacer />
            <AnchorButton href={SIGN_UP_URL} variant="white">
              Find Hauls Now
            </AnchorButton>
          </Box>
          {announcement ? (
            <Responsive
              medium={
                <Box m={2} lm={4} style={{ maxWidth: 500, flex: 1 }}>
                  <Heading color="black">{announcement.title}</Heading>
                  <Spacer />
                  <div>{announcement.details}</div>
                  <AnnouncementLink announcement={announcement} />

                  {announcementControls ? (
                    <Flex mt={4}>
                      <Flex
                        alignItems="center"
                        bg="black"
                        color="white"
                        style={{
                          border: '1px solid #000',
                          borderRadius: 30,
                          overflow: 'hidden',
                        }}
                      >
                        {announcementControls}
                      </Flex>
                    </Flex>
                  ) : null}
                </Box>
              }
            />
          ) : null}
        </Flex>
      </Flex>

      {announcement ? (
        <Responsive medium={<></>}>
          <Box
            className={styles.Difference}
            p={3}
            lp={4}
            bg="black"
            color="white"
          >
            <Heading mb={3} lmb={4}>
              {announcement.title}
            </Heading>
            <Body3>{announcement.details}</Body3>
            <AnnouncementLink announcement={announcement} />

            {announcementControls ? (
              <Flex mt={4}>
                <Flex
                  alignItems="center"
                  bg="black"
                  color="white"
                  style={{
                    border: '1px solid #fff',
                    borderRadius: 30,
                    overflow: 'hidden',
                  }}
                >
                  {announcementControls}
                </Flex>
              </Flex>
            ) : null}
          </Box>
        </Responsive>
      ) : null}
      {/* Discover the Haully Difference */}
      <Box className={styles.Difference} p={3} lp={4} bg="light-gray">
        <Heading mb={3} lmb={4} id="DiscoverTheDifference">
          Discover the Haully Difference
        </Heading>

        <Flex
          justifyContent="center"
          alignItems="center"
          className={styles.DifferenceContainer}
        >
          {DIFFERENCE_POINTS.map(({ image, heading, text }, i) => (
            <Box bg="white" className={styles.DifferenceCard} key={i}>
              <img className={styles.DifferenceCardImage} src={image} />
              <Box p={3}>
                <H2Bold mb={2}>{heading.toUpperCase()}</H2Bold>
                <Body3>{text}</Body3>
              </Box>
            </Box>
          ))}
        </Flex>
      </Box>

      {/* How it Works */}
      <Box className={styles.HowItWorks} p={3} lp={4} bg="white">
        <Flex justifyContent="center">
          <Heading mb={3} lmb={4} id="HowItWorks">
            How it Works
          </Heading>
        </Flex>
        <MarketingVideoPlayer />
      </Box>

      {/* About Us */}
      <Box className={styles.AboutUsHero} />
      <Flex
        className={styles.AboutUs}
        p={3}
        lp={4}
        bg="white"
        justifyContent="center"
        mt={3}
        lmt={3}
      >
        <Flex mr={4} lmr={4} className={styles.AboutUsHeading}>
          <Heading mb={3} lmb={4}>
            About Us
          </Heading>
        </Flex>

        <Flex
          flexDirection="column"
          className={styles.AboutUsDetails}
          mb={4}
          lmb={3}
        >
          <MediumBodyBold mb={3} lmb={3}>
            Powered by the car-hauling experts of United Road, Haully is a
            better way to find great loads.
          </MediumBodyBold>
          <Subheading>
            With unmatched direct access to high-volume and high-quality freight
            wherever you are, Haully paves the way for professional car haulers
            to grow their business. As one of the largest auto transporters in
            the country, we’re continuously investing in new technology and
            best-in-class customer service to find you the hauls that keep you
            moving.
          </Subheading>
        </Flex>
      </Flex>

      {/* Testimonials */}
      <Box
        className={styles.Testimonials}
        p={3}
        lp={4}
        bg="light-blue"
        color="white"
      >
        <Heading mb={3} lmb={4}>
          Here’s What Carriers Are Saying
        </Heading>

        <HorizontalScrollContainer>
          {TESTIMONIALS.map(({ message, attribution }, i) => (
            <Flex
              className={styles.TestimonialCard}
              mr={4}
              p={4}
              bg="white"
              key={i}
              flexDirection="column"
            >
              <Body3 mb={4} color="black">
                {message}
              </Body3>
              <Body3 color="black">{attribution}</Body3>
            </Flex>
          ))}
        </HorizontalScrollContainer>
      </Box>

      {/* BecomeCarrier */}
      <Flex
        className={styles.BecomeCarrier}
        p={3}
        lp={4}
        bg="black"
        flexDirection="column"
      >
        <Spacer />
        <Spacer />
        <Heading color="white" mb={3} lmb={4}>
          Ready to become a carrier with Haully?
        </Heading>

        <Box mt={3} mb={3}>
          <AnchorButton href={SIGN_UP_URL}>Sign Up Now!</AnchorButton>
        </Box>
      </Flex>

      {/* Footer */}
      <Flex
        className={styles.Footer}
        p={3}
        lp={4}
        bg="black"
        justifyContent="space-between"
        alignItems="center"
        id="ContactUs"
      >
        <Flex m={2} lm={4} color="white" flexDirection="column">
          <Box mb={3}>
            <HaullyLogoWhite />
          </Box>
          <Subheading color="white">
            &copy; {new Date().getFullYear()} United Road Services.
            <br />
            All Rights Reserved.
          </Subheading>
        </Flex>

        <Flex m={2} lm={4} flexDirection="column">
          <Box mb={3} color="white">
            <a href="https://unitedroad.com" className={styles.LinkH2}>
              unitedroad.com
            </a>
          </Box>
          <Subheading color="white" mb={3}>
            {CONTACT_ADDRESS}
            <br />
            P: {CONTACT_PHONE_NUMBER}
          </Subheading>
          <Box color="white">
            <a
              href="https://www.facebook.com/HaullyApp/"
              aria-label="Haully on Facebook"
              target="_blank"
            >
              <FacebookIcon />
            </a>
          </Box>
        </Flex>
      </Flex>

      <LoginModal
        isOpen={isLoginModalOpen}
        onRequestClose={() => {
          if (atLoginPath()) {
            navigate(welcomePath())
          } else {
            setIsLoginModalOpen(false)
          }
        }}
      />
    </MarketingContainer>
  )
}

export default MarketingLanding

/**
 * @param {*} props
 */
const MarketingVideoPlayer = props => {
  const { isLarge } = useWindowSize()

  return (
    <ReactPlayer
      url="https://youtu.be/mCCseCvoWso"
      className={styles.Video}
      width={isLarge ? 640 : 340}
      height={isLarge ? 360 : 190}
      controls
      {...props}
    />
  )
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max))
}
