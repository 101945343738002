import React from 'react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import DocumentTitle from './DocumentTitle'

/**
 * A component to be used in top level screens:
 *
 * - Ensures page is scrolled to top
 * - Sets the document title
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {?string} [props.section]
 */
const PageMeta = ({ title, section, ...rest }) => (
  <>
    <ScrollToTopOnMount />
    <DocumentTitle title={title} section={section} {...rest} />
  </>
)

export default PageMeta
