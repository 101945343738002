import React, { Component } from 'react'
import HeadingContainer from 'src/components/HeadingContainer'
import PageMeta from 'src/components/PageMeta'
import { inject, observer } from 'mobx-react'
import SessionStore from 'src/stores/SessionStore'
import { any, func } from 'prop-types'
import LoadingScreen from 'src/components/Loading'
import InputGroup from 'src/components/InputGroup'
import TextInput, { Label } from 'src/components/TextInput'
import RegistrationStore from 'src/stores/RegistrationStore'
import { PrimaryButton } from 'src/components/Button'
import FieldErrors from 'src/components/FieldErrors'
import states from 'src/data/states.json'
import { Column, Row } from 'src/components/Layout'
import { Select, Option, OptionBlank } from 'src/components/Select'
import { Heading, Title } from 'src/components/Text'
import { onboardAddHaulerPath } from 'src/paths'
import OnboardingContainer from 'src/components/OnboardingContainer'
import Flex from 'src/components/Flex'
import MaxWidth from 'src/components/MaxWidth'

@inject('sessionStore', 'registrationStore')
@observer
class OnboardConfirmInfo extends Component {
  static propTypes = {
    sessionStore: any.isRequired,
    registrationStore: any.isRequired,
    navigate: func.isRequired,
  }

  async componentDidMount() {
    const { viewer } = this.sessionStore

    this.registrationStore.userDetailsForm.reset()
    this.registrationStore.userDetailsForm.update({
      primaryPhone: viewer.primaryPhone.phoneNumber || '',
      cellPhone: viewer.cellPhone.phoneNumber || '',
      userName: viewer.userName || '',
      email: viewer.email || '',
      address1: [viewer.address.address1, viewer.address.address2]
        .filter(x => x)
        .join(' '),
      city: viewer.address.city || '',
      state: viewer.address.state || '',
      zip: viewer.address.zip || '',
      clientId: viewer.client.clientId,
      id: viewer.userId,
    })
  }

  /** @returns {SessionStore} */
  get sessionStore() {
    return this.props.sessionStore
  }

  /** @returns {RegistrationStore} */
  get registrationStore() {
    return this.props.registrationStore
  }

  handleSubmit = async e => {
    e.preventDefault()
    if (await this.registrationStore.saveUserDetails()) {
      this.props.navigate(onboardAddHaulerPath())
    }
  }

  render() {
    const { viewer } = this.sessionStore
    const form = this.registrationStore.userDetailsForm

    if (!viewer || !form.values) return <LoadingScreen />

    return (
      <OnboardingContainer>
        <PageMeta title="Onboard Confirm Info" />
        <HeadingContainer>
          <Heading lmb={4}>
            Please confirm the information from your registration process.
          </Heading>
        </HeadingContainer>

        <MaxWidth value={460}>
          <Title>{viewer.client.name}</Title>
          {viewer.name ? <p>{viewer.name}</p> : null}
          <form id="onboardConfirmInfoForm" onSubmit={this.handleSubmit}>
            {BASIC_FIELDS.map(({ id, ...rest }) => (
              <InputGroup key={id}>
                <TextInput
                  id={id}
                  name={id}
                  value={form.values[id]}
                  onChange={this.handleBasicFieldChange}
                  required={true}
                  {...rest}
                />
                <FieldErrors field={id} errors={form.validationErrors} />
              </InputGroup>
            ))}

            <Row>
              <Column>
                <InputGroup>
                  <Label placeholder="State" floating={true}>
                    <Select
                      name="state"
                      id="state"
                      value={form.values.state}
                      onChange={this.handleBasicFieldChange}
                      required={true}
                    >
                      <OptionBlank />
                      {Object.keys(states).map(code => (
                        <Option key={code} value={code}>
                          {states[code]}
                        </Option>
                      ))}
                    </Select>
                  </Label>

                  <FieldErrors field="state" errors={form.validationErrors} />
                </InputGroup>
              </Column>
              <Column>
                <InputGroup>
                  <TextInput
                    id="zip"
                    name="zip"
                    placeholder="Zip code"
                    value={form.values.zip}
                    onChange={this.handleBasicFieldChange}
                  />
                  <FieldErrors field="zip" errors={form.validationErrors} />
                </InputGroup>
              </Column>
            </Row>

            <Flex justifyContent="center">
              <PrimaryButton
                type="submit"
                data-test="onboardConfirmInfoSubmitButton"
              >
                Yes, Looks Good!
              </PrimaryButton>
            </Flex>
          </form>
        </MaxWidth>
      </OnboardingContainer>
    )
  }

  handleBasicFieldChange = e => {
    this.registrationStore.userDetailsForm.update({
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }
}

const BASIC_FIELDS = [
  {
    id: 'primaryPhone',
    placeholder: 'Phone',
    type: 'tel',
  },
  {
    id: 'cellPhone',
    placeholder: 'Mobile Phone',
    type: 'tel',
  },
  {
    id: 'userName',
    placeholder: 'Username',
  },
  {
    id: 'email',
    placeholder: 'Email',
  },
  {
    id: 'address1',
    placeholder: 'Address',
  },
  {
    id: 'city',
    placeholder: 'City',
  },
]

export default OnboardConfirmInfo
