import React from 'react'
import styles from './LinkBanner.css'
import { Link } from '@reach/router'
import RightArrow from 'src/icons/RightArrow.svg'

/** @param {*} props */
const LinkBanner = ({ children, ...props }) => (
  <Link className={styles.LinkBanner} {...props}>
    <div className={styles.Message}>{children}</div>

    <div className={styles.BannerCTA}>
      <RightArrow />
    </div>
  </Link>
)

export default LinkBanner
