import { observable } from 'mobx'
import FilterForm from 'src/models/FilterForm'
import PaginatedRequestState from 'src/models/PaginatedRequestState'
import { emailActionPaginated, tsvDownloadAction } from 'src/util/apiActions'
import { paginatedRequestCriteria } from 'src/util/paginatedRequest'
import Store from './Store'

class InvoiceStore extends Store {
  /** @type {import('mobx').ObservableMap<Haully.OrderId, Haully.Invoice>} */
  invoices = observable.map([])

  invoiceFilterForm = new FilterForm()

  /** @type {object} */
  @observable defaultPaymentMethodData

  /** @type {boolean} */
  @observable paymentMethodChangeRequested = false

  tsvDownloadAction = tsvDownloadAction(this.rootStore.apiRequest)
  emailPaginatedAction = emailActionPaginated(this.rootStore.apiRequest)

  dateRangeFilterForStore = () => {
    const { from, to } = this.invoiceFilterForm.dateDropdownParams()

    return { fromDate: from, thruDate: to }
  }

  sortFiltersForStore = () => {
    return {
      sortField: this.invoiceFilterForm.values.sortField,
      sortAscending: this.invoiceFilterForm.values.sortAscending,
    }
  }

  invoicesPaidParams = () => ({
    criteria: {
      status: 'Paid',
      driverId: this.invoiceFilterForm.values.driverId,
      ...this.dateRangeFilterForStore(),
      ...this.sortFiltersForStore(),
    },
  })

  invoicesPaid = new PaginatedRequestState(
    pageNumber =>
      paginatedRequestCriteria({
        path: `CarrierApInvoice/list`,
        method: 'POST',
        rootStore: this.rootStore,
        data: this.invoicesPaidParams(),
      })(pageNumber),
    // @ts-ignore not supported by ts 3.8
    /** @type {Haully.Invoice} */ ({})
  )

  invoicesPaidEmail = this.emailPaginatedAction({
    apiPath: `CarrierApInvoice/list`,
    data: this.invoicesPaidParams(),
  })

  invoicesPaidDownload = this.tsvDownloadAction({
    apiPath: `CarrierApInvoice/list`,
    filename: `Paid Invoices.csv`,
    data: this.invoicesPaidParams(),
  })

  invoicesPendingParams = () => ({
    criteria: {
      status: 'Pending',
      driverId: this.invoiceFilterForm.values.driverId,
      ...this.dateRangeFilterForStore(),
      ...this.sortFiltersForStore(),
    },
  })

  invoicesPending = new PaginatedRequestState(
    pageNumber =>
      paginatedRequestCriteria({
        path: `CarrierApInvoice/list`,
        method: 'POST',
        rootStore: this.rootStore,
        data: this.invoicesPendingParams(),
      })(pageNumber),
    // @ts-ignore not supported by ts 3.8
    /** @type {Haully.Invoice} */ ({})
  )

  invoicesPendingEmail = this.emailPaginatedAction({
    apiPath: `CarrierApInvoice/list`,
    data: this.invoicesPendingParams(),
  })

  invoicesPendingDownload = this.tsvDownloadAction({
    apiPath: `CarrierApInvoice/list`,
    filename: `Pending Invoices.csv`,
    data: this.invoicesPendingParams(),
  })

  /** @param {Haully.OrderId} orderId */
  getInvoice = async orderId => {
    const cachedInvoice = this.invoices.get(orderId)
    if (cachedInvoice) return cachedInvoice
    return this.fetchInvoice(orderId)
  }

  /** @param {Haully.OrderId} orderId */
  fetchInvoice = async orderId => {
    const resp = await this.rootStore.apiRequest(
      `CarrierApInvoice/details/${orderId}`
    )

    if (resp.ok) {
      /** @type {Haully.Invoice} */
      const invoice = resp.data
      this.invoices.set(orderId, invoice)
      return invoice
    }

    return null
  }

  loadDefaultPaymentMethod = async () => {
    const resp = await this.rootStore.apiRequest(
      `CarrierApInvoice/DefaultPaymentMethod`
    )

    if (resp.ok) {
      this.defaultPaymentMethodData = resp.data
    }
  }

  defaultPaymentMethodChangeRequest = async paymentMethod => {
    const resp = await this.rootStore.apiRequest(
      `CarrierApInvoice/DefaultPaymentMethodChangeRequest?requestedSettlementOption=${paymentMethod}`,
      { method: 'POST' }
    )

    if (resp.ok) {
      this.paymentMethodChangeRequested = true
    }

    return true
  }
}

export default InvoiceStore
