import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RegistrationStore from 'src/stores/RegistrationStore'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading, Subheading } from 'src/components/Text'
import { PrimaryButton } from 'src/components/Button'
import { inject, observer } from 'mobx-react'
import { onboardAddDriverPath, onboardAddHaulerPath } from 'src/paths'
import PageMeta from 'src/components/PageMeta'
import Checkbox from 'src/components/Checkbox'
import OnboardingContainer from 'src/components/OnboardingContainer'
import OnboardingCounter from 'src/components/OnboardingCounter'
import CheckboxContainer from 'src/components/CheckboxContainer'
import Flex from 'src/components/Flex'
import { OnboardFormMaxWidth } from 'src/components/MaxWidth'
import Box from 'src/components/Box'

@inject('registrationStore')
@observer
class OnboardHaulEquipment extends Component {
  static propTypes = {
    registrationStore: PropTypes.any,
    navigate: PropTypes.func.isRequired,
  }

  /** @returns {RegistrationStore} */
  get registrationStore() {
    return this.props.registrationStore
  }

  handleSubmit = async e => {
    e.preventDefault()
    if (await this.registrationStore.submitHaulEquipmentForm()) {
      this.props.navigate(onboardAddDriverPath())
    }
  }

  handleCheck = e => {
    const form = this.registrationStore.haulEquipmentForm
    form.update({
      [e.currentTarget.name]: e.currentTarget.checked,
    })
  }

  render() {
    const form = this.registrationStore.haulEquipmentForm

    const checkboxes = [
      { name: 'carrierHaveTwicCard', label: 'TWIC card' },
      { name: 'carrierHaveStraps', label: 'Straps' },
      { name: 'carrierHaveWinches', label: 'Winches' },
      { name: 'carrierTrucksHaveInternet', label: 'Trucks have internet' },
      { name: 'carrierHandleInops', label: 'INOP capabilities' },
    ].map(v => ({
      ...v,
      checked: form.values[v.name],
    }))

    return (
      <OnboardingContainer>
        <PageMeta title="Haul Equipment" />
        <Box mb={4}>
          <form onSubmit={this.handleSubmit}>
            <OnboardingCounter position={3} backTo={onboardAddHaulerPath()} />
            <OnboardFormMaxWidth>
              <HeadingContainer>
                <Heading mb={3}>What equipment do you have for hauls?</Heading>
                <Subheading>Check all that apply.</Subheading>
              </HeadingContainer>

              {checkboxes.map(({ label, name, checked }) => (
                <CheckboxContainer key={label}>
                  <Checkbox
                    name={name}
                    checked={checked}
                    onChange={this.handleCheck}
                  >
                    {label}
                  </Checkbox>
                </CheckboxContainer>
              ))}
              <Flex mt={3} justifyContent="center">
                <PrimaryButton
                  type="submit"
                  data-test="onboardHaulEquipmentSubmitButton"
                >
                  Next
                </PrimaryButton>
              </Flex>
            </OnboardFormMaxWidth>
          </form>
        </Box>
      </OnboardingContainer>
    )
  }
}

export default OnboardHaulEquipment
