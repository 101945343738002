import React, { Component } from 'react'
import { Screen } from 'src/components/Screen'
import {
  OverlayHeader,
  OverlayHeaderContent,
  OverlayHeaderLeftButton,
} from 'src/components/OverlayHeader'
import PageMeta from 'src/components/PageMeta'
import { inject, observer } from 'mobx-react'
import Box from 'src/components/Box'
import { BodyText, H1, Body2 } from 'src/components/Text'
import { PrimaryButton, PlainButton } from 'src/components/Button'
import InputGroup from 'src/components/InputGroup'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import styles from 'src/components/Settings.css'
import { Row, Column } from 'src/components/Layout'
import LoadingScreen from 'src/components/Loading'
import TextArea from 'src/components/TextArea'

/**
 * @typedef {object} Props
 * @property {import('src/stores/RegistrationStore').default} registrationStore
 * @property {import('src/stores/FlashMessageStore').default} flashMessageStore
 *
 * @augments {Component<Props>}
 */
@inject('registrationStore', 'flashMessageStore')
@observer
class ContactUs extends Component {
  async componentDidMount() {
    this.props.registrationStore.contactUsForm.reset()
  }

  handleSubmit = async e => {
    e.preventDefault()
    if (await this.props.registrationStore.submitContactUsForm()) {
      this.props.flashMessageStore.queue('Thank you for your feedback!')
      window.history.go(-1)
    }
  }

  render() {
    const form = this.props.registrationStore.contactUsForm

    if (!form) return <LoadingScreen />

    return (
      <Screen>
        <PageMeta title="Contact Us" section="Account" />

        <OverlayHeader>
          <OverlayHeaderLeftButton>
            <PlainButton onClick={() => window.history.go(-1)}>
              <LeftArrowIcon className={styles.FillCurrentColor} />
            </PlainButton>
          </OverlayHeaderLeftButton>
          <OverlayHeaderContent>
            <BodyText>Contact Us</BodyText>
          </OverlayHeaderContent>
        </OverlayHeader>

        <Box pt={3} pr={4} pl={4}>
          <H1 mb={2}>Submit Your Feedback</H1>
          <Body2 mb={3}>
            Please send us your feedback. Tell us about an issue you experienced
            or what you like.
          </Body2>

          <form id="editProfileForm" onSubmit={this.handleSubmit}>
            <InputGroup>
              <TextArea
                id="feedback"
                name="feedback"
                value={form.values.feedback}
                onChange={e => form.update({ feedback: e.currentTarget.value })}
                required={true}
                placeholder="Send us your feedback. We'd love to hear from you!"
                rows={5}
              />
            </InputGroup>

            <Row>
              <Column width="equal">
                <PrimaryButton
                  block={true}
                  variant="outline"
                  onClick={() => window.history.go(-1)}
                >
                  Cancel
                </PrimaryButton>
              </Column>
              <Column width="equal">
                <PrimaryButton
                  block={true}
                  type="submit"
                  variant="primary"
                  disabled={form.values.feedback === ''}
                >
                  Submit
                </PrimaryButton>
              </Column>
            </Row>
          </form>
        </Box>
      </Screen>
    )
  }
}

export default ContactUs
