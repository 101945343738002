import React from 'react'
import Box from 'src/components/Box'
import HeadingContainer from 'src/components/HeadingContainer'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import { Heading, BodyText, TextContainer } from 'src/components/Text'
import { LinkUnderlinedH2 } from 'src/components/ButtonUnderlined'

import MaxWidth from 'src/components/MaxWidth'
import { welcomePath } from 'src/paths'

const RegisterConfirmation = () => {
  return (
    <OnboardingContainer>
      <PageMeta title="Success!" />

      <HeadingContainer>
        <Heading mb={2} lmb={4}>
          Great!
        </Heading>
        <Box mb={3} lmb={4}>
          <MaxWidth value={632}>
            <BodyText lmb={3}>
              We will process your information.
              <br />
              Check your email for more details about
              <br />
              your application.
            </BodyText>

            <BodyText lmb={3}>
              We can&apos;t wait to find your first load.
            </BodyText>
          </MaxWidth>
        </Box>
      </HeadingContainer>

      <TextContainer lpt={5} lmb={2}>
        <LinkUnderlinedH2 color="light-blue" to={welcomePath()}>
          Close
        </LinkUnderlinedH2>
      </TextContainer>
    </OnboardingContainer>
  )
}

export default RegisterConfirmation
