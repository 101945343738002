import React from 'react'
import styles from './withSpacing.css'
import cn from 'classnames'
import getDisplayName from './getDisplayName'

type SpaceScale = 0 | 1 | 2 | 3 | 4 | 5 | undefined

interface HOCProps {
  m?: SpaceScale
  mt?: SpaceScale
  mb?: SpaceScale
  ml?: SpaceScale
  mr?: SpaceScale
  p?: SpaceScale
  pt?: SpaceScale
  pb?: SpaceScale
  pl?: SpaceScale
  pr?: SpaceScale
  lm?: SpaceScale
  lmt?: SpaceScale
  lmb?: SpaceScale
  lml?: SpaceScale
  lmr?: SpaceScale
  lp?: SpaceScale
  lpt?: SpaceScale
  lpb?: SpaceScale
  lpl?: SpaceScale
  lpr?: SpaceScale
}

interface WithClass {
  className?: string | undefined
}

export default function withSpacing<T extends WithClass = WithClass>(
  TargetComponent: React.ComponentType<T>
) {
  const WrappedComponent: React.FC<HOCProps & T> = props => {
    const {
      className,
      m,
      mt,
      mb,
      ml,
      mr,
      p,
      pt,
      pb,
      pl,
      pr,
      lm,
      lmt,
      lmb,
      lml,
      lmr,
      lp,
      lpt,
      lpb,
      lpl,
      lpr,
      ...rest
    } = props

    const enhancedClassName = cn(className, {
      [styles[`m-${m}`]]: m != null,
      [styles[`mt-${mt}`]]: mt != null,
      [styles[`mb-${mb}`]]: mb != null,
      [styles[`ml-${ml}`]]: ml != null,
      [styles[`mr-${mr}`]]: mr != null,
      [styles[`p-${p}`]]: p != null,
      [styles[`pt-${pt}`]]: pt != null,
      [styles[`pb-${pb}`]]: pb != null,
      [styles[`pl-${pl}`]]: pl != null,
      [styles[`pr-${pr}`]]: pr != null,
      [styles[`lm-${lm}`]]: lm != null,
      [styles[`lmt-${lmt}`]]: lmt != null,
      [styles[`lmb-${lmb}`]]: lmb != null,
      [styles[`lml-${lml}`]]: lml != null,
      [styles[`lmr-${lmr}`]]: lmr != null,
      [styles[`lp-${lp}`]]: lp != null,
      [styles[`lpt-${lpt}`]]: lpt != null,
      [styles[`lpb-${lpb}`]]: lpb != null,
      [styles[`lpl-${lpl}`]]: lpl != null,
      [styles[`lpr-${lpr}`]]: lpr != null,
    })

    const newProps = rest as T

    return <TargetComponent className={enhancedClassName} {...newProps} />
  }

  WrappedComponent.displayName = `WithSpacing(${getDisplayName(
    TargetComponent
  )})`

  return WrappedComponent
}
