import React from 'react'
import styles from './withBorder.css'
import cn from 'classnames'
import getDisplayName from './getDisplayName'

interface HOCProps {
  border?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'all'
    | 'horizontal'
    | 'vertical'
    | undefined
  borderWidth?: 1 | 2
  borderColor?: 'current'
}

interface WithClass {
  className?: string | undefined
}

export default function withBorder<T extends WithClass = WithClass>(
  TargetComponent: React.ComponentType<T>
) {
  const WrappedComponent: React.FC<HOCProps & T> = props => {
    const { className, border, borderWidth, borderColor, ...rest } = props
    const enhancedClassName = cn(className, styles.withBorder, {
      [styles[`Border-${border}`]]: border,
      [styles[`BorderWidth-${borderWidth}`]]: borderWidth,
      [styles[`borderColor-${borderColor}`]]: borderColor,
    })
    const newProps = rest as T

    return <TargetComponent className={enhancedClassName} {...newProps} />
  }

  WrappedComponent.displayName = `WithBorder(${getDisplayName(
    TargetComponent
  )})`

  return WrappedComponent
}
