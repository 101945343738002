import { Link } from '@reach/router'
import cn from 'classnames'
import React from 'react'
import withBackground from 'src/hocs/withBackground'
import withBorder from 'src/hocs/withBorder'
import withColor from 'src/hocs/withColor'
import withSpacing from 'src/hocs/withSpacing'
import styles from './Button.css'
import { Spinner } from './Loading'

type ButtonVariant = 'primary' | 'black' | 'outline' | 'white'
interface ButtonProps {
  variant?: ButtonVariant
  block?: boolean
  isLoading?: boolean
}

export const PrimaryButton: React.FC<ButtonProps &
  React.ButtonHTMLAttributes<any>> = ({
  className,
  variant = 'primary',
  block = false,
  type = 'button',
  isLoading,
  children,
  disabled,
  ...props
}) => (
  <button
    className={primaryButtonClassName(className, variant, block)}
    type={type}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading ? <Spinner style={{ width: 16, height: 16 }} /> : children}
  </button>
)

export const LinkButton: React.FC<{
  to: string
  disabled?: boolean
  className?: string
} & ButtonProps> = props => {
  const { to, ...buttonProps } = props

  const {
    className,
    variant = 'primary',
    block = false,
    disabled = false,
    ...rest
  } = buttonProps

  // no such thing as disabled link, so return the button instead
  if (disabled) {
    const { ...buttonPropsWithoutType } = buttonProps
    return <PrimaryButton {...buttonPropsWithoutType} />
  }

  return (
    <Link
      className={primaryButtonClassName(
        cn(styles.LinkButton, className),
        variant,
        block
      )}
      to={to}
      {...rest}
    />
  )
}

export const AnchorButton: React.FC<{ href: string } & ButtonProps &
  React.AnchorHTMLAttributes<any>> = props => {
  const { href, children, ...buttonProps } = props

  const { className, variant = 'primary', block = false, ...rest } = buttonProps

  return (
    <a
      className={primaryButtonClassName(
        cn(styles.LinkButton, className),
        variant,
        block
      )}
      href={href}
      {...rest}
    >
      {children}
    </a>
  )
}

const _IconButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  type = 'button',
  ...props
}) => (
  <button className={cn(className, styles.IconButton)} type={type} {...props} />
)
export const IconButton = withSpacing(_IconButton)

export const PlainButton: React.FC<React.ButtonHTMLAttributes<
  HTMLButtonElement
>> = ({ className, type = 'button', ...props }) => (
  <button
    className={cn(className, styles.PlainButton)}
    type={type}
    {...props}
  />
)

const _BoxButton: React.FC<{
  testID?: string
} & React.ButtonHTMLAttributes<any>> = ({
  className,
  type = 'button',
  testID,
  ...props
}) => (
  <button
    className={cn(className, styles.BoxButton)}
    type={type}
    data-test={testID}
    {...props}
  />
)

export const BoxButton = withBackground(withColor(withSpacing(_BoxButton)))

const primaryButtonClassName = (className, variant, block) =>
  cn(className, styles.PrimaryButton, styles[`variant-${variant}`], {
    [styles.block]: block,
  })

export const DismissButton: React.FC<React.ButtonHTMLAttributes<
  any
>> = props => (
  <PlainButton {...props} className={styles.DismissButton}>
    &times;
  </PlainButton>
)

const _BlockButton: React.FC<React.ButtonHTMLAttributes<any>> = ({
  className,
  ...props
}) => (
  <button
    style={{ display: 'flex', height: 50, alignItems: 'center' }}
    type="button"
    className={cn(className, styles.BlockButton)}
    {...props}
  />
)
export const BlockButton = withBorder(
  withSpacing(withColor(withBackground(_BlockButton)))
)
