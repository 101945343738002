import React from 'react'
import styles from './Flex.css'
import cn from 'classnames'
import withSpacing from 'src/hocs/withSpacing'
import withBackground from 'src/hocs/withBackground'
import withBorder from 'src/hocs/withBorder'
import withColor from 'src/hocs/withColor'

/**
 * @typedef FlexProps
 * @property {'center'|'space-between'|'flex-start'|'flex-end'|undefined} [justifyContent]
 * @property {'center'|'baseline'|'stretch'|'flex-end'|'flex-start'|undefined} [alignItems]
 * @property {'row'|'column'|undefined} [flexDirection]
 * @property {1|"auto"|undefined} [flex]
 *
 * @param {FlexProps & React.HTMLProps} props
 */
const Flex = ({
  className,
  justifyContent,
  alignItems,
  flexDirection,
  flex,
  ...props
}) => (
  <div
    className={cn(className, styles.Flex, {
      [styles[`justifyContent-${justifyContent}`]]: justifyContent,
      [styles[`alignItems-${alignItems}`]]: alignItems,
      [styles[`flexDirection-${flexDirection}`]]: flexDirection,
      [styles[`flex-${flex}`]]: flex,
    })}
    {...props}
  />
)

export default withColor(withBorder(withBackground(withSpacing(Flex))))
