import { Redirect } from '@reach/router'
import React, { useState } from 'react'
import CenteredContainer from 'src/components/CenteredContainer'
import Flex from 'src/components/Flex'
import { Anchor } from 'src/components/Link'
import LookupValue from 'src/components/LookupValue'
import { NoticeBannerHeader } from 'src/components/NoticeBanner'
import PageMeta from 'src/components/PageMeta'
import { Body1, Body2, Title } from 'src/components/Text'
import { DISPATCH_PHONE_NUMBER } from 'src/data/company'
import { useSessionStore } from 'src/hooks/useStores'
import PaperAirplaneIcon from 'src/icons/PaperAirplane.svg'
import TelephoneIcon from 'src/icons/Telephone.svg'
import { welcomePath } from 'src/paths'

const ContactDispatch = () => {
  const [isClosed, setIsClosed] = useState(false)
  const sessionStore = useSessionStore()
  const clientId = sessionStore.clientId

  if (isClosed)
    return <Redirect to={welcomePath()} noThrow={true} replace={false} />

  return (
    <Flex color="white" bg="black" flexDirection="column" flex={1}>
      <NoticeBannerHeader onDismiss={() => setIsClosed(true)} />

      <PageMeta title="Contact Dispatch" />
      <CenteredContainer centerText={true} pl={3} pr={3}>
        <Title mt={5} mb={2}>
          Need help finding your next load?
        </Title>
        <Body2 mb={4}>
          Our Carrier Concierge team is here to help you find loads faster.
        </Body2>

        <Anchor href={`tel:${DISPATCH_PHONE_NUMBER}`}>
          <Flex justifyContent="center" pt={2} pb={2}>
            <TelephoneIcon width={50} height={50} />
          </Flex>

          <Body1 mb={2}>CALL {DISPATCH_PHONE_NUMBER}</Body1>
          <Body2 mb={1}>We’re here to help you move more. Give us a call</Body2>
          <Body2 mb={1}>Monday - Friday 7am-7pm</Body2>
          <Body2 mb={3}>Weekends - 8am-4pm</Body2>
          <Body2 mb={5}>
            After hours? Leave us a message and we’ll get back to you within 24
            hours.
          </Body2>
        </Anchor>

        <LookupValue lookupKey="CarrierDispatchEmailAddress">
          {dispatchEmail => (
            <Anchor
              href={`mailto:${dispatchEmail}?subject=Message from client #${clientId}`}
            >
              <Flex justifyContent="center" pt={2} pb={2}>
                <PaperAirplaneIcon width={50} height={50} />
              </Flex>

              <Body1 mb={2}>Email</Body1>
              <Body2 mb={5}>
                Send us a message and we’ll get back to you within 24 hours
              </Body2>
            </Anchor>
          )}
        </LookupValue>
      </CenteredContainer>
    </Flex>
  )
}

export default ContactDispatch
