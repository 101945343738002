import cn from 'classnames'
import { Observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Box from 'src/components/Box'
import { PrimaryButton } from 'src/components/Button'
import { ButtonUnderlinedH2 } from 'src/components/ButtonUnderlined'
import Checkbox from 'src/components/Checkbox'
import CheckboxContainer from 'src/components/CheckboxContainer'
import CloseButton from 'src/components/CloseButton'
import Flex from 'src/components/Flex'
import LoadingScreen from 'src/components/Loading'
import { useLookup } from 'src/components/LookupValue'
import PageMeta from 'src/components/PageMeta'
import styles from 'src/components/PaymentPreferences.css'
import Spacer from 'src/components/Spacer'
import { Body2, H1, H2, Small, TextContainer, Title } from 'src/components/Text'
import Wrapper from 'src/components/Wrapper'
import { useInvoiceStore } from 'src/hooks/useStores'

// const settlements = {
//   TP: {
//     type: 'Express',
//     description: 'EPACH - 2 days 95%',
//     timing: '2nd',
//     amount: '95%',
//   },
//   qpach: {
//     type: 'Quick',
//     description: 'QPACH - 14 days 97.5%',
//     timing: '14th',
//     amount: '97.5%',
//   },
//   rpach: {
//     type: 'Regular',
//     description: 'RPACH - 30 days 100%',
//     timing: '30th',
//     amount: '100%',
//   },
// }

const PaymentPreferences = () => {
  const [editMode, setEditMode] = useState(true)
  const [newPaymentMethod, setNewPaymentMethod] = useState('')
  const { data: settlements, isLoading, error } = useLookup('PaymentTerms')

  const invoiceStore = useInvoiceStore()

  useEffect(() => {
    invoiceStore.loadDefaultPaymentMethod()
  }, [invoiceStore])

  useEffect(() => {
    if (editMode) window.scrollTo(0, 0)
  }, [editMode])

  if (isLoading) return <LoadingScreen />
  if (error) return null

  return (
    <Observer>
      {() => {
        const {
          defaultPaymentMethodData,
          paymentMethodChangeRequested,
        } = invoiceStore
        if (!defaultPaymentMethodData) return null

        const { defaultPaymentMethod } = defaultPaymentMethodData

        if (paymentMethodChangeRequested)
          return (
            <Flex bg="black" flex={1} flexDirection="column">
              <Flex justifyContent="flex-end" mr={3} mt={3}>
                <CloseButton
                  title="Close navigation"
                  onClick={() => window.history.go(-1)}
                />
              </Flex>
              <Flex justifyContent="center" flexDirection="column" m={4}>
                <TextContainer color="white">
                  <Title mb={3}>Change Request Received</Title>
                  <Body2 mb={4}>
                    We have received your request for a change to your
                    settlement terms. You will receive an email from our team
                    shortly. Approved settlement term request changes will be
                    reflected upon approval.
                  </Body2>
                  <ButtonUnderlinedH2 onClick={() => window.history.go(-1)}>
                    Close
                  </ButtonUnderlinedH2>
                </TextContainer>
              </Flex>
            </Flex>
          )

        const settlement = settlements.find(
          settlement => settlement.code === defaultPaymentMethod
        )

        return (
          <Wrapper bg="light-gray">
            <PageMeta title="Payment Preferences" />

            <Flex justifyContent="center" flexDirection="column" mt={3} mb={3}>
              <TextContainer>
                <Title>Settlement Options</Title>
              </TextContainer>

              <TextContainer mt={2} mb={1} ml={4} mr={4}>
                <Body2 mb={2}>
                  By selecting one of the options below, you are agreeing to
                  accept the specified{' '}
                  <strong>
                    settlement percentage of that payment terms as PAYMENT IN
                    FULL
                  </strong>{' '}
                  for all orders dispatched to you.
                </Body2>
                <Small>
                  (Please note, if pay out date falls on a banking holiday,
                  settlement will be deposited the next banking day.)
                </Small>
              </TextContainer>
            </Flex>

            <Box bg="light-gray-blue" color="black">
              <Flex pt={4} pl={3} mb={2} flexDirection="column">
                <H2 className={styles.Uppercase}>
                  Your Current Settlement Selection
                </H2>
              </Flex>

              <Flex pl={3} pr={3} pb={4} pt={2} flexDirection="column">
                {settlement ? (
                  <Settlement selected={true} settlement={settlement} />
                ) : (
                  <Body2>
                    {defaultPaymentMethod ||
                      'None selected. Please make a selection below:'}
                  </Body2>
                )}
              </Flex>
            </Box>

            <Flex justifyContent="center" flexDirection="column" mt={3} mb={3}>
              <TextContainer>
                <H1>Change Settlement Option</H1>
              </TextContainer>
            </Flex>

            {settlements
              .filter(({ code }) => code !== defaultPaymentMethod)
              .map((settlement, i) => (
                <Settlement
                  key={i}
                  settlement={settlement}
                  editMode={editMode}
                  defaultPaymentMethod={settlement.code}
                  newPaymentMethod={newPaymentMethod}
                  setNewPaymentMethod={setNewPaymentMethod}
                />
              ))}

            <Flex justifyContent="center" flexDirection="column" m={3}>
              {editMode ? (
                <Flex>
                  <Box mr={1}>
                    <PrimaryButton
                      variant="outline"
                      onClick={() => window.history.go(-1)}
                    >
                      Cancel
                    </PrimaryButton>
                  </Box>
                  <PrimaryButton
                    disabled={newPaymentMethod === undefined}
                    onClick={() =>
                      invoiceStore.defaultPaymentMethodChangeRequest(
                        newPaymentMethod
                      )
                    }
                  >
                    Submit Change Request
                  </PrimaryButton>
                </Flex>
              ) : (
                <PrimaryButton onClick={() => setEditMode(true)}>
                  Request to Change Settlement
                </PrimaryButton>
              )}

              <TextContainer>
                <Body2 m={3}>
                  All settlement occurs after signed proof of delivery has been
                  received and accepted.
                </Body2>
              </TextContainer>
            </Flex>
            <Spacer />
            <Spacer />
            <Spacer />
          </Wrapper>
        )
      }}
    </Observer>
  )
}

/**
 * @param {object} props
 * @param {object} props.settlement
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.selected]
 * @param {string} [props.defaultPaymentMethod]
 * @param {string} [props.newPaymentMethod]
 * @param {*} [props.setNewPaymentMethod]
 */
const Settlement = ({
  settlement,
  selected,
  editMode,
  defaultPaymentMethod,
  newPaymentMethod,
  setNewPaymentMethod,
  ...props
}) => {
  return (
    <Box
      ml={3}
      mr={3}
      mb={3}
      className={cn(styles.SettlementContainer, {
        [styles.selected]: selected,
      })}
      {...props}
    >
      <Box className={cn(styles.Settlement, { [styles.selected]: selected })}>
        <H2 className={styles.Uppercase}>{settlement.type} Settlement</H2>
        <Title mb={3}>{settlement.description}</Title>
        <Body2 className={styles.Uppercase}>Credit Timing</Body2>
        <H2 className={styles.Bold} mb={3}>
          {settlement.timing} business day
        </H2>
        <Body2 className={styles.Uppercase}>Settlement Amount</Body2>
        <H2 className={styles.Bold}>{settlement.amount} of carrier amount</H2>
      </Box>
      {editMode && !selected && (
        <Flex pt={3} pr={3} pl={3} pb={2}>
          <CheckboxContainer mb={2}>
            <Checkbox
              name={defaultPaymentMethod}
              checked={newPaymentMethod === defaultPaymentMethod}
              onChange={() => setNewPaymentMethod(defaultPaymentMethod)}
            >
              <H2 className={styles.Bold} ml={2}>
                Select {settlement.type} Settlement
              </H2>
            </Checkbox>
          </CheckboxContainer>
        </Flex>
      )}
    </Box>
  )
}
export default PaymentPreferences
