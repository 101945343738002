import {
  format as dateFnsFormat,
  parse as dateFnsParse,
  format,
  isValid,
  parse,
} from 'date-fns'
import React, { Component } from 'react'
import DayPicker, {
  DayPickerInputProps,
  DayPickerProps,
} from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { INPUT_DATE, MONTH_DAY_YEAR } from 'src/data/dateFormats'
import useWindowSize from 'src/hooks/useWindowSize'
import dayPickerStyles from './DayPicker.css'
import dayPickerInputStyles from './DayPickerInput.css'
import TextInput, { Input } from './TextInput'

/**
 * @typedef {object} InputProps
 * @property {(value: string) => void} onChangeText
 * @property {string} value
 * @property {string|undefined} [min]
 * @property {string|undefined} [max]
 * @property {string} [id]
 * @property {boolean} [required]
 * @property {React.CSSProperties|undefined} [style]
 * @property {string} [placeholder]
 * @property {boolean} [disabled]
 */

/** @param {InputProps} props */
export const InputDate = props => {
  const { isLarge } = useWindowSize()

  if (isDateInputSupported() && !isLarge) {
    const { onChangeText, ...rest } = props
    return (
      <Input
        type="date"
        onChange={e => onChangeText(e.currentTarget.value)}
        {...rest}
      />
    )
  }
  return <InputDateDesktop {...props} />
}

/**
 * @typedef {Pick<DayPickerInputProps, Exclude<keyof DayPickerInputProps, keyof typeof inputFormatProps>>} PropsWithoutFormat
 *
 * @param {InputProps & PropsWithoutFormat} props
 */
export const InputDateDesktop = ({ min, max, required, ...props }) => {
  const disabledDays = (() => {
    const minR = min ? { before: parse(min) } : null
    const maxR = max ? { after: parse(max) } : null

    if (minR && maxR) return { ...minR, ...maxR }
    if (minR) return minR
    if (maxR) return maxR
    return
  })()

  const { onChangeText, style, placeholder = '', ...rest } = props
  return (
    <DayPickerInput
      component={DateInput}
      style={{ pointerEvents: props.disabled ? 'none' : 'auto' }}
      inputProps={{
        required,
        style,
      }}
      classNames={dayPickerInputStyles}
      dayPickerProps={{ classNames: dayPickerStyles, disabledDays }}
      onDayChange={value => onChangeText(format(value, INPUT_DATE))}
      placeholder={placeholder}
      {...inputFormatProps}
      {...rest}
    />
  )
}

/** @param {DayPickerProps} props */
export const DatePicker = props => (
  <DayPicker classNames={dayPickerStyles} {...props} />
)

/**
 * based off http://react-day-picker.js.org/examples/input-date-fns
 * @param {string} str
 */
function parseDate(str) {
  const parsed = dateFnsParse(str)
  return isValid(parsed) ? parsed : undefined
}

/**
 * based off http://react-day-picker.js.org/examples/input-date-fns
 * @param {Date} date
 * @param {string} format
 * @param {string} locale
 */
function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale })
}

const inputFormatProps = {
  formatDate: formatDate,
  format: INPUT_DATE,
  parseDate: parseDate,
}

/**
 * The default component used as Overlay.
 *
 * @param {Object} props
 */
export function OverlayComponent({
  input,
  selectedDay,
  month,
  children,
  classNames,
  ...props
}) {
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>{children}</div>
    </div>
  )
}

/**
 * https://stackoverflow.com/a/10199306/692224
 */
function isDateInputSupported() {
  const input = document.createElement('input')
  input.setAttribute('type', 'date')

  if (input.type !== 'date') return false

  const notADateValue = 'not-a-date'
  input.setAttribute('value', notADateValue)
  return input.value !== notADateValue
}

/**
 * @typedef {object} ReadonlyDateInputProps
 * @property {string} placeholder
 * @property {string} value
 * @property {boolean} required
 *
 * @augments Component<ReadonlyDateInputProps & React.InputHTMLAttributes<HTMLInputElement>>
 */
class DateInput extends React.Component {
  render() {
    const { props } = this
    const { value } = props
    const valueDate = parse(value)
    const fmtValue = isValid(valueDate) ? format(valueDate, MONTH_DAY_YEAR) : ''

    return (
      <TextInput
        {...props}
        required={props.required}
        value={fmtValue}
        className={dayPickerInputStyles.DateInput}
      />
    )
  }
}
