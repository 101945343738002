import React from 'react'
import styles from './ScrollContainer.css'
import Flex from './Flex'
import cn from 'classnames'

/**
 * @typedef HorizontalScrollContainerProps
 * @property {React.ReactNode} [children]
 * @property {boolean} [snap]
 * @property {boolean} [isFlush]
 *
 * @param {HorizontalScrollContainerProps} props
 */
export const HorizontalScrollContainer = ({
  snap = true,
  isFlush = false,
  ...props
}) => (
  <div
    className={cn(
      styles.HorizontalScrollContainer,
      snap && styles.Snap,
      isFlush && styles.isFlush
    )}
    {...props}
  />
)

/**
 * @typedef VerticalScrollContainerProps
 * @property {React.ReactNode} children
 * @property {number} height
 * @property {boolean} [snap]
 *
 * @param {VerticalScrollContainerProps} props
 */
export const VerticalScrollContainer = ({ height, snap = true, ...props }) => (
  <div
    className={cn(styles.VerticalScrollContainer, snap && styles.Snap)}
    style={{ maxHeight: height }}
    {...props}
  />
)

/**
 * @typedef ScrollContainerProps
 * @property {"horizontal"|"vertical"} direction
 *
 * @param {ScrollContainerProps & VerticalScrollContainerProps & HorizontalScrollContainerProps} props
 */
export const ScrollContainer = ({ direction, ...props }) => {
  switch (direction) {
    case 'horizontal':
      return <HorizontalScrollContainer {...props} />
    case 'vertical':
      return <VerticalScrollContainer {...props} />
    default:
      throw new Error(`unknown direction: ${direction}`)
  }
}

/**
 * @typedef {object} ScrollCardProps
 * @property {React.ReactNode} [props.children]
 * @property {any[]} props.collection
 * @property {number} props.position
 * @property {"load"|undefined} [props.width]
 */

/** @param {ScrollCardProps} props */
export const HorizontalScrollCard = ({
  children,
  collection,
  position,
  width,
}) => {
  const isLast = position === collection.length - 1
  const style = width === 'load' ? { minWidth: 290 } : undefined

  return (
    <Flex pl={3} pb={3} pt={2} pr={isLast ? 3 : 1} style={style}>
      {children}
    </Flex>
  )
}

/** @param {ScrollCardProps} props */
export const VerticalScrollCard = ({ children }) => {
  return (
    <Flex pl={3} pr={3}>
      {children}
    </Flex>
  )
}

/**
 * @param {{direction: "horizontal"|"vertical"} & ScrollCardProps} props
 */
export const ScrollCard = props => {
  const { direction, ...rest } = props
  switch (direction) {
    case 'horizontal':
      return <HorizontalScrollCard {...rest} />
    case 'vertical':
      return <VerticalScrollCard {...rest} />
    default:
      throw new Error(`unknown direction: ${direction}`)
  }
}
