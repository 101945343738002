import React from 'react'
import styles from './NoticeBannerMessage.css'

const NoticeBannerMessage = ({ children, ...props }) => (
  <div className={styles.NoticeBannerMessage} {...props}>
    <span>{children}</span>
  </div>
)

export default NoticeBannerMessage
