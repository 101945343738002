import { Observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Box from 'src/components/Box'
import { PrimaryButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import { LoadingHeading } from 'src/components/Loading'
import { ScrollCard, ScrollContainer } from 'src/components/ScrollContainer'
import { LinkedLoadCardContainer } from 'src/containers/LoadCardContainer'
import { useBackhaulStore } from 'src/hooks/useStores'
import useWindowSize from 'src/hooks/useWindowSize'
import PaginatedRequestState from 'src/models/PaginatedRequestState'
import BackhaulStore from 'src/stores/BackhaulStore'

/**
 * @param {{load: Haully.Load, backhaulStore?: BackhaulStore}} props
 */
const LoadBackhaulSection = props => {
  const [backhauls, setBackhauls] = useState(
    // @ts-ignore not supported by ts 3.8
    /** @type {PaginatedRequestState<Haully.Load>|null} */ (null)
  )

  const [isActivated, setIsActivated] = useState(false)

  const backhaulStore = useBackhaulStore()

  useEffect(() => {
    if (!isActivated) return
    backhaulStore.backhauls(props.load).then(backhauls => {
      backhauls.fetchFirstPage()
      setBackhauls(backhauls)
    })
  }, [backhaulStore, props.load, isActivated])

  const { isLarge } = useWindowSize()

  if (!isActivated) {
    return (
      <Flex alignItems="center" p={4} justifyContent="center">
        <PrimaryButton onClick={() => setIsActivated(true)}>
          Search for backhauls
        </PrimaryButton>
      </Flex>
    )
  }

  if (backhauls === null) return null

  return (
    <Observer>
      {() => {
        const { collection: loads, isLoading } = backhauls
        return (
          <ScrollContainer
            direction={isLarge ? 'vertical' : 'horizontal'}
            data-test="LoadBackhauls"
            height={705}
          >
            {isLoading ? (
              <Box p={3}>
                <LoadingHeading />
              </Box>
            ) : loads.length === 0 ? (
              <Box p={3}>No backhauls found.</Box>
            ) : (
              loads.map((load, i) => (
                <ScrollCard
                  direction={isLarge ? 'vertical' : 'horizontal'}
                  width={'load'}
                  collection={loads}
                  position={i}
                  key={load.id}
                >
                  <Flex mb={3} flex={1}>
                    <LinkedLoadCardContainer load={load} />
                  </Flex>
                </ScrollCard>
              ))
            )}
          </ScrollContainer>
        )
      }}
    </Observer>
  )
}

export default LoadBackhaulSection
