import React, { Component } from 'react'
import PageMeta from 'src/components/PageMeta'
import LoadingScreen from 'src/components/Loading'
import LoadSearch from 'src/containers/LoadSearch'
import { inject, observer } from 'mobx-react'
import { LoadCardTopContainer, LoadBackButton } from 'src/components/LoadCard'
import Box from 'src/components/Box'
import Wrapper from 'src/components/Wrapper'
import Sidebar from 'src/components/Sidebar'

/**
 * @typedef Props
 * @property {import('src/stores/LoadStore').default} loadStore
 * @property {import('src/stores/BackhaulStore').default} backhaulStore
 * @property {string} loadId
 *
 * @typedef State
 * @property {(Haully.SearchParameters & Haully.SavedSearchParameters) | null} searchParams
 *
 * @augments {Component<Props & import('@reach/router').History, State>}
 */
@inject('loadStore', 'backhaulStore')
@observer
class LoadBackhauls extends Component {
  /** @type {State} */
  state = {
    searchParams: null,
  }

  async componentDidMount() {
    const load = await this.props.loadStore.getLoad(parseInt(this.props.loadId))

    if (load === null) return

    const searchParams = await this.props.backhaulStore.backhaulSearchParameters(
      load
    )

    this.setState({ searchParams })
  }

  render() {
    if (!this.state.searchParams) return <LoadingScreen />

    const load = this.props.loadStore.loads.get(parseInt(this.props.loadId))
    if (!load) throw new Error('invariant: load should be present')

    return (
      <Wrapper
        sidebar={<Sidebar />}
        topContent={
          <Box bg="black" color="white">
            <LoadCardTopContainer>
              <LoadBackButton load={load} />
            </LoadCardTopContainer>
          </Box>
        }
      >
        <PageMeta
          title="Find Backhauls"
          section={`Load ${this.props.loadId}`}
        />
        <LoadSearch
          title="Find Backhauls"
          searchParams={this.state.searchParams}
        />
      </Wrapper>
    )
  }
}
export default LoadBackhauls
