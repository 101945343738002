import React from 'react'

/**
 * @param {Object} props
 * @param {string} props.name
 */
const Initials = ({ name }) => <>{initialialize(name)}</>

/** @param {string} name */
const initialialize = name => {
  const matches = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g)
  if (!matches) return ''

  return matches
    .join('')
    .toUpperCase()
    .slice(0, 2)
}

export default Initials
