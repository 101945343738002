import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Wrapper from 'src/components/Wrapper'
import PageMeta from 'src/components/PageMeta'
import Box from 'src/components/Box'
import HeaderWithBackButton from 'src/components/HeaderWithBackButton'
import { Body1 } from 'src/components/Text'
import { DocumentCardButton } from 'src/components/DocumentCard'
import { ListEmptyMessage } from 'src/components/PaginatedList'
import { LoadingMessage } from 'src/components/Loading'

/**
 * @typedef {object} Props
 * @property {import('src/stores/DocumentStore').default} documentStore
 * @property {import('src/stores/FlashMessageStore').default} flashMessageStore
 *
 * @augments {Component<Props>}
 */
@inject('documentStore', 'flashMessageStore')
@observer
class MyDocuments extends Component {
  componentDidMount() {
    this.props.documentStore.getInsuranceDocuments()
    this.props.documentStore.getBrokerAgreements()
  }

  async downloadDocument(documentId) {
    const downloadUrl = await this.props.documentStore.getDocumentUrls(
      documentId
    )
    if (downloadUrl) {
      window.location = downloadUrl
    } else {
      this.props.flashMessageStore.add(
        `Could not download document. Please try again later.`
      )
    }
  }

  render() {
    const {
      insuranceDocuments,
      isInsuranceDocumentsLoading,
      brokerAgreements,
      isBrokerAgreementsLoading,
    } = this.props.documentStore

    return (
      <Wrapper bg="light-gray">
        <PageMeta title="My Documents" />

        <HeaderWithBackButton label="My Documents" />

        <Box p={3}>
          <Body1 mb={2}>Insurance Documents</Body1>
          {isInsuranceDocumentsLoading ? (
            <LoadingMessage />
          ) : (
            <>
              {insuranceDocuments.length === 0 && (
                <ListEmptyMessage>No documents found</ListEmptyMessage>
              )}
              {insuranceDocuments.map((document, i) => (
                <DocumentCardButton
                  document={document}
                  key={i}
                  bg="white"
                  onClick={() => {
                    this.downloadDocument(document.documentId)
                  }}
                />
              ))}
            </>
          )}
        </Box>

        <Box p={3}>
          <Body1 mb={2}>Broker Agreements</Body1>
          {isBrokerAgreementsLoading ? (
            <LoadingMessage />
          ) : (
            <>
              {brokerAgreements.length === 0 && (
                <ListEmptyMessage>No documents found</ListEmptyMessage>
              )}
              {brokerAgreements.map((document, i) => (
                <DocumentCardButton
                  document={document}
                  key={i}
                  bg="white"
                  onClick={() => {
                    this.downloadDocument(document.documentId)
                  }}
                />
              ))}
            </>
          )}
        </Box>
      </Wrapper>
    )
  }
}

export default MyDocuments
