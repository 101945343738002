import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { CallToActionBasic } from 'src/components/CallToAction'
import { LoadCollectionCount } from 'src/components/CollectionCounts'
import DownloadButton from 'src/components/DownloadButton'
import Flex from 'src/components/Flex'
import PageMeta from 'src/components/PageMeta'
import PaginatedList from 'src/components/PaginatedList'
import Responsive from 'src/components/Responsive'
import TabNavManageLoads from 'src/components/TabNavManageLoads'
import Wrapper from 'src/components/Wrapper'
import DriverDateFilters from 'src/containers/DriverDateFilters'
import { ActionableLoadCardContainer } from 'src/containers/LoadCardContainer'
import { searchPath } from 'src/paths'
import EmailLoadsButton from 'src/components/EmailLoadsButton'
import Grid from 'src/components/Grid'

/**
 * claimed = assigned
 *
 * @typedef {object} Props
 * @property {import('src/stores/LoadStore').default} loadStore
 * @property {import('src/stores/DriverStore').default} driverStore
 *
 * @augments {Component<Props>}
 */
@inject('loadStore', 'driverStore')
@observer
class LoadsClaimed extends Component {
  componentDidMount() {
    this.props.loadStore.claimedLoads.fetchFirstPage()
  }

  render() {
    const { claimedLoads } = this.props.loadStore

    return (
      <Wrapper bg="light-gray">
        <PageMeta title="Claimed Loads" section="Manage Loads" />
        <TabNavManageLoads />

        <DriverDateFilters
          form={this.props.loadStore.manageLoadFilterForm}
          driverStore={this.props.driverStore}
          filterDrivers={true}
          filterTomorrowFuture={false}
          filterDateDropdown={false}
          onFilterChange={() =>
            this.props.loadStore.claimedLoads.fetchFirstPage()
          }
          isFilterDriversOnRight={true}
        />

        <Flex p={3} bg="white" border="bottom" justifyContent="space-between">
          <LoadCollectionCount paginatedRequestState={claimedLoads} />

          <Grid gap={2} autoFlow="column">
            <EmailLoadsButton
              onSendEmail={this.props.loadStore.claimedLoadsEmail}
              actionLabel="Email Claimed Loads"
            />
            <DownloadButton
              onClick={this.props.loadStore.claimedLoadsDownload}
              actionLabel="Download Claimed Loads"
            />
          </Grid>
        </Flex>

        <Box p={3}>
          <PaginatedList
            paginatedRequestState={claimedLoads}
            emptyMessage="No claimed loads"
            footer={
              <Responsive
                large={
                  <Box mt={3}>
                    <CallToActionBasic
                      title="Keep Moving!"
                      description="Find your next load or backhaul using search. Save a search to receive notifications for your favorite routes."
                      actionLabel="Find Loads"
                      actionTo={searchPath()}
                    />
                  </Box>
                }
              />
            }
          >
            {claimedLoads.collection.map(load => (
              <ActionableLoadCardContainer key={load.id} load={load} />
            ))}
          </PaginatedList>
        </Box>
      </Wrapper>
    )
  }
}

export default LoadsClaimed
