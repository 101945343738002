import { pascalizeKeys, camelizeKeys, camelize } from 'humps'
import 'whatwg-fetch'

const PROD = process.env.NODE_ENV === 'production'

const applyDefaultOptions = options => ({
  method: 'GET',
  ...options,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers,
  },
})

/** @param {Headers} headers */
function isApplicationJson(headers) {
  const responseContentType = headers.get('Content-Type') || ''
  return /application\/json/.test(responseContentType)
}

/** @param {Headers} headers */
function isApplicationBlob(headers) {
  const responseContentType = headers.get('Content-Type') || ''
  return /application\/pdf/.test(responseContentType)
}

/** @param {Headers} headers */
function isApplicationText(headers) {
  const responseContentType = headers.get('Content-Type') || ''
  return /text\/plain/.test(responseContentType)
}

/**
 * @typedef {Object} RequestResponse
 * @property {*} data
 * @property {*} validationErrors
 * @property {?string} failure
 * @property {?Error} error
 * @property {boolean} ok
 * @property {?number} status
 * @property {string} url
 * @property {?Blob} blob
 * @property {?string} text
 */

/**
 * Wraps fetch
 *
 * - adds some default headers to speak in json
 * - includes authorization token in headers
 * - returns a promise with pascalized json and a couple response properties
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
 *
 * @param {string | Request} input
 * @param {RequestInit} [init] - fetch options that get some defaults applied
 * @param {*} [data] - data to underscore-ize and stringify as JSON as the body
 *
 *
 * @returns {Promise<RequestResponse>}
 */
export default async function request(input, init = {}, data = undefined) {
  const initWithDefaults = applyDefaultOptions({
    ...init,
    ...(data && { body: serializeData(data) }),
  })

  const defaultResponse = {
    data: null,
    validationErrors: null,
    failure: null,
    error: null,
    ok: false,
    status: null,
    url: `${input}`,
    blob: null,
    text: null,
  }

  let response
  try {
    response = await fetch(input, initWithDefaults)
  } catch (e) {
    return {
      ...defaultResponse,
      failure: e.message,
      error: e,
    }
  }

  const { ok, status } = response
  const responseHeaders = { ok, status }

  const isJson = isApplicationJson(response.headers)
  const responseJson = isJson
    ? await (async () => {
        try {
          return await response.json()
        } catch (e) {
          // handles empty responses return null
        }
      })()
    : null

  if (isJson && responseJson != null) {
    /** @type {*} */
    const json = camelizeKeys(responseJson)
    const errorsCamelized = getErrors(json)

    // consider checking response code
    const isValidationError = !!errorsCamelized

    if (!PROD && !ok && json.traces) {
      // print out rails' stack trace
      // eslint-disable-next-line no-console
      console.warn(
        json.traces.applicationTrace.map(({ trace }) => trace).join('\n')
      )
    }

    return {
      ...defaultResponse,
      ...responseHeaders,
      data: json,
      ...(!ok && isValidationError && { validationErrors: errorsCamelized }),
      ...(!ok &&
        !isValidationError && {
          failure:
            json.message ||
            json.error ||
            `${response.statusText} (${response.status})`,
        }),
    }
  }

  if (isApplicationBlob(response.headers)) {
    const blob = await response.blob()

    return {
      ...defaultResponse,
      ...responseHeaders,
      blob,
      ...(!ok && {
        failure: `${response.statusText} (${response.status})`,
      }),
    }
  }

  if (isApplicationText(response.headers)) {
    const text = await response.text()

    return {
      ...defaultResponse,
      ...responseHeaders,
      text,
      ...(!ok && {
        failure: `${response.statusText} (${response.status})`,
      }),
    }
  }

  return {
    ...defaultResponse,
    ...responseHeaders,
    ...(!ok && {
      failure: `${response.statusText} (${response.status})`,
    }),
  }
}

const serializeData = data => JSON.stringify(pascalizeKeys(data))

const getErrors = data =>
  data.errors ? processFieldProperty(data.errors) : null

/**
 * @param {*[]} errors
 */
const processFieldProperty = errors =>
  errors.map(({ field, ...rest }) => ({
    field: camelize(field),
    ...rest,
  }))
