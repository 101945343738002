import React, { useState } from 'react'
import Box from 'src/components/Box'
import { LinkButton, PrimaryButton } from 'src/components/Button'
import ErrorMessage from 'src/components/ErrorMessage'
import HeadingContainer from 'src/components/HeadingContainer'
import InputGroup from 'src/components/InputGroup'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import Spacer from 'src/components/Spacer'
import { Heading, TextContainer } from 'src/components/Text'
import TextInput from 'src/components/TextInput'
import { useApiRequest, useSessionStore } from 'src/hooks/useStores'
import { loginPath } from 'src/paths'

const ExpiredPassword = () => {
  const sessionStore = useSessionStore()
  const apiRequest = useApiRequest()

  const [username, setUsername] = useState(
    sessionStore.loginForm.emailAddress || ''
  )
  const [oldPassword, setOldPassword] = useState(
    sessionStore.loginForm.password || ''
  )
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [errorMessage, setErrorMessage] = useState(
    /** @type {string|undefined} */ (undefined)
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)

    const resp = await apiRequest(
      'Users/ExpiredPasswordUpdate',
      {
        method: 'PUT',
        isErrorGlobal: false,
      },
      {
        username,
        oldPassword,
        password,
        confirmationPassword: passwordConfirmation,
      }
    )

    if (resp.ok) {
      setIsComplete(true)
    } else {
      setErrorMessage(resp.data.message)
    }

    setIsLoading(false)
  }

  return (
    <OnboardingContainer>
      <PageMeta title="Expired Password" />
      <Spacer />
      <HeadingContainer>
        <Heading>Password Expired</Heading>
      </HeadingContainer>
      {isComplete ? (
        <TextContainer mt={3}>
          <Box mb={3}>Password updated successfully</Box>
          <LinkButton
            data-test="complete-link"
            to={loginPath({ email: username })}
          >
            Log in
          </LinkButton>
        </TextContainer>
      ) : (
        <form onSubmit={handleSubmit}>
          <InputGroup>
            <TextInput
              value={username}
              onChangeText={setUsername}
              placeholder="Enter your email"
              autoCapitalize="none"
              autoCorrect="off"
              data-test="username"
            />
          </InputGroup>

          <InputGroup>
            <TextInput
              value={oldPassword}
              onChangeText={setOldPassword}
              placeholder="Old Password"
              type="password"
              data-test="old-password"
              autoComplete="current-password"
              id="current-password"
            />
          </InputGroup>

          <InputGroup>
            <TextInput
              value={password}
              onChangeText={setPassword}
              placeholder="New Password"
              type="password"
              data-test="new-password"
              autoComplete="new-password"
              id="new-password"
            />
          </InputGroup>

          <InputGroup>
            <TextInput
              value={passwordConfirmation}
              onChangeText={setPasswordConfirmation}
              placeholder="New Password Confirmation"
              type="password"
              data-test="new-password-confirmation"
              autoComplete="new-password"
              id="new-password-confirmation"
            />
          </InputGroup>

          {errorMessage && (
            <Box mb={3}>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </Box>
          )}

          <PrimaryButton
            data-test="submit"
            type="submit"
            block={true}
            isLoading={isLoading}
          >
            Update Password
          </PrimaryButton>
        </form>
      )}
    </OnboardingContainer>
  )
}

export default ExpiredPassword
