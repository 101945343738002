import React from 'react'
import { format } from 'date-fns'
import { PlainButton } from 'src/components/Button'
import styles from './SearchCard.css'
import RightArrow from 'src/icons/RightArrow.svg'
import Remove from 'src/icons/Remove.svg'
import PickupDropoff from 'src/components/PickupDropoff'
import cityState from 'src/util/cityState'

/**
 * @typedef {object} SearchCardProps
 * @property {object} props.search
 */

/**
 * @param {SearchCardProps & React.ButtonHTMLAttributes} props
 */
export const SearchCard = props => {
  const { search } = props
  const { id } = search
  const { availableFrom, availableThru } = search.carrierSearchDto

  return (
    <PlainButton
      className={styles.SearchCard}
      data-test-search-id={id}
      {...props}
    >
      <div>
        <SearchPickupDropoff search={search.carrierSearchDto} />
        <PickupDropoff>
          {availableFrom &&
            availableThru &&
            [
              format(availableFrom, 'MMM D'),
              ' – ',
              format(availableThru, 'MMM D'),
            ].join('')}
        </PickupDropoff>
      </div>

      <div className={styles.SearchCTA}>
        <RightArrow />
      </div>
    </PlainButton>
  )
}

/**
 * @param {SearchCardProps & React.ButtonHTMLAttributes & {action: 'save'|'delete'}} props
 */
export const SearchCardRow = props => {
  const { search, action } = props
  const { id } = search

  return (
    <PlainButton
      className={styles.SearchCardRow}
      data-test-search-id={id}
      {...props}
    >
      <div>
        <SearchPickupDropoff search={search.carrierSearchDto} />
      </div>

      <div className={styles.SearchCTA}>
        {action === 'save' ? (
          <div className={styles.SaveButton}>Save</div>
        ) : (
          <Remove />
        )}
      </div>
    </PlainButton>
  )
}

export const SearchPickupDropoff = ({ search }) => {
  const { originCity, originState, destinationCity, destinationState } = search

  return (
    <>
      <PickupDropoff indicatorColor="blue">
        {cityState(originCity, originState)}
      </PickupDropoff>
      <PickupDropoff indicatorColor="gray">
        {cityState(destinationCity, destinationState)}
      </PickupDropoff>
    </>
  )
}
