import React from 'react'
import ReactModal from 'react-modal'
import styles from './Modal.css'
import cn from 'classnames'
import useWindowSize from 'src/hooks/useWindowSize'

ReactModal.setAppElement('#react-root')

/**
 * @typedef {object} ExtraModalProps
 * @property {React.ReactNode} [children]
 * @property {'white'|'blue'} [overlayBackground]
 * @property {'white'|'black'} [contentBackground]
 * @property {boolean} [fillSmallScreen]
 *
 * @typedef {ExtraModalProps & import('./ModalProps').Props} ModalProps
 */

/**
 * @param {ModalProps} props
 */
const Modal = ({
  overlayBackground = 'white',
  contentBackground = 'white',
  fillSmallScreen = false,
  ...props
}) => {
  const { isLarge } = useWindowSize()
  const shouldFillScreen = fillSmallScreen && !isLarge

  return (
    <ReactModal
      overlayClassName={cn(
        styles.ModalOverlay,
        styles[`background-${overlayBackground}`]
      )}
      className={cn(
        styles.ModalContent,
        styles[`content-${contentBackground}`],
        styles[shouldFillScreen ? 'content-fillSmallScreen' : '']
      )}
      bodyOpenClassName={styles.ModalBodyOpen}
      {...props}
    />
  )
}

export default Modal
