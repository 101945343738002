import { Location } from '@reach/router'
import cn from 'classnames'
import React from 'react'
import FlashMessages from 'src/containers/FlashMessages'
import withBackground from 'src/hocs/withBackground'
import useWindowSize from 'src/hooks/useWindowSize'
import Navigation from './Navigation'
import NavigationDesktop from './NavigationDesktop'
import Responsive from './Responsive'
import styles from './Wrapper.css'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 * @param {React.ReactNode} [props.sidebar] shows up on large screens only
 * @param {React.ReactNode} [props.topContent]
 * @param {string|undefined} [props.className]
 */
const Wrapper = ({ children, className, sidebar, topContent }) => {
  const { isLarge } = useWindowSize()
  const isSidebarVisible = !!(sidebar && isLarge)
  return (
    <>
      <SkipNavLink />
      <div className={cn(styles.Wrapper, className)}>
        <Responsive large={<NavigationDesktop />}>
          <Navigation />
        </Responsive>
        <Location>
          {({ location }) => <FlashMessages currentUrl={location.href} />}
        </Location>
        {topContent}
        <div
          className={cn(
            styles.WrapperContainer,
            isSidebarVisible && styles.WrapperContainerWithSidebar
          )}
        >
          <div className={styles.WrapperBody}>
            <SkipNavContent />
            {children}
          </div>
          {isSidebarVisible ? (
            <div className={styles.WrapperSidebar}>{sidebar}</div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default withBackground(Wrapper)
