import React from 'react'
import { TabNav, Tab } from './TabNav'
import * as paths from 'src/paths'

const TabNavInvoices = () => (
  <TabNav>
    <Tab to={paths.invoicesPaidPath()}>Paid</Tab>
    <Tab to={paths.invoicesPendingPath()}>Pending</Tab>
  </TabNav>
)
export default TabNavInvoices
