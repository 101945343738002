import { Link } from '@reach/router'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React, { useEffect, useState } from 'react'
import { PlainButton, IconButton } from 'src/components/Button'
import Hamburger from 'src/icons/Hamburger.svg'
import Logo from 'src/icons/HaullyLogo.svg'
import { welcomePath } from 'src/paths'
import styles from './Navigation.css'
import NavigationMenu from './NavigationMenu.js'
import ShortcutMenu from './ShortcutMenu'
import SearchIcon from 'src/icons/SearchIcon.svg'
import Flex from './Flex'
import SearchLoadNumber from 'src/screens/SearchLoadNumber'
import { H2Bold } from './Text'

const Navigation = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [navbarHeight, setNavbarHeight] = useState(62)
  const [showLoadNumberSearch, setShowLoadNumberSearch] = useState(false)

  /** @type {React.RefObject<HTMLDivElement>} */
  const navbar = React.createRef()

  useEffect(() => {
    if (navbar.current === null)
      throw new Error('invariant: expected navbar to not be null')
    setNavbarHeight(navbar.current.clientHeight)
  }, [navbar])

  useEffect(() => {
    const el = document.querySelector('#NavigationMenu')
    const disableScroll = () => disableBodyScroll(el)
    const enableScroll = () => enableBodyScroll(el)

    isExpanded ? disableScroll() : enableScroll()
    return () => enableScroll()
  })

  return (
    <>
      <NavigationMenu
        isExpanded={isExpanded}
        onRequestClose={() => setIsExpanded(false)}
      />
      <div style={{ height: navbarHeight }} />
      <div ref={navbar} className={styles.NavigationBar}>
        <Flex alignItems="center">
          <Link to={welcomePath()} className={styles.Logo}>
            <Logo />
          </Link>
          <IconButton
            onClick={() => setShowLoadNumberSearch(!showLoadNumberSearch)}
            ml={4}
          >
            <H2Bold className={styles.SearchText}>Search Load #</H2Bold>
            <SearchIcon className={styles.Icon} />
          </IconButton>
        </Flex>
        <PlainButton
          onClick={() => setIsExpanded(true)}
          className={styles.Hamburger}
          title="Expand navigation"
        >
          <Hamburger />
        </PlainButton>
      </div>
      <ShortcutMenu />

      {showLoadNumberSearch && (
        <SearchLoadNumber onClose={() => setShowLoadNumberSearch(false)} />
      )}
    </>
  )
}

export default Navigation
