import L, { LatLng, Map } from 'leaflet'
import 'leaflet-routing-machine'
import React, { useEffect, useMemo, useState } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import 'src/maps/leaflet.global.css'
import { env } from 'src/util/env'
import styles from './RouteMap.css'

export const CENTER = L.latLng(39.5, 98.35)
export const ZOOM = 5
export const ATTRIBUTION =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
const FIT_PADDING = 100

const OsmRouteMap = ({ load }) => {
  const [map, setMap] = useState<Map | undefined>(undefined)

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={CENTER}
        zoom={ZOOM}
        scrollWheelZoom={false}
        whenCreated={map => setMap(map)}
        className={styles.RouteMap}
      >
        <TileLayer attribution={ATTRIBUTION} url={env('MAP_TILE_API') || ''} />
      </MapContainer>
    ),
    []
  )

  // https://github.com/nextzen/lrm-mapzen/blob/gh-pages/js/fitRoutesWithPadding.js
  const onRoutesFound = (route, map) => {
    /* We turned off the default behaviour of routing machine, which fits start and destination point to the map without buffer */
    /* We are setting our desired behaviour here (adding topLeft[100,100] buffer, with bottomRight [100, 100] buffer) */
    const allRoutesCoords: LatLng[] = []
    /* Collecting all the result route coordinates */
    for (let i = 0, j = route.routes.length; i < j; i++) {
      for (let k = 0, l = route.routes[i].coordinates.length; k < l; k++) {
        allRoutesCoords.push(route.routes[i].coordinates[k])
      }
    }

    const bounds = L.latLngBounds(allRoutesCoords)
    map.fitBounds(bounds, {
      paddingTopLeft: [FIT_PADDING, FIT_PADDING],
      paddingBottomRight: [FIT_PADDING, FIT_PADDING],
    })
  }

  const customMarkerHtml = (i: number) =>
    `<div class="CustomLeafletIcon">
      <div class="CustomleafletIconLabel">
        ${String.fromCharCode(i + 65)}
      </div>
    </div>`

  useEffect(() => {
    const getOriginAddress = () => [
      load.originAddress,
      load.originCity,
      load.originState,
      load.originZip,
    ]

    const getDestinationAddress = () => [
      load.destinationAddress,
      load.destinationCity,
      load.destinationState,
      load.destinationZip,
    ]

    if (map) {
      const control = L.Routing.control({
        plan: L.Routing.plan(
          [
            L.latLng(load.originLat, load.originLon),
            L.latLng(load.destinationLat, load.destinationLon),
          ],
          {
            createMarker: (i: number, wp: L.Routing.Waypoint) => {
              const icon = L.divIcon({
                className: 'CustomLeafletIconContainer',
                html: customMarkerHtml(i),
                iconSize: [27, 43],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
              })
              const marker = L.marker(wp.latLng, { icon })
              const address =
                i === 0 ? getOriginAddress() : getDestinationAddress()
              marker.bindPopup(address.filter(a => a).join(', '))
              return marker
            },
          }
        ),
        router: new L.Routing.OSRMv1({
          serviceUrl: env('OSRM_API'),
        }),
        lineOptions: {
          styles: [
            {
              color: '#5a98dd',
              opacity: 0.7,
              weight: 5,
            },
          ],
          extendToWaypoints: false,
          missingRouteTolerance: 10,
        },
        routeWhileDragging: false,
        fitSelectedRoutes: false,
      })
      control.on('routesfound', route => onRoutesFound(route, map))
      map.attributionControl.setPrefix('')
      if (typeof control.onAdd !== 'undefined') control.onAdd(map)
    }
  }, [load, map])

  return <>{displayMap}</>
}

export default OsmRouteMap
