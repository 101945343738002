import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import styles from './NavigationItem.css'
import cn from 'classnames'

/** @param {*} props */
const NavigationItem = ({ to, children, onClick }) => (
  <li className={styles.NavigationItem}>
    <Link
      getProps={({ isCurrent }) => ({
        className: cn(styles.NavigationItemLink, {
          [styles.NavigationItemLinkActive]: isCurrent,
        }),
      })}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  </li>
)
NavigationItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
}

export default NavigationItem
