import { navigate } from '@reach/router'
import { differenceInHours } from 'date-fns'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { PrimaryButton } from 'src/components/Button'
import CollapsibleContainerMySummary from 'src/components/CollapsibleContainerMySummary'
import CollapsibleContainerMyTasks from 'src/components/CollapsibleContainerMyTasks'
import CollapsibleContainerMyTrends from 'src/components/CollapsibleContainerMyTrends'
import Flex from 'src/components/Flex'
import LoadingScreen from 'src/components/Loading'
import NoticeBanner from 'src/components/NoticeBanner'
import NoticeBannerMessage from 'src/components/NoticeBannerMessage'
import PageMeta from 'src/components/PageMeta'
import Responsive from 'src/components/Responsive'
import Wrapper from 'src/components/Wrapper'
import { searchPath } from 'src/paths'
import DashboardStore from 'src/stores/DashboardStore'
import SessionStore from 'src/stores/SessionStore'

@inject('sessionStore', 'dashboardStore')
@observer
class Dashboard extends Component {
  state = {
    loading: true,
  }

  async componentDidMount() {
    try {
      await this.dashboardStore.loadLoadsAddedToday()
    } finally {
      this.setState({ loading: false })
    }
  }

  /** @returns {SessionStore} */
  get sessionStore() {
    return this.props.sessionStore
  }

  /** @returns {DashboardStore} */
  get dashboardStore() {
    return this.props.dashboardStore
  }

  handleBannerMessage = e => {
    e.preventDefault()
    this.dashboardStore.bannerDismissedOn = new Date()
  }

  render() {
    const { dashboard, bannerDismissedOn } = this.dashboardStore

    const bannerDismissedOverAnHourAgo =
      !bannerDismissedOn ||
      (bannerDismissedOn &&
        differenceInHours(new Date(), bannerDismissedOn) >= 1)

    const showLoadsAddedToday =
      bannerDismissedOverAnHourAgo && dashboard.loadsAddedToday

    if (this.state.loading || !this.sessionStore.viewer)
      return <LoadingScreen />

    return (
      <>
        <PageMeta title="Dashboard" />

        <Wrapper
          bg="light-gray"
          sidebar={
            <Box mt={4}>
              <CollapsibleContainerMyTasks />
            </Box>
          }
          topContent={
            <>
              {showLoadsAddedToday ? (
                <NoticeBanner onDismiss={this.handleBannerMessage}>
                  <NoticeBannerMessage>
                    {dashboard.loadsAddedToday} New Loads Added Today!
                  </NoticeBannerMessage>

                  <Flex justifyContent="center">
                    <PrimaryButton
                      variant="black"
                      onClick={() => navigate(searchPath())}
                    >
                      Find Loads Now
                    </PrimaryButton>
                  </Flex>
                </NoticeBanner>
              ) : (
                <meta data-test="DashboardNoLoadsAddedToday" />
              )}
            </>
          }
        >
          <Responsive large={null}>
            <CollapsibleContainerMyTasks />
          </Responsive>
          <Box lp={4}>
            <Box lmb={4}>
              <CollapsibleContainerMyTrends />
            </Box>
            <Box lmb={4}>
              <CollapsibleContainerMySummary />
            </Box>
          </Box>
        </Wrapper>
      </>
    )
  }
}

export default Dashboard
