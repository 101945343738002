import { inject, observer } from 'mobx-react'
import React from 'react'
import { DetailLoadCard, LinkedLoadCard } from 'src/components/LoadCard'
import LoadCardActionable from 'src/components/LoadCardActionable'
import FavoriteStore from 'src/stores/FavoriteStore'

/**
 * @typedef {import('src/components/LoadCard').LoadCardProps} LoadCardProps
 *
 * @typedef {Object} HOCProps
 * @property {function} props.onFavoriteClick
 * @property {boolean} props.isFavoriteLoading
 * @property {FavoriteStore} props.favoriteStore
 */

/**
 * @template T
 * @param {React.ComponentType<T & HOCProps & LoadCardProps>} TargetComponent
 * @returns {React.ComponentType<Pick<T, Exclude<keyof T, keyof HOCProps>>>}
 */
const withLoadActions = TargetComponent => {
  /**
   * @param {T & HOCProps & LoadCardProps} props
   */
  const WrappedComponent = props => (
    <TargetComponent
      onFavoriteClick={() => props.favoriteStore.toggleFavorite(props.load)}
      isFavoriteLoading={props.favoriteStore.isFavoriteLoading(props.load.id)}
      {...props}
    />
  )

  return inject('favoriteStore')(observer(WrappedComponent))
}

export const LinkedLoadCardContainer = withLoadActions(LinkedLoadCard)
export const DetailLoadCardContainer = withLoadActions(DetailLoadCard)

// @ts-ignore
export const ActionableLoadCardContainer = withLoadActions(LoadCardActionable)
