import React from 'react'
import Box from 'src/components/Box'
import { PrimaryButton } from 'src/components/Button'
import HeadingContainer from 'src/components/HeadingContainer'
import MobileButtonContainer from 'src/components/MobileButtonContainer'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import { Heading } from 'src/components/Text'
import { registerBusinessLocationPath, registerFleetPath } from 'src/paths'
import { useRegistrationStore } from 'src/hooks/useStores'
import TextInput from 'src/components/TextInput'
import MaxWidth from 'src/components/MaxWidth'
import { Observer } from 'mobx-react'
import { Row, Column } from 'src/components/Layout'
import OnboardingCounter from 'src/components/OnboardingCounter'
import { navigate } from '@reach/router'

const RegisterInsuranceDetails = () => {
  const registrationStore = useRegistrationStore()

  return (
    <Observer>
      {() => {
        const { carrierRegistrationForm } = registrationStore

        const handleBasicFieldChange = e => {
          carrierRegistrationForm.update({
            [e.target.name]: e.target.value,
          })
        }

        const handleSubmit = e => {
          e.preventDefault()
          navigate(registerFleetPath())
        }

        return (
          <OnboardingContainer>
            <PageMeta title="Onboard Welcome" />
            <OnboardingCounter
              position={3}
              total={4}
              backTo={registerBusinessLocationPath()}
            />

            <HeadingContainer>
              <Heading mb={2} lmb={4}>
                Insurance and Tax Details
              </Heading>
            </HeadingContainer>
            <form onSubmit={handleSubmit}>
              <Box mb={3} lmb={4}>
                <MaxWidth value={460}>
                  <TextInput
                    name="insuranceAgentName"
                    value={carrierRegistrationForm.values.insuranceAgentName}
                    onChange={handleBasicFieldChange}
                    required={true}
                    placeholder="Insurance Agent Name"
                  />
                  <Row>
                    <Column>
                      <TextInput
                        name="agentPhone"
                        value={carrierRegistrationForm.values.agentPhone}
                        onChange={handleBasicFieldChange}
                        required={true}
                        type="tel"
                        placeholder="Agent Phone"
                      />
                    </Column>
                    <Column>
                      <TextInput
                        name="agentEmail"
                        value={carrierRegistrationForm.values.agentEmail}
                        onChange={handleBasicFieldChange}
                        required={false}
                        type="email"
                        placeholder="Agent Email (optional)"
                      />
                    </Column>
                  </Row>
                  <TextInput
                    name="insuranceName"
                    value={carrierRegistrationForm.values.insuranceName}
                    onChange={handleBasicFieldChange}
                    required={false}
                    placeholder="Insurance Name"
                  />
                  <Row>
                    <Column>
                      <TextInput
                        name="dotNumber"
                        value={carrierRegistrationForm.values.dotNumber}
                        onChange={handleBasicFieldChange}
                        required={true}
                        placeholder="DOT Number"
                      />
                    </Column>
                    <Column>
                      <TextInput
                        name="mcNumber"
                        value={carrierRegistrationForm.values.mcNumber}
                        onChange={handleBasicFieldChange}
                        required={true}
                        placeholder="MC Number"
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <TextInput
                        name="federalTaxId"
                        value={carrierRegistrationForm.values.federalTaxId}
                        onChange={handleBasicFieldChange}
                        required={true}
                        placeholder="Federal Tax ID"
                      />
                    </Column>
                  </Row>
                </MaxWidth>
              </Box>
              <Box mb={3} lmb={4}>
                <MobileButtonContainer>
                  <PrimaryButton type="submit">Next</PrimaryButton>
                </MobileButtonContainer>
              </Box>
            </form>
          </OnboardingContainer>
        )
      }}
    </Observer>
  )
}

export default RegisterInsuranceDetails
