import React, { Component } from 'react'
import { Title, H2 } from 'src/components/Text'
import UnassignReasonStore from 'src/stores/UnassignReasonStore'
import FlashMessageStore from 'src/stores/FlashMessageStore'
import LoadStore from 'src/stores/LoadStore'
import { LoadingHeading } from 'src/components/Loading'
import { observer, inject } from 'mobx-react'
import { PrimaryButton } from 'src/components/Button'
import Radio from 'src/components/Radio'
import Box from 'src/components/Box'
import CloseButton from 'src/components/CloseButton'
import Flex from 'src/components/Flex'
import { navigate } from '@reach/router'
import { loadPath } from 'src/paths'
import Spacer from 'src/components/Spacer'

/**
 * @typedef Props
 * @property {UnassignReasonStore} [unassignReasonStore]
 * @property {LoadStore} [loadStore]
 * @property {FlashMessageStore} [flashMessageStore]
 * @property {Haully.Load} load
 * @property {() => void} onRequestClose
 * @property {boolean} [returnToDetailOnCancel]
 *
 * @typedef State
 * @property {string?} reason
 * @property {boolean} isLoadingReasons
 * @property {boolean} isSubmitting
 *
 * @augments Component<Props,State>
 */
@inject('unassignReasonStore', 'loadStore', 'flashMessageStore')
@observer
class CancelLoad extends Component {
  state = {
    /** @type {string?} */
    reason: null,
    isLoadingReasons: true,
    isSubmitting: false,
  }

  async componentDidMount() {
    await this.unassignReasonStore.getReasons()
    this.setState({ isLoadingReasons: false })
  }

  get unassignReasonStore() {
    return /** @type {UnassignReasonStore} */ (this.props.unassignReasonStore)
  }

  get loadStore() {
    return /** @type {LoadStore} */ (this.props.loadStore)
  }

  get flashMessageStore() {
    return /** @type {FlashMessageStore} */ (this.props.flashMessageStore)
  }

  handleSubmit = async () => {
    const { reason } = this.state
    const { load } = this.props
    if (reason === null) throw new Error('invariant')

    this.setState({ isSubmitting: true })
    const isCancelled = await this.unassignReasonStore.submitCancellation(
      load.id,
      reason
    )
    this.setState({ isSubmitting: false })

    if (isCancelled) {
      if (this.props.returnToDetailOnCancel) {
        this.props.onRequestClose()
        this.flashMessageStore.queue(`Cancelled load ${load.id}`)
        navigate(loadPath(load.id))
      } else {
        this.flashMessageStore.add(`Cancelled load ${load.id}`)
        this.loadStore.claimedLoads.fetchFirstPage()
      }
    }
  }

  /** @param {string} reasonKey */
  handleChange = reasonKey => () => {
    this.setState({ reason: reasonKey })
  }

  render() {
    const {
      isUnassignReasonsLoading,
      unassignReasonGroups,
    } = this.unassignReasonStore

    const { onRequestClose } = this.props

    return (
      <>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          pt={2}
          pr={2}
        >
          <Title p={3} pt={1}>
            Reason for Cancelling
          </Title>
          <CloseButton variant="black" onClick={onRequestClose} />
        </Flex>
        <Box pl={3} pr={3} pb={3}>
          <Box mb={3}>
            {isUnassignReasonsLoading && <LoadingHeading />}
            {unassignReasonGroups.map((group, i) => (
              <Box mb={3} key={i}>
                <H2>{group.label.toUpperCase()}</H2>
                {group.reasons.map(({ code, label }) => (
                  <Box mt={2} key={code}>
                    <Radio
                      value={code}
                      checked={this.state.reason === code}
                      onChange={this.handleChange(code)}
                    >
                      {label}
                    </Radio>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
          <PrimaryButton
            onClick={this.handleSubmit}
            disabled={this.state.isSubmitting || this.state.reason === null}
            data-test="CancelLoadSubmit"
          >
            Cancel Load
          </PrimaryButton>
          <Spacer />
          <Spacer />
        </Box>
      </>
    )
  }
}

export default CancelLoad
