import React from 'react'
import styles from './ShortcutMenuItem.css'

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 */
const ShortcutMenuItem = props => (
  <div className={styles.ShortcutMenuItem} {...props} />
)

export default ShortcutMenuItem
