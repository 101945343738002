import { useContext } from 'react'
import StoreContext from 'src/stores/StoreContext'

export const useRootStore = () => useContext(StoreContext)

export const useSessionStore = () => useContext(StoreContext).sessionStore
export const useRegistrationStore = () =>
  useContext(StoreContext).registrationStore
export const useErrorStore = () => useContext(StoreContext).errorStore
export const useConfigStore = () => useContext(StoreContext).configStore
export const useDashboardStore = () => useContext(StoreContext).dashboardStore
export const useFavoriteStore = () => useContext(StoreContext).favoriteStore
export const usePlacesStore = () => useContext(StoreContext).placesStore
export const useLoadStore = () => useContext(StoreContext).loadStore
export const useSearchStore = () => useContext(StoreContext).searchStore
export const useDriverStore = () => useContext(StoreContext).driverStore
export const useTruckStore = () => useContext(StoreContext).truckStore
export const useBackhaulStore = () => useContext(StoreContext).backhaulStore
export const useUnassignReasonStore = () =>
  useContext(StoreContext).unassignReasonStore
export const useFlashMessageStore = () =>
  useContext(StoreContext).flashMessageStore
export const useInvoiceStore = () => useContext(StoreContext).invoiceStore
export const useNotificationSettingsStore = () =>
  useContext(StoreContext).notificationSettingsStore

export const useApiRequest = () => useContext(StoreContext).apiRequest
