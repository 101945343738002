import React, { useState } from 'react'
import { parse } from 'qs'
import { camelizeKeys } from 'humps'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import MaxWidth from 'src/components/MaxWidth'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading, BodyText } from 'src/components/Text'
import { PrimaryButton, LinkButton } from 'src/components/Button'
import TextInput from 'src/components/TextInput'
import Box from 'src/components/Box'
import { useApiRequest } from 'src/hooks/useStores'
import ErrorMessage from 'src/components/ErrorMessage'
import { loginPath, resetPath } from 'src/paths'
import Flex from 'src/components/Flex'
import LinkBasic from 'src/components/LinkBasic'

const ResetPassword = () => {
  /** @type {{userId?: string, resetCode?: string}} */
  const params = camelizeKeys(
    parse(window.location.search, { ignoreQueryPrefix: true })
  )
  const apiRequest = useApiRequest()

  const { userId, resetCode } = params

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [isComplete, setIsComplete] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()
    setIsSubmitting(true)
    try {
      if (password !== passwordConfirmation) {
        setError('Passwords must match')
        return
      }

      const resp = await apiRequest(
        'Users/ResetForgotPassword',
        { method: 'PUT', isErrorGlobal: false },
        {
          userId,
          resetCode,
          password,
          confirmationPassword: passwordConfirmation,
        }
      )

      if (resp.ok) {
        setIsComplete(true)
      } else {
        setError(resp.data)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  if (!userId || !resetCode) {
    return (
      <OnboardingContainer>
        Error: missing user id or reset code
      </OnboardingContainer>
    )
  }

  return (
    <OnboardingContainer>
      <PageMeta title="Reset Password" />

      <MaxWidth value={320}>
        <HeadingContainer>
          <Heading mb={2} lmb={4}>
            Reset Password
          </Heading>
          <BodyText>
            {isComplete ? (
              <>
                You’ve successfully changed your password.
                <Flex justifyContent="center" mt={3}>
                  <LinkButton to={loginPath()}>Log in</LinkButton>
                </Flex>
              </>
            ) : (
              <>Enter a new password to reset the password on your account</>
            )}
          </BodyText>
        </HeadingContainer>

        {!isComplete && (
          <form onSubmit={handleSubmit}>
            <Box mb={3}>
              <TextInput
                type="password"
                value={password}
                onChangeText={setPassword}
                placeholder="New password"
                required={true}
              />
            </Box>
            <Box mb={3}>
              <TextInput
                type="password"
                value={passwordConfirmation}
                onChangeText={setPasswordConfirmation}
                placeholder="Confirm password"
                required={true}
              />
            </Box>

            {error && (
              <Box mb={3}>
                <ErrorMessage>{error}</ErrorMessage>{' '}
                {error.includes('expired') && (
                  <LinkBasic to={resetPath()}>
                    Request new password reset email.
                  </LinkBasic>
                )}
              </Box>
            )}

            <PrimaryButton type="submit" block={true} disabled={isSubmitting}>
              Set new password
            </PrimaryButton>
          </form>
        )}
      </MaxWidth>
    </OnboardingContainer>
  )
}

export default ResetPassword
