import { format } from 'date-fns'
import React from 'react'
import { LinkUnderlinedH2 } from 'src/components/ButtonUnderlined'
import { Column, Row } from 'src/components/Layout'
import { Body1, H2 } from 'src/components/Text'
import { WEEKDAY_DAY_MONTH_TIME } from 'src/data/dateFormats'
import { loadEditPickupDropoffPath } from 'src/paths'
import Flex from './Flex'
import Box from './Box'

/** @param {{ load: Haully.LoadDetailed }} props */
export const PickupDropoffDetails = ({ load }) => (
  <Row p={3}>
    <Column>
      <Row>
        <Column>
          <PickupDropoffDetail label="Pick-up" time={load.scheduledPickup} />
        </Column>
        <Column>
          <Flex justifyContent="space-between">
            <Box>
              <PickupDropoffDetail
                label="Drop Off"
                time={load.scheduledDelivery}
              />
            </Box>
          </Flex>
        </Column>
      </Row>
    </Column>

    <Column width="auto">
      <Box>
        <LinkUnderlinedH2 to={loadEditPickupDropoffPath(load.id)}>
          Edit
        </LinkUnderlinedH2>
      </Box>
    </Column>
  </Row>
)

/** @param {{label: string, time: string | null}} props */
const PickupDropoffDetail = ({ label, time }) => (
  <>
    <H2>{label.toUpperCase()}</H2>
    <Body1>{time ? format(time, WEEKDAY_DAY_MONTH_TIME) : 'Missing'}</Body1>
  </>
)
