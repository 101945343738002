import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Screen } from 'src/components/Screen'
import { BodyText } from 'src/components/Text'
import { PlainButton } from 'src/components/Button'
import PageMeta from 'src/components/PageMeta'
import { settingsFleetPath } from 'src/paths'
import {
  OverlayHeader,
  OverlayHeaderContent,
  OverlayHeaderLeftButton,
} from 'src/components/OverlayHeader'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import { navigate } from '@reach/router'
import styles from 'src/components/Settings.css'
import Box from 'src/components/Box'
import EditHaulerForm from 'src/components/EditHaulerForm'
import TruckStore from 'src/stores/TruckStore'
import { LoadingMessage } from 'src/components/Loading'
import CenteredContainer from 'src/components/CenteredContainer'

/**
 * @typedef {object} Props
 *
 * @property {string} truckId
 * @property {import('src/stores/TruckStore').default} truckStore
 *
 * @augments {Component<Props>}
 */
@inject('truckStore')
@observer
class SettingsEditHauler extends Component {
  state = {
    loading: true,
  }

  async componentDidMount() {
    await this.truckStore.fetchCarrierTrucks()
    this.setState({ loading: false })
  }

  get returnPath() {
    return settingsFleetPath()
  }

  get truckStore() {
    return /** @type {TruckStore} */ (this.props.truckStore)
  }

  render() {
    const { loading } = this.state
    const truck = this.truckStore.trucks.get(parseInt(this.props.truckId, 10))

    return (
      <Screen>
        <PageMeta title="Edit a Truck" section="Drivers and Fleet" />

        <OverlayHeader>
          <OverlayHeaderLeftButton>
            <PlainButton onClick={() => navigate(this.returnPath)}>
              <LeftArrowIcon className={styles.FillCurrentColor} />
            </PlainButton>
          </OverlayHeaderLeftButton>
          <OverlayHeaderContent>
            <BodyText>Fleet</BodyText>
          </OverlayHeaderContent>
        </OverlayHeader>
        <CenteredContainer width="460">
          <Box pt={3} pb={3}>
            {loading && <LoadingMessage />}
            {truck && (
              <EditHaulerForm
                cancelPath={this.returnPath}
                nextPath={this.returnPath}
                truck={truck}
              />
            )}
          </Box>
        </CenteredContainer>
      </Screen>
    )
  }
}

export default SettingsEditHauler
