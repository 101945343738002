import { observable, toJS } from 'mobx'

/**
 * @typedef {object} ValidationError
 * @property {string} field
 * @property {string} message
 */

/**
 * @template ValuesType
 * @template ChoicesType
 */
class Form {
  /** @type ValuesType */
  @observable values

  /** @type ChoicesType */
  @observable choices

  /** @type {import('mobx').IObservableArray<ValidationError>} */
  validationErrors = observable.array([])

  @observable isSubmitting = false

  /**
   * @param {Object} args
   * @param {ValuesType} args.values
   * @param {ChoicesType} [args.choices]
   */
  constructor({ values, choices }) {
    this.values = values
    this.initialValue = toJS(this.values)

    this.choices = choices
  }

  /** @param {Partial<ValuesType>} values */
  update = values => {
    const valueKeys = Object.keys(this.values)

    Object.keys(values).forEach(key => {
      if (!valueKeys.includes(key))
        console.warn(
          `Attempted to update key "${key}" that doesn't exist on this form`
        )
    })

    Object.assign(this.values, values)
  }

  /** @param {ValidationError[]} validationErrors */
  updateValidationErrors = validationErrors => {
    this.validationErrors.replace(validationErrors)
  }

  /** @param {keyof ValuesType} key */
  getValue = key => {
    if (!Object.prototype.hasOwnProperty.call(this.values, key))
      throw new TypeError(
        `tried to access '${key}' property but it doesn't exist, try one of ${Object.keys(
          this.values
        ).join(', ')}`
      )

    return this.values[key]
  }

  reset = () => {
    this.update(this.initialValue)
    this.validationErrors.clear()
  }
}

export default Form
