import React from 'react'
import styles from './MobileButtonContainer.css'

/**
 * Full-width on small screens, automatic width and centered on large screens
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 */
const MobileButtonContainer = ({ children }) => (
  <div className={styles.MobileButtonContainer}>{children}</div>
)

export default MobileButtonContainer
