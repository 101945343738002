import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading, Subheading } from 'src/components/Text'
import Checkbox from 'src/components/Checkbox'
import PageMeta from 'src/components/PageMeta'
import { PrimaryButton } from 'src/components/Button'
import RegistrationStore from 'src/stores/RegistrationStore'
import { onboardLocationPath, onboardAddDriverConfirmPath } from 'src/paths'
import OnboardingContainer from 'src/components/OnboardingContainer'
import OnboardingCounter from 'src/components/OnboardingCounter'
import CheckboxContainer from 'src/components/CheckboxContainer'
import Flex from 'src/components/Flex'
import MaxWidth from 'src/components/MaxWidth'
import Box from 'src/components/Box'

@inject('registrationStore')
@observer
class OnboardHaulDrivePref extends Component {
  static propTypes = {
    registrationStore: PropTypes.any.isRequired,
    navigate: PropTypes.func.isRequired,
  }

  /** @returns {RegistrationStore} */
  get registrationStore() {
    return this.props.registrationStore
  }

  componentDidMount() {
    this.registrationStore.haulDrivePrefForm.reset()
  }

  handleSubmit = async e => {
    e.preventDefault()
    if (await this.registrationStore.submitHaulDrivePrefForm()) {
      this.props.navigate(onboardLocationPath())
    }
  }

  render() {
    const form = this.registrationStore.haulDrivePrefForm

    /** @type {{field: keyof typeof form.values, label: string}[]} */
    const choices = [
      { field: 'lessThan500', label: 'Less than 500 miles in a trip' },
      { field: 'moreThan500', label: 'More than 500 miles in a trip' },
    ]

    return (
      <OnboardingContainer>
        <PageMeta title="Driving Preferences" />

        <OnboardingCounter
          position={6}
          backTo={onboardAddDriverConfirmPath()}
        />

        <HeadingContainer>
          <Heading mb={3}>Tell us about your driving preferences.</Heading>
          <Subheading>Check all that apply</Subheading>
        </HeadingContainer>

        <MaxWidth value={260}>
          <form onSubmit={this.handleSubmit}>
            <Box lmb={4}>
              {choices.map(({ field, label }) => (
                <CheckboxContainer mb={2} key={field}>
                  <Checkbox
                    checked={form.getValue(field)}
                    onChange={e =>
                      form.update({ [field]: e.currentTarget.checked })
                    }
                  >
                    {label}
                  </Checkbox>
                </CheckboxContainer>
              ))}
            </Box>

            <Flex mt={3} lmb={4} justifyContent="center">
              <PrimaryButton
                type="submit"
                disabled={form.isSubmitting}
                data-test="onboardHaulDrivePrefSubmitButton"
              >
                Next
              </PrimaryButton>
            </Flex>
          </form>
        </MaxWidth>
      </OnboardingContainer>
    )
  }
}

export default OnboardHaulDrivePref
