import React from 'react'
import { useSessionStore } from 'src/hooks/useStores'
import Box from './Box'
import Logo from 'src/icons/HaullyLogo.svg'
import Flex from './Flex'
import { desktopNavLinks, navLinks } from 'src/data/navLinks'
import { Link } from '@reach/router'
import styles from './NavigationDesktop.css'
import {
  Menu,
  MenuList,
  MenuButton,
  MenuLink,
  MenuItem,
} from '@reach/menu-button' // docs: https://ui.reach.tech/menu-button
import { welcomePath } from 'src/paths'
import ChevronDownIcon from 'src/icons/ChevronDown.svg'
import ShortcutMenu from './ShortcutMenu'
import Avatar from './Avatar'
import { nameOrUsername } from 'src/formatter/user'
import cn from 'classnames'
import VisuallyHidden from '@reach/visually-hidden'
import { GlobalSearchBar } from './GlobalSearchBar'

const NavigationDesktop = () => {
  const sessionStore = useSessionStore()

  const settingsNavLinks = [
    ...navLinks.settings,
    {
      label: 'Log out',
      onSelect: () => {
        sessionStore.destroySession()
      },
    },
  ]

  return (
    <Flex
      bg="black"
      color="white"
      justifyContent="space-between"
      alignItems="center"
      className={styles.DesktopNavigation}
      role="navigation"
    >
      <Flex alignItems="center">
        <Link to={welcomePath()} className={styles.LogoLink}>
          <Logo title="Haully" />
        </Link>

        <Box ml={4}>
          <GlobalSearchBar />
        </Box>
      </Flex>

      <Flex alignItems="center">
        {desktopNavLinks.map((link, i) =>
          'navLinks' in link ? (
            <LabelDropdown key={i} {...link} />
          ) : (
            <Link key={i} to={link.to} className={styles.NavLink}>
              {link.label}
            </Link>
          )
        )}

        <Box pl={2} pr={3}>
          <Dropdown
            navLinks={settingsNavLinks}
            className={styles.AvatarDropdown}
          >
            <VisuallyHidden>Settings</VisuallyHidden>
            <Avatar
              name={
                sessionStore.viewer ? nameOrUsername(sessionStore.viewer) : ''
              }
              bg="light-blue"
              color="white"
              size="desktopNav"
            />
          </Dropdown>
        </Box>
        <ShortcutMenu />
      </Flex>
    </Flex>
  )
}

/**
 * @typedef {{ label: string, onSelect: () => void}} NavAction
 */

/**
 * @param {object} props
 * @param {string} props.label
 * @param {(import('src/data/navLinks').NavLink)[]} props.navLinks
 */
const LabelDropdown = ({ label, ...rest }) => (
  <Dropdown {...rest} className={styles.LabelDropdown}>
    {label} <ChevronDownIcon className={styles.NavLinkChevron} />
  </Dropdown>
)

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.className]
 * @param {(import('src/data/navLinks').NavLink |  NavAction)[]} props.navLinks
 */
const Dropdown = ({ children, navLinks, className }) => (
  <Menu>
    <MenuButton className={cn(className, styles.MenuButton)}>
      {children}
    </MenuButton>
    <MenuList className={styles.MenuList}>
      {navLinks.map((navLink, i) => {
        const { label, ...rest } = navLink

        if ('onSelect' in rest) {
          return (
            <MenuItem {...rest} key={i} className={styles.MenuLink}>
              {label}
            </MenuItem>
          )
        }

        return (
          <MenuLink {...rest} as={Link} key={i} className={styles.MenuLink}>
            {label}
          </MenuLink>
        )
      })}
    </MenuList>
  </Menu>
)

export default NavigationDesktop
