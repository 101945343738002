import React from 'react'
import ReactDocumentTitle from 'react-document-title'

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {?string} [props.section]
 */
const DocumentTitle = ({ title, section, ...rest }) => (
  <ReactDocumentTitle title={fmtTitle(title, section)} {...rest} />
)

const suffix = ' · Haully'
const fmtTitle = (title, section) =>
  section ? `${title} – ${section}${suffix}` : `${title}${suffix}`

export default DocumentTitle
