import { format, max } from 'date-fns'
import { Observer } from 'mobx-react'
import React from 'react'
import InputTime from 'src/InputTime'
import { PrimaryButton } from 'src/components/Button'
import ErrorMessage from 'src/components/ErrorMessage'
import FieldErrors from 'src/components/FieldErrors'
import Flex from 'src/components/Flex'
import { InputDate } from 'src/components/InputDate'
import { Column, Row } from 'src/components/Layout'
import { INPUT_DATE } from 'src/data/dateFormats'
import { DATE_INPUT_MAX, DATE_INPUT_MIN } from 'src/data/dateInput'
import useWindowSize from 'src/hooks/useWindowSize'
import LoadStore from 'src/stores/LoadStore'

/**
 * @param {string | null} earliestPickupStr
 */
const getEarliestPickupMin = earliestPickupStr => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  if (!earliestPickupStr) {
    return today
  }
  return max(new Date(earliestPickupStr), today)
}

/**
 * @param {string} dateStr
 */
const isDateInThePast = dateStr => {
  const date = new Date(dateStr)
  return date < new Date(new Date().setHours(0, 0, 0, 0))
}

/**
 * @param {object} props
 * @param {LoadStore} props.loadStore
 * @param {Haully.Load} props.load
 * @param {() => void} props.onSubmit
 */
const LoadPickupDropoffForm = ({ loadStore, load, onSubmit }) => {
  const { isLarge } = useWindowSize()

  return (
    <Observer>
      {() => {
        const form = loadStore.loadPickupDeliveryForm

        const earliestPickupMin = getEarliestPickupMin(load.earliestPickup)
        const earliestPickup = format(earliestPickupMin, INPUT_DATE)

        const latestPickup = load.latestPickup
          ? format(load.latestPickup, INPUT_DATE)
          : null

        const latestDelivery = load.latestDelivery
          ? format(load.latestDelivery, INPUT_DATE)
          : null

        const isPickupInThePast =
          !!load.latestPickup && isDateInThePast(load.latestPickup)

        return (
          <form
            onSubmit={e => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <Flex justifyContent="center">
              <Flex flexDirection={isLarge ? 'row' : 'column'}>
                <Row mb={4} lmr={4}>
                  <Column width={isLarge ? 'auto' : undefined}>
                    <InputDate
                      id="pickupDate"
                      min={earliestPickup}
                      max={latestPickup || DATE_INPUT_MAX}
                      value={form.values.pickupDate}
                      onChangeText={value => form.update({ pickupDate: value })}
                      required={true}
                      disabled={isPickupInThePast}
                      placeholder="Set Pick-Up"
                      style={{ minWidth: 90 }}
                    />
                  </Column>
                  <Column width={isLarge ? 'auto' : undefined}>
                    <InputTime
                      id="pickupTime"
                      value={form.values.pickupTime}
                      required={true}
                      onChangeText={value => form.update({ pickupTime: value })}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column width={isLarge ? 'auto' : undefined}>
                    <InputDate
                      id="deliveryDate"
                      disabled={!form.values.pickupDate}
                      min={form.values.pickupDate || DATE_INPUT_MIN}
                      max={latestDelivery || DATE_INPUT_MAX}
                      value={form.values.deliveryDate}
                      onChangeText={value =>
                        form.update({ deliveryDate: value })
                      }
                      required={true}
                      placeholder="Set Drop-Off"
                      style={{ minWidth: 90 }}
                    />
                  </Column>
                  <Column width={isLarge ? 'auto' : undefined}>
                    <InputTime
                      id="deliveryTime"
                      value={form.values.deliveryTime}
                      required={true}
                      onChangeText={value =>
                        form.update({ deliveryTime: value })
                      }
                    />
                  </Column>
                </Row>
              </Flex>
            </Flex>

            {isPickupInThePast && (
              <ErrorMessage>Pick-Up date range has passed</ErrorMessage>
            )}

            <FieldErrors field="root" errors={form.validationErrors} />

            <Flex mt={4} justifyContent="center">
              <PrimaryButton
                type="submit"
                data-test="LoadPickupDropoffFormSubmit"
                disabled={form.isSubmitting}
              >
                Submit
              </PrimaryButton>
            </Flex>
          </form>
        )
      }}
    </Observer>
  )
}

export default LoadPickupDropoffForm
