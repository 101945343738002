import React from 'react'
import styles from './OverlayHeader.css'
import Flex from 'src/components/Flex'

/** @param {*} props */
export const OverlayHeader = props => (
  <Flex justifyContent="center" className={styles.OverlayHeader} {...props} />
)

export const OverlayHeaderContent = props => (
  <Flex className={styles.OverlayHeaderContent} {...props} />
)

export const OverlayHeaderLeftButton = props => (
  <Flex className={styles.OverlayHeaderLeftButton} {...props} />
)

export const OverlayHeaderRightButton = props => (
  <Flex pr={2} className={styles.OverlayHeaderRightButton} {...props} />
)
