import { navigate } from '@reach/router'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { PlainButton, PrimaryButton } from 'src/components/Button'
import Divider from 'src/components/Divider'
import FieldErrors from 'src/components/FieldErrors'
import Grid from 'src/components/Grid'
import InputGroup from 'src/components/InputGroup'
import {
  OverlayHeader,
  OverlayHeaderContent,
  OverlayHeaderLeftButton,
} from 'src/components/OverlayHeader'
import PageMeta from 'src/components/PageMeta'
import { Screen } from 'src/components/Screen'
import styles from 'src/components/Settings.css'
import { BodyText, Title } from 'src/components/Text'
import TextInput from 'src/components/TextInput'
import { nameOrUsername } from 'src/formatter/user'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import { myAccountPath } from 'src/paths'

/**
 * @typedef {object} Props
 * @property {import('src/stores/SessionStore').default} sessionStore
 * @property {import('src/stores/RegistrationStore').default} registrationStore
 *
 * @augments {Component<Props>}
 */
@inject('sessionStore', 'registrationStore')
@observer
class BusinessInfo extends Component {
  state = {
    isSubmitting: false,
  }

  async componentDidMount() {
    const { viewer } = this.props.sessionStore

    this.props.registrationStore.userDetailsForm.reset()
    this.props.registrationStore.userDetailsForm.update({
      userName: viewer.userName || '',
      primaryPhone: viewer.primaryPhone.phoneNumber || '',
      cellPhone: viewer.cellPhone.phoneNumber || '',
      address1: viewer.address.address1 || '',
      address2: viewer.address.address2 || '',
      city: viewer.address.city || '',
      state: viewer.address.state || '',
      zip: viewer.address.zip || '',
      email: viewer.email || '',
      clientId: viewer.client.clientId,
      id: viewer.userId,
    })

    this.props.registrationStore.registrationForm.reset()
    this.props.registrationStore.registrationForm.update({
      emailAddress: viewer.email,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ isSubmitting: true })
    if (await this.props.registrationStore.saveUserDetails()) {
      navigate(myAccountPath())
    } else {
      this.setState({ isSubmitting: false })
    }
  }

  handleBasicFieldChange = e => {
    this.props.registrationStore.userDetailsForm.update({
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  render() {
    const { viewer } = this.props.sessionStore
    const userDetailsForm = this.props.registrationStore.userDetailsForm

    return (
      <Screen>
        <PageMeta title="Business Information" section="Account" />

        <OverlayHeader>
          <OverlayHeaderLeftButton>
            <PlainButton onClick={() => navigate(myAccountPath())}>
              <LeftArrowIcon className={styles.FillCurrentColor} />
            </PlainButton>
          </OverlayHeaderLeftButton>
          <OverlayHeaderContent>
            <BodyText>Business Information</BodyText>
          </OverlayHeaderContent>
        </OverlayHeader>

        <Box p={4}>
          <Title>{viewer.client.name}</Title>
          <div>{nameOrUsername(viewer)}</div>
        </Box>

        <Box pl={4} pr={4}>
          <Divider />

          <form id="editBusinessInfoForm" onSubmit={this.handleSubmit}>
            {BASIC_FIELDS.map(({ id, ...rest }) => (
              <InputGroup key={id}>
                <TextInput
                  id={id}
                  name={id}
                  value={userDetailsForm.values[id]}
                  onChange={this.handleBasicFieldChange}
                  {...rest}
                />
                <FieldErrors
                  field={id}
                  errors={userDetailsForm.validationErrors}
                />
              </InputGroup>
            ))}
            <Grid gap={3} autoFlow="column">
              <PrimaryButton
                variant="outline"
                onClick={() => navigate(myAccountPath())}
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton
                type="submit"
                variant="primary"
                isLoading={this.state.isSubmitting}
              >
                Save
              </PrimaryButton>
            </Grid>
          </form>
        </Box>
      </Screen>
    )
  }
}

const BASIC_FIELDS = [
  {
    id: 'primaryPhone',
    placeholder: 'Phone',
    type: 'tel',
  },
  {
    id: 'cellPhone',
    placeholder: 'Mobile Phone',
    type: 'tel',
  },
  {
    id: 'email',
    placeholder: 'Email',
  },
  {
    id: 'address1',
    placeholder: 'Address',
    required: true,
  },
  {
    id: 'address2',
    placeholder: 'Address line 2',
  },
  {
    id: 'city',
    placeholder: 'City',
    required: true,
  },
  {
    id: 'state',
    placeholder: 'State',
  },
  {
    id: 'zip',
    placeholder: 'Zip',
  },
]

export default BusinessInfo
