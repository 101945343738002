import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { LoadCollectionCount } from 'src/components/CollectionCounts'
import DownloadButton from 'src/components/DownloadButton'
import Flex from 'src/components/Flex'
import PageMeta from 'src/components/PageMeta'
import PaginatedList from 'src/components/PaginatedList'
import TabNavManageLoads from 'src/components/TabNavManageLoads'
import Wrapper from 'src/components/Wrapper'
import DriverDateFilters from 'src/containers/DriverDateFilters'
import { ActionableLoadCardContainer } from 'src/containers/LoadCardContainer'
import NotificationTrucksFull from 'src/containers/NotificationTrucksFull'
import Grid from 'src/components/Grid'
import EmailLoadsButton from 'src/components/EmailLoadsButton'

/**
 * @typedef {object} Props
 * @property {import('src/stores/LoadStore').default} loadStore
 * @property {import('src/stores/DriverStore').default} driverStore
 *
 * @augments {Component<Props>}
 */
@inject('loadStore', 'driverStore')
@observer
class LoadsInProgress extends Component {
  componentDidMount() {
    this.props.loadStore.inProgressLoads.fetchFirstPage()
  }

  render() {
    const { inProgressLoads } = this.props.loadStore
    return (
      <Wrapper bg="light-gray">
        <PageMeta title="In Progress Loads" section="Manage Loads" />

        <TabNavManageLoads />

        <DriverDateFilters
          form={this.props.loadStore.manageLoadFilterForm}
          driverStore={this.props.driverStore}
          filterDrivers={true}
          filterTomorrowFuture={false}
          filterDateDropdown={false}
          onFilterChange={() =>
            this.props.loadStore.inProgressLoads.fetchFirstPage()
          }
          isFilterDriversOnRight={true}
        />

        <Flex p={3} bg="white" border="bottom" justifyContent="space-between">
          <LoadCollectionCount paginatedRequestState={inProgressLoads} />
          <Grid gap={2} autoFlow="column">
            <EmailLoadsButton
              onSendEmail={this.props.loadStore.inProgressLoadsEmail}
              actionLabel="Email In Progress Loads"
            />
            <DownloadButton
              onClick={this.props.loadStore.inProgressLoadsDownload}
              actionLabel="Download In Progress Loads"
            />
          </Grid>
        </Flex>

        <Box p={3}>
          <PaginatedList
            paginatedRequestState={inProgressLoads}
            emptyMessage="No in progress loads"
            footer={
              <Box mt={3}>
                <NotificationTrucksFull />
              </Box>
            }
          >
            {inProgressLoads.collection.map(load => (
              <ActionableLoadCardContainer key={load.id} load={load} />
            ))}
          </PaginatedList>
        </Box>
      </Wrapper>
    )
  }
}

export default LoadsInProgress
