import React from 'react'
import styles from './AlertInline.css'
import LargeAlert from 'src/icons/LargeAlert.svg'

const AlertInline = ({ children, ...props }) => (
  <div className={styles.AlertInline} {...props}>
    <LargeAlert />
    {children}
  </div>
)

export default AlertInline
