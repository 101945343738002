import { navigate } from '@reach/router'
import { uniqBy } from 'lodash'
import { inject, observer } from 'mobx-react'
import React from 'react'
import Box from 'src/components/Box'
import { PrimaryButton } from 'src/components/Button'
import CloseButton from 'src/components/CloseButton'
import Flex from 'src/components/Flex'
import Modal from 'src/components/Modal'
import { Heading, TextContainer } from 'src/components/Text'
import { contactDispatchPath } from 'src/paths'
import ErrorStore from 'src/stores/ErrorStore'

/**
 * @param {object} props
 * @param {ErrorStore} [props.errorStore] - injected
 */
const GlobalErrors = ({ errorStore }) => {
  if (errorStore.errors.length === 0) {
    return null
  }

  const errors = uniqBy(errorStore.errors, err => err.message)

  return (
    <Modal
      data-test="FlashMessages"
      isOpen={true}
      contentBackground="black"
      fillSmallScreen={true}
    >
      <Box bg="black" p={3}>
        <Flex justifyContent="flex-end" pt={2} pr={2}>
          <CloseButton variant="white" onClick={() => errorStore.clear()} />
        </Flex>
        <TextContainer color="white" mb={3}>
          <Heading>Oops, looks like there was an error.</Heading>
        </TextContainer>
        {errors.map(error => (
          <Flex key={error.id} flexDirection="column" color="white">
            <Box p={3}>
              <TextContainer
                style={{ wordWrap: 'break-word', textAlign: 'left' }}
              >
                {error.message}
              </TextContainer>
            </Box>
          </Flex>
        ))}
        <Flex justifyContent="center" mt={3}>
          <PrimaryButton
            onClick={() => {
              errorStore.clear()
              navigate(contactDispatchPath())
            }}
          >
            Contact Dispatch
          </PrimaryButton>
        </Flex>
      </Box>
    </Modal>
  )
}

export default inject('errorStore')(observer(GlobalErrors))
