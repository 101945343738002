import { env } from 'src/util/env'

declare global {
  interface Window {
    _hsq: any[]
  }
}

const ID = 'hs-script-loader'

export const loadHubspot = () => {
  if (env('HUBSPOT_ID') && !document.getElementById(ID)) {
    const script = document.createElement('script')
    script.src = `//js.hs-scripts.com/${env('HUBSPOT_ID')}.js`
    script.id = ID
    script.async = true
    script.defer = true
    script.type = 'text/javascript'
    document.body.appendChild(script)
  }
}

export const trackHubspotPageView = (path: string) => {
  if (env('HUBSPOT_ID') && Array.isArray(window._hsq)) {
    setTimeout(() => {
      window._hsq.push(['setPath', path])
      window._hsq.push(['trackPageView'])
    }, 100) // without the timeout, hubspot doesn't record the latest page view
  }
}

export const setHubspotIdentity = (viewer: Haully.Viewer) => {
  if (env('HUBSPOT_ID') && Array.isArray(window._hsq)) {
    window._hsq.push([
      'identify',
      {
        email: viewer.email,
        firstName: viewer.firstName,
        lastName: viewer.lastName,
        phoneNumber: viewer.primaryPhone,
        id: viewer.userId,
        userName: viewer.userName,
      },
    ])
  }
}
