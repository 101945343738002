import { computed, observable, reaction } from 'mobx'
import { stringify } from 'qs'
import RootStore from 'src/stores/RootStore'
import { setHubspotIdentity } from 'src/util/hubspot'
import Store from './Store'

export default class SessionStore extends Store {
  @observable
  loginForm = {
    emailAddress: '',
    password: '',
    isLoading: false,
    validationErrors: [],
  }

  @observable
  token

  /** @type {Haully.Viewer} */
  @observable viewer

  /**
   * @param {RootStore} rootStore
   * @param {() => void} [onLogout] - callback that is run after logging out, can use this to clear localstorage etc
   */
  constructor(rootStore, onLogout = () => {}) {
    super(rootStore)
    this.onLogout = onLogout

    reaction(
      () => this.viewer,
      viewer => {
        if (viewer) setHubspotIdentity(viewer)
      }
    )
  }

  /**
   * Creating sessions is a bit different than other api requests,
   * e.g. it's x-www-form-urlencoded and not json.
   */
  createSessionRequest = async ({ username, password }) => {
    const params = stringify({
      grant_type: 'password',
      username,
      password,
    })

    try {
      const response = await fetch(
        `${this.rootStore.configStore.apiRoot}/token`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          method: 'POST',
          body: params,
        }
      )

      const serverStatusMessage = `${response.statusText} (${response.status})`

      const data = await (async () => {
        try {
          return await response.json()
        } catch (e) {
          throw new Error(serverStatusMessage)
        }
      })()

      if (response.ok) {
        return {
          data,
          validationErrors: null,
          ok: response.ok,
        }
      } else {
        const errorMessage = data.error_description
        if (!errorMessage) {
          throw new Error(serverStatusMessage)
        }

        return {
          data,
          validationErrors: [
            {
              field: 'password',
              message: errorMessage,
            },
          ],
          ok: response.ok,
        }
      }
    } catch (e) {
      this.rootStore.errorStore.addError(e.message, { report: true })
      return {
        data: null,
        validationErrors: null,
        ok: false,
      }
    }
  }

  /**
   * @returns {Promise<[false, "expired"|"validation"] | [true, undefined]>}
   */
  createSession = async () => {
    this.updateLoginForm({
      isLoading: true,
      validationErrors: [],
      errorMessage: null,
    })

    try {
      const { emailAddress, password } = this.loginForm

      const resp = await this.createSessionRequest({
        username: emailAddress,
        password,
      })

      if (resp.ok) {
        this.setToken(resp.data)
        this.loadViewer()
        return [true, undefined]
      }

      if (resp.data && resp.data.error === 'password_expired') {
        return [false, 'expired']
      }

      if (resp.validationErrors) {
        this.updateLoginForm({ validationErrors: resp.validationErrors })
      }

      return [false, 'validation']
    } finally {
      this.updateLoginForm({
        isLoading: false,
      })
    }
  }

  setToken = sessionResponse => {
    this.token = `${sessionResponse['token_type']} ${sessionResponse['access_token']}`
  }

  destroySession = async () => {
    // TODO: consider DELETE'ing the session
    this.token = null
    this.onLogout()
  }

  updateLoginForm = state => {
    Object.assign(this.loginForm, state)
  }

  resetLoginForm = () => {
    this.loginForm = {
      emailAddress: '',
      password: '',
      isLoading: false,
      validationErrors: [],
    }
  }

  loadViewer = async () => {
    if (this.viewer) return true
    return this.fetchViewer()
  }

  fetchViewer = async () => {
    const resp = await this.rootStore.apiRequest('CurrentUser')

    if (resp.ok) {
      this.viewer = resp.data
      return true
    }
    return false
  }

  @computed
  get isLoggedIn() {
    return !!this.token
  }

  @computed
  get serialize() {
    return {
      token: this.token,
    }
  }

  deserialize = data => {
    this.token = data.token
  }

  @computed
  get clientId() {
    if (!this.isLoggedIn)
      throw new Error('invariant: attempted to access viewer but not logged in')

    if (!this.viewer)
      throw new Error(
        'invariant: attempted to access viewer but viewer is not loaded'
      )

    return this.viewer.client.clientId
  }
}
