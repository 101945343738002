import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import Avatar from 'src/components/Avatar'
import Box from 'src/components/Box'
import { PlainButton, AnchorButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import { PickupDropoffRow, VehicleIcons } from 'src/components/LoadCard'
import loadCardStyles from 'src/components/LoadCard.css'
import PageMeta from 'src/components/PageMeta'
import { Body2, H1, H2 } from 'src/components/Text'
import Wrapper from 'src/components/Wrapper'
import { emailDisputeFormatter } from 'src/data/company'
import { useInvoiceStore, useSessionStore } from 'src/hooks/useStores'
import useWindowSize from 'src/hooks/useWindowSize'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import { formatCurrency } from 'src/util/formatCurrency'
import pluralize from 'src/util/pluralize'
import styles from './InvoiceDetail.css'
import LookupValue from 'src/components/LookupValue'

/**
 * @typedef Props
 * @property {string} orderId
 */

/** @param {Props} props */
const InvoiceDetail = ({ orderId }) => {
  const sessionStore = useSessionStore()
  const invoiceStore = useInvoiceStore()
  const { isLarge } = useWindowSize()
  const [invoice, setInvoice] = useState(
    /** @type {Haully.Invoice|null} */ (null)
  )

  useEffect(() => {
    invoiceStore.fetchInvoice(parseInt(orderId)).then(result => {
      setInvoice(result)
    })
  }, [invoiceStore, orderId])

  return (
    <Wrapper>
      <PageMeta title={`Invoice ${orderId}`} />
      {invoice && (
        <>
          <Box bg="black" color="white">
            <div className={styles.InvoiceDetailGrid}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                pl={2}
                pr={2}
                pt={1}
                pb={1}
                className={styles.InvoiceDetailGridAreaLoadId}
              >
                <PlainButton onClick={() => window.history.go(-1)}>
                  <Box mr={2}>
                    <LeftArrowIcon
                      className={loadCardStyles.FillCurrentColor}
                    />
                  </Box>
                  <H2>Load {orderId}</H2>
                </PlainButton>
              </Flex>

              <Flex
                pl={3}
                mb={3}
                flexDirection="column"
                className={styles.InvoiceDetailGridAreaPayment}
              >
                <H1>{formatCurrency(invoice.amount)}</H1>
                <H2>
                  {invoice.paidStatus}{' '}
                  {invoice.paidDate && format(invoice.paidDate)}
                </H2>
              </Flex>

              <Flex
                pl={3}
                pr={3}
                flexDirection="column"
                border={isLarge ? undefined : 'bottom'}
                className={styles.InvoiceDetailGridAreaPickupDropoffVehicles}
              >
                <InvoicePickupDropoff invoice={invoice} />

                <VehicleIcons
                  operableVehicles={invoice.numberOfVehicles}
                  operableVehicleLabel={pluralize(
                    invoice.numberOfVehicles || 0,
                    'vehicle'
                  )}
                />
              </Flex>

              {invoice.driverFullName && (
                <Flex
                  p={3}
                  flexDirection="column"
                  className={styles.InvoiceDetailGridAreaDriverName}
                >
                  <Flex alignItems="center">
                    <Box mr={2}>
                      <Avatar
                        name={invoice.driverFullName}
                        bg="light-blue"
                        color="white"
                      />
                    </Box>
                    <Body2>{invoice.driverFullName}</Body2>
                  </Flex>
                </Flex>
              )}
            </div>
          </Box>

          {invoice.paidStatus == 'Paid' && (
            <Flex mt={4} justifyContent="center">
              <LookupValue lookupKey="DisputePaymentEmailAddress">
                {disputeEmail => {
                  const { subject, body } = emailDisputeFormatter(
                    'Payment Dispute',
                    sessionStore.viewer,
                    orderId
                  )

                  const mailto = `mailto:${disputeEmail}?subject=${subject}&body=${body}`

                  return (
                    <AnchorButton variant="outline" href={mailto}>
                      Dispute Payment
                    </AnchorButton>
                  )
                }}
              </LookupValue>
            </Flex>
          )}
        </>
      )}
    </Wrapper>
  )
}

/**
 * @param {object} props
 * @param {Haully.Invoice} props.invoice
 */
export const InvoicePickupDropoff = ({ invoice }) => {
  const {
    originCity,
    originState,
    actualPickupDate,
    destinationCity,
    destinationState,
    actualDeliveryDate,
  } = invoice

  return (
    <Flex>
      <div>
        <PickupDropoffRow
          indicatorColor="blue"
          city={originCity}
          state={originState}
          dates={[actualPickupDate]}
        />
        <PickupDropoffRow
          indicatorColor="gray"
          city={destinationCity}
          state={destinationState}
          dates={[actualDeliveryDate]}
        />
      </div>
    </Flex>
  )
}
export default InvoiceDetail
