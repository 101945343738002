import React from 'react'
import styles from './TextArea.css'

/**
 * @typedef {object} TextAreaProps
 * @property {(value: String) => void} [onChangeText]
 *
 * @param {TextAreaProps & React.HTMLProps<HTMLTextAreaElement>} props
 */
const TextArea = props => {
  const { onChange, onChangeText, ...rest } = props

  /** @param {React.ChangeEvent<HTMLTextAreaElement>} e */
  const handleChange = e => {
    onChangeText && onChangeText(e.currentTarget.value)
    onChange && onChange(e)
  }

  return (
    <textarea onChange={handleChange} className={styles.TextArea} {...rest} />
  )
}

export default TextArea
