import React from 'react'
import styles from './ButtonUnderlined.css'
import cn from 'classnames'
import withColor from 'src/hocs/withColor'
import { Link } from '@reach/router'
import withSpacing from 'src/hocs/withSpacing'

export const ButtonUnderlinedH2 = withColor(
  /**
   * @param {React.ButtonHTMLAttributes} props
   */
  ({ type = 'button', className, ...props }) => (
    <button
      className={cn(className, styles.ButtonUnderlinedH2)}
      type={type}
      {...props}
    />
  )
)

const _LinkUnderlinedH2 = withColor(
  /**
   * @param {{ to: string, className?: string }} props
   */
  props => (
    <Link {...props} className={cn(props.className, styles.LinkUnderlinedH2)} />
  )
)
export const LinkUnderlinedH2 = withSpacing(_LinkUnderlinedH2)

const _AnchorUnderlinedH2 = withColor(
  /**
   * @param {{ href: string, className?: string }} props
   */
  props => (
    <a {...props} className={cn(props.className, styles.LinkUnderlinedH2)} />
  )
)
export const AnchorUnderlinedH2 = withSpacing(_AnchorUnderlinedH2)
