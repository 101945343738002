import React from 'react'
import Box from 'src/components/Box'
import LinkedList from 'src/components/LinkedList'
import LinkedListItem from 'src/components/LinkedListItem'
import MarketingContainer from 'src/components/MarketingContainer'
import PageMeta from 'src/components/PageMeta'
import { Body2, Body3, H1 } from 'src/components/Text'
import * as paths from 'src/paths'

const TIPS_AND_TOOLS_LINKS = [
  {
    sectionLabel: 'Video',
    links: [
      {
        to: paths.videoResourcePath('search-by-load'),
        label: 'Search by Load - #1 Requested Feature Requested by Carriers',
        featured: true,
      },
      {
        to: paths.videoResourcePath('overview'),
        label: 'Overview',
        featured: false,
      },

      {
        to: paths.videoResourcePath('navigation-overview'),
        label: 'Navigation Overview',
        featured: false,
      },
      {
        to: paths.videoResourcePath('find-hauls'),
        label: 'How to Find Hauls',
        featured: false,
      },
      {
        to: paths.videoResourcePath('claiming-freight'),
        label: 'Claiming Freight',
        featured: false,
      },
      {
        to: paths.videoResourcePath('managing-hauls'),
        label: 'Managing Hauls',
        featured: false,
      },
      {
        to: paths.videoResourcePath('vtas-andriod'),
        label: 'V-TAS Delivery Guide - Android',
        featured: false,
      },
      {
        to: paths.videoResourcePath('vtas-andriod-spanish'),
        label: 'V-TAS Delivery Guide - Android - Español',
        featured: false,
      },
      {
        to: paths.videoResourcePath('vtas-iphone'),
        label: 'V-TAS Delivery Guide - iPhone',
        featured: false,
      },
      {
        to: paths.videoResourcePath('vtas-iphone-spanish'),
        label: 'V-TAS Delivery Guide - iPhone - Español',
        featured: false,
      },
    ],
  },
  {
    sectionLabel: 'FAQS',
    sectionDetail: 'Get answers to common questions.',
    links: [{ to: paths.faqPath(), label: 'FAQ', featured: false }],
  },
]

const TipsAndTools = () => (
  <MarketingContainer>
    <PageMeta title="Tips and Tools" />

    <Box bg="light-gray">
      {/* <OverlayHeader>
      <OverlayHeaderLeftButton>
        <PlainButton onClick={() => window.history.go(-1)}>
          <LeftArrowIcon className={styles.FillCurrentColor} />
        </PlainButton>
      </OverlayHeaderLeftButton>
      <OverlayHeaderContent>
        <BodyText>Resources and FAQs</BodyText>
      </OverlayHeaderContent>
    </OverlayHeader> */}

      <Box p={3} bg="light-gray-blue">
        <H1>Resources</H1>
        <Body2>
          Learn the ins and outs of Haully and find the best loads for you.
        </Body2>
      </Box>

      {TIPS_AND_TOOLS_LINKS.map(({ sectionLabel, sectionDetail, links }, i) => (
        <Box mt={3} key={i}>
          <Box ml={3} mb={1}>
            <Body3>{sectionLabel.toUpperCase()}</Body3>
            {sectionDetail && <Body2>{sectionDetail}</Body2>}
          </Box>
          <LinkedList>
            {links.map(({ to, label, featured }, ii) => (
              <LinkedListItem key={ii} to={to} featured={featured}>
                {label}
              </LinkedListItem>
            ))}
          </LinkedList>
        </Box>
      ))}
    </Box>
  </MarketingContainer>
)

export default TipsAndTools
