import React, { useState } from 'react'
import DownloadIcon from 'src/icons/Download.svg'
import { IconButton } from './Button'
import { Spinner } from './Loading'

/**
 * @param {object} props
 * @param {() => Promise<void>} props.onClick
 * @param {string} props.actionLabel
 */
const DownloadButton = ({ onClick, actionLabel }) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const handleClick = async () => {
    if (isDownloading) return
    setIsDownloading(true)
    try {
      await onClick()
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <IconButton onClick={handleClick}>
      {isDownloading ? (
        <Spinner style={{ width: 20, height: 20 }} />
      ) : (
        <DownloadIcon title={actionLabel} />
      )}
    </IconButton>
  )
}
export default DownloadButton
