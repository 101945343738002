import { Link, navigate } from '@reach/router'
import { Observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import CancelLoad from 'src/containers/CancelLoad'
import { useDriverStore, useLoadStore } from 'src/hooks/useStores'
import useWindowSize from 'src/hooks/useWindowSize'
import DriverIcon from 'src/icons/Driver.svg'
import MoreIcon from 'src/icons/More.svg'
import { loadEditDriverPath, loadPath } from 'src/paths'
import Box from './Box'
import { BoxButton, IconButton } from './Button'
import { DriverCard } from './DriverCard'
import { DropdownMenu, DropdownMenuItem } from './DropdownMenu'
import { EmailLoadsForm, EmailLoadsModal } from './EmailLoadsButton'
import Flex from './Flex'
import { Column, Row } from './Layout'
import { LoadCardHeading, LoadFigures, LoadPickupDropoff } from './LoadCard'
import styles from './LoadCardActionable.css'
import { ScheduledLoadTimes } from './LoadTimes'
import Modal from './Modal'
import Responsive from './Responsive'
import { Body1, Body3 } from './Text'

/**
 * @typedef Props
 * @property {Haully.LoadDetailed} load
 *
 * @typedef State
 * @property {boolean} isMenuExpanded
 * @property {boolean} isCancelModalOpen
 * @property {boolean} isExploreLoadInformationLoading
 */

/** @param {Props} props */
const LoadCardActionable = ({ load }) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [
    isExploreLoadInformationLoading,
    setIsExploreLoadInformationLoading,
  ] = useState(false)
  const [isEmailLoadsModalOpen, setIsEmailLoadsModalOpen] = useState(false)

  const driverStore = useDriverStore()
  const loadStore = useLoadStore()
  const { isLarge } = useWindowSize()

  useEffect(() => {
    if (load.driverId !== null) {
      driverStore.loadDriver(load.driverId)
    }
  }, [driverStore, load])

  const handleMenuOpen = e => {
    // prevent link behind this button from opening
    e.preventDefault()
    setIsMenuExpanded(true)
  }

  const handleMenuClose = e => {
    // prevent link behind this button from opening
    e.preventDefault()
    setIsMenuExpanded(false)
  }

  const openModal = e => {
    e.preventDefault()
    setIsCancelModalOpen(true)
  }

  const closeModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCloseEmailModal = () => {
    setIsEmailLoadsModalOpen(false)
  }

  const handleExportLoadInformation = async e => {
    e.preventDefault()
    setIsExploreLoadInformationLoading(true)

    await loadStore.downloadLoadInformation(load)

    setIsExploreLoadInformationLoading(false)
  }

  return (
    <Observer>
      {() => {
        const isDriverLoading =
          load.driverId !== null &&
          driverStore.loadingDrivers.includes(load.driverId)

        const driver =
          load.driverId !== null
            ? driverStore.drivers.get(load.driverId)
            : undefined

        return (
          <div
            className={styles.LoadCardActionable}
            data-test="LoadCardActionable"
          >
            <Flex
              className={styles.LoadCardActionableContainer}
              flexDirection={isLarge ? 'row' : 'column'}
              flex={1}
            >
              <Link
                to={loadPath(load.id)}
                className={styles.LoadCardActionableLink}
              >
                <Flex justifyContent="space-between">
                  <Flex
                    flexDirection={isLarge ? 'row' : 'column'}
                    flex={isLarge ? 1 : undefined}
                  >
                    <Flex flexDirection="column" flex={isLarge ? 1 : undefined}>
                      <LoadCardHeading load={load} />
                      <Flex>
                        <Flex
                          className={styles.PickupDropoff}
                          justifyContent="space-between"
                        >
                          <LoadPickupDropoff load={load} />
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex
                      justifyContent="space-between"
                      style={{ marginTop: isLarge ? 36 : undefined }}
                      flex={isLarge ? 1 : undefined}
                    >
                      <LoadFigures load={load} />
                    </Flex>
                  </Flex>

                  <div>
                    <IconButton
                      onClick={handleMenuOpen}
                      className={styles.MoreButton}
                      data-test="LoadCardActionableMenuOpen"
                    >
                      <MoreIcon title="Show Load Actions" />
                    </IconButton>
                  </div>
                </Flex>

                <DropdownMenu
                  onRequestClose={() => setIsMenuExpanded(false)}
                  isOpen={isMenuExpanded}
                >
                  <Flex justifyContent="flex-end">
                    <IconButton
                      onClick={handleMenuClose}
                      className={styles.MoreButton}
                    >
                      <MoreIcon />
                    </IconButton>
                  </Flex>
                  <DropdownMenuItem
                    onClick={e => {
                      e.preventDefault()
                      setIsEmailLoadsModalOpen(true)
                    }}
                  >
                    Email Load Information
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={e => {
                      e.preventDefault()
                      navigate(loadEditDriverPath(load.id))
                    }}
                  >
                    Edit Driver
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={handleExportLoadInformation}>
                    {isExploreLoadInformationLoading
                      ? 'Exporting...'
                      : 'Export Load Information'}
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    onClick={openModal}
                    isLast={true}
                    testID="LoadCardActionableCancelLoad"
                  >
                    Cancel Load
                  </DropdownMenuItem>
                </DropdownMenu>
              </Link>

              {load.scheduledPickup ||
              load.scheduledDelivery ||
              load.actualPickup ||
              load.actualDelivery ? (
                <Box
                  className={styles.RequestedTimes}
                  border={isLarge ? 'left' : 'top'}
                  p={3}
                >
                  <Row>
                    {load.scheduledPickup || load.scheduledDelivery ? (
                      <Column>
                        <ScheduledLoadTimes
                          label="Scheduled Times"
                          load={load}
                          pickup={load.scheduledPickup}
                          delivery={load.scheduledDelivery}
                        />
                      </Column>
                    ) : null}

                    {load.actualPickup || load.actualDelivery ? (
                      <Column>
                        <ScheduledLoadTimes
                          label="Actual"
                          load={load}
                          pickup={load.actualPickup}
                          delivery={load.actualDelivery}
                        />
                      </Column>
                    ) : null}
                  </Row>
                </Box>
              ) : (
                <Responsive
                  large={
                    <Box
                      className={styles.RequestedTimes}
                      border={isLarge ? 'left' : 'top'}
                      p={3}
                    >
                      <div />
                    </Box>
                  }
                />
              )}

              {load.driverId === null ? (
                <CardFooterButton
                  onClick={() => navigate(loadEditDriverPath(load.id))}
                >
                  <Box pt={1} pb={1} mr={3}>
                    <DriverIcon className={styles.DriverIcon} />
                  </Box>
                  <Body1>Assign a driver</Body1>
                </CardFooterButton>
              ) : (
                <CardFooterButton
                  onClick={() => navigate(loadEditDriverPath(load.id))}
                >
                  {isDriverLoading ? (
                    <Body1>Loading Driver</Body1>
                  ) : driver ? (
                    <DriverCard driver={driver} />
                  ) : (
                    <Body3>Unknown driver {JSON.stringify(driver)}!</Body3>
                  )}
                </CardFooterButton>
              )}
            </Flex>

            <Modal isOpen={isCancelModalOpen} onRequestClose={closeModal}>
              <CancelLoad load={load} onRequestClose={closeModal} />
            </Modal>

            <EmailLoadsModal
              isOpen={isEmailLoadsModalOpen}
              onRequestClose={handleCloseEmailModal}
            >
              <EmailLoadsForm
                assignedUser={driver}
                onRequestClose={handleCloseEmailModal}
                onSendEmail={loadStore.sendLoadEmail(load.id)}
              />
            </EmailLoadsModal>
          </div>
        )
      }}
    </Observer>
  )
}
export default LoadCardActionable

const CardFooterButton = ({ children, onClick }) => (
  <BoxButton
    className={styles.CardFooter}
    onClick={onClick}
    testID="CardFooterButton"
  >
    {children}
  </BoxButton>
)
