import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PageMeta from 'src/components/PageMeta'
import HeadingContainer from 'src/components/HeadingContainer'
import TextInput from 'src/components/TextInput'
import InputGroup from 'src/components/InputGroup'
import { ButtonUnderlinedH2 } from 'src/components/ButtonUnderlined'
import { PrimaryButton } from 'src/components/Button'
import { inject, observer } from 'mobx-react'
import RegistrationStore from 'src/stores/RegistrationStore'
import FieldErrors from 'src/components/FieldErrors'
import { parse } from 'qs'
import { Heading } from 'src/components/Text'
import { onboardWelcomePath } from 'src/paths'
import OnboardingContainer from 'src/components/OnboardingContainer'
import Flex from 'src/components/Flex'

@inject('registrationStore')
@observer
class Signup extends Component {
  static propTypes = {
    registrationStore: PropTypes.any.isRequired,
    location: PropTypes.any.isRequired,
    navigate: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const queryParams = parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const { emailAddress = '', temporaryPassword = '' } = queryParams

    this.registrationStore.registrationForm.reset()
    this.registrationStore.registrationForm.update({
      emailAddress: `${emailAddress}`,
      temporaryPassword: `${temporaryPassword}`,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    if (await this.registrationStore.register()) {
      this.props.navigate(onboardWelcomePath())
    }
  }

  /**
   * @returns {RegistrationStore}
   */
  get registrationStore() {
    return this.props.registrationStore
  }

  render() {
    const form = this.registrationStore.registrationForm
    const { update, values } = form

    return (
      <OnboardingContainer>
        <PageMeta title="Signup" />
        <HeadingContainer>
          <Heading pl={2} pr={2}>
            Congratulations you’re now a trusted carrier partner! We can’t wait
            to find your first load.
          </Heading>
        </HeadingContainer>

        <form id="signupForm" onSubmit={this.handleSubmit}>
          <InputGroup>
            <TextInput
              id="emailAddress"
              placeholder="Enter your email"
              value={values.emailAddress}
              onChange={e =>
                update({
                  emailAddress: e.currentTarget.value,
                })
              }
              type="email"
            />
            <FieldErrors field="username" errors={form.validationErrors} />
          </InputGroup>

          <InputGroup>
            <TextInput
              id="temporaryPassword"
              placeholder="Temporary Password Provided"
              value={values.temporaryPassword}
              onChange={e =>
                update({
                  temporaryPassword: e.currentTarget.value,
                })
              }
            />
            <FieldErrors
              field="temporaryPassword"
              errors={form.validationErrors}
            />
            <div style={{ display: 'flex' }}>
              <ButtonUnderlinedH2
                color="light-blue"
                onClick={() => alert('TODO')}
              >
                Please send my invitation again
              </ButtonUnderlinedH2>
            </div>
          </InputGroup>

          <InputGroup>
            <TextInput
              id="password"
              placeholder="New Password"
              value={values.password}
              onChange={e => update({ password: e.currentTarget.value })}
              type="password"
            />
            <FieldErrors field="password" errors={form.validationErrors} />
          </InputGroup>

          <InputGroup>
            <TextInput
              id="confirmationPassword"
              placeholder="Confirm Password"
              value={values.confirmationPassword}
              onChange={e =>
                update({ confirmationPassword: e.currentTarget.value })
              }
              type="password"
            />
            <FieldErrors
              field="confirmationPassword"
              errors={form.validationErrors}
            />
          </InputGroup>

          <Flex justifyContent="center">
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </Flex>
        </form>
      </OnboardingContainer>
    )
  }
}

export default Signup
