import { format } from 'date-fns'
import React from 'react'
import { LinkButton, PlainButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import styles from 'src/components/InvoiceCard.css'
import { Body2, Small } from 'src/components/Text'
import { MONTH_DAY } from 'src/data/dateFormats'
import withBackground from 'src/hocs/withBackground'
import useWindowSize from 'src/hooks/useWindowSize'
import { formatCurrency } from 'src/util/formatCurrency'
import Box from './Box'
import DropdownIcon from 'src/icons/Dropdown.svg'
import cn from 'classnames'

/**
 * @typedef {'Pending'|'Paid'|'Undelivered'}  InvoiceStatus
 */

/**
 * @typedef {Object} ICProps
 * @property {Haully.Invoice} invoice
 * @property {InvoiceStatus} [status]
 *
 * @param {ICProps} props
 */
export const InvoiceCard = props => {
  const { isLarge } = useWindowSize()
  return isLarge ? (
    <InvoiceCardDesktop {...props} />
  ) : (
    <InvoiceCardMobile {...props} />
  )
}

/**
 * @param {object} props
 * @param {InvoiceStatus} [props.status]
 * @param {object} [props.sortOption]
 * @param {() => void} [props.onSortChanged]
 */
export const InvoiceHeader = props => {
  const { isLarge } = useWindowSize()
  return isLarge ? <InvoiceHeaderDesktop {...props} /> : null
}

/**
 * @param {object} props
 * @param {Haully.Invoice} props.invoice
 */
const OriginDestination = ({ invoice }) => (
  <>
    {invoice.originCity}, {invoice.originState} to {invoice.destinationCity},{' '}
    {invoice.destinationState}
  </>
)

/**
 * @param {object} props
 * @param {InvoiceStatus} props.status
 * @param {Haully.Invoice} props.invoice
 */
const InvoicePaidOrPending = ({ status, invoice }) => {
  if (status === 'Paid' && invoice.paidDate) {
    return <>{format(invoice.paidDate, MONTH_DAY)}</>
  }
  if (status === 'Pending' && invoice.estimatedPayDate) {
    return <>{format(invoice.estimatedPayDate, MONTH_DAY)}</>
  }

  return <>{status} (Date unknown)</>
}

/** @param {ICProps} props */
const InvoiceCardMobile = ({ invoice, status = 'Pending' }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Box>
      <Body2 data-test="InvoiceCardLabel">{invoice.orderNumber}</Body2>
      <Small color="black">
        <OriginDestination invoice={invoice} />
      </Small>
    </Box>
    <div>
      <Body2 data-test="InvoiceCardPayable">
        {formatCurrency(invoice.amount)}
      </Body2>
      <Small color="dark-gray">
        <InvoicePaidOrPending invoice={invoice} status={status} />
      </Small>
    </div>
  </Flex>
)

/** @param {ICProps} props */
const InvoiceCardDesktop = ({ invoice, status = 'Pending' }) => (
  <Flex>
    <InvoiceColumn flex={2} data-test="InvoiceCardLabel">
      {invoice.orderNumber}
    </InvoiceColumn>
    <InvoiceColumn flex={5}>
      <OriginDestination invoice={invoice} />
    </InvoiceColumn>
    <InvoiceColumn flex={2} isRightAligned={true}>
      {formatCurrency(invoice.amount)}
    </InvoiceColumn>
    <InvoiceColumn flex={3} isRightAligned={true} isLast={true}>
      <InvoicePaidOrPending invoice={invoice} status={status} />
    </InvoiceColumn>
  </Flex>
)

/** @param {object} props */
const InvoiceHeaderDesktop = ({
  status = 'Pending',
  sortOption,
  onSortChanged,
}) => {
  const switchSortOptionTo =
    sortOption.ascending === undefined ? false : !sortOption.ascending

  return (
    <Flex border="bottom" p={3}>
      <InvoiceColumn
        flex={2}
        data-test="InvoiceCardLabel"
        className={styles.Sortable}
      >
        <PlainButton
          className={styles.SortableButton}
          onClick={() =>
            onSortChanged({
              field: 'OrderNumber',
              ascending: switchSortOptionTo,
            })
          }
        >
          <Flex mr={2}>Order Number</Flex>

          <DropdownIcon
            className={cn(styles.DropdownIcon, {
              [styles.Active]: sortOption.field === 'OrderNumber',
              [styles.DropupIcon]: sortOption.ascending === true,
            })}
          />
        </PlainButton>
      </InvoiceColumn>
      <InvoiceColumn flex={5}>Origin and Destination</InvoiceColumn>
      <InvoiceColumn flex={2} isRightAligned={true}>
        Amount
      </InvoiceColumn>
      <InvoiceColumn
        flex={3}
        isRightAligned={true}
        isLast={true}
        className={styles.Sortable}
      >
        <PlainButton
          className={styles.SortableButton}
          onClick={() =>
            onSortChanged({
              field: 'InvoicedDate',
              ascending: switchSortOptionTo,
            })
          }
        >
          <Flex mr={2}>
            {status === 'Pending' ? 'Est. Paid Date' : 'Paid Date'}
          </Flex>
          <DropdownIcon
            className={cn(styles.DropdownIcon, {
              [styles.Active]: sortOption.field === 'InvoicedDate',
              [styles.DropupIcon]: sortOption.ascending === true,
            })}
          />
        </PlainButton>
      </InvoiceColumn>
    </Flex>
  )
}

/**
 * @param {object} props
 * @param {number} props.flex
 * @param {boolean} [props.isRightAligned]
 * @param {boolean} [props.isLast]
 * @param {string} [props.className]
 * @param {React.ReactNode} props.children
 */
const InvoiceColumn = ({ flex, isRightAligned, isLast, ...props }) => (
  <Box
    mr={isLast ? undefined : 3}
    style={{ flex, textAlign: isRightAligned ? 'right' : undefined }}
    {...props}
  />
)

/**
 * @typedef {Object} ICBProps
 * @property {string} props.to
 * @property {string|undefined} [props.className]
 *
 * @param {ICBProps & ICProps} props
 */
const _InvoiceCardButton = ({ to, ...rest }) => (
  <LinkButton to={to} className={styles.InvoiceCardButton}>
    <Box pt={1} pb={1}>
      <InvoiceCard {...rest} />
    </Box>
  </LinkButton>
)

export const InvoiceCardButton = withBackground(_InvoiceCardButton)
