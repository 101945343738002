import { observable } from 'mobx'
import Store from './Store'

export default class SearchStore extends Store {
  @observable
  currentSearchIsSaved = false

  @observable
  savedSearches = null

  @observable
  recentSearches = null

  // https://popups.vtvsolutions.com/Oviss/swagger/ui/index#!/CarrierLoadsSearchHistory/CarrierLoadsSearchHistory_SaveSearch
  saveSearch = async searchValues => {
    const resp = await this.rootStore.apiRequest(
      'CarrierLoadsSearchHistory/SaveSearchHistory',
      { method: 'POST' },
      searchValues
    )

    if (resp.ok) {
      this.currentSearchIsSaved = true
      return true
    }
    return false
  }

  // https://popups.vtvsolutions.com/Oviss/swagger/ui/index#!/CarrierLoadsSearchHistory/CarrierLoadsSearchHistory_SaveRecentSearch
  saveRecentSearch = async searchValues => {
    const resp = await this.rootStore.apiRequest(
      'CarrierLoadsSearchHistory/SaveRecentSearchHistory',
      { method: 'POST' },
      searchValues
    )

    return resp.ok
  }

  fetchSavedSearches = async () => {
    const resp = await this.rootStore.apiRequest(
      'CarrierLoadsSearchHistory/GetSavedSearchHistory'
    )

    if (resp.ok) {
      this.savedSearches = resp.data
      return true
    }
    return false
  }

  // https://popups.vtvsolutions.com/Oviss/swagger/ui/index#!/CarrierLoadsSearchHistory/CarrierLoadsSearchHistory_RemoveSearch
  removeSavedSearch = async searchId => {
    const resp = await this.rootStore.apiRequest(
      `CarrierLoadsSearchHistory/RemoveSearchHistory?searchHistoryId=${searchId}`,
      { method: 'DELETE' }
    )

    return resp.ok
  }

  fetchRecentSearches = async () => {
    const resp = await this.rootStore.apiRequest(
      'CarrierLoadsSearchHistory/GetRecentSearchHistory'
    )

    if (resp.ok) {
      this.recentSearches = resp.data

      return true
    }
    return false
  }
}
