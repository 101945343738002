import React from 'react'

export default function AnnouncementLink({
  announcement,
}: {
  announcement: {
    callToActionCopy: string | null
    callToActionUrl: string | null
  }
}) {
  if (!announcement.callToActionCopy || !announcement.callToActionUrl) {
    return null
  }

  return (
    <a
      href={announcement.callToActionUrl}
      style={{
        color: 'var(--color-light-blue)',
        marginTop: 8,
        display: 'block',
      }}
    >
      {announcement.callToActionCopy}
    </a>
  )
}
