import React, { Component } from 'react'
import { Screen } from 'src/components/Screen'
import { BodyText } from 'src/components/Text'
import { PlainButton } from 'src/components/Button'
import PageMeta from 'src/components/PageMeta'
import { settingsDriversPath } from 'src/paths'
import {
  OverlayHeader,
  OverlayHeaderContent,
  OverlayHeaderLeftButton,
} from 'src/components/OverlayHeader'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import { navigate } from '@reach/router'
import styles from 'src/components/Settings.css'
import Box from 'src/components/Box'
import AddDriverForm from 'src/components/AddDriverForm'

/**
 * @typedef {object} Props
 *
 * @augments {Component<Props>}
 */
class SettingsAddDriver extends Component {
  get returnPath() {
    return settingsDriversPath()
  }

  render() {
    return (
      <Screen>
        <PageMeta title="Add a Driver" section="Drivers and Fleet" />

        <OverlayHeader>
          <OverlayHeaderLeftButton>
            <PlainButton onClick={() => navigate(this.returnPath)}>
              <LeftArrowIcon className={styles.FillCurrentColor} />
            </PlainButton>
          </OverlayHeaderLeftButton>
          <OverlayHeaderContent>
            <BodyText>Drivers</BodyText>
          </OverlayHeaderContent>
        </OverlayHeader>

        <Box p={3}>
          <AddDriverForm
            cancelPath={this.returnPath}
            nextPath={this.returnPath}
          />
        </Box>
      </Screen>
    )
  }
}

export default SettingsAddDriver
