import React from 'react'
import Logo from 'src/icons/HaullyLogoBlackLarge.svg'
import Flex from './Flex'
import { navLinks, SIGN_UP_URL } from 'src/data/navLinks'
import LinkBasic from 'src/components/LinkBasic'
import styles from './MarketingNavigationDesktop.css'
import { welcomePath, searchPath } from 'src/paths'
import { PlainButton, LinkButton, AnchorButton } from 'src/components/Button'
import { Link } from '@reach/router'
import cn from 'classnames'
import { useSessionStore } from 'src/hooks/useStores'

/**
 * @param {Object} props
 * @param {() => void} props.onRequestLogin
 */
const MarketingNavigationDesktop = ({ onRequestLogin }) => {
  const sessionStore = useSessionStore()
  const isLoggedIn = sessionStore.isLoggedIn

  return (
    <Flex
      bg="white"
      color="black"
      justifyContent="space-between"
      alignItems="center"
      border="bottom"
      className={styles.DesktopNavigation}
    >
      <Link to={welcomePath()} className={styles.LogoLink}>
        <Logo title="Haully" />
      </Link>

      <Flex alignItems="center">
        {navLinks.marketing.map((link, i) => (
          <LinkBasic
            key={i}
            to={link.to}
            tag={link.tag}
            target={link.target}
            className={styles.NavLink}
          >
            {link.label}
          </LinkBasic>
        ))}
        {isLoggedIn ? (
          <LinkButton to={searchPath()}>Dashboard</LinkButton>
        ) : (
          <>
            <PlainButton
              onClick={onRequestLogin}
              className={cn(styles.NavLink, styles.LoginButton)}
            >
              Log in
            </PlainButton>
            <AnchorButton href={SIGN_UP_URL}>Sign Up</AnchorButton>
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default MarketingNavigationDesktop
