import React from 'react'
import { Link } from '@reach/router'
import styles from './LinkedListItem.css'
import RightArrow from 'src/icons/RightArrow.svg'
import useWindowSize from 'src/hooks/useWindowSize'
import cn from 'classnames'

/** @param {*} props */
const LinkedListItem = ({ to, children, featured, props }) => {
  const { isLarge } = useWindowSize()

  return (
    <li className={styles.LinkedListItem} {...props}>
      <Link
        className={cn(styles.LinkedListItemLink, {
          [styles.featured]: featured,
        })}
        to={to}
      >
        <div>{children}</div>
        {isLarge && (
          <div>
            <RightArrow />
          </div>
        )}
      </Link>
    </li>
  )
}

export default LinkedListItem
