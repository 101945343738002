import React from 'react'
import styles from './Fixed.css'
import withColor from 'src/hocs/withColor'
import withSpacing from 'src/hocs/withSpacing'
import withBackground from 'src/hocs/withBackground'
import cn from 'classnames'

/** @param {React.HTMLAttributes} props */
const _FixedBottom = ({ className, ...props }) => (
  <div className={cn(className, styles.FixedBottom)} {...props} />
)
export const FixedBottom = withColor(withSpacing(withBackground(_FixedBottom)))

/** @param {{children: React.ReactNode}} props */
export const FixedFullWindow = ({ children }) => (
  <div className={styles.FixedFullWindow}>{children}</div>
)
