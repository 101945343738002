import { observable } from 'mobx'
import Store from './Store'

export default class DashboardStore extends Store {
  /** @type {Haully.NotificationSettings|undefined} */
  @observable emailNotificationSettings

  /** @type {Haully.NotificationSettings|undefined} */
  @observable textNotificationSettings

  loadEmailNotificationsSettings = async () => {
    const resp = await this.rootStore.apiRequest(
      `carrierNotificationSettings/EmailNotifications`
    )

    if (resp.ok) {
      this.emailNotificationSettings = resp.data
      return true
    }
    return false
  }

  loadTextNotificationsSettings = async () => {
    const resp = await this.rootStore.apiRequest(
      `carrierNotificationSettings/TextNotifications`
    )

    if (resp.ok) {
      this.textNotificationSettings = resp.data
      return true
    }
    return false
  }

  toggleEmailNotificationSetting = key => {
    if (this.emailNotificationSettings)
      this.emailNotificationSettings[key] = !this.emailNotificationSettings[key]

    this.updateEmailNotificationsSettings()
  }

  updateEmailNotificationsSettings = async () => {
    const resp = await this.rootStore.apiRequest(
      `carrierNotificationSettings/EmailNotifications`,
      { method: 'POST' },
      this.emailNotificationSettings
    )

    return resp.ok
  }

  toggleTextNotificationSetting = key => {
    if (this.textNotificationSettings)
      this.textNotificationSettings[key] = !this.textNotificationSettings[key]

    this.updateTextNotificationsSettings()
  }

  updateTextNotificationsSettings = async () => {
    const resp = await this.rootStore.apiRequest(
      `carrierNotificationSettings/TextNotifications`,
      { method: 'POST' },
      this.textNotificationSettings
    )

    return resp.ok
  }
}
