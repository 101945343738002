import { Match, Router } from '@reach/router'
import React from 'react'
import Flex from 'src/components/Flex'
import PageMeta from 'src/components/PageMeta'
import Sidebar from 'src/components/Sidebar'
import Wrapper from 'src/components/Wrapper'
import LoadSearch from 'src/containers/LoadSearch'
import * as paths from '../paths'
import LoadDetail from './LoadDetail'

// The search page remains mounted to avoid refetching the search results when the user returns to the search page.
// Possible improvements would be to only lift the search state up, or use a more advanced caching strategy.

const Search = () => {
  return (
    <Match path={paths.searchLoadPath(':loadId')}>
      {({ match }) => {
        return (
          <div style={{ display: match ? 'none' : '' }}>
            <Wrapper bg="light-gray" sidebar={<Sidebar />}>
              <PageMeta title="Find Loads" />
              <Flex flexDirection="column" flex={1} lmt={4}>
                <LoadSearch title="Find New Vehicles to Move!" />
              </Flex>
            </Wrapper>
          </div>
        )
      }}
    </Match>
  )
}

const FindLoads = () => {
  return (
    <>
      <Search path="/" />
      <Router>
        {/*
          // @ts-expect-error */}
        <LoadDetail path={paths.loadPath(':loadId')} />
      </Router>
    </>
  )
}

export default FindLoads
