import L, { Map as LeafletMap } from 'leaflet'
import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { QueryStatus, useQuery } from 'react-query'
import Flex from 'src/components/Flex'
import { Spinner } from 'src/components/Loading'
import { ATTRIBUTION, CENTER, ZOOM } from 'src/components/RouteMap'
import { LinkedLoadCardContainer } from 'src/containers/LoadCardContainer'
import { useApiRequest } from 'src/hooks/useStores'
import 'src/maps/leaflet.global.css'
import { env } from 'src/util/env'
import style from './LoadsMap.css'

const iconCreateFunction = cluster => {
  const count = cluster.getChildCount()
  return new L.DivIcon({
    html: cluster.getChildCount(),
    className: 'marker-cluster',
    iconSize: count < 10 ? new L.Point(30, 30) : new L.Point(50, 50),
  })
}

const LoadsPopup = ({ loadIds }: { loadIds: Array<Haully.LoadId> }) => {
  const request = useApiRequest()
  const loadResult = useQuery(
    ['loads', { loadIds }],
    () => {
      return request(
        'CarrierLoads/search/available',
        {
          isErrorGlobal: false,
          method: 'POST',
        },
        {
          Criteria: {
            OrderIds: loadIds,
            PageSize: 300,
          },
        }
      )
    },
    { refetchOnWindowFocus: false, enabled: loadIds.length > 0 }
  )

  let loadList: Array<Haully.Load> = loadResult.data?.data?.records || []

  return (
    <>
      {loadResult.status === QueryStatus.Loading ? (
        <div style={{ height: '50000px', paddingTop: '80px' }}>
          <Flex justifyContent="center" alignItems="center" p={4}>
            <Spinner />
          </Flex>
        </div>
      ) : loadResult.status === QueryStatus.Error ? (
        <div>Error</div>
      ) : loadResult.status === QueryStatus.Success ? (
        loadList.map((load, i) => (
          <Flex
            key={load.id}
            border={i === loadList.length - 1 ? undefined : 'bottom'}
          >
            <LinkedLoadCardContainer load={load} />
          </Flex>
        ))
      ) : null}
    </>
  )
}

type MapLoad = {
  id: number
  originLat: number
  originLon: number
  orderType: string
}

const LoadsMap = ({ loads }: { loads: Array<MapLoad> }) => {
  const [map, setMap] = useState<LeafletMap | undefined>(undefined)
  const [popupPosition, setPopupPosition] = useState(undefined)
  const [clusterLoadIds, setClusterLoadIds] = useState<number[]>([])
  const markers = loads.map(load => L.latLng(load.originLat, load.originLon))

  useEffect(() => {
    if (!map) return
    const bounds = L.latLngBounds(markers)
    if (bounds.isValid()) map.fitBounds(bounds, { padding: [30, 30] })
  }, [loads, map, markers])

  const onMarkerClick = e => {
    const marker = e.target
    const loadId = marker.options.data
    setClusterLoadIds([loadId])
    setPopupPosition(e.latlng)
  }

  const onClusterClick = cluster => {
    const loadIds = cluster.layer
      .getAllChildMarkers()
      .map(marker => marker.options.data)
    setClusterLoadIds(loadIds)
    setPopupPosition(cluster.latlng)
  }

  return (
    <>
      <MapContainer
        center={CENTER}
        zoom={ZOOM}
        scrollWheelZoom={false}
        whenCreated={map => setMap(map)}
        className={style.LoadsMap}
      >
        <TileLayer attribution={ATTRIBUTION} url={env('MAP_TILE_API') || ''} />
        <MarkerClusterGroup
          singleMarkerMode={true}
          zoomToBoundsOnClick={false}
          maxClusterRadius={45}
          spiderfyOnMaxZoom={false}
          showCoverageOnHover={false}
          iconCreateFunction={iconCreateFunction}
          onClick={onClusterClick}
        >
          {loads.map(load => {
            return (
              <Marker
                key={load.id}
                position={[load.originLat, load.originLon]}
                // @ts-ignore data works with Marker
                data={load.id}
                eventHandlers={{
                  click: onMarkerClick,
                }}
              />
            )
          })}

          {typeof popupPosition !== 'undefined' && (
            <div className="leaflet-popup-loads">
              <Popup minWidth={340} maxHeight={300} position={popupPosition}>
                <LoadsPopup loadIds={clusterLoadIds} />
              </Popup>
            </div>
          )}
        </MarkerClusterGroup>
      </MapContainer>
    </>
  )
}

export default LoadsMap
