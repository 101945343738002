import React, { Component } from 'react'
import Box from 'src/components/Box'
import Flex from 'src/components/Flex'
import { DismissButton } from 'src/components/Button'
import { observer, inject } from 'mobx-react'
import FlashMessageStore from 'src/stores/FlashMessageStore'

/**
 * @typedef {object} Props
 * @property {FlashMessageStore} [props.flashMessageStore]
 * @property {string} currentUrl
 *
 * @augments Component<Props>
 */
@inject('flashMessageStore')
@observer
class FlashMessages extends Component {
  componentDidMount() {
    this.flashMessageStore.next()
  }

  /** @param {Props} prevProps  */
  componentDidUpdate(prevProps) {
    if (prevProps.currentUrl !== this.props.currentUrl) {
      this.flashMessageStore.next()
    }
  }

  get flashMessageStore() {
    return /** @type {FlashMessageStore} */ (this.props.flashMessageStore)
  }

  render() {
    const { messages, dismiss } = this.flashMessageStore

    if (messages.length === 0) return null

    return (
      <Box bg="white" border="bottom" data-test="FlashMessages">
        {messages.map((message, i) => (
          <Flex key={i} justifyContent="space-between" alignItems="center">
            <Box p={3}>{message}</Box>
            <Box pr={2}>
              <DismissButton onClick={() => dismiss(i)} />
            </Box>
          </Flex>
        ))}
      </Box>
    )
  }
}

export default FlashMessages
