import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import CenteredContainer from 'src/components/CenteredContainer'
import LinkedList from 'src/components/LinkedList'
import LinkedListItem from 'src/components/LinkedListItem'
import MyAccountTabNav from 'src/components/MyAccountTabNav'
import PageMeta from 'src/components/PageMeta'
import { SearchCardRow } from 'src/components/SearchCard'
import { Title } from 'src/components/Text'
import Wrapper from 'src/components/Wrapper'
import * as paths from 'src/paths'
import {
  HorizontalScrollContainer,
  HorizontalScrollCard,
} from 'src/components/ScrollContainer'
import { ResponsiveParent } from 'src/components/Responsive'
import appVersion from 'src/util/appVersion'

const SETTINGS_LINKS = [
  {
    to: `${paths.editProfilePath()}?backTo=${paths.accountSettingsPath()}`,
    label: 'Edit Profile',
  },
  /*
  { to: paths.settingsTextNotificationsPath(), label: 'Text Notifications' },
  { to: paths.settingsEmailNotificationsPath(), label: 'Email Notifications' },
  */
]

/**
 * @typedef {object} Props
 * @property {import('src/stores/SearchStore').default} searchStore
 * @property {import('src/stores/FlashMessageStore').default} flashMessageStore
 *
 * @augments {Component<Props>}
 */
@inject('searchStore', 'flashMessageStore')
@observer
class AccountSettings extends Component {
  componentDidMount() {
    this.props.searchStore.fetchSavedSearches()
    this.props.searchStore.fetchRecentSearches()
  }

  removeSavedSearch = async searchId => {
    this.props.searchStore.savedSearches = this.props.searchStore.savedSearches.filter(
      search => search.id != searchId
    )

    if (await this.props.searchStore.removeSavedSearch(searchId)) {
      this.props.flashMessageStore.next()
      this.props.flashMessageStore.add(`Removed Search ${searchId}`)
    }
  }

  saveRecentSearch = async search => {
    await this.props.searchStore.saveSearch(search.carrierSearchDto)
    await this.props.searchStore.fetchSavedSearches()

    this.props.flashMessageStore.next()
    this.props.flashMessageStore.add('Saved Recent Search')
  }

  render() {
    const { savedSearches, recentSearches } = this.props.searchStore
    const isSavedSearchesEmpty = savedSearches && savedSearches.totalCount === 0
    const isRecentSearchesEmpty =
      recentSearches && recentSearches.totalCount === 0

    return (
      <Wrapper bg="light-gray">
        <PageMeta title="My Account" section="Account" />

        <MyAccountTabNav />

        <CenteredContainer width="900">
          <LinkedList border="top">
            {SETTINGS_LINKS.map(({ to, label }, i) => (
              <LinkedListItem key={i} to={to}>
                {label}
              </LinkedListItem>
            ))}
          </LinkedList>
          <Box p={3} pt={2} lpl={0} lpr={0}>
            {savedSearches && !isSavedSearchesEmpty && (
              <Box mb={3}>
                <Title mb={2}>Saved Searches</Title>
                <ResponsiveParent
                  large={<HorizontalScrollContainer isFlush={true} />}
                  base={<div />}
                >
                  {savedSearches.map((savedSearch, i) => (
                    <ResponsiveParent
                      key={savedSearch.id}
                      large={
                        <HorizontalScrollCard
                          width="load"
                          collection={savedSearches}
                          position={i}
                        />
                      }
                      base={<div />}
                    >
                      <SearchCardRow
                        search={savedSearch}
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you wish to delete this search?'
                            )
                          )
                            this.removeSavedSearch(savedSearch.id)
                        }}
                        action="delete"
                      />
                    </ResponsiveParent>
                  ))}
                </ResponsiveParent>
              </Box>
            )}

            {recentSearches && !isRecentSearchesEmpty && (
              <Box mb={3}>
                <Title mb={2}>Recent Searches</Title>
                <ResponsiveParent
                  large={<HorizontalScrollContainer isFlush={true} />}
                  base={<div />}
                >
                  {recentSearches.map((recentSearch, i) => (
                    <ResponsiveParent
                      key={recentSearch.id}
                      large={
                        <HorizontalScrollCard
                          width="load"
                          collection={recentSearches}
                          position={i}
                        />
                      }
                      base={<div />}
                    >
                      <SearchCardRow
                        search={recentSearch}
                        onClick={() => this.saveRecentSearch(recentSearch)}
                        action="save"
                      />
                    </ResponsiveParent>
                  ))}
                </ResponsiveParent>
              </Box>
            )}
          </Box>
          <Box mb={3}>Haully Version {appVersion() || 'UNKNOWN'}</Box>
        </CenteredContainer>
      </Wrapper>
    )
  }
}

export default AccountSettings
