import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { parse } from 'qs'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { LinkButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import HeadingContainer from 'src/components/HeadingContainer'
import LoadingScreen from 'src/components/Loading'
import MobileButtonContainer from 'src/components/MobileButtonContainer'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import {
  BodyText,
  Heading,
  Subheading,
  TextContainer,
} from 'src/components/Text'
import { LinkedLoadCardContainer } from 'src/containers/LoadCardContainer'
import { DISPATCH_PHONE_NUMBER } from 'src/data/company'
import { onboardEndPath } from 'src/paths'
import RegistrationStore from 'src/stores/RegistrationStore'

@inject('registrationStore', 'favoriteStore')
@observer
class OnboardLoadsPref extends Component {
  static propTypes = {
    registrationStore: PropTypes.any.isRequired,
    location: PropTypes.any,
  }

  componentDidMount() {
    this.registrationStore.loadSuggestedLoads()
  }

  /** @returns {RegistrationStore} */
  get registrationStore() {
    return this.props.registrationStore
  }

  render() {
    const suggestedLoads =
      this.registrationStore.suggestedLoads &&
      this.registrationStore.suggestedLoads.slice(0, 6)

    const params = parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })

    const isLoadsEmpty =
      (suggestedLoads && suggestedLoads.length === 0) || !!params.forceEmpty

    return (
      <OnboardingContainer>
        <PageMeta title="Load Preferences" />
        <HeadingContainer>
          <Subheading>
            To keep things moving, we let you save loads by clicking on the
            star. You can claim your saved loads when you’re ready to commit.
          </Subheading>
          <Heading>Let us know which loads you have your eye on.</Heading>
        </HeadingContainer>
        {suggestedLoads ? (
          <>
            {isLoadsEmpty ? (
              <TextContainer mb={4}>
                <BodyText>
                  We’re sorry, but no loads match your exact preferences at this
                  time. Try expanding your preferences or reach out to dispatch
                  and we’ll get you moving.{' '}
                  <a href={`tel:${DISPATCH_PHONE_NUMBER}`}>
                    {DISPATCH_PHONE_NUMBER}
                  </a>
                </BodyText>
              </TextContainer>
            ) : (
              suggestedLoads.map(load => (
                <Box key={load.id} mb={3}>
                  <LinkedLoadCardContainer key={load.id} load={load} />
                </Box>
              ))
            )}
            <Flex mt={3} mb={4} justifyContent="center">
              <MobileButtonContainer>
                <LinkButton
                  data-test="OnboardLoadsPref-Next"
                  to={onboardEndPath()}
                  block={true}
                >
                  Next
                </LinkButton>
              </MobileButtonContainer>
            </Flex>
          </>
        ) : (
          <LoadingScreen />
        )}
      </OnboardingContainer>
    )
  }
}

export default OnboardLoadsPref
