import { observable, IObservableArray } from 'mobx'
import Store from './Store'
import lscache from 'lscache'

export default class FavoriteStore extends Store {
  /** @type {IObservableArray<number>} */
  favoriteLoadsLoading = observable.array([])

  /**
   * @param {Haully.Load} load
   */
  createFavorite = async load => {
    lscache.flush()

    const loadId = load.id
    if (load.currentUserFavorite) throw new Error('already favorited')

    if (this.favoriteLoadsLoading.includes(loadId))
      throw new Error('already saving favorite')

    this.favoriteLoadsLoading.push(loadId)

    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/${loadId}/favorite`,
      { method: 'POST' }
    )

    this.favoriteLoadsLoading.remove(loadId)

    if (resp.ok) {
      load.currentUserFavorite = true
    }
  }

  /** @param {Haully.Load} load */
  deleteFavorite = async load => {
    lscache.flush()

    const loadId = load.id
    this.favoriteLoadsLoading.push(loadId)

    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/${loadId}/favorite`,
      { method: 'DELETE' }
    )

    this.favoriteLoadsLoading.remove(loadId)

    if (resp.ok) {
      load.currentUserFavorite = false
    }
  }

  /** @param {Haully.Load} load */
  toggleFavorite = load => {
    return load.currentUserFavorite
      ? this.deleteFavorite(load)
      : this.createFavorite(load)
  }

  /**
   * @param {Number} loadId
   * @returns {boolean}
   */
  isFavoriteLoading = loadId => this.favoriteLoadsLoading.includes(loadId)
}
