import { parse, format } from 'date-fns'
import React from 'react'
import { Input } from './components/TextInput'
import useWindowSize from './hooks/useWindowSize'
import DatePicker from 'react-datepicker'
import './components/DatePicker.css'
import styles from 'src/components/InputTime.css'

/**
 * @typedef {object} InputTimeProps
 * @property {string} value
 * @property {(value: string) => void} onChangeText
 * @property {string} [id]
 * @property {boolean} [required]
 */

/**
 * @param {InputTimeProps} props
 */
const InputTime = props => {
  const { isLarge } = useWindowSize()

  if (isTimeInputSupported() && !isLarge) {
    return <Input type="time" {...props} />
  }
  return <InputTimeDesktop {...props} />
}

/**
 * @param {InputTimeProps} props
 */
const InputTimeDesktop = props => {
  const { onChangeText } = props
  const hourDefault = '12'
  const minuteDefault = '00'

  const time = parse(
    `2000-01-01 ${props.value || `${hourDefault}:${minuteDefault}`}`
  )

  return (
    <DatePicker
      selected={time}
      onChange={value => onChangeText(format(value, 'HH:mm'))}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat="h:mm aa"
      timeCaption="Time"
      className={styles.InputTime}
    />
  )
}

export default InputTime

function isTimeInputSupported() {
  const input = document.createElement('input')
  input.setAttribute('type', 'time')

  if (input.type !== 'time') return false

  const invalidValue = 'junk'
  input.setAttribute('value', invalidValue)
  return input.value !== invalidValue
}
