import React from 'react'
import { TabNav, Tab } from './TabNav'
import {
  loadsSavedPath,
  loadsClaimedPath,
  loadsInProgressPath,
  loadsCompletedPath,
} from 'src/paths'

const TabNavManageLoads = () => (
  <TabNav>
    <Tab to={loadsSavedPath()}>Saved</Tab>
    <Tab to={loadsClaimedPath()}>Claimed</Tab>
    <Tab to={loadsInProgressPath()}>In Progress</Tab>
    <Tab to={loadsCompletedPath()}>Completed</Tab>
  </TabNav>
)

export default TabNavManageLoads
