import { computed, observable } from 'mobx'
import { CARRIER_TRUCK_TYPES } from 'src/models/CarrierTruck'
import TruckForm from 'src/models/TruckForm'
import Store from './Store'

export default class TruckStore extends Store {
  /** @type {import('mobx').IObservableArray<Haully.CarrierTruck>} */
  carrierTrucks = observable.array([])

  /** @type {import('mobx').ObservableMap<Haully.CarrierTruckId, Haully.CarrierTruck>} */
  trucks = observable.map([])

  @observable isTrucksLoading = false

  @observable
  truckForm = new TruckForm()

  /** @type {Haully.CarrierVehicleCount|undefined} */
  @observable carrierVehicleCount

  fetchCarrierTrucks = async () => {
    this.isTrucksLoading = true
    const resp = await this.rootStore.apiRequest(
      `CarrierTrucks?clientId=${this.clientId}`
    )
    this.isTrucksLoading = false

    if (resp.ok) {
      this.carrierTrucks.replace(resp.data)
      this.carrierTrucks.map(truck =>
        this.trucks.set(truck.carrierTruckId, truck)
      )

      return true
    }
    return false
  }

  submitTruckForm = async () => {
    const payload = {
      clientId: this.rootStore.sessionStore.clientId,
      trucks: this.truckForm.values.trucks.map(truck => ({
        quantity: truck.quantity,
        carrierEquipmentType: truck.carrierEquipmentType,
        isFlatBed: CARRIER_TRUCK_TYPES[truck.carrierTruckIdx].isFlatBed,
        capacity: CARRIER_TRUCK_TYPES[truck.carrierTruckIdx].capacity,
      })),
    }

    const resp = await this.rootStore.apiRequest(
      'CarrierTrucks',
      { method: 'POST', isErrorGlobal: false },
      payload
    )

    if (!resp.ok && resp.data && resp.data.message) {
      this.truckForm.form.updateValidationErrors([
        { field: 'root', message: resp.data.message },
      ])
    }

    return resp.ok
  }

  /** @param {Haully.CarrierTruck} carrierTruck */
  submitEditTruckForm = async carrierTruck => {
    const payload = {
      clientId: this.rootStore.sessionStore.clientId,
      trucks: this.truckForm.values.trucks.map(truck => ({
        carrierTruckId: carrierTruck.carrierTruckId,
        quantity: parseInt(truck.quantity),
      })),
    }

    const resp = await this.rootStore.apiRequest(
      'CarrierTrucks',
      { method: 'PUT' },
      payload
    )

    return resp.ok
  }

  /** @param {Haully.CarrierTruckId} carrierTruckId */
  deleteTruck = async carrierTruckId => {
    const resp = await this.rootStore.apiRequest(
      `CarrierTrucks`,
      { method: 'DELETE' },
      {
        clientId: this.rootStore.sessionStore.clientId,
        carrierTruckIds: [carrierTruckId],
      }
    )

    this.trucks.delete(carrierTruckId)

    return resp.ok
  }

  getCarrierVehicleCount = async () => {
    const resp = await this.rootStore.apiRequest(
      'CarrierTrucks/CarrierVehicleCount'
    )

    if (resp.ok) {
      this.carrierVehicleCount = resp.data
      return this.carrierVehicleCount
    }
    return false
  }

  @computed
  get clientId() {
    return this.rootStore.sessionStore.clientId
  }
}
