import RootStore from './RootStore'
import { computed } from 'mobx'

export default class Store {
  /** @param {RootStore} rootStore */
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  get clientId() {
    return this.rootStore.sessionStore.clientId
  }

  @computed
  get serialize() {
    return {}
  }

  deserialize = _data => {}
}
