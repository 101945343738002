import { env } from './env'

export default function appVersion() {
  const envVersion =
    process.env.GIT_COMMIT || process.env.SOURCE_VERSION || process.env.VERSION
  if (envVersion) return envVersion

  let sha = env('HEROKU_SLUG_COMMIT')
  if (sha) return sha.slice(0, 6)
  return
}
