// based on:
// https://github.com/mike-engel/floating-label-react
import React, { Component, useState } from 'react'
import styles from './TextInput.css'
import cn from 'classnames'

/**
 * @typedef {Object} TextInputProps
 * @property {string} value
 * @property {string} placeholder
 * @property {(text: string) => void|undefined} [onChangeText]
 */
const TextInput = React.forwardRef(
  /**
   * @param {TextInputProps & React.InputHTMLAttributes<HTMLInputElement>} props
   *
   */
  (props, ref) => {
    const [focused, setFocused] = useState(false)

    const handleFocusChange = () => {
      setFocused(!focused)
    }

    const inputClass = cn({ [styles.focusStyles]: focused })

    const { placeholder, id, value, ...rest } = props

    const pattern = rest.type == 'tel' ? '^[0-9-+\\s()]*$' : undefined

    if (typeof value != 'string')
      throw new Error(`invariant: value must be a string, got ${typeof value}`)

    return (
      <Label
        placeholder={placeholder}
        htmlFor={id}
        floating={value.length > 0 || focused}
      >
        <Input
          id={id}
          onBlur={handleFocusChange}
          onFocus={handleFocusChange}
          onChangeText={props.onChangeText}
          onChange={props.onChange}
          className={inputClass}
          value={value}
          ref={ref}
          pattern={pattern}
          {...rest}
        />
      </Label>
    )
  }
)
export default TextInput

/**
 * Put the label text in the placeholder prop!
 *
 * @param {{ floating?: boolean, placeholder: string } & React.HTMLProps} props
 * @example
 *  <Label placeholder="Phone number"><input type="tel" /></Label>
 */
export const Label = ({ placeholder, children, floating = true, ...props }) => {
  const labelClass = styles.labelStyles
  const spanClass = cn(styles.spanStyles, {
    [styles.floatingStyles]: floating,
  })

  return (
    <label className={labelClass} {...props}>
      <span className={spanClass}>{placeholder}</span>
      {children}
    </label>
  )
}

// eslint-disable-next-line react/display-name
export const Input = React.forwardRef(
  /**
   * @param {{ onChangeText?: (text: string) => void} & React.InputHTMLAttributes<HTMLInputElement>} param
   */
  ({ className, onChangeText, onChange, ...rest }, ref) => (
    <input
      className={cn(styles.inputStyles, className)}
      onChange={e => {
        if (onChangeText) {
          onChangeText(e.currentTarget.value)
        }
        if (onChange) {
          onChange(e)
        }
      }}
      ref={ref}
      {...rest}
    />
  )
)

/**
 * @typedef Props
 * @property {string} value
 * @property {(text: string) => void} onChangeText
 *
 * @augments Component<Props & React.InputHTMLAttributes<HTMLInputElement>>
 */
export class InputDebounced extends Component {
  state = {
    value: this.props.value,
  }
  timer = null

  /** @param {string} text */
  handleChange = text => {
    this.setState({ value: text })

    if (this.timer) clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      this.props.onChangeText(text)
      this.timer = null
    }, 300)
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer)
  }

  render() {
    return (
      <Input
        {...this.props}
        value={this.state.value}
        onChangeText={this.handleChange}
      />
    )
  }
}

export const InputText = props => (
  <div className={styles.InputText} {...props} />
)
