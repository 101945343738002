import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Screen } from 'src/components/Screen'
import { BodyText } from 'src/components/Text'
import { PlainButton } from 'src/components/Button'
import PageMeta from 'src/components/PageMeta'
import { settingsDriversPath } from 'src/paths'
import {
  OverlayHeader,
  OverlayHeaderContent,
  OverlayHeaderLeftButton,
  OverlayHeaderRightButton,
} from 'src/components/OverlayHeader'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import { navigate } from '@reach/router'
import styles from 'src/components/Settings.css'
import Box from 'src/components/Box'
import AddDriverForm from 'src/components/AddDriverForm'
import DriverStore from 'src/stores/DriverStore'
import { LoadingHeading } from 'src/components/Loading'
import { ButtonUnderlinedH2 } from 'src/components/ButtonUnderlined'

/**
 * @typedef {object} Props
 *
 * @property {number} driverId
 * @property {import('src/stores/DriverStore').default} driverStore
 * @property {import('src/stores/FlashMessageStore').default} flashMessageStore
 *
 * @augments {Component<Props>}
 */
@inject('driverStore', 'flashMessageStore')
@observer
class SettingsEditDriver extends Component {
  state = {
    loading: true,
  }

  async componentDidMount() {
    await this.driverStore.fetchDriver(this.props.driverId)
    this.setState({ loading: false })
  }

  get returnPath() {
    return settingsDriversPath()
  }

  get driverStore() {
    return /** @type {DriverStore} */ (this.props.driverStore)
  }

  toggleDriverActivated = async driver => {
    if (driver.enabled) {
      if (await this.driverStore.deactivateDriver(this.props.driverId)) {
        this.props.flashMessageStore.queue(
          `Deactivated Driver ${this.props.driverId}`
        )
      }
      // driver.enabled = false
    } else {
      if (await this.driverStore.activateDriver(this.props.driverId)) {
        this.props.flashMessageStore.queue(
          `Activated Driver ${this.props.driverId}`
        )
      }
    }

    navigate(this.returnPath)
  }

  render() {
    const driver = this.driverStore.drivers.get(this.props.driverId)
    const { loading } = this.state

    if (loading || !driver) return <LoadingHeading />
    return (
      <Screen>
        <PageMeta title="Edit a Driver" section="Drivers and Fleet" />

        <OverlayHeader>
          <OverlayHeaderLeftButton>
            <PlainButton onClick={() => navigate(this.returnPath)}>
              <LeftArrowIcon className={styles.FillCurrentColor} />
            </PlainButton>
          </OverlayHeaderLeftButton>
          <OverlayHeaderContent>
            <BodyText>Drivers</BodyText>
          </OverlayHeaderContent>
          <OverlayHeaderRightButton>
            <ButtonUnderlinedH2
              className={styles.OverlayHeaderLinkButton}
              onClick={() => this.toggleDriverActivated(driver)}
            >
              {driver.enabled ? 'Deactivate' : 'Activate'}
            </ButtonUnderlinedH2>
          </OverlayHeaderRightButton>
        </OverlayHeader>

        <Box p={3}>
          <AddDriverForm
            cancelPath={this.returnPath}
            nextPath={this.returnPath}
            driver={driver}
          />
        </Box>
      </Screen>
    )
  }
}

export default SettingsEditDriver
