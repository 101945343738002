import React from 'react'
import useWindowSize from 'src/hooks/useWindowSize'

/**
 * @param {object} props
 * @param {React.ReactNode} [props.medium]
 * @param {React.ReactNode} [props.large]
 * @param {React.ReactNode} [props.children]
 */
const Responsive = props => {
  const { isLarge, isMedium } = useWindowSize()

  if (isLarge && props.large !== undefined) return <>{props.large}</>
  if (isMedium && props.medium !== undefined) return <>{props.medium}</>
  return <>{props.children}</>
}

export default Responsive

/**
 * @example
 *   <ResponsiveParent
 *     base={<div />}
 *     large={<Flex p={3} />}
 *   >
 *     <div>content shows up in a div on small screens, flex on big screens</div>
 *   </ResponsiveParent>
 *
 * @param {object} props
 * @param {React.ReactNode} props.base
 * @param {React.ReactNode} [props.medium]
 * @param {React.ReactNode} [props.large]
 * @param {React.ReactNode} props.children
 */
export const ResponsiveParent = props => {
  const { isLarge, isMedium } = useWindowSize()

  if (isLarge && props.large !== undefined && React.isValidElement(props.large))
    return React.cloneElement(props.large, {}, props.children)

  if (
    isMedium &&
    props.medium !== undefined &&
    React.isValidElement(props.medium)
  )
    return React.cloneElement(props.medium, {}, props.children)

  if (React.isValidElement(props.base))
    return React.cloneElement(props.base, {}, props.children)

  throw new Error('invariant: expected a valid element')
}
