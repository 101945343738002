import { useState, useEffect } from 'react'
import { breakpointLarge, breakpointMedium } from 'src/theme'

// based on https://github.com/rehooks/window-size

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    isLarge: window.innerWidth >= breakpointLarge,
    isMedium: window.innerWidth >= breakpointMedium,
  }
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}
