import React from 'react'
import withSpacing from 'src/hocs/withSpacing'
import withBackground from 'src/hocs/withBackground'
import withColor from 'src/hocs/withColor'
import withBorder from 'src/hocs/withBorder'

/** @param {React.HTMLAttributes<HTMLDivElement>} props */
const Box = props => <div data-box {...props} />

export default withColor(withSpacing(withBackground(withBorder(Box))))
