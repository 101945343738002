import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading } from 'src/components/Text'
import PageMeta from 'src/components/PageMeta'
import {
  onboardAddDriverConfirmPath,
  onboardHaulEquipmentPath,
} from 'src/paths'
import OnboardingContainer from 'src/components/OnboardingContainer'
import OnboardingCounter from 'src/components/OnboardingCounter'
import AddDriverForm from 'src/components/AddDriverForm'
import Box from 'src/components/Box'
import { OnboardFormMaxWidth } from 'src/components/MaxWidth'

/**
 * @typedef {object} Props
 * @property {import('src/stores/DriverStore').default} driverStore
 *
 * @augments {Component<Props>}
 */
@inject('driverStore')
@observer
class OnboardAddDriver extends Component {
  get cancelPath() {
    return this.props.driverStore.driversAddedCount === 0
      ? onboardHaulEquipmentPath()
      : onboardAddDriverConfirmPath()
  }

  get nextPath() {
    return onboardAddDriverConfirmPath()
  }

  render() {
    return (
      <OnboardingContainer>
        <PageMeta title="Add Driver" />
        <OnboardingCounter position={4} backTo={onboardHaulEquipmentPath()} />
        <HeadingContainer>
          <Heading>Add a Driver</Heading>
        </HeadingContainer>

        <Box mb={4}>
          <OnboardFormMaxWidth>
            <AddDriverForm
              cancelPath={this.cancelPath}
              nextPath={this.nextPath}
            />
          </OnboardFormMaxWidth>
        </Box>
      </OnboardingContainer>
    )
  }
}

export default OnboardAddDriver
