import React from 'react'
import styles from './Text.css'
import textStyles from '../text.css'
import cn from 'classnames'
import withSpacing from 'src/hocs/withSpacing'
import withColor from 'src/hocs/withColor'

const basicComponent = (displayName, componentClassName) => {
  if (!componentClassName) throw new Error(`no class: ${displayName}`)
  /** @param {React.HTMLAttributes} props  */
  const Comp = ({ className, ...props }) => (
    <div className={cn(className, componentClassName)} {...props} />
  )
  Comp.displayName = displayName
  return withSpacing(withColor(Comp))
}

export const BodyText = basicComponent('BodyText', styles.BodyText)
export const Heading = basicComponent('Heading', styles.Heading)
export const Subheading = basicComponent('Subheading', styles.Subheading)
export const NoWrap = basicComponent('NoWrap', styles.NoWrap)
export const Small = basicComponent('Small', styles.Small)

export const TextContainer = basicComponent(
  'TextContainer',
  styles.TextContainer
)

export const H1 = basicComponent('H1', textStyles['Styleguide-H1'])
export const H2 = basicComponent('H2', textStyles['Styleguide-H2'])
export const H2Bold = basicComponent('H2', styles.H2Bold)
export const Title = basicComponent('Title', textStyles['Styleguide-Title'])
export const Body1 = basicComponent('Body1', textStyles['Styleguide-Body-1'])
export const Body2 = basicComponent('Body2', textStyles['Styleguide-Body-2'])
export const Body3 = basicComponent('Body3', textStyles['Styleguide-Body-3'])
export const MediumBodyBold = basicComponent(
  'MediumBodyBold',
  textStyles['Styleguide-MediumBodyBold']
)

export const ButtonCTA = basicComponent(
  'ButtonCTA',
  textStyles['Styleguide-Button-CTA']
)

export const Numerals = basicComponent(
  'Numerals',
  textStyles['Styleguide-Numerals']
)
