/** @example "5/22" */
export const MONTH_DAY = 'M/D'

/** @example "5/22/2019" */
export const MONTH_DAY_YEAR = 'M/D/YYYY'

/** @example "5/22/19" */
export const MONTH_DAY_YEAR_SHORT = 'M/D/YY'

/** @example "Th 5/22 9:30AM" */
export const WEEKDAY_DAY_MONTH_TIME = `dd ${MONTH_DAY} h:mmA`

export const INPUT_DATE = 'YYYY-MM-DD'

export const INPUT_TIME = 'HH:mm'

export const MONTH_SHORT = 'MMM'
