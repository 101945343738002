import React from 'react'
import styles from './OnboardingContainer.css'
import HaullyLogo from 'src/icons/HaullyLogoBlack.svg'
import { welcomePath } from 'src/paths'
import { Link } from '@reach/router'

/** @param {*} props */
const OnboardingContainer = props => (
  <div className={styles.OnboardingContainer}>
    <div className={styles.OnboardingContainerNav}>
      <Link to={welcomePath()}>
        <HaullyLogo className={styles.OnboardingContainerNavIcon} />
      </Link>
    </div>
    <div className={styles.OnboardingContainerBodyMargin}>
      <div className={styles.OnboardingContainerBody} {...props} />
    </div>
  </div>
)

export default OnboardingContainer
