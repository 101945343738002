import React from 'react'
import Flex from 'src/components/Flex'
import Box from './Box'
import { BoxButton } from 'src/components/Button'
import withBackground from 'src/hocs/withBackground'
import { Body1, Body2 } from 'src/components/Text'
import styles from 'src/components/DocumentCard.css'
import underlinedStyles from 'src/components/ButtonUnderlined.css'

/**
 * @typedef {Object} DCProps
 * @property {Haully.CarrierDocument} document
 *
 * @param {DCProps} props
 */
export const DocumentCard = ({ document }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Flex>
      <div>
        <Body1 data-test="TruckCardLabel">{document.title}</Body1>
        <Body2 color="dark-gray">{document.dateAdded}</Body2>
      </div>
    </Flex>
    <div className={underlinedStyles.ButtonUnderlinedH2}>Download</div>
  </Flex>
)

/**
 * @typedef {Object} DCBProps
 * @property {(e: React.MouseEvent) => void} props.onClick
 * @property {string|undefined} [props.className]
 *
 * @param {DCBProps & DCProps} props
 */
const _DocumentCardButton = ({ onClick, ...rest }) => (
  <BoxButton
    onClick={onClick}
    testID="DocumentCard"
    className={styles.DocumentCardButton}
  >
    <Box pt={1} pb={1} pl={3} pr={3}>
      <DocumentCard {...rest} />
    </Box>
  </BoxButton>
)

export const DocumentCardButton = withBackground(_DocumentCardButton)
