import { Observer } from 'mobx-react'
import React, { useEffect } from 'react'
import AlertInline from 'src/components/AlertInline'
import CollapsibleContainer from 'src/components/CollapsibleContainer'
import LoadCountCard from 'src/components/LoadCountCard'
import {
  useDashboardStore,
  useLoadStore,
  useInvoiceStore,
} from 'src/hooks/useStores'
import {
  invoicesPendingPath,
  loadsClaimedPath,
  loadsInProgressPath,
  loadsSavedPath,
} from 'src/paths'
import pluralize from 'src/util/pluralize'
import styles from './CollapsibleContainerMyTasks.css'

const CollapsibleContainerMyTasks = () => {
  const dashboardStore = useDashboardStore()
  const loadStore = useLoadStore()
  const invoiceStore = useInvoiceStore()

  useEffect(() => {
    dashboardStore.loadMyTasks()
    dashboardStore.loadInsuranceExpiration()
  }, [dashboardStore])

  return (
    <Observer>
      {() => {
        const {
          myTasks,
          insuranceExpiration,
          sectionExpandedState,
        } = dashboardStore

        if (!myTasks || !insuranceExpiration) return null

        const {
          showExpirationAlert,
          expirationAlertMessage,
        } = formatExpirationMessages(insuranceExpiration)

        return (
          <CollapsibleContainer
            title="My Tasks"
            isExpanded={sectionExpandedState.myTasks}
            onToggle={() => dashboardStore.toggleSectionCollapse('myTasks')}
            bg="light-gray"
          >
            {showExpirationAlert && (
              <AlertInline>
                <p>{expirationAlertMessage}</p>
              </AlertInline>
            )}
            <div className={styles.LoadCountGrid}>
              <LoadCountCard
                count={myTasks.inRouteCount}
                label="Loads In Progress"
                linkPath={loadsInProgressPath()}
                onDownload={loadStore.inProgressLoadsDownload}
                onEmail={loadStore.inProgressLoadsEmail}
              />
              <LoadCountCard
                count={myTasks.pendingPickupCount}
                label="Claimed Loads"
                linkPath={loadsClaimedPath()}
                onDownload={loadStore.claimedLoadsDownload}
                onEmail={loadStore.claimedLoadsEmail}
              />
              <LoadCountCard
                count={myTasks.savedCount}
                label="Saved Loads"
                linkPath={loadsSavedPath()}
              />
              <LoadCountCard
                count={myTasks.pendingPaymentCount}
                label="Pending Payments"
                linkPath={invoicesPendingPath()}
                onDownload={invoiceStore.invoicesPendingDownload}
                ctaLabel="View Pending"
              />
            </div>
          </CollapsibleContainer>
        )
      }}
    </Observer>
  )
}

export default CollapsibleContainerMyTasks

/** @param {Haully.InsuranceExpiration} insuranceExpiration */
const formatExpirationMessages = insuranceExpiration => {
  let message = ''
  let alertTypes = []

  const showDamageAlert =
    insuranceExpiration.daysUntilDamageExpiration <=
    insuranceExpiration.insuranceExpirationWarningThreshold

  const showLiabilityAlert =
    insuranceExpiration.daysUntilLiabilityExpiration <=
    insuranceExpiration.insuranceExpirationWarningThreshold

  const dasyUntilExpiration = Math.min(
    insuranceExpiration.daysUntilLiabilityExpiration,
    insuranceExpiration.daysUntilDamageExpiration
  )

  if (showDamageAlert || showLiabilityAlert) {
    if (showDamageAlert) alertTypes.push('damage')
    if (showLiabilityAlert) alertTypes.push('liability')
    message = `Your ${alertTypes.join(
      ' and '
    )} insurance expires in ${dasyUntilExpiration} ${pluralize(
      dasyUntilExpiration,
      'day'
    )}!`
  }

  return {
    showExpirationAlert: showDamageAlert || showLiabilityAlert,
    expirationAlertMessage: message,
  }
}
