import * as paths from 'src/paths'

const MESSAGE_FROM_CEO_URL = 'https://adobe.ly/3IsrY2n'

export const SIGN_UP_URL =
  'https://haullycarriers.rmissecure.com/_s/reg/GeneralRequirementsV2.aspx'

/**
 * @typedef NavLink
 * @property {string} to
 * @property {string} label
 * @property {string} [tag]
 * @property {string} [target]
 *
 * @typedef NavLinkGroup
 * @property {string} label
 * @property {NavLink[]} navLinks
 *
 */

/** @type {{[x: string]: NavLink[]}} */
export const navLinks = {
  uncategorized: [
    { to: paths.dashboardPath(), label: 'Dashboard' },
    { to: paths.searchPath(), label: 'Find Loads' },
  ],
  loadManagement: [
    { to: paths.loadsSavedPath(), label: 'Saved Loads' },
    { to: paths.loadsClaimedPath(), label: 'Claimed Loads' },
    { to: paths.loadsInProgressPath(), label: 'In Progress Loads' },
    { to: paths.loadsCompletedPath(), label: 'Completed Loads' },
  ],
  payments: [
    { to: paths.invoicesPaidPath(), label: 'Paid Invoices' },
    { to: paths.invoicesPendingPath(), label: 'Pending Payment' },
  ],
  settings: [
    { to: paths.myAccountPath(), label: 'My Account' },
    { to: paths.settingsFleetPath(), label: 'Fleet' },
    { to: paths.settingsDriversPath(), label: 'Drivers' },
    { to: paths.accountSettingsPath(), label: 'Settings' },
    { to: paths.contactUsPath(), label: 'Contact Us' },
  ],
  marketing: [
    {
      to: MESSAGE_FROM_CEO_URL,
      label: "CEO's Letter",
      tag: 'a',
      target: '_blank',
    },
    { to: paths.welcomePath() + '#DiscoverTheDifference', label: 'Features' },
    { to: paths.welcomePath() + '#HowItWorks', label: 'How it Works' },
    { to: paths.welcomePath() + '#ContactUs', label: 'Contact' },
    { to: paths.tipsAndToolsPath(), label: 'Resources & FAQs' },
  ],
}

/** @type {(NavLink|NavLinkGroup)[]} */
export const desktopNavLinks = [
  ...navLinks.uncategorized,
  { label: 'My Loads', navLinks: navLinks.loadManagement },
  { label: 'Payments', navLinks: navLinks.payments },
]

/**
 * @type {NavLink[][]}
 */
export const mobileNavLinks = [
  navLinks.uncategorized,
  navLinks.loadManagement,
  navLinks.payments,
  navLinks.settings,
]
