import { Link, Location, navigate } from '@reach/router'
import cn from 'classnames'
import { format } from 'date-fns'
import { Observer, observer } from 'mobx-react'
import React from 'react'
import {
  IconButton,
  LinkButton,
  PlainButton,
  PrimaryButton,
} from 'src/components/Button'
import Flex from 'src/components/Flex'
import { MONTH_DAY_YEAR_SHORT } from 'src/data/dateFormats'
import { useLoadStore } from 'src/hooks/useStores'
import useWindowSize from 'src/hooks/useWindowSize'
import InopIcon from 'src/icons/Inop.svg'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import PlusIcon from 'src/icons/Plus.svg'
import PlusOIcon from 'src/icons/PlusO.svg'
import SedanIcon from 'src/icons/Sedan.svg'
import StarIcon from 'src/icons/Star.svg'
import { loadAssignDetailsPath, loadAssignPath, loadPath } from 'src/paths'
import { formatCurrency } from 'src/util/formatCurrency'
import pluralize from 'src/util/pluralize'
import * as LoadNotifications from '../util/loadNotifications'
import Box from './Box'
import CenteredContainer from './CenteredContainer'
import CloseButton from './CloseButton'
import DownloadButton from './DownloadButton'
import EmailLoadsButton from './EmailLoadsButton'
import { Column, Row } from './Layout'
import styles from './LoadCard.css'
import { LoadTimes } from './LoadTimes'
import Responsive from './Responsive'
import { Body2, H2, Numerals, Title } from './Text'
import Truncated from './Truncated'

/**
 * @typedef {object} LoadCardProps
 * @property {Haully.Load|Haully.LoadDetailed} props.load
 * @property {() => void} props.onFavoriteClick
 * @property {boolean} props.isFavoriteLoading
 * @property {String} [props.to]
 * @property {Boolean} [props.preserveLocationSearch]
 */

/** @param {LoadCardProps} props */
export const LinkedLoadCard = props => (
  <Observer>
    {() => {
      const { load, to, preserveLocationSearch } = props
      return (
        <LoadCardLink
          load={load}
          to={to}
          preserveLocationSearch={preserveLocationSearch}
        >
          <Box pl={3} pr={3} pb={2}>
            <LoadCardHeading load={load} />

            <Flex
              className={styles.PickupDropoff}
              justifyContent="space-between"
            >
              <LoadPickupDropoff load={load} />
            </Flex>

            <Flex justifyContent="space-between">
              <Box>
                <LoadFigures load={load} />
                <LoadVehicleIcons load={load} />
              </Box>
              {load.orderStatus !== 'Delivered' && (
                <LoadCardActions {...props} />
              )}
            </Flex>
          </Box>

          {'scheduledPickup' in load && (
            <Box border={'top'} p={3}>
              <Row>
                {load.scheduledPickup || load.scheduledDelivery ? (
                  <Column>
                    <LoadTimes
                      label="Scheduled Times"
                      pickup={load.scheduledPickup}
                      delivery={load.scheduledDelivery}
                    />
                  </Column>
                ) : null}

                {load.actualPickup || load.actualDelivery ? (
                  <Column>
                    <LoadTimes
                      label="Actual"
                      pickup={load.actualPickup}
                      delivery={load.actualDelivery}
                    />
                  </Column>
                ) : null}
              </Row>
            </Box>
          )}
        </LoadCardLink>
      )
    }}
  </Observer>
)

/**
 * @typedef {object} LoadDetailedCardProps
 * @property {Haully.LoadDetailed} props.load
 * @property {function} props.onFavoriteClick
 * @property {boolean} props.isFavoriteLoading
 *
 * @param {LoadDetailedCardProps} props
 */
export const DetailLoadCard = ({
  load,
  isFavoriteLoading,
  onFavoriteClick,
}) => {
  const { isLarge } = useWindowSize()
  const loadStore = useLoadStore()
  const isActionDisabled = load.orderStatus === 'Delivered'

  return (
    <Observer>
      {() => (
        <Box bg="black" color="white">
          <LoadCardTopContainer>
            <LoadBackButton load={load} />
          </LoadCardTopContainer>

          <CenteredContainer width="900">
            <Flex
              pl={3}
              pr={3}
              pb={3}
              flexDirection={isLarge ? 'row' : 'column'}
              justifyContent="space-between"
            >
              <Flex
                mb={3}
                flexDirection="column"
                flex={isLarge ? 1 : undefined}
                pr={3}
                justifyContent="space-between"
              >
                <Flex>
                  <div>
                    <LoadPickupDropoff load={load} />
                  </div>
                </Flex>
                <Responsive large={<LoadVehicleIcons load={load} />} />
              </Flex>

              <Flex flexDirection="column" flex={isLarge ? 1 : undefined}>
                <LoadFigures load={load} />
                <Responsive large={null}>
                  <LoadVehicleIcons load={load} />
                </Responsive>

                <Flex mb={2} lmt={3}>
                  <Flex mr={3}>
                    <PrimaryButton
                      variant={load.currentUserFavorite ? 'white' : 'outline'}
                      onClick={() => onFavoriteClick()}
                      disabled={isActionDisabled || isFavoriteLoading}
                      className={styles.IconWithTextButton}
                    >
                      <Box mr={2}>
                        <StarIcon
                          className={cn(styles.StrokeCurrentColor, {
                            [styles.FillCurrentColor]: load.currentUserFavorite,
                          })}
                        />
                      </Box>
                      {load.currentUserFavorite ? 'SAVED' : 'SAVE'}
                    </PrimaryButton>
                  </Flex>

                  <LinkButton
                    variant={
                      load.assignedToCurrentCarrier ? 'white' : 'outline'
                    }
                    to={
                      load.assignedToCurrentCarrier
                        ? loadAssignDetailsPath(load.id)
                        : loadAssignPath(load.id)
                    }
                    data-test="claimLoad"
                    disabled={
                      isActionDisabled ||
                      (!load.unAssigned && !load.assignedToCurrentCarrier)
                    }
                    className={styles.IconWithTextButton}
                  >
                    <Box mr={2}>
                      <PlusIcon className={styles.StrokeCurrentColor} />
                    </Box>
                    {load.assignedToCurrentCarrier
                      ? 'LOAD CLAIMED'
                      : 'CLAIM LOAD'}
                  </LinkButton>
                </Flex>

                {load.numberOfFavorites > 0 && (
                  <Box pt={3} pb={3}>
                    {load.numberOfFavorites}{' '}
                    {pluralize(load.numberOfFavorites, 'carrier')}{' '}
                    {load.numberOfFavorites === 1 ? 'has' : 'have'} saved this
                    load.
                  </Box>
                )}

                {load.assignedToCurrentCarrier && (
                  <Flex color="white" mt={2}>
                    <Flex mr={2}>
                      <DownloadButton
                        onClick={() => loadStore.downloadLoadInformation(load)}
                        actionLabel="Download Claimed Loads"
                      />
                    </Flex>

                    <EmailLoadsButton
                      onSendEmail={loadStore.sendLoadEmail(load.id)}
                      actionLabel="Email load details"
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </CenteredContainer>
        </Box>
      )}
    </Observer>
  )
}

/**
 * @param {object} props
 * @param {Haully.Load} props.load
 * @param {1|2} props.stepCount
 * @param {string} props.stepDescription
 * @param {string} props.title
 * @param {() => void} props.onRequestClose
 * @param {React.ReactNode} [props.children]
 */
export const LoadAssignCard = ({
  load,
  stepCount,
  stepDescription,
  title,
  children,
  onRequestClose,
}) => {
  const { isLarge } = useWindowSize()
  return (
    <Box bg="black" color="white">
      <LoadCardTopContainer>
        <LoadBackButton load={load} />
        <CloseButton onClick={onRequestClose} />
      </LoadCardTopContainer>

      <LoadAssignCardBody>
        <Flex
          ml={3}
          mb={2}
          mr={2}
          justifyContent={isLarge ? 'center' : undefined}
        >
          <Box>
            <H2 pr={2} data-test="loadAssignCardStep">
              STEP {stepCount} OF 2:{' '}
              {stepDescription && stepDescription.toUpperCase()}
            </H2>
          </Box>
        </Flex>
        <Box pl={3} pr={3} pb={3}>
          <Title mb={3}>{title}</Title>
          <Box mb={3}>
            <LoadPickupDropoff load={load} />
          </Box>
          {children}
        </Box>
      </LoadAssignCardBody>
    </Box>
  )
}

/**
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 */
export const LoadAssignCardBody = ({ children }) => (
  <div className={styles.LoadAssignCardBody}>{children}</div>
)

/**
 * @param {object} props
 * @param {Haully.Load} props.load
 */
export const LoadBasicCard = ({ load }) => (
  <Box bg="black" color="white">
    <LoadCardTopContainer>
      <LoadBackButton load={load} />
    </LoadCardTopContainer>
    <div className={styles.LoadAssignCardBody}>
      <Box pl={3} pr={3} pb={3}>
        <Box mb={3}>
          <LoadPickupDropoff load={load} />
        </Box>
      </Box>
    </div>
  </Box>
)

/** @param {{load: Haully.Load }} props */
export const LoadBackButton = ({ load }) => (
  <PlainButton onClick={() => window.history.go(-1)}>
    <Box mr={2}>
      <LeftArrowIcon className={cn(styles.FillCurrentColor)} />
    </Box>
    <H2>Load {load.id}</H2>
  </PlainButton>
)

export const LoadCardTopContainer = ({ children }) => (
  <CenteredContainer width="1050">
    <Flex
      justifyContent="space-between"
      alignItems="center"
      pl={2}
      pr={2}
      pt={1}
      pb={1}
      lmb={3}
    >
      {children}
    </Flex>
  </CenteredContainer>
)

/**
 * @param {LoadCardProps} props
 */
const _LoadCardActions = ({ load, onFavoriteClick, isFavoriteLoading }) => {
  const favoriteDisabled = load.orderStatus === 'Delivered'
  return (
    <Flex
      alignItems="center"
      className={styles.LoadCardActions}
      onClick={e => {
        if (favoriteDisabled) stop(e)
      }}
    >
      <IconButton
        className={styles.StarButton}
        onClick={e => stop(e) && onFavoriteClick()}
        disabled={isFavoriteLoading || favoriteDisabled}
        data-test={`LoadCard-FavoriteButton`}
        title={
          load.currentUserFavorite
            ? 'Remove this load from your favorites'
            : 'Save this load to your favorites'
        }
      >
        <StarIcon
          className={cn({
            [styles.StarIconOutlined]: !load.currentUserFavorite,
            [styles.StarIconFilled]: load.currentUserFavorite,
          })}
        />
      </IconButton>

      {load.unAssigned ? (
        <IconButton
          onClick={e => stop(e) && navigate(loadAssignPath(load.id))}
          className={styles.ClaimButton}
          data-test="claimLoad"
        >
          <PlusOIcon />
        </IconButton>
      ) : (
        <div onClick={stop}>
          <IconButton className={styles.ClaimButton} disabled={true}>
            <PlusOIcon />
          </IconButton>
        </div>
      )}
    </Flex>
  )
}
export const LoadCardActions = observer(_LoadCardActions)

/**
 * @param {object} props
 * @param {Haully.Load} props.load
 */
export const LoadFigures = ({ load }) => {
  const { rate, miles, vehicles } = load
  const ratePerMile = rate > 0 && miles > 0 ? rate / miles : null

  const weight = totalVehicleWeight(vehicles)

  return (
    <Flex flexDirection="column">
      <Numerals className={styles.LoadCardRate}>
        {formatCurrency(rate)}
      </Numerals>
      <Body2 className={styles.LoadCardMilesWeightDetails}>
        <div>
          {miles.toLocaleString()} mi.{' '}
          {miles ? (
            <>
              {ratePerMile ? ` (${formatCurrency(ratePerMile)} per mi.)` : null}
            </>
          ) : null}
        </div>
        <div>
          {vehicles.length} {pluralize(vehicles.length, 'vehicle')}
          {weight ? ` • ${weight.toLocaleString()}  lbs.` : null}
        </div>
      </Body2>
    </Flex>
  )
}

/**
 * @param {object} props
 * @param {Haully.Load} props.load
 */
const LoadVehicleIcons = ({ load }) => {
  const operableVehicleCount = load.vehicles.filter(
    vehicle => !vehicle.inoperable
  ).length
  const inoperableVehicleCount = load.vehicles.filter(
    vehicle => vehicle.inoperable
  ).length

  return (
    <VehicleIcons
      operableVehicles={operableVehicleCount}
      inoperableVehicles={inoperableVehicleCount}
    />
  )
}

/**
 * @param {object} props
 * @param {Haully.Load} props.load
 */
export const LoadPickupDropoff = ({ load }) => {
  const {
    originCity,
    originState,
    earliestPickup,
    earliestDelivery,
    destinationCity,
    destinationState,
    latestPickup,
    latestDelivery,
  } = load

  return (
    <Flex flex={1} flexDirection="column">
      <PickupDropoffRow
        indicatorColor="blue"
        city={originCity}
        state={originState}
        dates={[earliestPickup, latestPickup]}
      />
      <PickupDropoffRow
        indicatorColor="gray"
        city={destinationCity}
        state={destinationState}
        dates={[earliestDelivery, latestDelivery]}
      />
    </Flex>
  )
}

/**
 * @param {object} props
 * @param {"blue"|"gray"} props.indicatorColor
 * @param {string} props.city
 * @param {string} props.state
 * @param {(string|null)[]} props.dates
 */
export const PickupDropoffRow = ({
  indicatorColor,
  city,
  state,
  dates: maybeDates,
}) => {
  const dates = /** @type {string[]} */ (maybeDates.filter(Boolean))
  return (
    <div className={styles.PickupDropoffRow}>
      <Flex>
        <div
          className={cn(styles.Indicator, {
            [styles.IndicatorBlue]: indicatorColor === 'blue',
            [styles.IndicatorGray]: indicatorColor === 'gray',
          })}
        />
        <div className={styles.CityState}>
          <Truncated title={city} />, {state}
        </div>
      </Flex>
      <Box mb={1}>
        {dates.map(date => format(date, MONTH_DAY_YEAR_SHORT)).join(' – ')}
      </Box>
    </div>
  )
}

/**
 * @param {Object} props
 * @param {Haully.Load} props.load
 * @param {String} [props.to]
 * @param {Boolean} [props.preserveLocationSearch]
 * @param {React.ReactNode} props.children
 */
export const LoadCardLink = ({
  load,
  to,
  preserveLocationSearch,
  children,
}) => {
  const toPath = to ? to : loadPath(load.id)
  return (
    <Location>
      {({ location }) => (
        <Link
          to={preserveLocationSearch ? `${to}${location.search}` : toPath}
          className={styles.LoadCardLink}
          data-test-load-id={load.id}
          data-test="LinkedLoadCard"
        >
          {children}
        </Link>
      )}
    </Location>
  )
}

/**
 * @param {Object} props
 * @param {Haully.Load} props.load
 */
export const LoadCardHeading = ({ load }) => (
  <div className={styles.LoadCardHeading}>
    <H2 pr={2}>{load.id}</H2>
    <LoadTags load={load} />
  </div>
)

/**
 * @param {object} props
 * @param {Haully.Load | Haully.LoadDetailed} props.load
 */
const LoadTags = ({ load }) => (
  <div className={styles.LoadTags}>
    {load.twicRequired && <Tag>TWIC</Tag>}
    {load.rushSold && <Tag>Rush</Tag>}
    {load.enclosedTruckRequired && <Tag>Encl Truck Req</Tag>}
    {load.guaranteedPickup && <Tag>Guaranteed PU</Tag>}
    {load.guaranteedDelivery && <Tag>Guaranteed Delv</Tag>}
    {load.myLane && <Tag>My Lane</Tag>}
    {'scheduledPickup' in load && <LoadDetailTags load={load} />}
  </div>
)

/**
 * @param {object} props
 * @param {Haully.LoadDetailed} props.load
 */
const LoadDetailTags = ({ load }) => (
  <>
    {LoadNotifications.isPickupMissed(load) && (
      <Tag isAlert={true}>Pick up missed</Tag>
    )}
    {LoadNotifications.isDeliveryMissed(load) && (
      <Tag isAlert={true}>Delivery missed</Tag>
    )}
    {LoadNotifications.isPickupWithinDays(1, load) && (
      <Tag isAlert={true}>1 day to pickup</Tag>
    )}
    {LoadNotifications.isDeliveryWithinDays(1, load) && (
      <Tag isAlert={true}>1 day to deliver</Tag>
    )}
  </>
)

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {boolean} [props.isAlert]
 */
const Tag = ({ children, isAlert }) => (
  <div className={cn(styles.Tag, isAlert && styles.TagAlert)}>
    <div className={styles.TagBody}>{children}</div>
  </div>
)

/**
 * @param {object} props
 * @param {number} props.operableVehicles
 * @param {number} [props.inoperableVehicles]
 * @param {string} [props.operableVehicleLabel]
 */
export const VehicleIcons = ({
  operableVehicles,
  operableVehicleLabel,
  inoperableVehicles,
}) => (
  <Flex alignItems="center" className={styles.VehicleIcons}>
    {operableVehicles ? (
      <div
        className={cn(styles.VehicleIconsGroup, styles.VehicleIconsSedan, {
          [styles.VehicleIconsGroupBordered]: inoperableVehicles,
        })}
      >
        <SedanIcon
          className={cn(styles.VehicleIconsIcon, styles.FillCurrentColor)}
        />{' '}
        {operableVehicles} {operableVehicleLabel}
      </div>
    ) : null}

    {inoperableVehicles ? (
      <div className={styles.VehicleIconsGroup}>
        <InopIcon
          className={cn(styles.VehicleIconsIcon, styles.InopIconFill)}
        />{' '}
        {inoperableVehicles}
      </div>
    ) : null}
  </Flex>
)

/** @param {Haully.Vehicle[]} vehicles */
function totalVehicleWeight(vehicles) {
  return Math.round(
    vehicles.reduce((acc, vehicle) => {
      const weight = vehicle.weight !== null ? parseFloat(vehicle.weight) : 0
      if (Number.isFinite(weight)) {
        return acc + weight
      }
      return acc
    }, 0)
  )
}

/** @param {React.MouseEvent<any>} e */
function stop(e) {
  e.preventDefault()
  e.stopPropagation()
  return true
}
