import React from 'react'
import useOnlineStatus from '@rehooks/online-status'
import styles from './OfflineWarning.css'

export const OfflineWarning = () => {
  const onlineStatus = useOnlineStatus()
  if (onlineStatus) return null
  return (
    <div className={styles.offlineWarning}>
      Offline, please check connection
    </div>
  )
}
