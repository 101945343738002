import { useQuery } from 'react-query'
import { useApiRequest } from 'src/hooks/useStores'

export const useAnnouncements = () => {
  const request = useApiRequest()
  const announcementsResult = useQuery(
    'announcements',
    () =>
      request('Announcements/List?active=true&current=true', {
        isErrorGlobal: false,
      }),
    { refetchOnWindowFocus: false }
  )

  const announcements =
    announcementsResult.status === 'success' &&
    announcementsResult.data &&
    announcementsResult.data.ok
      ? announcementsResult.data.data.announcements
      : []

  return { announcements }
}
