import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { LoadingHeading } from 'src/components/Loading'
import { ListEmptyMessage } from 'src/components/PaginatedList'
import { DriverCardButton } from 'src/components/DriverCard'
import { InputDebounced } from 'src/components/TextInput'
import Box from 'src/components/Box'
import DriverIcon from 'src/icons/Driver.svg'
import { BoxButton, PrimaryButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import { Body1, Body2, TextContainer } from 'src/components/Text'
import { ESC_KEY } from 'src/data/keys'
import Form from 'src/models/Form'

/**
 * @typedef {Object} Props
 * @property {import('src/stores/DriverStore').default} driverStore
 * @property {(driver: Haully.DriverTypeahead | null) => void} onDriverSelected
 * @property {Haully.DriverId|null} selectedDriverId
 * @property {boolean} [isEmailRequired]
 * @property {boolean} [isAllDriversOption]
 *
 * @typedef {Object} State
 * @property {string} driverSearch
 *
 * @augments Component<Props>
 */
@observer
class FilteredDriverList extends Component {
  form = new Form({
    values: {
      driverSearch: '',
      /** @type {?number} */
      selectedDriver: null,
    },
  })

  componentDidMount() {
    this.form.reset()

    if (this.props.selectedDriverId) {
      this.form.values.selectedDriver = this.props.selectedDriverId
    }

    this.props.driverStore.clientDrivers.fetchFirstPage()
  }

  render() {
    const {
      driverStore,
      onDriverSelected,
      isEmailRequired,
      isAllDriversOption,
    } = this.props
    const form = this.form
    const { selectedDriver } = form.values
    const { clientDrivers } = driverStore

    const isFilter = form.values.driverSearch.trim().length > 0

    if (clientDrivers.collection.length === 0 && clientDrivers.isLoading)
      return <LoadingHeading />
    if (clientDrivers.totalCount === 0) {
      return (
        <TextContainer>
          <Body2>
            It&#39;s ok to skip for now but, don&#39;t forget to add a driver
            and assign them later!
          </Body2>
        </TextContainer>
      )
    }

    return (
      <>
        <Box pl={3} pr={3}>
          <InputDebounced
            placeholder="Name"
            value={form.values.driverSearch}
            onChangeText={text => {
              form.update({ driverSearch: text })
              const trimmed = text.trim()
              trimmed && driverStore.updateFilteredDrivers(trimmed)
            }}
            data-test="loadAssignDriverFilterInput"
            onKeyDown={e => {
              if (e.keyCode === ESC_KEY) {
                e.stopPropagation()
                this.props.onDriverSelected(null)
              }
            }}
            autoFocus={true}
          />
        </Box>

        <Box mb={4} pb={4}>
          <DriverList
            onSelectDriver={driver => {
              form.values.selectedDriver = driver ? driver.driverId : null
              onDriverSelected(driver)
            }}
            selectedDriverId={selectedDriver}
            isAllDriversOption={!!isAllDriversOption}
            drivers={
              isFilter
                ? driverStore.filteredClientDrivers
                : clientDrivers.collection
            }
            isEmailRequired={isEmailRequired}
          />

          {!isFilter && clientDrivers.isMoreResults && (
            <Box ml={3} mr={3} pt={2}>
              <PrimaryButton
                variant="outline"
                disabled={clientDrivers.isLoading}
                onClick={() => clientDrivers.fetchNextPage()}
              >
                Load More
              </PrimaryButton>
            </Box>
          )}
          <Box pb={2} />
        </Box>
      </>
    )
  }
}
export default FilteredDriverList

/**
 * @param {Object} props
 * @param {(driver: Haully.DriverTypeahead | null) => void} props.onSelectDriver
 * @param {Haully.DriverId|null} props.selectedDriverId
 * @param {boolean} props.isAllDriversOption
 * @param {boolean|undefined} [props.isEmailRequired]
 * @param {(Haully.Driver|Haully.DriverTypeahead)[]} props.drivers
 */
const _DriverList = ({
  onSelectDriver,
  selectedDriverId,
  isAllDriversOption,
  drivers,
  isEmailRequired,
}) => {
  if (drivers.length === 0)
    return (
      <Box p={3}>
        <ListEmptyMessage>No results</ListEmptyMessage>
      </Box>
    )

  return (
    <>
      {isAllDriversOption && (
        <BoxButton onClick={() => onSelectDriver(null)}>
          <Flex
            alignItems="center"
            pt={1}
            pb={1}
            pl={3}
            pr={3}
            bg={selectedDriverId === null ? 'light-gray' : undefined}
          >
            <Box mr={3}>
              <DriverIcon />
            </Box>
            <Body1>All Drivers</Body1>
          </Flex>
        </BoxButton>
      )}
      {drivers.map(driver => {
        const isSelected = selectedDriverId === driver.driverId
        return (
          <DriverCardButton
            driver={driver}
            key={driver.driverId}
            onClick={() => onSelectDriver(driver)}
            disabled={isEmailRequired && !driver.email}
            isActive={isSelected}
          />
        )
      })}
    </>
  )
}
const DriverList = observer(_DriverList)
