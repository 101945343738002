import { format } from 'date-fns'
import React, { useState } from 'react'
import FilteredUserList from 'src/containers/FilteredUserList'
import { MONTH_DAY_YEAR } from 'src/data/dateFormats'
import { fullName } from 'src/formatter/driver'
import { useDriverStore, useFlashMessageStore } from 'src/hooks/useStores'
import PaperAirplaneIcon from 'src/icons/PaperAirplane.svg'
import Box from './Box'
import { IconButton, PrimaryButton } from './Button'
import Grid from './Grid'
import Modal from './Modal'
import TextArea from './TextArea'
import TextInput, { Label } from './TextInput'

/**
 * @param {object} props
 * @param {string} props.actionLabel
 * @param {import('src/util/apiActions').EmailCallback} props.onSendEmail
 */
const EmailLoadsButton = ({ onSendEmail, actionLabel }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => setIsOpen(false)

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)}>
        <PaperAirplaneIcon title={actionLabel} width={20} height={20} />
      </IconButton>
      <EmailLoadsModal isOpen={isOpen} onRequestClose={handleClose}>
        <EmailLoadsForm
          onRequestClose={handleClose}
          onSendEmail={onSendEmail}
        />
      </EmailLoadsModal>
    </>
  )
}

/**
 * @param {object} props
 * @param {Haully.UserTypeahead|Haully.Driver|undefined} [props.assignedUser]
 * @param {() => void} props.onRequestClose
 * @param {import('src/util/apiActions').EmailCallback} props.onSendEmail
 */
export const EmailLoadsForm = ({
  assignedUser,
  onRequestClose,
  onSendEmail,
}) => {
  const [screen, setScreen] = useState(/** @type {"form"|"driver"} */ ('form'))
  const [subjectFocus, setSubjectFocus] = useState(false)
  const [subject, setSubject] = useState(
    `Documents for loads on ${format(new Date(), MONTH_DAY_YEAR)}`
  )
  const [message, setMessage] = useState(
    'Here are the documents for your loads tomorrow.'
  )
  const driverStore = useDriverStore()
  const [user, setUser] = useState(
    /** @type {Haully.UserTypeahead|null} */ (assignedUser)
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const flashStore = useFlashMessageStore()

  if (screen === 'driver') {
    return (
      <Box pt={3}>
        <FilteredUserList
          driverStore={driverStore}
          onUserSelected={user => {
            setUser(user)
            setScreen('form')
            setSubjectFocus(true)
          }}
          selectedUserId={user ? user.id : null}
        />
      </Box>
    )
  }

  return (
    <Box p={3}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (!user) throw new Error('Invariant: no driver')
          setIsSubmitting(true)

          try {
            const [ok, responseText] = await onSendEmail({
              driverId: user.id,
              subject,
              message,
            })

            setIsSubmitting(false)

            if (ok) {
              flashStore.add(responseText)
              onRequestClose()
            }
          } catch (e) {
            setIsSubmitting(false)
            throw e
          }
        }}
      >
        <Box mb={3}>
          <TextInput
            value={user ? `${fullName(user)}, ${user.email}` : ''}
            onChangeText={() => {}}
            placeholder="Name"
            onClick={() => setScreen('driver')}
            onKeyDown={() => setScreen('driver')}
            required={true}
          />
        </Box>
        <Box mb={3}>
          <TextInput
            value={subject}
            onChangeText={setSubject}
            placeholder="Subject"
            autoFocus={subjectFocus}
          />
        </Box>
        <Box mb={3}>
          <Label placeholder="Message" htmlFor="EmailLoadsButton-message" />
          <Box pt={3}>
            <TextArea
              value={message}
              onChangeText={setMessage}
              id="EmailLoadsButton-message"
              rows={8}
            />
          </Box>
        </Box>
        <Grid autoFlow="column" gap={3} autoColumns="1fr">
          <PrimaryButton onClick={onRequestClose} variant="outline">
            Cancel
          </PrimaryButton>
          <PrimaryButton type="submit" isLoading={isSubmitting}>
            Send Documents
          </PrimaryButton>
        </Grid>
      </form>
    </Box>
  )
}

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onRequestClose
 * @param {React.ReactNode} props.children
 */
export const EmailLoadsModal = ({ isOpen, onRequestClose, children }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    <Box bg="black" color="white" p={3}>
      Send Documents
    </Box>
    <Box p={3}>Choose a driver or team member to send the documents to.</Box>
    {children}
  </Modal>
)

export default EmailLoadsButton
