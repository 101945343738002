import React from 'react'
import styles from './withColor.css'
import cn from 'classnames'
import getDisplayName from './getDisplayName'

interface HOCProps {
  color?: 'black' | 'light-blue' | 'white' | 'red' | 'dark-gray' | undefined
}

interface WithClass {
  className?: string | undefined
}

export default function withColor<T extends WithClass = WithClass>(
  TargetComponent: React.ComponentType<T>
) {
  const WrappedComponent: React.FC<HOCProps & T> = props => {
    const { className, color, ...rest } = props
    const enhancedClassName = cn(className, {
      [styles[`Color-${color}`]]: color,
    })
    const newProps = rest as T

    return <TargetComponent className={enhancedClassName} {...newProps} />
  }

  WrappedComponent.displayName = `WithColor(${getDisplayName(TargetComponent)})`

  return WrappedComponent
}
