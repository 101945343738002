import React from 'react'
import HeadingContainer from 'src/components/HeadingContainer'
import { Heading } from 'src/components/Text'
import Wrapper from 'src/components/Wrapper'
import PageMeta from 'src/components/PageMeta'

const Todo = () => (
  <Wrapper>
    <PageMeta title="TODO" />
    <HeadingContainer>
      <Heading>TODO</Heading>
      <p>This page is not complete yet</p>
    </HeadingContainer>
  </Wrapper>
)
export default Todo
