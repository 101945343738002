import React from 'react'
import styles from './Switch.css'

/**
 * @param {object} props
 * @param {string} props.name
 * @param {boolean} props.checked
 * @param {() => void} props.onToggle
 */
const Switch = props => (
  <label className={styles.Switch} {...props}>
    <input
      type="checkbox"
      className={styles.Input}
      name={props.name}
      onChange={props.onToggle}
      checked={props.checked}
    />
    <span className={styles.Slider} />
  </label>
)

export default Switch
