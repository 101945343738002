import { observable, computed } from 'mobx'
import Store from './Store'
import { env } from 'src/util/env'

const getApiRoot = () => {
  const apiRoot = env('API_ROOT')
  if (!apiRoot) throw new Error('api root required')
  return apiRoot
}

export const DEFAULT_API_ROOT = getApiRoot()

export default class ConfigStore extends Store {
  DEFAULT_API_ROOT = DEFAULT_API_ROOT

  @observable apiRoot = this.DEFAULT_API_ROOT

  @computed
  get serialize() {
    return {
      apiRoot: this.apiRoot,
    }
  }

  deserialize = data => {
    this.apiRoot = data.apiRoot || this.DEFAULT_API_ROOT
  }
}
