import React from 'react'
import Flex from 'src/components/Flex'
import MarketingContainer from 'src/components/MarketingContainer'
import PageMeta from 'src/components/PageMeta'
import { Title } from 'src/components/Text'

const ComingSoon = () => (
  <MarketingContainer>
    <PageMeta title="Coming soon" />
    <Flex justifyContent="center" alignItems="center" p={5}>
      <Title>Coming soon!</Title>
    </Flex>
  </MarketingContainer>
)
export default ComingSoon
