import React from 'react'
import { Link } from '@reach/router'
import withColor from 'src/hocs/withColor'

const LinkBasic = props =>
  props.to.includes('#') || props.tag === 'a' ? (
    <a href={props.to} {...props} />
  ) : (
    <Link {...props} />
  )

export default withColor(LinkBasic)
