import React from 'react'
import Box from 'src/components/Box'
import HeadingContainer from 'src/components/HeadingContainer'
import MobileButtonContainer from 'src/components/MobileButtonContainer'
import OnboardingContainer from 'src/components/OnboardingContainer'
import PageMeta from 'src/components/PageMeta'
import { Heading } from 'src/components/Text'
import { registerWelcomePath, registerInsuranceDetailsPath } from 'src/paths'
import { useRegistrationStore } from 'src/hooks/useStores'
import TextInput, { Label } from 'src/components/TextInput'
import MaxWidth from 'src/components/MaxWidth'
import { Observer } from 'mobx-react'
import { Row, Column } from 'src/components/Layout'
import OnboardingCounter from 'src/components/OnboardingCounter'
import { Select, Option, OptionBlank } from 'src/components/Select'
import states from 'src/data/states.json'
import { navigate } from '@reach/router'
import { PrimaryButton } from 'src/components/Button'

const RegisterBusinessLocation = () => {
  const registrationStore = useRegistrationStore()

  return (
    <Observer>
      {() => {
        const { carrierRegistrationForm } = registrationStore

        const handleBasicFieldChange = e => {
          carrierRegistrationForm.update({
            [e.target.name]: e.target.value,
          })
        }

        const handleSubmit = e => {
          e.preventDefault()
          navigate(registerInsuranceDetailsPath())
        }

        return (
          <OnboardingContainer>
            <PageMeta title="Onboard Welcome" />
            <OnboardingCounter
              position={2}
              total={4}
              backTo={registerWelcomePath(
                carrierRegistrationForm.values.registrationCode
              )}
            />

            <HeadingContainer>
              <Heading mb={2} lmb={4}>
                Business Location
              </Heading>
            </HeadingContainer>
            <form onSubmit={handleSubmit}>
              <Box mb={3} lmb={4}>
                <MaxWidth value={460}>
                  <TextInput
                    name="address1"
                    value={carrierRegistrationForm.values.address1}
                    onChange={handleBasicFieldChange}
                    required={true}
                    placeholder="Street Address"
                  />
                  <TextInput
                    name="address2"
                    value={carrierRegistrationForm.values.address2}
                    onChange={handleBasicFieldChange}
                    required={false}
                    placeholder="Street Address 2"
                  />
                  <Row>
                    <Column>
                      <TextInput
                        name="city"
                        value={carrierRegistrationForm.values.city}
                        onChange={handleBasicFieldChange}
                        required={true}
                        placeholder="City"
                      />
                    </Column>
                    <Column>
                      <Label placeholder="State" floating={true}>
                        <Select
                          name="state"
                          id="state"
                          value={carrierRegistrationForm.values.state}
                          onChange={handleBasicFieldChange}
                          required={true}
                        >
                          <OptionBlank />
                          {Object.keys(states).map(code => (
                            <Option key={code} value={code}>
                              {states[code]}
                            </Option>
                          ))}
                        </Select>
                      </Label>
                    </Column>
                  </Row>
                  <TextInput
                    name="zip"
                    value={carrierRegistrationForm.values.zip}
                    onChange={handleBasicFieldChange}
                    required={true}
                    placeholder="Zip Code"
                  />
                </MaxWidth>
              </Box>
              <Box mb={3} lmb={4}>
                <MobileButtonContainer>
                  <PrimaryButton type="submit">Next</PrimaryButton>
                </MobileButtonContainer>
              </Box>
            </form>
          </OnboardingContainer>
        )
      }}
    </Observer>
  )
}

export default RegisterBusinessLocation
