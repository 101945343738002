import { observable, computed, toJS } from 'mobx'
import { max, format, parse, subDays } from 'date-fns'
import Store from './Store'
import dateRangeParams from 'src/util/dateRangeParams'
import URLSearchParams from '@ungap/url-search-params'

export default class DashboardStore extends Store {
  @observable
  dashboard = {
    newLoadsToday: 0,
    /** @type {number|null} */
    loadsAddedToday: null,
  }

  @observable
  myTasks

  /** @type {Haully.InsuranceExpiration|undefined} */
  @observable insuranceExpiration

  /** @type {Haully.LoadTrends|undefined} */
  @observable loadTrends

  /** @type {Haully.PaymentSummary|undefined} */
  @observable paymentSummary

  /** @type {Haully.LoadSummary|undefined} */
  @observable loadSummary

  /** @type {Haully.RevenueSummary|undefined} */
  @observable revenueSummary

  /** @type {Date|undefined} */
  @observable
  bannerDismissedOn

  @observable
  sectionExpandedState = {
    myTasks: true,
    myTrends: true,
    mySummary: true,
    invoiceChart: true,
  }

  loadLoadsAddedToday = async () => {
    const today = new Date()
    const yesterday = subDays(today, 1)
    const visibleToCarriersFrom = max(
      this.bannerDismissedOn || yesterday,
      yesterday
    ).toISOString()

    const params = new URLSearchParams()
    params.append('visibleToCarriersFrom', visibleToCarriersFrom)
    params.append('visibleToCarriersThru', today.toISOString())

    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/newLoadsToday?${params}`
    )

    if (resp.ok) {
      if (Number.isFinite(resp.data)) {
        this.dashboard.loadsAddedToday = resp.data
        return
      }

      throw new Error(`expected number, got ${resp.data}`)
    }
  }

  loadInsuranceExpiration = async () => {
    const resp = await this.rootStore.apiRequest(
      `Carriers/InsuranceExpiration?carrierId=${this.rootStore.sessionStore.clientId}`
    )

    if (resp.ok) {
      this.insuranceExpiration = resp.data
      return true
    }
    return false
  }

  loadMyTasks = async () => {
    const resp = await this.rootStore.apiRequest('CarrierLoads/MyTasks')

    if (resp.ok) {
      this.myTasks = resp.data
      return true
    }
    return false
  }

  loadLoadTrends = async (range = 'This Week') => {
    const params = dateRangeParams(range)

    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/LoadTrends?${params}`
    )

    if (resp.ok) {
      this.loadTrends = resp.data
      return true
    }
    return false
  }

  loadPaymentSummary = async () => {
    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/SixMonthPaymentSummary`
    )

    if (resp.ok) {
      this.paymentSummary = resp.data
      return true
    }
    return false
  }

  loadLoadSummary = async () => {
    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/SixMonthLoadSummary`
    )

    if (resp.ok) {
      this.loadSummary = resp.data
      return true
    }
    return false
  }

  loadRevenueSummary = async (region = 'MidAtlantic') => {
    const resp = await this.rootStore.apiRequest(
      `CarrierLoads/SixMonthRevenueSummary?compareRegion=${region}`
    )

    if (resp.ok) {
      this.revenueSummary = resp.data
      return true
    }
    return false
  }

  toggleSectionCollapse(section) {
    this.sectionExpandedState[section] = !this.sectionExpandedState[section]
  }

  @computed
  get serialize() {
    return {
      bannerDismissedOn: this.bannerDismissedOn
        ? format(this.bannerDismissedOn)
        : undefined,
      sectionExpandedState: toJS(this.sectionExpandedState),
    }
  }

  deserialize = data => {
    this.bannerDismissedOn = data.bannerDismissedOn
      ? parse(data.bannerDismissedOn)
      : undefined
    this.sectionExpandedState = {
      ...this.sectionExpandedState,
      ...data.sectionExpandedState,
    }
  }
}
