import { Link } from '@reach/router'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { IconButton } from 'src/components/Button'
import ShortcutMenuItem from 'src/components/ShortcutMenuItem'
import { Title } from 'src/components/Text'
import CloseMenuIcon from 'src/icons/CloseMenuIcon.svg'
import ContactDispatchIcon from 'src/icons/ContactDispatchIcon.svg'
import DashboardIcon from 'src/icons/DashboardIcon.svg'
import FindLoadsIcon from 'src/icons/FindLoadsIcon.svg'
import SavedLoadsIcon from 'src/icons/SavedLoadsIcon.svg'
import ShortcutMenuIcon from 'src/icons/ShortcutMenuIcon.svg'
import {
  contactDispatchPath,
  dashboardPath,
  loadsSavedPath,
  searchPath,
} from 'src/paths'
import Box from './Box'
import styles from './ShortcutMenu.css'

const SHORTCUT_LINKS = [
  { to: dashboardPath(), label: 'Dashboard', icon: <DashboardIcon /> },
  { to: loadsSavedPath(), label: 'Saved Loads', icon: <SavedLoadsIcon /> },
  { to: searchPath(), label: 'Find Loads', icon: <FindLoadsIcon /> },
  {
    to: contactDispatchPath(),
    label: 'Contact Dispatch',
    icon: <ContactDispatchIcon />,
  },
]

const ShortcutMenu = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const overlayRef = React.createRef()

  useEffect(() => {
    const el = overlayRef.current
    if (isExpanded) {
      disableBodyScroll(el)
    } else {
      enableBodyScroll(el)
    }
    return () => enableBodyScroll(el)
  }, [isExpanded, overlayRef])

  return (
    <>
      <div
        className={cn(
          styles.ShortcutMenuOverlay,
          isExpanded ? styles.isExpanded : styles.isCollapsed
        )}
        ref={overlayRef}
        onClick={() => setIsExpanded(false)}
      />

      <div className={styles.ShortcutMenu}>
        <ShortcutMenuItem>
          <IconButton
            className={styles.ShortcutMenuButton}
            onClick={() => toggleExpanded()}
          >
            {isExpanded ? (
              <CloseMenuIcon />
            ) : (
              <ShortcutMenuIcon title="Shortcut Menu" />
            )}
          </IconButton>
        </ShortcutMenuItem>
      </div>

      {isExpanded && (
        <div className={styles.ShortcutMenuLinks}>
          {SHORTCUT_LINKS.map((link, i) => (
            <Box mb={3} key={i}>
              <Link
                to={link.to}
                className={styles.ShortcutMenuLink}
                onClick={() => setIsExpanded(false)}
              >
                <ShortcutMenuItem>
                  <Title mr={2}>{link.label}</Title>
                  <Box className={styles.ShortcutMenuButton}>{link.icon}</Box>
                </ShortcutMenuItem>
              </Link>
            </Box>
          ))}
        </div>
      )}
    </>
  )
}

export default ShortcutMenu
