import { navigate } from '@reach/router'
import { inject, observer } from 'mobx-react'
import { parse, stringify } from 'qs'
import React, { Component } from 'react'
import LoadingScreen from 'src/components/Loading'
import { searchPath } from 'src/paths'
import DateSelector from 'src/screens/DateSelector'
import LocationSearch from 'src/screens/LocationSearch'
import DashboardStore from 'src/stores/DashboardStore'
import LoadStore from 'src/stores/LoadStore'
import SearchStore from 'src/stores/SearchStore'
import SessionStore from 'src/stores/SessionStore'
import { FindLoadsSplash } from './FindLoadsSplash'
import { LoadSearchResults } from './LoadSearchResults'
import { MainSearchForm } from './MainSearchForm'

export const SEARCH_MODES = {
  from: /** @type {"From"} */ ('From'),
  to: /** @type {"To"} */ ('To'),
}

/**
 * @typedef Props
 * @property {string} title
 * @property {SessionStore} [sessionStore]
 * @property {DashboardStore} [dashboardStore]
 * @property {LoadStore} [loadStore]
 * @property {SearchStore} [searchStore]
 * @property {Haully.SearchParameters} [searchParams]
 *
 * @typedef State
 * @property {boolean} isExpandedLocationSearch
 * @property {boolean} isExpandedDateSelector
 * @property {boolean} showSaveSearchButton
 * @property {'From'|'To'|null} searchMode
 *
 * @augments Component<Props, State>
 */
@inject('sessionStore', 'dashboardStore', 'loadStore', 'searchStore')
@observer
class LoadSearch extends Component {
  /** @type {State} */
  state = {
    isExpandedLocationSearch: false,
    isExpandedDateSelector: false,
    showSaveSearchButton: false,
    searchMode: null,
  }

  componentWillUnmount() {
    this.loadStore.searchForm.reset()
    this.loadStore.searchLoads.clear()
  }

  componentWillReceiveProps() {
    if (!this.showSearchByParams()) {
      this.loadStore.searchForm.reset()
      this.loadStore.searchLoads.clear()
    }
  }

  componentDidMount() {
    const { searchParams } = this.props

    // Redirect the search load page to the root load page
    if (window.location.pathname.startsWith('/search/load')) {
      window.location.href = window.location.pathname.replace('/search', '')
    }

    if (searchParams || this.showSearchByParams()) {
      this.loadStore.searchForm.reset()
      this.loadStore.searchForm.update(searchParams || this.urlParams())
      this.performSearch()
    } else {
      this.loadStore.searchForm.reset()
    }

    this.searchStore.fetchSavedSearches()
    this.searchStore.fetchRecentSearches()
    this.loadStore.fetchRecommendedLoads()
  }

  showSearchByParams() {
    return Object.entries(this.urlParams()).length > 0
  }

  urlParams() {
    const { advanced, ...rest } = parse(window.location.search, {
      ignoreQueryPrefix: true,
      decoder: (str, defaultDecoder, _charset, type) => {
        if (type === 'value') {
          if (str === 'true') return true
          if (str === 'false') return false
        }
        return defaultDecoder(str)
      },
    })

    return rest
  }

  get sessionStore() {
    return /** @type {SessionStore} */ (this.props.sessionStore)
  }

  get dashboardStore() {
    return /** @type {DashboardStore} */ (this.props.dashboardStore)
  }

  get loadStore() {
    return /** @type {LoadStore} */ (this.props.loadStore)
  }

  get searchStore() {
    return /** @type {SearchStore} */ (this.props.searchStore)
  }

  handleShowLocationSearchFrom = () => {
    this.setState({
      searchMode: SEARCH_MODES.from,
      isExpandedLocationSearch: true,
    })
  }

  handleShowLocationSearchTo = () => {
    this.setState({
      searchMode: SEARCH_MODES.to,
      isExpandedLocationSearch: true,
    })
  }

  handleCloseLocationSearchForm = () =>
    this.setState({ isExpandedLocationSearch: false })

  handleAdvancedSearchApplyFilters = () => {
    this.performSearch()
  }

  handleShowDateSelector = () => this.setState({ isExpandedDateSelector: true })

  handleCloseDateSelector = () =>
    this.setState({ isExpandedDateSelector: false })

  /** @param {import('src/stores/PlacesStore').PlaceResult} result */
  handleLocationSelect = result => {
    this.setState({ isExpandedLocationSearch: false })

    if (!result) return

    switch (this.state.searchMode) {
      case SEARCH_MODES.from:
        this.resetOriginSearchForm()

        if (result.isState) {
          this.loadStore.searchForm.update({
            originState: result.largeLabel,
            origins: [result.largeLabel],
          })
        } else {
          this.loadStore.searchForm.update({
            originCity: result.detailLabel || '',
            originState: result.largeLabel,
            originGeoCodeLatitude: result.latitude,
            originGeoCodeLongitude: result.longitude,
          })
        }

        return
      case SEARCH_MODES.to:
        this.resetDestinationSearchForm()

        if (result.isState) {
          this.loadStore.searchForm.update({
            destinationState: result.largeLabel,
            destinations: [result.largeLabel],
          })
        } else {
          this.loadStore.searchForm.update({
            destinationCity: result.detailLabel || '',
            destinationState: result.largeLabel,
            destinationGeoCodeLatitude: result.latitude,
            destinationGeoCodeLongitude: result.longitude,
          })
        }
        return
      default:
        throw new Error(`unexpected search mode: "${this.state.searchMode}"`)
    }
  }

  handleAnywhereSelect = () => {
    this.setState({ isExpandedLocationSearch: false })

    switch (this.state.searchMode) {
      case SEARCH_MODES.from:
        this.resetOriginSearchForm()
        this.loadStore.searchForm.update({ originState: 'Anywhere' })
        return
      case SEARCH_MODES.to:
        this.resetDestinationSearchForm()
        this.loadStore.searchForm.update({ destinationState: 'Anywhere' })
        return
      default:
        throw new Error(`unexpected search mode: "${this.state.searchMode}"`)
    }
  }

  resetOriginSearchForm = () => {
    this.loadStore.searchForm.update({
      originCity: '',
      originState: '',
      originGeoCodeLatitude: undefined,
      originGeoCodeLongitude: undefined,
      origins: [],
    })
  }

  resetDestinationSearchForm = () => {
    this.loadStore.searchForm.update({
      destinationCity: '',
      destinationState: '',
      destinationGeoCodeLatitude: undefined,
      destinationGeoCodeLongitude: undefined,
      destinations: [],
    })
  }

  /**
   * @param {object} args
   * @param {Date} args.from
   * @param {Date} args.to
   */
  handleDateSelectorSubmit = ({ from, to }) => {
    this.loadStore.searchForm.update({
      availableFrom: from,
      availableThru: to,
    })
    this.handleCloseDateSelector()
    this.handleSubmit()
  }

  handleSortChange = e => {
    const option = this.loadStore.searchForm.choices.sortOptions[
      e.currentTarget.value
    ]

    this.loadStore.searchForm.update({
      sortField: option.field,
      sortAscending: option.ascending,
      sortKey: option.key,
    })

    this.handleSubmit()
  }

  handleSubmitFormButton = e => {
    e.preventDefault()
    this.handleSubmit()
  }

  handleSubmit = async () => {
    this.searchStore.currentSearchIsSaved = false
    this.setState({ showSaveSearchButton: true })

    this.performSearch()
    await this.searchStore.saveRecentSearch(this.loadStore.searchForm.values)
    this.searchStore.fetchRecentSearches()
  }

  performSearch = async () => {
    navigate(`${searchPath()}?${this.searchValuesAsString()}`, {
      replace: true,
    })

    await Promise.all([
      this.loadStore.searchLoads.fetchFirstPage(),
      this.loadStore.mapSearchLoads.fetchFirstPage(),
    ])
  }

  saveSearch = async () => {
    await this.searchStore.saveSearch(this.loadStore.searchForm.values)
    this.searchStore.fetchSavedSearches()
  }

  searchValuesAsString = () => {
    let paramString = stringify(this.loadStore.searchForm.values)
    const { origins, destinations } = this.loadStore.searchForm.values
    if (origins && origins.length > 0) {
      let [origin] = origins
      paramString = `${paramString}&origins[]=${origin}`
    }

    if (destinations && destinations.length > 0) {
      let [destination] = destinations
      paramString = `${paramString}&destinations[]=${destination}`
    }

    return paramString
  }

  handleStoredSearchClick = async search => {
    // Only allow values in searchForm to be updated
    const values = Object.keys(this.loadStore.searchForm.values).reduce(
      (acc, searchFormValueKey) => {
        const value = search.carrierSearchDto[searchFormValueKey]

        // handle server not having that key or it being set to null
        if (value == null) {
          return acc
        }

        return {
          ...acc,
          [searchFormValueKey]: value,
        }
      },
      {}
    )

    this.loadStore.searchForm.reset()
    this.loadStore.searchForm.update(values)
    this.setState({
      showSaveSearchButton: false,
    })

    window.scrollTo(0, 0)
    this.performSearch()
  }

  render() {
    const { searchLoads, mapSearchLoads, recommendedLoads } = this.loadStore

    const {
      savedSearches,
      recentSearches,
      currentSearchIsSaved,
    } = this.searchStore

    const {
      availableFrom,
      availableThru,
      originCity,
      originState,
      destinationCity,
      destinationState,
      sortKey,
    } = this.loadStore.searchForm.values

    const sortOptions = this.loadStore.searchForm.choices.sortOptions
    const selectedSortOptionIndex =
      sortOptions.findIndex(option => option.key === sortKey) || 0

    const originCitySortOption = sortOptions.find(
      option => option.field == 'OriginCity'
    )
    const originCitySortOptionIndex = originCitySortOption
      ? sortOptions.indexOf(originCitySortOption)
      : 0

    const sortOptionsDisabled = !originCity ? [originCitySortOptionIndex] : [0]

    const {
      searchMode,
      isExpandedLocationSearch,
      isExpandedDateSelector,
      showSaveSearchButton,
    } = this.state

    const isInitialState = searchLoads.totalCount === null
    const noSearchResults = searchLoads.totalCount === 0
    const isMapButtonVisible = !isInitialState && !noSearchResults

    if (isExpandedLocationSearch) {
      if (savedSearches && recentSearches) {
        if (searchMode === null)
          throw new Error('invariant: expected from or to')

        return (
          <LocationSearch
            searchMode={searchMode}
            onClose={this.handleCloseLocationSearchForm}
            onSelect={this.handleLocationSelect}
            onAnywhereClick={this.handleAnywhereSelect}
            savedSearches={savedSearches}
            recentSearches={recentSearches}
            onSearchClick={this.handleStoredSearchClick}
          />
        )
      } else {
        return <LoadingScreen />
      }
    }

    if (isExpandedDateSelector) {
      return (
        <DateSelector
          from={availableFrom}
          to={availableThru}
          onClose={this.handleCloseDateSelector}
          onSubmit={this.handleDateSelectorSubmit}
        />
      )
    }

    return (
      <>
        <MainSearchForm
          onFromClick={this.handleShowLocationSearchFrom}
          onToClick={this.handleShowLocationSearchTo}
          originCity={originCity}
          originState={originState}
          destinationCity={destinationCity}
          destinationState={destinationState}
          onDateClick={this.handleShowDateSelector}
          availableFrom={availableFrom}
          availableThru={availableThru}
          title={this.props.title}
          isMapButtonVisible={isMapButtonVisible}
          showSaveSearchButton={showSaveSearchButton}
          currentSearchIsSaved={currentSearchIsSaved}
          onSaveSearch={this.saveSearch}
          onSubmit={this.handleSubmitFormButton}
          isSearchLoading={searchLoads.isLoading}
          searchValuesAsString={this.searchValuesAsString}
          onApplyFilters={this.handleAdvancedSearchApplyFilters}
        />

        {!isInitialState && (
          <LoadSearchResults
            searchLoads={searchLoads}
            mapSearchLoads={mapSearchLoads}
            sortOptions={sortOptions}
            sortValue={selectedSortOptionIndex}
            sortOptionsDisabled={sortOptionsDisabled}
            onSortChange={this.handleSortChange}
          />
        )}

        {(isInitialState || noSearchResults) && (
          <FindLoadsSplash
            savedSearches={savedSearches}
            recentSearches={recentSearches}
            recommendedLoads={recommendedLoads}
            onSearchClick={this.handleStoredSearchClick}
          />
        )}
      </>
    )
  }
}
export default LoadSearch
