import { Link, navigate } from '@reach/router'
import { format } from 'date-fns'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { LinkButton, PrimaryButton } from 'src/components/Button'
import FieldErrors, { FieldErrorMessages } from 'src/components/FieldErrors'
import InputGroup from 'src/components/InputGroup'
import { Column, Row } from 'src/components/Layout'
import TextArea from 'src/components/TextArea'
import TextInput, { Input, Label } from 'src/components/TextInput'
import { INPUT_DATE } from 'src/data/dateFormats'
import { DATE_INPUT_MAX, DATE_INPUT_MIN } from 'src/data/dateInput'
import DriverStore from 'src/stores/DriverStore'
import ErrorMessage from './ErrorMessage'
import { InputDate } from './InputDate'

/**
 * @typedef {object} Props
 * @property {import('src/stores/DriverStore').default} [driverStore]
 * @property {string} cancelPath
 * @property {string} nextPath
 * @property {Haully.Driver} [driver]
 *
 * @augments {Component<Props>}
 */
@inject('driverStore')
@observer
class AddDriverForm extends Component {
  state = {
    isLoading: false,
  }

  componentDidMount() {
    this.driverStore.addDriverForm.reset()

    if (this.props.driver) {
      const { driver } = this.props

      this.driverStore.addDriverForm.update({
        firstName: driver.firstName || '',
        lastName: driver.lastName || '',
        email: driver.email || '',
        cellPhoneNumber: driver.cellPhoneNumber || '',
        twicCardExpiration: driver.twicCardExpiration
          ? format(driver.twicCardExpiration, INPUT_DATE)
          : '',
        vtasPassword: '',
        notes: driver.notes || '',
      })
    }
  }

  handleBasicChange = e => {
    this.driverStore.addDriverForm.update({
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()

    this.setState({ isLoading: true })
    if (this.props.driver) {
      if (await this.driverStore.submitEditDriverForm(this.props.driver)) {
        navigate(this.props.nextPath)
      }
    } else {
      if (await this.driverStore.submitAddDriverForm()) {
        navigate(this.props.nextPath)
      }
    }
    this.setState({ isLoading: false })
  }

  get driverStore() {
    return /** @type {DriverStore} */ (this.props.driverStore)
  }

  render() {
    const form = this.driverStore.addDriverForm
    const { isLoading } = this.state

    /** @type {({name: keyof typeof form.values, placeholder: string, type?: string, required?: boolean })[]} */
    const basicFields = [
      { name: 'firstName', placeholder: 'First Name', required: true },
      { name: 'lastName', placeholder: 'Last Name', required: true },
      { name: 'email', placeholder: 'Email Address', type: 'email' },
      {
        name: 'cellPhoneNumber',
        placeholder: 'Mobile Phone Number',
        type: 'tel',
      },
    ]

    return (
      <form onSubmit={this.handleSubmit}>
        {basicFields.map((field, i) => (
          <InputGroup key={i}>
            <TextInput
              id={field.name}
              onChange={this.handleBasicChange}
              value={form.getValue(field.name)}
              {...field}
            />
            <FieldErrors field={field.name} errors={form.validationErrors} />
          </InputGroup>
        ))}

        <InputGroup>
          <Label
            htmlFor="twicCardExpiration"
            placeholder="TWIC Expiration Date"
          >
            <InputDate
              value={form.values.twicCardExpiration}
              onChangeText={value => form.update({ twicCardExpiration: value })}
              id="twicCardExpiration"
              min={DATE_INPUT_MIN}
              max={DATE_INPUT_MAX}
            />
          </Label>
          <FieldErrors
            field="twicCardExpiration"
            errors={form.validationErrors}
          />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="vtasPassword" placeholder="V-TAS Password">
            <Input
              type="password"
              value={form.values.vtasPassword}
              onChangeText={value => form.update({ vtasPassword: value })}
              id="vtasPassword"
            />
          </Label>
          <FieldErrors field="vtasPassword" errors={form.validationErrors} />
        </InputGroup>

        <InputGroup>
          <label>
            <p>Notes</p>
            <TextArea
              value={form.getValue('notes')}
              onChange={e => form.update({ notes: e.currentTarget.value })}
              id="notes"
            />
          </label>
          <FieldErrors field="notes" errors={form.validationErrors} />
        </InputGroup>

        <InputGroup>
          <FieldErrors field="root" errors={form.validationErrors} />
          <FieldErrorMessages field="root" errors={form.validationErrors}>
            {() => (
              <ErrorMessage>
                {' '}
                <Link to={this.props.nextPath}>Skip adding driver</Link>
              </ErrorMessage>
            )}
          </FieldErrorMessages>
        </InputGroup>

        <Row>
          <Column>
            <LinkButton
              variant="outline"
              block={true}
              to={this.props.cancelPath}
            >
              Cancel
            </LinkButton>
          </Column>
          <Column>
            <PrimaryButton type="submit" block={true} isLoading={isLoading}>
              {this.props.driver ? 'Update Driver' : 'Add Driver'}
            </PrimaryButton>
          </Column>
        </Row>
      </form>
    )
  }
}

export default AddDriverForm
