import React, { useEffect } from 'react'
import Wrapper from 'src/components/Wrapper'
import PageMeta from 'src/components/PageMeta'
import Box from 'src/components/Box'
import HeaderWithBackButton from 'src/components/HeaderWithBackButton'
import { Heading, Body2, BodyText } from 'src/components/Text'
import { useNotificationSettingsStore } from 'src/hooks/useStores'
import { Observer } from 'mobx-react'
import Flex from 'src/components/Flex'
import Switch from 'src/components/Switch'
import { NOTIFICATION_SETTINGS_DESCRIPTIONS } from 'src/data/company'
import CenteredContainer from 'src/components/CenteredContainer'

const SettingsTextNotifications = () => {
  const notificationSettingsStore = useNotificationSettingsStore()

  useEffect(() => {
    notificationSettingsStore.loadTextNotificationsSettings()
  }, [notificationSettingsStore])

  return (
    <Observer>
      {() => {
        const { textNotificationSettings } = notificationSettingsStore
        if (!textNotificationSettings) return null

        return (
          <Wrapper bg="light-gray">
            <PageMeta title="Notifications" />

            <HeaderWithBackButton label="Notifications" />

            <CenteredContainer width="900" mb={5}>
              <Box p={3} border="bottom">
                <Heading mb={2}>Text Notifications</Heading>
                <Body2>
                  Never miss a load when you sign up for our text notifications.
                  <br />
                  You can turn them off at any time.
                </Body2>
              </Box>

              {Object.keys(textNotificationSettings).map((key, i) => (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  border="bottom"
                  key={i}
                >
                  <Box>
                    <BodyText mb={2}>
                      {NOTIFICATION_SETTINGS_DESCRIPTIONS[key].heading}
                    </BodyText>
                    <Body2>
                      {NOTIFICATION_SETTINGS_DESCRIPTIONS[key].description}
                    </Body2>
                  </Box>

                  <Box ml={3}>
                    <Switch
                      name={key}
                      checked={textNotificationSettings[key]}
                      onToggle={_e =>
                        notificationSettingsStore.toggleTextNotificationSetting(
                          key
                        )
                      }
                    />
                  </Box>
                </Flex>
              ))}
            </CenteredContainer>
          </Wrapper>
        )
      }}
    </Observer>
  )
}

export default SettingsTextNotifications
