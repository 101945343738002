import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import DriverStore from 'src/stores/DriverStore'
import { LoadingMessage } from 'src/components/Loading'
import { DriverCard } from 'src/components/DriverCard'

/**
 * @typedef ADProps
 * @property {import('src/stores/DriverStore').default} [driverStore]
 * @property {Haully.DriverId} driverId
 *
 * @augments Component<ADProps>
 */
@inject('driverStore')
@observer
class AssignedDriverContainer extends Component {
  get driverStore() {
    return /** @type {DriverStore} */ (this.props.driverStore)
  }

  componentDidMount() {
    this.driverStore.loadDriver(this.props.driverId)
  }

  /** @param {ADProps} prevProps */
  componentDidUpdate(prevProps) {
    if (prevProps.driverId !== this.props.driverId) {
      this.driverStore.loadDriver(this.props.driverId)
    }
  }

  render() {
    const driver = this.driverStore.drivers.get(this.props.driverId)
    if (!driver) return <LoadingMessage />
    return <DriverCard driver={driver} />
  }
}

export default AssignedDriverContainer
