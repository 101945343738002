import React from 'react'
import { TabNav, Tab } from './TabNav'
import {
  myAccountPath,
  accountSettingsPath,
  settingsDriversPath,
  settingsFleetPath,
} from 'src/paths'

const MyAccountTabNav = () => (
  <TabNav>
    <Tab to={myAccountPath()}>Account</Tab>
    <Tab to={settingsDriversPath()}>Drivers</Tab>
    <Tab to={settingsFleetPath()}>Fleet</Tab>
    <Tab to={accountSettingsPath()}>Settings</Tab>
  </TabNav>
)

export default MyAccountTabNav
