import React from 'react'
import { observer } from 'mobx-react'
import { LoadingHeading } from './Loading'
import PaginatedRequestState from 'src/models/PaginatedRequestState'
import { Body2 } from './Text'
import { PrimaryButton } from './Button'
import Box from './Box'

/**
 * @param {object} props
 * @param {PaginatedRequestState} props.paginatedRequestState
 * @param {React.ReactNode} props.emptyMessage
 * @param {string} [props.loadMoreText]
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode} [props.footer]
 */
const PaginatedList = ({
  paginatedRequestState,
  emptyMessage,
  children,
  footer,
  loadMoreText = 'Load More',
}) => {
  const { isLoading, collection, isMoreResults } = paginatedRequestState
  if (isLoading && collection.length === 0) {
    return <LoadingHeading />
  }

  if (collection.length === 0) {
    return <ListEmptyMessage>{emptyMessage}</ListEmptyMessage>
  }

  return (
    <>
      {children}
      {isMoreResults && (
        <Box mt={3}>
          <PrimaryButton
            onClick={() => paginatedRequestState.fetchNextPage()}
            disabled={isLoading}
            data-test="PaginatedListLoadMoreButton"
          >
            {loadMoreText}
          </PrimaryButton>
        </Box>
      )}
      {footer}
    </>
  )
}

export const ListEmptyMessage = props => <Body2 color="dark-gray" {...props} />

export default observer(PaginatedList)
