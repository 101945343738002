import React from 'react'
import styles from './LoadCardGrid.css'
import withSpacing from 'src/hocs/withSpacing'
import cn from 'classnames'

/**
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 * @param {string} [props.className]
 * @param {import('src/theme').Breakpoint} [props.breakpoint]
 */
const LoadCardGrid = ({ className, breakpoint, ...props }) => (
  <div className={cn(styles.LoadCardGrid, className)} {...props} />
)

export default withSpacing(LoadCardGrid)
