import cn from 'classnames'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Avatar from 'src/components/Avatar'
import { PrimaryButton } from 'src/components/Button'
import CloseButton from 'src/components/CloseButton'
import Flex from 'src/components/Flex'
import NavigationItem from 'src/components/NavigationItem'
import NavigationSection from 'src/components/NavigationSection'
import { ESC_KEY } from 'src/data/keys'
import { mobileNavLinks } from 'src/data/navLinks'
import { nameOrUsername } from 'src/formatter/user'
import SessionStore from 'src/stores/SessionStore'
import Box from './Box'
import styles from './NavigationMenu.css'

/**
 * @typedef Props
 * @property {SessionStore} [sessionStore] - injected
 * @property {boolean} isExpanded
 * @property {() => void} onRequestClose
 *
 * @augments {Component<Props>}
 */
@inject('sessionStore')
class NavigationMenu extends Component {
  static propTypes = {
    sessionStore: PropTypes.instanceOf(SessionStore).isRequired,
    isExpanded: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  }

  /** @type {React.RefObject<HTMLDivElement>} */
  navigationMenu = React.createRef()

  handleLogout = e => {
    e.preventDefault()
    this.sessionStore.destroySession()
  }

  /** @param {Props} prevProps */
  componentDidUpdate(prevProps) {
    if (
      prevProps.isExpanded !== this.props.isExpanded &&
      this.props.isExpanded
    ) {
      if (!this.navigationMenu.current) throw new Error('invariant')
      this.navigationMenu.current.focus()
    }
  }

  /**
   * https://github.com/mobxjs/mobx-react/issues/256
   * @return {SessionStore}
   */
  get sessionStore() {
    return /** @type {SessionStore} */ (this.props.sessionStore)
  }

  /** @param {React.KeyboardEvent<HTMLDivElement>} e */
  handleKeyDown = e => {
    if (e.keyCode == ESC_KEY) {
      e.stopPropagation()
      this.props.onRequestClose()
    }
  }

  render() {
    const isExpanded = this.props.isExpanded
    const onRequestClose = this.props.onRequestClose
    const { viewer } = this.sessionStore

    return (
      <div>
        <div
          className={cn(
            styles.NavigationOverlay,
            isExpanded ? styles.isExpanded : styles.isCollapsed
          )}
          onClick={this.props.onRequestClose}
        />
        <nav
          ref={this.navigationMenu}
          className={cn(
            styles.NavigationMenu,
            isExpanded ? styles.isExpanded : styles.isCollapsed
          )}
          id="NavigationMenu"
          tabIndex={-1}
          onKeyDown={this.handleKeyDown}
        >
          <Flex
            pr={2}
            justifyContent="space-between"
            className={styles.NavigationMenuHeader}
          >
            {viewer && (
              <div className={styles.NavigationMenuHeaderTitle}>
                <Box mr={2}>
                  <Avatar
                    name={nameOrUsername(viewer)}
                    bg="white"
                    color="light-blue"
                  />
                </Box>
                {nameOrUsername(viewer)}
              </div>
            )}
            <CloseButton title="Close navigation" onClick={onRequestClose} />
          </Flex>

          {mobileNavLinks.map((section, i) => (
            <NavigationSection key={i}>
              {section.map(({ to, label }, ii) => (
                <NavigationItem key={ii} to={to} onClick={onRequestClose}>
                  {label}
                </NavigationItem>
              ))}
            </NavigationSection>
          ))}

          <Flex pl={2}>
            <PrimaryButton variant="primary" onClick={this.handleLogout}>
              Log out
            </PrimaryButton>
          </Flex>
        </nav>
      </div>
    )
  }
}

export default NavigationMenu
