import { format, endOfDay } from 'date-fns'
import React from 'react'
import { WEEKDAY_DAY_MONTH_TIME } from 'src/data/dateFormats'
import { Body2, H2 } from './Text'
import * as LoadNotifications from '../util/loadNotifications'
import { isPast } from 'date-fns'

/**
 * @param {object} props
 * @param {string} props.label
 * @param {string|null} props.pickup
 * @param {string|null} props.delivery
 */
export const LoadTimes = ({ label, pickup, delivery }) => (
  <>
    <H2>{label.toUpperCase()}</H2>

    {pickup ? (
      <Body2 title="Pickup">{format(pickup, WEEKDAY_DAY_MONTH_TIME)}</Body2>
    ) : null}

    {delivery ? (
      <Body2 title="Delivery">{format(delivery, WEEKDAY_DAY_MONTH_TIME)}</Body2>
    ) : null}
  </>
)

/**
 * @param {object} props
 * @param {string} props.label
 * @param {Haully.LoadDetailed} props.load
 * @param {string|null} props.pickup
 * @param {string|null} props.delivery
 * @param {'red'|'dark-gray'|undefined} [props.pickupColor]
 * @param {'red'|'dark-gray'|undefined} [props.deliveryColor]
 */
export const ScheduledLoadTimes = ({
  label,
  load,
  pickup,
  delivery,
  pickupColor,
  deliveryColor,
}) => {
  if (LoadNotifications.isPickupMissed(load)) pickupColor = 'red'
  if (LoadNotifications.isDeliveryMissed(load)) deliveryColor = 'red'

  if (pickup && isPast(endOfDay(pickup))) pickupColor = 'dark-gray'
  if (delivery && isPast(endOfDay(delivery))) deliveryColor = 'dark-gray'

  return (
    <>
      <H2>{label.toUpperCase()}</H2>

      {pickup ? (
        <Body2 title="Pickup" color={pickupColor}>
          {format(pickup, WEEKDAY_DAY_MONTH_TIME)}
        </Body2>
      ) : null}

      {delivery ? (
        <Body2 title="Delivery" color={deliveryColor}>
          {format(delivery, WEEKDAY_DAY_MONTH_TIME)}
        </Body2>
      ) : null}
    </>
  )
}
