/**
 * @param {Blob} blob
 * @param {string} filename
 * @param {string} mimetype
 */
export default function openBlob(blob, filename, mimetype) {
  const blobCopy = new Blob([blob], { type: mimetype })
  const data = URL.createObjectURL(blobCopy)

  const link = document.createElement('a')
  link.href = data
  link.download = filename
  document.body.appendChild(link)
  link.click()
  link.remove()

  setTimeout(() => {
    window.URL.revokeObjectURL(data)
  }, 500)
}

/**
 * @param {string} tabText
 */
function csvBlob(tabText) {
  return new Blob([tabText], {
    type: 'text/csv;charset=utf-8;',
  })
}

/**
 * @param {string} csvText
 * @param {string} filename
 */
export function openCsv(csvText, filename) {
  return openBlob(csvBlob(csvText), filename, 'text/csv')
}
