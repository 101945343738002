import { observer } from 'mobx-react'
import React from 'react'
import Avatar from 'src/components/Avatar'
import { BoxButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import { fullName } from 'src/formatter/driver'
import Box from './Box'
import { Body1, Small, Body2 } from './Text'

/**
 * @typedef {Object} DCProps
 * @property {{ email: string|null, firstName: string|null, lastName: string|null, driverId?: number|null, enabled?: boolean|null }} driver
 * @property {'light-blue'|'black'} [avatarColor]
 * @property {boolean} [showDriverId]
 *
 * @param {DCProps} props
 */
const _DriverCard = ({
  driver,
  avatarColor = 'light-blue',
  showDriverId = false,
}) => (
  <Flex justifyContent="space-between">
    <Flex alignItems="center">
      <Box pt={1} pb={1} mr={3}>
        <Avatar
          name={fullName(driver)}
          bg={driver.enabled ? avatarColor : 'light-gray-blue'}
          color={'white'}
        />
      </Box>
      <div>
        <Body1 data-test="DriverCardName">
          <DriverName driver={driver} />
        </Body1>
        {driver.email && <Small>{driver.email}</Small>}
      </div>
    </Flex>
    {showDriverId && (
      <Flex flexDirection="column">
        <Body2 style={{ lineHeight: 1 }}>DRIVER ID</Body2>
        <Body2>{driver.driverId}</Body2>
        {!driver.enabled && (
          <Small mt={1} color="dark-gray">
            Deactivated
          </Small>
        )}
      </Flex>
    )}
  </Flex>
)
export const DriverCard = observer(_DriverCard)

/**
 * @typedef {Object} DCBProps
 * @property {(e: React.MouseEvent) => void} props.onClick
 * @property {string} [className]
 * @property {boolean|undefined} [disabled]
 * @property {boolean} [isActive]
 *
 * @param {DCBProps & DCProps} props
 */
export const DriverCardButton = ({
  onClick,
  className,
  disabled,
  isActive,
  ...rest
}) => {
  return (
    <BoxButton
      onClick={onClick}
      testID="DriverCard"
      className={className}
      disabled={disabled}
      bg={disabled ? 'light-gray' : isActive ? 'light-blue' : undefined}
      color={disabled ? 'black' : isActive ? 'white' : undefined}
    >
      <Box p={3} border="bottom">
        <DriverCard {...rest} />
      </Box>
    </BoxButton>
  )
}

/**
 * @param {Object} props
 * @param {{firstName: string|null, lastName: string|null}} props.driver
 */
export const DriverName = ({ driver }) => {
  const isNameBlank = !(driver.firstName || driver.lastName)
  if (isNameBlank) return <>Unnamed</>
  return (
    <>
      {driver.firstName} {driver.lastName}
    </>
  )
}
