import React from 'react'
import styles from './OverlayButton.css'
import { PrimaryButton } from './Button'

/**
 * @param {{ buttonText: string } & React.ButtonHTMLAttributes} props
 */
const OverlayButton = ({ children, buttonText, ...rest }) => (
  <div className={styles.OverlayButton}>
    {children}
    <div className={styles.OverlayButtonOverlay}>
      <PrimaryButton {...rest}>{buttonText}</PrimaryButton>
    </div>
  </div>
)

export default OverlayButton
