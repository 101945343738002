import { Redirect, Router, globalHistory } from '@reach/router'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import { hot } from 'react-hot-loader'
import FindLoads from 'src/screens/FindLoads'
import LoadsMapScreen from 'src/screens/LoadsMapScreen'
import OnboardAddDriver from 'src/screens/OnboardAddDriver'
import OnboardAddDriverConfirm from 'src/screens/OnboardAddDriverConfirm'
import OnboardAddHauler from 'src/screens/OnboardAddHauler'
import OnboardConfirmInfo from 'src/screens/OnboardConfirmInfo'
import OnboardEnd from 'src/screens/OnboardEnd'
import OnboardHaulDrivePref from 'src/screens/OnboardHaulDrivePref'
import OnboardHaulEquipment from 'src/screens/OnboardHaulEquipment'
import OnboardLoadsPref from 'src/screens/OnboardLoadsPref'
import OnboardLocation from 'src/screens/OnboardLocation'
import OnboardWelcome from 'src/screens/OnboardWelcome'
import RegisterBusinessLocation from 'src/screens/Register/BusinessLocation'
import RegisterConfirmation from 'src/screens/Register/Confirmation'
import RegisterFleet from 'src/screens/Register/Fleet'
import RegisterInsuranceDetails from 'src/screens/Register/InsuranceDetails'
import RegisterWelcome from 'src/screens/Register/Welcome'
import Signup from 'src/screens/Signup'
import Todo from 'src/screens/Todo'
import SessionStore from 'src/stores/SessionStore'
import { QueryParamProvider } from 'use-query-params'
import styles from './Routes.css'
import LoadingScreen from './components/Loading'
import * as paths from './paths'
import AccountSettings from './screens/AccountSettings'
import BusinessInfo from './screens/BusinessInfo'
import ComingSoon from './screens/ComingSoon'
import Config from './screens/Config'
import ContactDispatch from './screens/ContactDispatch'
import ContactUs from './screens/ContactUs'
import Dashboard from './screens/Dashboard'
import EditProfile from './screens/EditProfile'
import ExpiredPassword from './screens/ExpiredPassword'
import FAQ from './screens/FAQ'
import InvoiceDetail from './screens/InvoiceDetail'
import Invoices from './screens/Invoices'
import InvoicesPaid from './screens/InvoicesPaid'
import InvoicesPending from './screens/InvoicesPending'
import LegalInformation from './screens/LegalInformation'
import LoadAssign from './screens/LoadAssign'
import LoadAssignDetails from './screens/LoadAssignDetails'
import LoadAssignDriver from './screens/LoadAssignDriver'
import LoadBackhauls from './screens/LoadBackhauls'
import LoadDetail from './screens/LoadDetail'
import LoadEditDriver from './screens/LoadEditDriver'
import LoadEditPickupDropoff from './screens/LoadEditPickupDropoff'
import LoadsClaimed from './screens/LoadsClaimed'
import LoadsCompleted from './screens/LoadsCompleted'
import LoadsInProgress from './screens/LoadsInProgress'
import LoadsSaved from './screens/LoadsSaved'
import MarketingLanding from './screens/MarketingLanding'
import MyAccount from './screens/MyAccount'
import MyDocuments from './screens/MyDocuments'
import NotFound from './screens/NotFound'
import PaymentPreferences from './screens/PaymentPreferences'
import Reset from './screens/Reset'
import ResetPassword from './screens/ResetPassword'
import SettingsAddDriver from './screens/SettingsAddDriver'
import SettingsAddHauler from './screens/SettingsAddHauler'
import SettingsDrivers from './screens/SettingsDrivers'
import SettingsEditDriver from './screens/SettingsEditDriver'
import SettingsEditHauler from './screens/SettingsEditHauler'
import SettingsEmailNotifications from './screens/SettingsEmailNotifications'
import SettingsFleet from './screens/SettingsFleet'
import SettingsTextNotifications from './screens/SettingsTextNotifications'
import Styleguide from './screens/Styleguide'
import TipsAndTools from './screens/TipsAndTools'
import VideoResource from './screens/VideoResource'

/** @type {{ path: string, component: React.ReactNode}[]} */
const AUTHENTICATED_ROUTES = [
  { path: paths.invoicesPath(), component: Invoices },
  { path: paths.onboardWelcomePath(), component: OnboardWelcome },
  { path: paths.onboardConfirmInfoPath(), component: OnboardConfirmInfo },
  { path: paths.onboardAddHaulerPath(), component: OnboardAddHauler },
  { path: paths.onboardHaulEquipmentPath(), component: OnboardHaulEquipment },
  { path: paths.onboardAddDriverPath(), component: OnboardAddDriver },
  {
    path: paths.onboardAddDriverConfirmPath(),
    component: OnboardAddDriverConfirm,
  },
  { path: paths.onboardHaulDrivePrefPath(), component: OnboardHaulDrivePref },
  { path: paths.onboardLocationPath(), component: OnboardLocation },
  { path: paths.onboardLoadsPrefPath(), component: OnboardLoadsPref },
  { path: paths.onboardEndPath(), component: OnboardEnd },
  { path: paths.loadPath(':loadId'), component: LoadDetail },
  {
    path: `${paths.searchPath()}/*`,
    component: FindLoads,
  },
  { path: paths.searchMapPath(), component: LoadsMapScreen },
  { path: paths.loadsSavedPath(), component: LoadsSaved },
  { path: paths.loadAssignPath(':loadId'), component: LoadAssign },
  { path: paths.loadAssignDriverPath(':loadId'), component: LoadAssignDriver },
  { path: paths.loadEditDriverPath(':loadId'), component: LoadEditDriver },
  {
    path: paths.loadEditPickupDropoffPath(':loadId'),
    component: LoadEditPickupDropoff,
  },
  {
    path: paths.loadAssignDetailsPath(':loadId'),
    component: LoadAssignDetails,
  },
  {
    path: paths.loadBackhaulPath(':loadId'),
    component: LoadBackhauls,
  },
  { path: paths.loadsClaimedPath(), component: LoadsClaimed },
  { path: paths.loadsInProgressPath(), component: LoadsInProgress },
  { path: paths.loadsCompletedPath(), component: LoadsCompleted },
  { path: paths.myAccountPath(), component: MyAccount },
  { path: paths.editProfilePath(), component: EditProfile },
  { path: paths.accountSettingsPath(), component: AccountSettings },
  { path: paths.settingsDriversPath(), component: SettingsDrivers },
  { path: paths.settingsFleetPath(), component: SettingsFleet },
  { path: paths.businessInfoPath(), component: BusinessInfo },
  { path: paths.settingsAddDriverPath(), component: SettingsAddDriver },
  {
    path: paths.settingsEditDriverPath(':driverId'),
    component: SettingsEditDriver,
  },
  { path: paths.settingsAddHaulerPath(), component: SettingsAddHauler },
  {
    path: paths.settingsEditHaulerPath(':truckId'),
    component: SettingsEditHauler,
  },
  { path: paths.invoicesPaidPath(), component: InvoicesPaid },
  { path: paths.invoicesPendingPath(), component: InvoicesPending },
  { path: paths.invoiceDetailsPath(':orderId'), component: InvoiceDetail },
  { path: paths.contactUsPath(), component: ContactUs },
  { path: paths.contactDispatchPath(), component: ContactDispatch },
  { path: paths.legalInformationPath(), component: LegalInformation },
  { path: paths.myDocumentsPath(), component: MyDocuments },
  {
    path: paths.settingsEmailNotificationsPath(),
    component: SettingsEmailNotifications,
  },
  {
    path: paths.settingsTextNotificationsPath(),
    component: SettingsTextNotifications,
  },
  {
    path: paths.paymentPreferencesPath(),
    component: PaymentPreferences,
  },
  {
    path: paths.dashboardPath(),
    component: Dashboard,
  },
]

const UNAUTHENTICATED_ROUTES = [
  { path: paths.loginPath(), component: MarketingLanding },
  {
    path: paths.registerWelcomePath(':registrationCode'),
    component: RegisterWelcome,
  },
  {
    path: paths.registerBusinessLocationPath(),
    component: RegisterBusinessLocation,
  },
  {
    path: paths.registerInsuranceDetailsPath(),
    component: RegisterInsuranceDetails,
  },
  {
    path: paths.registerFleetPath(),
    component: RegisterFleet,
  },
  {
    path: paths.registerConfirmationPath(),
    component: RegisterConfirmation,
  },
]

const Routes = ({ sessionStore, ...props }) => (
  <QueryParamProvider reachHistory={globalHistory}>
    <Router className={styles.Routes} {...props}>
      <MarketingLanding path={paths.welcomePath()} />

      {AUTHENTICATED_ROUTES.map(route => (
        <AuthenticatedRoute
          key={route.path}
          isAuthenticated={sessionStore.isLoggedIn}
          viewer={sessionStore.viewer}
          {...route}
        />
      ))}

      {UNAUTHENTICATED_ROUTES.map(route => (
        <UnauthenticatedRoute
          key={route.path}
          isAuthenticated={sessionStore.isLoggedIn}
          {...route}
        />
      ))}

      <Signup path={paths.signupPath()} />
      <Todo path={paths.todoPath()} />
      <Styleguide path="styleguide" />
      <Config path={paths.configPath()} />
      <Reset path={paths.resetPath()} />
      <ResetPassword path={paths.resetPasswordPath()} />
      <ExpiredPassword path={paths.expiredPasswordPath()} />
      <ComingSoon path={paths.comingSoonPath()} />
      <TipsAndTools path={paths.tipsAndToolsPath()} />
      <FAQ path={paths.faqPath()} />
      <VideoResource path={paths.videoResourcePath(':slug')} />
      <Redirect path="endpoint" from="endpoint" to="config" noThrow={true} />
      <NotFound default />
    </Router>
  </QueryParamProvider>
)

Routes.propTypes = {
  sessionStore: PropTypes.instanceOf(SessionStore),
}

const AuthenticatedRoute = ({
  isAuthenticated,
  viewer,
  component,
  ...props
}) => {
  if (!isAuthenticated) {
    return (
      <Redirect
        noThrow={true}
        to={paths.loginPath({
          redirectTo: `${props.location.pathname}${props.location.search}`,
        })}
      />
    )
  }

  if (!viewer) {
    return <LoadingScreen />
  }

  return React.createElement(component, props)
}

const UnauthenticatedRoute = ({ isAuthenticated, component, ...props }) => {
  if (isAuthenticated) {
    return <Redirect noThrow={true} to={paths.searchPath()} />
  }

  return React.createElement(component, props)
}

export default hot(module)(observer(Routes))
