import React from 'react'
import styles from './HeadingContainer.css'
import cn from 'classnames'
import withBackground from 'src/hocs/withBackground'

/**
 * @typedef {object} HeadingContainerProps
 * @property {'center'|'table'|'bar'} [theme]
 */

/** @param {HeadingContainerProps & React.HTMLAttributes} props */
const HeadingContainer = ({ className, theme = 'center', ...props }) => (
  <div
    className={cn(className, styles.HeadingContainer, styles[`theme-${theme}`])}
    {...props}
  />
)

export default withBackground(HeadingContainer)
