import { format } from 'date-fns'
import { inject, observer } from 'mobx-react'
import { parse } from 'qs'
import React, { Component } from 'react'
import { LinkButton } from 'src/components/Button'
import LoadingScreen from 'src/components/Loading'
import styles from 'src/components/Map.css'
import {
  OverlayHeader,
  OverlayHeaderContent,
  OverlayHeaderLeftButton,
} from 'src/components/OverlayHeader'
import { Screen } from 'src/components/Screen'
import { BodyText } from 'src/components/Text'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import { searchPath } from 'src/paths'
import LoadStore from 'src/stores/LoadStore'
import cityState from 'src/util/cityState'
import LoadsMap from './LoadsMap'

/**
 * @typedef Props
 * @property {object} location
 * @property {LoadStore} [loadStore]
 *
 * @augments Component<Props>
 */
@inject('loadStore')
@observer
class LoadsMapScreen extends Component {
  componentDidMount() {
    this.loadStore.searchForm.reset()
    this.loadStore.searchForm.update(this.urlParams())
    this.performSearch()
  }

  urlParams() {
    return parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
  }
  async performSearch() {
    await this.loadStore.mapSearchLoads.fetchFirstPage()
  }

  get loadStore() {
    return /** @type {LoadStore} */ (this.props.loadStore)
  }

  render() {
    const {
      availableFrom,
      availableThru,
      originCity,
      originState,
      destinationCity,
      destinationState,
    } = this.urlParams()

    const { mapSearchLoads } = this.loadStore
    const isSearchLoading = mapSearchLoads.isLoading

    if (isSearchLoading) return <LoadingScreen />

    return (
      <Screen>
        <OverlayHeader>
          <OverlayHeaderLeftButton>
            <LinkButton to={searchPath(this.urlParams())}>
              <LeftArrowIcon className={styles.FillCurrentColor} />
            </LinkButton>
          </OverlayHeaderLeftButton>
          <OverlayHeaderContent>
            <BodyText>
              {cityState(originCity, originState)} -{' '}
              {cityState(destinationCity, destinationState)}
            </BodyText>
            <BodyText>
              {availableFrom &&
                availableThru &&
                [
                  format(availableFrom, 'M/D'),
                  ' - ',
                  format(availableThru, 'M/D'),
                ].join('')}
            </BodyText>
          </OverlayHeaderContent>
        </OverlayHeader>
        <LoadsMap loads={mapSearchLoads.collection} />
      </Screen>
    )
  }
}

export default LoadsMapScreen
