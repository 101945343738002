import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { BlockListbox } from 'src/components/BlockSelect'
import { BlockButton } from 'src/components/Button'
import Flex from 'src/components/Flex'
import { Heading } from 'src/components/Text'
import LoadStore from 'src/stores/LoadStore'
import styles from './AdvancedSearch.css'
import { range } from 'lodash'
import { formatCurrency } from 'src/util/formatCurrency'

/**
 * mutates global loadStore.searchForm
 *
 * @typedef Props
 * @property {() => void} onApplyFilters -
 * @property {LoadStore} [loadStore]
 * @property {string} [className]
 *
 * @augments {Component<Props>}
 */
@inject('loadStore')
@observer
class AdvancedSearch extends Component {
  state = {}

  componentDidMount() {
    this.storeInitialFormFieldValues()
  }

  storeInitialFormFieldValues() {
    ADVANCED_SEARCH_FIELDS.map(({ id }) =>
      this.setState({ [id]: this.loadStore.searchForm.values[id] })
    )
    this.setState({
      enclosedTruckRequired: this.loadStore.searchForm.values
        .enclosedTruckRequired,
    })
  }

  resetToInitialFormFieldValues = () => {
    ADVANCED_SEARCH_FIELDS.map(
      ({ id }) => (this.loadStore.searchForm.values[id] = this.state[id])
    )
    this.loadStore.searchForm.values.enclosedTruckRequired = this.state.enclosedTruckRequired
  }

  handleBasicNumberChange = e => {
    const value = e.currentTarget.value
    const period = value.indexOf('.')
    const trimmed = period !== -1 ? value.substring(0, period + 3) : value
    this.loadStore.searchForm.update({
      [e.currentTarget.name]: trimmed,
    })
  }

  get loadStore() {
    return /** @type {LoadStore} */ (this.props.loadStore)
  }

  handleToggleChoice = e => {
    const value = e.currentTarget.value === 'true' ? true : false
    this.loadStore.searchForm.update({
      [e.currentTarget.name]: value,
    })
  }

  handleToggleChoiceString = e => {
    this.loadStore.searchForm.update({
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  render() {
    const { onApplyFilters } = this.props
    const { searchForm } = this.loadStore

    return (
      <div className={this.props.className}>
        <Heading className={styles.heading}>Advanced Search Options</Heading>

        <div className={styles.advancedSearchGrid}>
          <BlockListbox
            title="Minimum Price Per Mile"
            options={[...range(0, 3, 0.5), 3].reduce((acc, val) => {
              acc.set(val.toString(), formatCurrency(val))
              return acc
            }, new Map())}
            value={searchForm.values.minPricePerMile || 'default'}
            onChange={value => searchForm.update({ minPricePerMile: value })}
          />
          <BlockListbox
            title="Maximum Vehicles"
            options={[...range(1, 10), 10].reduce((acc, val) => {
              acc.set(val.toString(), val.toString())
              return acc
            }, new Map())}
            value={searchForm.values.maxVehicles || 'default'}
            onChange={value => searchForm.update({ maxVehicles: value })}
          />
          <BlockListbox
            title="Maximum Weight"
            options={[...range(0, 40000, 5000), 40000].reduce((acc, val) => {
              acc.set(val.toString(), `${val.toLocaleString()} lbs`)
              return acc
            }, new Map())}
            value={searchForm.values.maxWeightLbs || 'default'}
            onChange={value => searchForm.update({ maxWeightLbs: value })}
          />
          <BlockListbox
            title="Origin Radius (Miles)"
            options={[...range(25, 150, 25), 150].reduce((acc, val) => {
              acc.set(val.toString(), `${val} MILES`)
              return acc
            }, new Map())}
            value={searchForm.values.originGeoCodeMileRadius || 'default'}
            onChange={value =>
              searchForm.update({ originGeoCodeMileRadius: value })
            }
          />
          <BlockListbox
            title="Destination Radius (Miles)"
            options={[...range(25, 150, 25), 150].reduce((acc, val) => {
              acc.set(val.toString(), `${val} MILES`)
              return acc
            }, new Map())}
            value={searchForm.values.destinationGeoCodeMileRadius || 'default'}
            onChange={value =>
              searchForm.update({ destinationGeoCodeMileRadius: value })
            }
          />
          <Flex>
            <BlockListbox
              title="Haul Type"
              options={
                new Map(
                  Object.entries({
                    true: 'Enclosed',
                    false: 'Open',
                  })
                )
              }
              value={
                searchForm.values.enclosedTruckRequired == null
                  ? 'default'
                  : searchForm.values.enclosedTruckRequired
                  ? 'true'
                  : 'false'
              }
              onChange={value =>
                searchForm.update({
                  enclosedTruckRequired: { true: true, false: false }[value],
                })
              }
              style={{ flex: 1, marginRight: 1 }}
            />
            <BlockListbox
              title="Vehicle Type"
              options={
                new Map(
                  Object.entries({
                    Any: 'All',
                    Operable: 'Operable',
                    InOperable: 'InOperable',
                  })
                )
              }
              value={searchForm.values.inOperableVehiclesQueryType || 'default'}
              onChange={value =>
                searchForm.update({
                  inOperableVehiclesQueryType: value,
                })
              }
              style={{ flex: 1 }}
            />
          </Flex>
        </div>

        <Flex mb={3}>
          <BlockButton
            onClick={onApplyFilters}
            bg="light-blue"
            color="white"
            style={{ flex: 1 }}
          >
            APPLY ADVANCED SEARCH FILTERS
          </BlockButton>
        </Flex>
        <Flex mb={3}>
          <BlockButton
            onClick={this.resetToInitialFormFieldValues}
            bg="transparent"
            style={{ flex: 1, fontSize: 12, color: 'rgba(0, 0, 0, 0.5)' }}
          >
            CLEAR ADVANCED FILTERS
          </BlockButton>
        </Flex>
      </div>
    )
  }
}

const ADVANCED_SEARCH_FIELDS = [
  { id: 'minPricePerMile' },
  { id: 'maxVehicles' },
  { id: 'maxWeightLbs' },
  { id: 'originGeoCodeMileRadius' },
  { id: 'destinationGeoCodeMileRadius' },
  { id: 'inOperableVehiclesQueryType' },
]

export default AdvancedSearch
