import cn from 'classnames'
import React from 'react'
import useWindowSize from 'src/hooks/useWindowSize'
import styles from './CenteredContainer.css'
import withSpacing from 'src/hocs/withSpacing'

/**
 * @param {object} props
 * @param {import('src/theme').Breakpoint} [props.breakpoint]
 * @param {React.ReactNode} [props.children]
 * @param {"460"|"600"|"900"|"1050"|undefined} [props.width]
 * @param {boolean} [props.centerText]
 * @param {string} [props.className]
 */
const CenteredContainer = props => {
  const { isLarge, isMedium } = useWindowSize()

  if (props.breakpoint === 'large' && !isLarge) return <>{props.children}</>
  if (props.breakpoint === 'medium' && !isMedium) return <>{props.children}</>

  return (
    <div
      className={cn(props.className, styles.CenteredContainer, {
        [styles[`CenteredContainer-width-${props.width}`]]: props.width,
        [styles[`CenteredContainer-centerText`]]: props.centerText,
      })}
    >
      {props.children}
    </div>
  )
}

export default withSpacing(CenteredContainer)
