import React, { Component } from 'react'
import PageMeta from 'src/components/PageMeta'
import { PlainButton } from 'src/components/Button'
import { Screen } from 'src/components/Screen'
import { BodyText } from 'src/components/Text'
import { settingsFleetPath } from 'src/paths'
import {
  OverlayHeader,
  OverlayHeaderContent,
  OverlayHeaderLeftButton,
} from 'src/components/OverlayHeader'
import LeftArrowIcon from 'src/icons/LeftArrow.svg'
import { navigate } from '@reach/router'
import styles from 'src/components/Settings.css'
import Box from 'src/components/Box'
import AddHaulerForm from 'src/components/AddHaulerForm'
import CenteredContainer from 'src/components/CenteredContainer'

class SettingsAddHauler extends Component {
  get returnPath() {
    return settingsFleetPath()
  }

  render() {
    return (
      <Screen>
        <PageMeta title="Add a Truck" section="Drivers and Fleet" />

        <OverlayHeader>
          <OverlayHeaderLeftButton>
            <PlainButton onClick={() => navigate(this.returnPath)}>
              <LeftArrowIcon className={styles.FillCurrentColor} />
            </PlainButton>
          </OverlayHeaderLeftButton>
          <OverlayHeaderContent>
            <BodyText>Fleet</BodyText>
          </OverlayHeaderContent>
        </OverlayHeader>

        <CenteredContainer width="460">
          <Box pt={3} pb={3}>
            <AddHaulerForm
              nextPath={this.returnPath}
              cancelPath={this.returnPath}
            />
          </Box>
        </CenteredContainer>
      </Screen>
    )
  }
}

export default SettingsAddHauler
