import React from 'react'
import styles from './Select.css'
import DropdownIcon from 'src/icons/Dropdown.svg'
import cn from 'classnames'

/** @param {React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>} props */
export const Select = props => (
  <div className={cn(styles.container, props.disabled && styles.disabled)}>
    <select className={styles.select} {...props} />
    <DropdownIcon className={styles.DropdownIcon} />
  </div>
)

/** @param {React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>} props */
export const PlainSelect = props => (
  <div className={styles.container}>
    <select className={styles.plainSelect} {...props} />
    <DropdownIcon className={styles.DropdownIcon} />
  </div>
)

/**
 * simply proxies option for now, but abstracting this in case
 * we want styled options in the future
 *
 * @param {React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>} props
 */
export const Option = props => <option {...props} />

export const OptionBlank = props => (
  <option value="" {...props}>
    Choose one
  </option>
)
