import React, { useEffect, useState } from 'react'
import { CallToActionBasic } from 'src/components/CallToAction'
import { contactDispatchPath } from 'src/paths'
import { useTruckStore } from 'src/hooks/useStores'

const NotificationTrucksFull = () => {
  const truckStore = useTruckStore()
  const [isNotificationVisible, setIsNotificationVisible] = useState(false)

  useEffect(() => {
    truckStore.getCarrierVehicleCount().then(result => {
      if (result && result.allotmentRemaining === 0)
        setIsNotificationVisible(true)
    })
  }, [truckStore])

  if (!isNotificationVisible) return null

  return (
    <CallToActionBasic
      title="Congratulations! It looks like your trucks are full!"
      description="If you think you still have room for loads today or tomorrow contact dispatch and we will get you on the road."
      actionLabel="Contact Dispatch"
      actionTo={contactDispatchPath()}
    />
  )
}
export default NotificationTrucksFull
