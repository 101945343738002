import { INPUT_DATE } from 'src/data/dateFormats'
import { format } from 'date-fns'

/**
 * @param {string} filename
 * @param {Date|string} date
 * @returns {string}
 */
export function filenameWithDate(filename, date = new Date()) {
  const pieces = filename.split('.').reverse()
  const dateMsg = format(date, INPUT_DATE)
  if (pieces.length === 1) return `${pieces[0]} ${dateMsg}`
  const [end, ...start] = pieces
  return `${start.join('')} ${dateMsg}.${end}`
}
