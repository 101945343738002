import { stringify, parse } from 'qs'

/**
 * @param {string} href
 * @param {{[x: string]: any}} params
 */
export function addParams(href, params) {
  const [pathname, search] = href.split('?')

  const existingParams = search
    ? parse(search, { ignoreQueryPrefix: true })
    : {}

  const queryString = stringify(
    { ...existingParams, ...params },
    { addQueryPrefix: true }
  )

  return pathname + queryString
}
