import React from 'react'
import styles from './Layout.css'
import cn from 'classnames'
import withSpacing from 'src/hocs/withSpacing'
import Flex from './Flex'

/**
 * @param {{center?: boolean, className?: string} & import('./Flex').FlexProps} props
 */
const Row = ({ className, center = false, ...props }) => (
  <Flex
    className={cn(className, styles.Row, {
      [styles.center]: center,
    })}
    {...props}
  />
)

const DecoratedRow = withSpacing(Row)
export { DecoratedRow as Row }

/** @param {{ width?: 'equal'|'auto'} & React.HTMLAttributes} props */
export const Column = ({ width = 'equal', ...props }) => (
  <div className={cn(styles.Column, styles[`width-${width}`])} {...props} />
)
