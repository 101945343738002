import React from 'react'
import ErrorMessage from 'src/components/ErrorMessage'
import { observer } from 'mobx-react'

/**
 * @param {object} props
 * @param {string} props.field
 * @param {({ messages }: { messages: string[] }) => React.ReactNode} props.children
 * @param {import('src/models/Form').ValidationError[]} props.errors
 */
const _FieldErrorMessages = ({ field, errors, children }) => {
  const fieldErrors = errors.filter(error => error.field === field)
  if (fieldErrors.length === 0) return null

  return (
    <>
      <ErrorMessage>
        {children({ messages: fieldErrors.map(({ message }) => message) })}
      </ErrorMessage>
    </>
  )
}
export const FieldErrorMessages = observer(_FieldErrorMessages)

/**
 * @param {object} props
 * @param {string} props.field
 * @param {import('src/models/Form').ValidationError[]} props.errors
 */
const FieldErrors = props => (
  <FieldErrorMessages {...props}>
    {({ messages }) => messages.join(', ')}
  </FieldErrorMessages>
)

export default FieldErrors
