import React from 'react'
import styles from './RadioButton.css'
import cn from 'classnames'
import SmallCheckIcon from 'src/icons/SmallCheck.svg'
import Flex from './Flex'

/**
 * @param {Object} props
 * @param {boolean} props.isActive
 * @param {string} props.title
 * @param {() => void} props.onPress
 * @param {boolean} [props.isFirst]
 * @param {boolean} [props.isLast]
 * @param {string} [props.testID]
 */
export const RadioButton = props => (
  <button
    type="button"
    onClick={() => props.onPress()}
    className={cn(styles.RadioButton, {
      [styles.RadioButtonActive]: props.isActive,
      [styles.RadioButtonIsFirst]: props.isFirst,
      [styles.RadioButtonIsLast]: props.isLast,
    })}
    data-test={props.testID}
  >
    <Flex alignItems="center">
      {props.isActive && <SmallCheckIcon className={styles.SmallCheckIcon} />}
      <div>{props.title}</div>
    </Flex>
  </button>
)
