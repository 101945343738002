import React from 'react'
import PaginatedRequestState from 'src/models/PaginatedRequestState'
import { observer } from 'mobx-react'

export const CollectionCount = observer(
  /**
   * @param {object} props
   * @param {PaginatedRequestState} props.paginatedRequestState
   * @param {string} props.labelSingular
   * @param {string} props.labelPlural
   */
  props => {
    const { totalCount } = props.paginatedRequestState
    return (
      <div>
        {totalCount != null ? (
          <>
            {totalCount.toLocaleString()}{' '}
            {totalCount === 1
              ? props.labelSingular.toUpperCase()
              : props.labelPlural.toUpperCase()}
          </>
        ) : (
          <>&nbsp;</>
        )}
      </div>
    )
  }
)

/**
 * @param {object} props
 * @param {PaginatedRequestState} props.paginatedRequestState
 */
export const LoadCollectionCount = props => (
  <CollectionCount
    paginatedRequestState={props.paginatedRequestState}
    labelSingular={`Load`}
    labelPlural={`Loads`}
  />
)
