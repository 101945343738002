import GoogleMapsApiLoader from 'google-maps-api-loader'
import { computed, observable } from 'mobx'
import Store from './Store'
import { env } from 'src/util/env'

/**
 * @typedef PlaceResult
 * @property {string} largeLabel
 * @property {string|null} detailLabel
 * @property {boolean} isState
 * @property {number} [latitude]
 * @property {number} [longitude]
 */

class PlacesStore extends Store {
  /** @type {import('mobx').IObservableArray<google.maps.places.AutocompletePrediction>} */
  suggestions = observable.array([])

  /** @type {boolean} */
  @observable isLoadingMaps

  /** @type {google.maps.places.AutocompleteService?} */
  autocompleteService = null

  /** @type {google.maps.Geocoder?} */
  geocoder = null

  /** @type {typeof google | null} */
  @observable google

  constructor(rootStore) {
    super(rootStore)
    this.loadGoogle()
  }

  loadGoogle = () => {
    return new Promise((resolve, reject) => {
      this.isLoadingMaps = true
      GoogleMapsApiLoader({
        libraries: ['places'],
        apiKey: env('GOOGLE_MAPS_API_KEY'),
      }).then(
        googleApi => {
          this.google = googleApi
          this.autocompleteService = new googleApi.maps.places.AutocompleteService()
          this.geocoder = new googleApi.maps.Geocoder()

          this.isLoadingMaps = false
          resolve()
        },
        error => {
          this.rootStore.errorStore.addError(
            `Failed to load google maps. ${error.message}`,
            {
              report: true,
              context: { error, message: error.message },
            }
          )
          reject(error)
        }
      )
    })
  }

  fetchPredictions = value => {
    if (!this.autocompleteService)
      throw new Error(
        `Attempted to fetch predictions before autocomplete service is loaded`
      )

    if (value.length >= 2) {
      /**
       * https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteService.getPlacePredictions
       */
      this.autocompleteService.getPlacePredictions(
        /**
         * https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
         */
        {
          input: value,

          // docs on types:
          // https://developers.google.com/places/supported_types#table3
          types: ['(regions)'],

          componentRestrictions: { country: ['us', 'ca'] },
        },
        (predictions, status) => {
          if (status === 'OK' || status === 'ZERO_RESULTS') {
            this.suggestions.replace(predictions || [])
          } else {
            this.rootStore.errorStore.addError(
              `Failed to load location suggestions (${status})`,
              { report: true }
            )
          }
        }
      )
    } else {
      this.reset()
    }
  }

  /**
   * @param {google.maps.GeocoderRequest} args
   * @returns {Promise<google.maps.GeocoderResult[]>}
   */
  geocodeAsync = args =>
    new Promise((resolve, reject) => {
      if (!this.geocoder) throw new Error('invariant: expected geocoder')
      this.geocoder.geocode(
        args,
        /**
         * @param {google.maps.GeocoderResult[]} results
         * @param {google.maps.GeocoderStatus} status
         */
        (results, status) => {
          if (status === 'OK') {
            resolve(results)
          } else {
            reject(status)
          }
        }
      )
    })

  /**
   * See google's docs on more possible option parameters:
   *
   * - https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderRequest
   * - https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingRequests
   *
   * @param {{placeId?: any, location?: any}} options
   */
  geocodeAndFormatResult = async options => {
    try {
      const results = await this.geocodeAsync(options)
      const firstResult = results[0]

      const addressComponents = firstResult.address_components

      const largeLabelComponent = addressComponents.find(component =>
        component.types.includes('administrative_area_level_1')
      )
      const largeLabel = largeLabelComponent
        ? largeLabelComponent.short_name
        : 'Unnamed'

      const detailLabelComponent = addressComponents.find(
        component =>
          component.types.includes('locality') ||
          component.types.includes('sublocality')
      )
      const detailLabel = detailLabelComponent
        ? detailLabelComponent.short_name
        : null

      const isState = addressComponents[0].types.includes(
        'administrative_area_level_1'
      )

      /** @type {PlaceResult} */
      const placeResult = {
        largeLabel,
        detailLabel,
        isState,
        ...(!isState && {
          latitude: firstResult.geometry.location.lat(),
          longitude: firstResult.geometry.location.lng(),
        }),
      }

      return placeResult
    } catch (e) {
      this.rootStore.errorStore.addError(`Error during geocoding`, {
        report: true,
        context: {
          options,
          error: e.message,
        },
      })
      return null
    }
  }

  getCurrentPositionAsync = (options = {}) =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    })

  findUserLocation = async () => {
    if (!this.google) {
      await this.loadGoogle()
      if (!this.google) throw new Error('invariant: expected google')
    }

    if (!navigator.geolocation) {
      this.rootStore.errorStore.addError(
        'Sorry, finding your current location is not available',
        { report: false }
      )
    }

    try {
      const position = await this.getCurrentPositionAsync()
      const latlng = new this.google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      )
      return await this.geocodeAndFormatResult({ location: latlng })
    } catch (e) {
      let msg = `Failed to load your location: ${e.message}`
      let report = false

      if (e.code) {
        const positionError = /** @type {PositionError} */ (e)
        switch (positionError.code) {
          case positionError.PERMISSION_DENIED:
            msg =
              'Permission denied, please configure your browser to allow geolocation'
            break
          case positionError.POSITION_UNAVAILABLE:
            msg = 'Position not available'
            break
          case positionError.TIMEOUT:
            msg = 'Timed out waiting for position'
            break
        }
      } else {
        report = true
      }

      this.rootStore.errorStore.addError(msg, {
        report,
        context: {
          message: e.message,
        },
      })
      return null
    }
  }

  reset() {
    this.suggestions.clear()
  }

  @computed
  get filteredSuggestions() {
    return this.suggestions.filter(suggestion => !isCountry(suggestion))
  }
}

/** @param {google.maps.places.AutocompletePrediction} autocompletePrediction */
const isCountry = autocompletePrediction =>
  autocompletePrediction.types.includes('country')

export default PlacesStore
