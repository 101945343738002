import { navigate } from '@reach/router'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Box from 'src/components/Box'
import { PrimaryButton } from 'src/components/Button'
import { ButtonUnderlinedH2 } from 'src/components/ButtonUnderlined'
import CenteredContainer from 'src/components/CenteredContainer'
import CloseButton from 'src/components/CloseButton'
import { FixedBottom } from 'src/components/Fixed'
import Flex from 'src/components/Flex'
import { LoadAssignCard } from 'src/components/LoadCard'
import LoadingScreen from 'src/components/Loading'
import Modal from 'src/components/Modal'
import PageMeta from 'src/components/PageMeta'
import { Screen } from 'src/components/Screen'
import { Body1, Body2 } from 'src/components/Text'
import FilteredDriverList from 'src/containers/FilteredDriverList'
import { loadAssignDetailsPath, loadPath } from 'src/paths'

/**
 * @typedef Props
 * @property {import('src/stores/LoadStore').default} loadStore
 * @property {import('src/stores/DriverStore').default} driverStore
 * @property {import('src/stores/UnassignReasonStore').default} unassignReasonStore
 * @property {import('src/stores/FlashMessageStore').default} flashMessageStore
 * @property {string} loadId
 *
 * @typedef State
 * @property {boolean} isCancelModalOpen
 *
 * @augments {Component<Props & import('@reach/router').History, State>}
 */
@inject('loadStore', 'driverStore', 'unassignReasonStore', 'flashMessageStore')
@observer
class LoadAssignDriver extends Component {
  state = {
    isCancelModalOpen: false,
  }

  componentDidMount() {
    this.props.loadStore.fetchLoad(parseInt(this.props.loadId))
  }

  handleSubmit = async () => {
    const loadId = parseInt(this.props.loadId)

    if (await this.props.driverStore.submitAssignLoadDriverForm(loadId)) {
      this.props.navigate(loadAssignDetailsPath(loadId))
    }
  }

  handleCancel = async () => {
    const isCancelled = await this.props.unassignReasonStore.submitCancellation(
      parseInt(this.props.loadId),
      /* use 'AE' as hard-coded reason meaning Assigned in Error */
      'AE'
    )

    if (isCancelled) {
      this.setState({ isCancelModalOpen: false })
      navigate(loadPath(this.props.loadId))
    } else {
      this.props.flashMessageStore.add(
        `Could not cancel load ${this.props.loadId}`
      )
    }
  }

  render() {
    const loadId = parseInt(this.props.loadId)
    const load = this.props.loadStore.load(loadId)
    const form = this.props.driverStore.loadDriverForm

    if (!load) return <LoadingScreen />

    return (
      <Screen>
        <PageMeta title={`Assign Driver`} section={`Load ${load.id}`} />
        <LoadAssignCard
          load={load}
          stepCount={2}
          stepDescription="Assign a driver"
          title="Almost Done"
          onRequestClose={() => this.setState({ isCancelModalOpen: true })}
        />

        <CenteredContainer width="600" breakpoint="large">
          <Box p={3}>
            <CenteredContainer centerText={true} breakpoint="large">
              <Body2 mb={2}>
                We just need you to complete a few details before you hit the
                road.
              </Body2>

              <Body1>Assign a driver</Body1>
              <ButtonUnderlinedH2
                onClick={() =>
                  this.props.navigate(loadAssignDetailsPath(load.id))
                }
              >
                SKIP AND ASSIGN LATER
              </ButtonUnderlinedH2>
            </CenteredContainer>
          </Box>

          <FilteredDriverList
            driverStore={this.props.driverStore}
            onDriverSelected={selectedDriver => {
              form.update({
                driverId: selectedDriver ? selectedDriver.driverId : null,
              })
            }}
            selectedDriverId={form.values.driverId}
          />

          {form.values.driverId !== null && (
            <FixedBottom bg="white" p={2}>
              <Flex justifyContent="center">
                <PrimaryButton
                  onClick={this.handleSubmit}
                  disabled={form.isSubmitting}
                  data-test="loadAssignDriverSubmit"
                >
                  Assign Driver
                </PrimaryButton>
              </Flex>
            </FixedBottom>
          )}
        </CenteredContainer>

        <Modal
          isOpen={this.state.isCancelModalOpen}
          onRequestClose={() => this.setState({ isCancelModalOpen: false })}
        >
          <Flex justifyContent="flex-end" pt={2} pr={2}>
            <CloseButton
              variant="black"
              onClick={() => this.setState({ isCancelModalOpen: false })}
            />
          </Flex>
          <Flex
            pl={3}
            pr={3}
            pb={4}
            lpl={4}
            lpr={4}
            bg="white"
            flexDirection="column"
          >
            <Box m={2} lm={4}>
              <Body2>
                Are you sure you want to cancel? This load will become available
                to other drivers if you cancel.
              </Body2>
            </Box>
            <Box mt={4}>
              <PrimaryButton onClick={this.handleCancel}>
                Yes, please cancel
              </PrimaryButton>
            </Box>
          </Flex>
        </Modal>
      </Screen>
    )
  }
}

export default LoadAssignDriver
