import React from 'react'
import cn from 'classnames'
import styles from './PickupDropoff.css'

/**
 * @param {{ indicatorColor?: 'blue'|'gray'|'clear' } & React.HTMLAttributes} props
 */
const PickupDropoff = ({ children, indicatorColor = 'clear', ...props }) => (
  <div className={styles.PickupDropoffRow} {...props}>
    <div className={cn(styles.Indicator, styles[`theme-${indicatorColor}`])} />
    <div className={styles.CityState}>{children}</div>
  </div>
)

export default PickupDropoff
