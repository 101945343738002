import React, { Component } from 'react'
import { navigate } from '@reach/router'
import Wrapper from 'src/components/Wrapper'
import PageMeta from 'src/components/PageMeta'
import { inject, observer } from 'mobx-react'
import MyAccountTabNav from 'src/components/MyAccountTabNav'
import Box from 'src/components/Box'
import { Title } from 'src/components/Text'
import Flex from 'src/components/Flex'
import { PrimaryButton } from 'src/components/Button'
import LoadingScreen, { LoadingHeading } from 'src/components/Loading'
import { TruckCardButton } from 'src/components/TruckCard'
import pluralize from 'src/util/pluralize'
import { settingsEditHaulerPath, settingsAddHaulerPath } from 'src/paths'
import CenteredContainer from 'src/components/CenteredContainer'

/**
 * @typedef {object} Props
 * @property {import('src/stores/TruckStore').default} truckStore
 *
 * @augments {Component<Props>}
 */
@inject('truckStore')
@observer
class SettingsFleet extends Component {
  componentDidMount() {
    this.props.truckStore.fetchCarrierTrucks()
  }

  async removeTruck(truck) {
    await this.props.truckStore.deleteTruck(truck)
    await this.props.truckStore.fetchCarrierTrucks()
  }

  render() {
    const { carrierTrucks, isTrucksLoading } = this.props.truckStore

    if (!carrierTrucks) return <LoadingScreen />
    const carrierTruckCount = carrierTrucks.reduce(
      (acc, truck) => acc + truck.quantity,
      0
    )

    return (
      <Wrapper bg="light-gray">
        <PageMeta title="Drivers and Fleet" section="Account" />

        <MyAccountTabNav />

        <Box p={3} bg="black" color="white">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Title mb={2}>
              {carrierTruckCount} {pluralize(carrierTruckCount, 'Truck')}
            </Title>

            <PrimaryButton
              variant="outline"
              onClick={() => navigate(settingsAddHaulerPath())}
            >
              Add a Truck
            </PrimaryButton>
          </Flex>
        </Box>

        <CenteredContainer width="900">
          <Box p={3}>
            {isTrucksLoading ? (
              <LoadingHeading />
            ) : (
              carrierTrucks.map((truck, i) => (
                <TruckCardButton
                  truck={truck}
                  key={i}
                  bg="white"
                  onClick={() => {
                    navigate(settingsEditHaulerPath(truck.carrierTruckId))
                  }}
                />
              ))
            )}
          </Box>
        </CenteredContainer>
      </Wrapper>
    )
  }
}

export default SettingsFleet
