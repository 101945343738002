import React from 'react'
import Wrapper from 'src/components/Wrapper'
import PageMeta from 'src/components/PageMeta'
import Box from 'src/components/Box'
import { Body2, H1, H2Bold } from 'src/components/Text'
import HeaderWithBackButton from 'src/components/HeaderWithBackButton'

const LegalInformation = () => (
  <Wrapper bg="light-gray">
    <PageMeta title="Legal Information" section="Account" />

    <HeaderWithBackButton label="Legal Information" />
    <Box p={3}>
      <H1 mb={2}>UNITED ROAD Terms of Use, License, and Privacy Policy</H1>
      <Body2 mb={2}>
        THIS IS A BINDING AGREEMENT BETWEEN YOU AND UNITED ROAD. BY ACCEPTING
        THESE TERMS OF USE AND LICENSE OR USING THE UNITED ROAD APP AS DEFINED
        BELOW, YOU ARE AGREEING TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE,
        DO NOT USE THE UNITED ROAD APP.
      </Body2>

      <Body2 mb={2}>
        This license and terms of use (the &quot;Terms of Use&quot;) apply to
        the website and the mobile application that is the property of, or
        licensed to, URS Midwest, Inc. with offices at 41100 Plymouth Road, 4th
        Floor, Plymouth, MI 48170 (&quot;UNITED ROAD&quot;). The website and the
        mobile application are collectively referred to in these Terms of Use as
        the &quot;UNITED ROAD App.&quot; &quot;You&quot; means you individually
        and the entity for whom you act as an authorized representative,
        employee or agent. That entity or organization is referred to as your
        employer or contracting party.
      </Body2>

      <H2Bold>License to Use the UNITED ROAD App</H2Bold>

      <Body2 mb={2}>
        As long as you are authorized by your employer or contracting party to
        use the UNITED ROAD App and you comply with these Terms of Use, UNITED
        ROAD grants you a personal, non-exclusive, non-transferable, limited
        privilege to use the UNITED ROAD App. Your use of the services offered
        by UNITED ROAD through its UNITED ROAD App is subject to an agreement
        between your employer or contracting party and UNITED ROAD. You may use
        the UNITED ROAD App solely for the purposes authorized by your employer
        or contracting party. The UNITED ROAD App is not intended for the use of
        children under 18 or anyone not authorized by a customer of UNITED ROAD
        and no such person is authorized to use it.
      </Body2>

      <Body2 mb={2}>
        UNITED ROAD reserves the right, at its sole discretion, to (1) modify
        these Terms of Use at any time, (2) modify, suspend or terminate
        operation of or your access to the UNITED ROAD App for any reason; (3)
        modify or change the UNITED ROAD App, or (4) interrupt the operation of
        the UNITED ROAD App as necessary to perform routine or non-routine
        maintenance, error correction, or other changes. Your continued use of
        the UNITED ROAD App following the implementation of changes indicates
        your agreement to and acceptance of the changes to the updated Terms of
        Use.
      </Body2>

      <H2Bold>Restrictions on Your Use of the UNITED ROAD App</H2Bold>

      <Body2 mb={2}>
        You may not (1) attempt to gain unauthorized access to any portion or
        feature of the UNITED ROAD App or any other systems or networks
        connected to the UNITED ROAD App, to any UNITED ROAD server, or to any
        of the services offered on or through the UNITED ROAD App through any
        means, (2) probe, scan or test the vulnerability of the UNITED ROAD App,
        (3) breach the security or authentication measures on the UNITED ROAD
        App, (4) attempt to access any information of any other user of the
        UNITED ROAD App or any other customer of UNITED ROAD, (5) use any
        device, software or routine to interfere or attempt to interfere with
        the proper working of the UNITED ROAD App or with any other
        person&rsquo;s use of the UNITED ROAD App, (6) use the UNITED ROAD App
        or any Content for any purpose that is unlawful or prohibited by these
        Terms of Use, or (7) solicit the performance of any illegal activity or
        other activity which infringes the rights of UNITED ROAD or others.
      </Body2>

      <H2Bold>Accounts, Passwords, and Security</H2Bold>

      <Body2 mb={2}>
        Before you use the UNITED ROAD App, you will either be required to
        register and set up a user ID and password and enter a company code, or
        to use credentials provided by your employer or contracting party. You
        are entirely responsible for maintaining the confidentiality of the
        information you hold for your account, including your password. You are
        responsible for all activity that occurs under your account by others if
        you fail to keep this information secure and confidential. You agree to
        notify UNITED ROAD immediately of any unauthorized use of your account
        or password, or any other breach of security.
      </Body2>

      <Body2 mb={2}>
        You may not use anyone else&rsquo;s user ID, password, company code or
        account at any time without the express permission and consent of the
        holder of that user ID, password, company code or account and the
        express consent of your employer or contracting party. UNITED ROAD
        cannot and will not be liable for any loss or damage arising from your
        failure to comply with these obligations.
      </Body2>

      <H2Bold>Privacy</H2Bold>

      <Body2 mb={2}>
        UNITED ROAD&rsquo;s agreement with your employer or contracting party
        governs the privacy and use of the information that UNITED ROAD may
        collect from users. You acknowledge that you are using the UNITED ROAD
        App for the sole purposes authorized by your employer or contracting
        party and acknowledge that no information related to your use of the
        UNITED ROAD App is your private information but rather is owned by your
        employer or contracting party.
      </Body2>

      <H2Bold>Content</H2Bold>

      <Body2 mb={2}>
        The UNITED ROAD App, including all text, graphics, interfaces and
        computer code (collectively, &quot;Content&quot;), including but not
        limited to the design, structure, selection, coordination, expression,
        &quot;look and feel&quot; and arrangement of such Content, contained in
        the UNITED ROAD App is owned, controlled or licensed by or to UNITED
        ROAD, and is protected by trade dress, copyright, patent and trademark
        laws, and various other intellectual property rights and unfair
        competition laws. Except as expressly provided in these Terms of Use, no
        part of the UNITED ROAD App or any Content may be copied, reproduced,
        republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted or distributed in any way to any other computer, server,
        website or disclosed by you to any other person.
      </Body2>

      <H2Bold>Disclaimers</H2Bold>

      <Body2 mb={2}>
        The UNITED ROAD app is provided &quot;As Is, As Available.&quot; UNITED
        ROAD does not promise that the UNITED ROAD App or any content, service
        or feature of the UNITED ROAD App will be error-free or uninterrupted,
        or that any defects will be corrected, or that your use of the UNITED
        ROAD App will provide specific results. All information provided on the
        UNITED ROAD App is subject to change without notice. UNITED ROAD cannot
        ensure that any files or other data you transmit through the UNITED ROAD
        App will be free of viruses or contamination or destructive features.
      </Body2>

      <Body2 mb={2}>
        ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF
        ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
        PURPOSE, ARE HEREBY EXCLUDED TO THE EXTENT ALLOWED BY APPLICABLE LAW.
        UNITED ROAD DISCLAIMS ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT
        OF ANY THIRD PARTIES ABOUT OR RELATED TO YOUR USE OF THE UNITED ROAD
        APP. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE UNITED ROAD APP
        AND YOUR SOLE REMEDY AGAINST UNITED ROAD MOBILE FOR DISSATISFACTION WITH
        THE UNITED ROAD APP OR ANY CONTENT IS TO STOP USING THE UNITED ROAD APP.
      </Body2>

      <H2Bold>Limitation of Liability</H2Bold>

      <Body2 mb={2}>
        UNITED ROAD HAS NO LIABILITY TO YOU FOR ANY REASON. ALL AGREEMENTS
        REGARDING REMEDIES OR LIABILITY ARE BETWEEN UNITED ROAD AND YOUR
        EMPLOYER OR CONTRACTING PARTY.
      </Body2>

      <H2Bold>Limitation on Damages</H2Bold>

      <Body2 mb={2}>
        IN NO EVENT AND UNDER NO LEGAL THEORY, WHETHER TORT, CONTRACT OR
        OTHERWISE, WILL UNITED ROAD OR ITS THIRD PARTY LICENSORS BE LIABLE TO
        YOU FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL OR
        PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOSSES RELATING TO
        INTERRUPTION OF BUSINESS ACTIVITIES OR LOSS OF EVEN IF UNITED ROAD HAS
        BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES.
      </Body2>

      <H2Bold>Indemnity</H2Bold>

      <Body2 mb={2}>
        You agree to indemnify and hold UNITED ROAD, its officers, directors,
        shareholders, predecessors, successors in interest, employees, agents,
        subsidiaries and affiliates, harmless from any demands, loss, liability,
        claims or expenses (including attorneys&rsquo; fees), made against
        UNITED ROAD by any third party due to or arising out of or in connection
        with (1) your access to or use of the UNITED ROAD App; (2) your
        violation of these Terms of Use or any applicable law or regulation, (3)
        your violation of any rights of any third party; or (4) any disputes or
        issues between you and any third party. You will pay UNITED ROAD the
        cost of defending the claim (including reasonable attorney&rsquo;s fees
        and expenses) and any damages awarded or fines imposed on UNITED ROAD.
      </Body2>

      <H2Bold>Violation of These Terms of Use</H2Bold>

      <Body2 mb={2}>
        UNITED ROAD may disclose any information we have about you (including
        your identity) if we determine that such disclosure is necessary about
        any investigation or complaint regarding your use of the UNITED ROAD App
        or to identify, contact or bring legal action against someone who may be
        causing injury to or interference with (either intentionally or
        unintentionally) UNITED ROAD&rsquo;s rights or property or the rights
        UNITED ROAD customers. UNITED ROAD also may disclose your information
        when UNITED ROAD determines that applicable law requires or permits such
        disclosure, including exchanging information with other companies and
        organizations for fraud protection purposes.
      </Body2>

      <Body2 mb={2}>
        You agree that UNITED ROAD may, in its sole discretion and without prior
        notice, terminate your access to the UNITED ROAD App and/or block your
        future access to the UNITED ROAD App if requested by your employer or
        contracting party or if we determine that you have violated these Terms
        of Use or other agreements or guidelines which may be associated with
        your use of the UNITED ROAD App. If your license to use the UNITED ROAD
        App is terminated, you will cooperate with UNITED ROAD and your employer
        and contracting party to remove any their information from your computer
        or mobile device. You also agree that any violation by you of these
        Terms of Use will constitute an unlawful and unfair business practice,
        and will cause irreparable harm to UNITED ROAD for which monetary
        damages would be inadequate. You consent to UNITED ROAD obtaining any
        injunctive or equitable relief that UNITED ROAD deems necessary or
        appropriate in such circumstances. These remedies are in addition to any
        other remedies UNITED ROAD may have at law or in equity.
      </Body2>

      <H2Bold>Governing Law; Dispute Resolution</H2Bold>

      <Body2 mb={2}>
        You agree that all matters relating to your access to or use of the
        UNITED ROAD App including all disputes, will be governed by the laws of
        the United States and by the laws of the State of Michigan excluding:
        (1) its conflicts of law principles; and (2) the United Nations
        Convention on Contracts for the International Sale of Goods. You agree
        to the personal jurisdiction by, and venue in, the state and federal
        courts in Michigan, and waive any objection to such jurisdiction or
        venue. Any claim under these Terms of Use must be brought within one (1)
        year after the cause of action arises, or such claim or cause of action
        is barred. Claims made under the separate terms and conditions of
        purchase for goods and services may be subject to different limitations.
        You agree to the admissibility of computer records and electronic
        evidence in any dispute involving these Terms of Use. No class action
        may be pursued or maintained with respect to any dispute regarding these
        Terms of Use.
      </Body2>

      <H2Bold>Users Outside of the United States</H2Bold>

      <Body2 mb={2}>
        UNITED ROAD administers and operates the UNITED ROAD App from its
        location in the United States of America. Although the UNITED ROAD App
        may be accessible outside of the United States, not all features,
        products or services discussed, referenced, provided or offered through
        or on the UNITED ROAD App are available to all persons or in all
        geographic locations, or appropriate or available for use outside the
        United States. If you choose to access the UNITED ROAD App from outside
        the United States, you may only do so if authorized by your employer or
        contracting party and you are solely responsible for complying with
        applicable local laws.
      </Body2>

      <H2Bold>Miscellaneous</H2Bold>

      <Body2 mb={2}>
        All provisions of these Terms of Use are severable, and the
        unenforceability or invalidity of any of the provisions will not affect
        the enforceability or validity of the remaining provisions. These Terms
        of Use constitute the entire agreement between you and UNITED ROAD about
        your use of the UNITED ROAD App and all other written or oral agreements
        or understandings previously existing between you and UNITED ROAD with
        respect to such use are hereby superseded except to the extent that the
        agreement between UNITED ROAD and your employer or contracting party
        provides otherwise.
      </Body2>
    </Box>
  </Wrapper>
)

export default LegalInformation
